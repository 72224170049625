import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import { IUploadsContext } from "../../../@types";
import initialData from "../initialState";

export enum IUploadsActionTypes {
  SET_SELECTED_SUSA = "SET_SELECTED_SUSA",
  SET_MAPPED_SUSA = "SET_MAPPED_SUSA",
  NUKE_UPLOADS_CONTEXT = "NUKE_UPLOADS_CONTEXT",
  SET_DATA_STORE = "SET_DATA_STORE",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  IUploadsActionTypes,
  ActionCreatorWithPayload<Partial<IUploadsContext>, IUploadsActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(IUploadsActionTypes) as Array<keyof typeof IUploadsActionTypes>) {
  actionFunctions[IUploadsActionTypes[key]] = createAction(IUploadsActionTypes[key]);
}

// export interface IUploadsPayload extends IUploadsContext {

// }

export interface IUploadsAction {
  type: IUploadsActionTypes;
  payload?: Partial<IUploadsContext>;
}

const uploads = createReducer(initialData.uploads, (builder) => {
  builder
    .addCase(actionFunctions[IUploadsActionTypes.SET_SELECTED_SUSA], (state, action) => {
      if (!action.payload?.selectedSusa) return state;
      state.selectedSusa = action.payload.selectedSusa;
    })
    .addCase(actionFunctions[IUploadsActionTypes.SET_MAPPED_SUSA], (state, action) => {
      if (!action.payload?.mappedSusa) return state;
      state.mappedSusa = action.payload.mappedSusa;
    })
    .addCase(actionFunctions[IUploadsActionTypes.NUKE_UPLOADS_CONTEXT], (state, action) => {
      return initialData.uploads;
    })
    .addCase(actionFunctions[IUploadsActionTypes.SET_DATA_STORE], (state, action) => {
      if (!action.payload?.dataStore) return state;
      state.dataStore = action.payload.dataStore;
    });
});

export default uploads;
