import { FC, useContext, useEffect, useState } from "react";
import { ButtonVariantEnums, IconButtonProps } from "../Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import styled, { css } from "styled-components/macro";
import { StyledIconButton } from "../Button/styled.icon-button";
import { FormContext, FormDispatch } from "../Form";
import { rgba } from "polished";
export enum ECheckboxState {
  ON = "ON",
  OFF = "OFF",
}
export interface ICheckBoxProps extends Omit<IconButtonProps, "icon" | "type" | "onClick"> {
  checked?: ECheckboxState.ON | ECheckboxState.OFF;
  onChange?: (state: ECheckboxState) => void;
}

export const StyledCheckBox = styled.div<Partial<Omit<ICheckBoxProps, "onChange">>>(
  ({ theme, variant = ButtonVariantEnums.SECONDARY, inverted }) => {
    return css`
      display: inline-flex;
      align-items: center;
      align-content: flex-start;
    `;
  }
);
export const StyledCheckBoxButton = styled(StyledIconButton)<
  Partial<Omit<ICheckBoxProps, "onChange">>
>(({ theme, variant = ButtonVariantEnums.SECONDARY }) => {
  const { radius, color } = theme.branded.buttons[variant];
  return css`
    align-self: flex-start;
    border: 2px solid;
    border-radius: ${radius};
    display: inline-block;
    padding: ${theme.rem(0.25)};
    &:active {
      transition: all 300ms;
      transform: scale(0.9);
      background-image: linear-gradient(
        135deg,
        ${rgba(color.base.background, 0.1)} 5%,
        ${rgba(color.base.background, 0.2)} 50%,
        ${rgba(color.base.background, 0.1)} 95%
      );
    }
  `;
});
export const StyledCheckBoxText = styled.div<Partial<ICheckBoxProps>>(
  ({ theme, variant = ButtonVariantEnums.SECONDARY, inverted, disabled }) => {
    return css`
      padding-left: ${theme.rem(0.5)};
      cursor: ${disabled ? "default" : "pointer"};
    `;
  }
);

export const CheckBox: FC<ICheckBoxProps> = ({
  children,
  name,
  onChange,
  checked = ECheckboxState.OFF,
  ...props
}) => {
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);
  const [isChecked, setIsChecked] = useState(checked);
  const handleToggleCheck = (): void => {
    if (props.disabled) return;

    const newValue = isChecked === ECheckboxState.ON ? ECheckboxState.OFF : ECheckboxState.ON;
    onChange && onChange(newValue);
    setIsChecked(newValue);
  };
  useEffect(() => {
    if (!name || formContext[name] === isChecked) return;
    formDispatch({ [name]: isChecked });
  }, [name, isChecked, formContext, formDispatch]);
  return (
    <StyledCheckBox onClick={handleToggleCheck} {...props}>
      <StyledCheckBoxButton {...props}>
        <Icon
          name={isChecked === ECheckboxState.ON ? IconNameEnums.CHECK : IconNameEnums.BLANK}
          size={IconSizeEnums.XSMALL}
        />
      </StyledCheckBoxButton>
      <StyledCheckBoxText {...props}>{children}</StyledCheckBoxText>
    </StyledCheckBox>
  );
};
