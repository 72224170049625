import { IconButton, IconButtonProps } from "../Button";
import { StyledInputButton } from "./styled.input-button";
import { FC } from "react";

export const InputButton: FC<IconButtonProps> = ({ ...props }) => {
  return (
    <StyledInputButton>
      <IconButton {...props} />
    </StyledInputButton>
  );
};
