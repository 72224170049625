import { useDialogs } from "../useDialogs";
import { Trans, useTranslation } from "react-i18next";
import { AlignmentEnums, IDialogKeyEnums, EPlanningPage } from "../../../@types/index.d";
import { FormRow } from "../../../components/formElements/Form";
import { Button, ButtonVariantEnums } from "../../../components/formElements/Button";
import { usePlanningHelper } from "./usePlanningHelper";

interface NoPlanFountReturnValue {
  open: () => void;
  close: () => void;
}
type TUseNoPlanFoundWarning = () => NoPlanFountReturnValue;
export const useNoPlanFoundWarning: TUseNoPlanFoundWarning = () => {
  const dialogs = useDialogs();
  const { switchPlanningPage } = usePlanningHelper();
  const { t } = useTranslation();
  const handleCloseDialog = (): void => {
    dialogs.close(IDialogKeyEnums.NO_PLAN_WARNING);
    switchPlanningPage(EPlanningPage.CREATE);
  };
  const open = (): void => {
    // if (history.location.pathname !== EPrivatePathName.PLAN) return;
    //TODO: this dialog should be shown in all views.
    // get state of available plans after initial upload from TransactionEventListener
    // and show this dialog in all views.
    dialogs.open({
      dialogKey: IDialogKeyEnums.NO_PLAN_WARNING,
      title: t("dialogs.no_plan.title"),
      content: <Trans i18nKey={"dialogs.no_plan.text"} />,
      footer: (
        <FormRow align={AlignmentEnums.RIGHT}>
          <Button
            variant={ButtonVariantEnums.SECONDARY}
            inverted={false}
            type={"button"}
            onClick={handleCloseDialog}
            inline={true}
          >
            {t("planning.options.create_new_plan")}
          </Button>
        </FormRow>
      ),
    });
  };
  const close = (): void => {
    dialogs.close(IDialogKeyEnums.NO_PLAN_WARNING);
  };
  return {
    open,
    close,
  };
};
