import {
  createContext,
  CSSProperties,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { StyledListWrapper, StyledSelectWrapper } from "./styled.select";
import {
  IDialogKeyEnums,
  IDialogOptions,
  InputTypes,
  ISelectProps,
  SelectionTypes,
} from "../../../@types/index.d";
import { InputConstructor } from "../Input/InputConstructor";

import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import { useDialogs } from "../../../_lib/hooks";
import { InputButton } from "../Input/InputButton";
import { ButtonVariantEnums } from "../Button";
import { FormContext, FormDispatch } from "../Form";

interface SelectAction {
  text: string;
  value: string;
  open: boolean;
}

export const SelectContext = createContext({ text: "", value: "" } as SelectAction);
export const SelectDispatch = createContext<Dispatch<SelectAction>>(() => {
  // initial dispatch function
});
export const selectTestID = "Select";
export const selectListTestID = `${selectTestID}List`;

export const Select: FC<ISelectProps> = ({
  children,
  name,
  text,
  label,
  type,
  value,
  isOpen = false,
  onChange,
  valid,
  invalidWarning,
  testID = "",
  ...rest
}) => {
  const selectListRef = useRef<HTMLDivElement>(null);
  const UListRef = useRef<HTMLUListElement>(null);

  const [listPosition, setListPosition] = useState<CSSProperties | undefined>();

  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);
  const dialogs = useDialogs();

  function reduceSelection(state: SelectAction, action: Partial<SelectAction>): SelectAction {
    return { ...state, ...action };
  }

  const initializerArg: SelectAction = {
    value,
    text,
    open: isOpen,
  };

  const [select, selectDispatcher] = useReducer(reduceSelection, initializerArg);

  useEffect(() => {
    if (formContext[name] !== select.value) formDispatch({ [name]: select?.value || "" });
    if (select.text !== initializerArg.text || select.value !== initializerArg.value) {
      if (type === SelectionTypes.DIALOG) {
        selectDispatcher({
          text,
          value,
        });
      }
    }
  }, [
    formContext,
    formDispatch,
    initializerArg.text,
    initializerArg.value,
    name,
    select.text,
    select.value,
    text,
    type,
    value,
  ]);

  useLayoutEffect(() => {
    const closeOnOuterClick = (e: MouseEvent): void => {
      if (selectListRef === null) return;
      if (selectListRef.current === null) return;
      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(
        selectListRef.current === e.target || selectListRef.current.contains(e.target as Node)
      );

      isOuter && select.open && selectDispatcher({ open: false });
    };

    if (select.open) {
      window.document
        .querySelector("body")
        ?.addEventListener("mouseup", closeOnOuterClick, { capture: true });
      return (): void => {
        window.document
          .querySelector("body")
          ?.removeEventListener("mouseup", closeOnOuterClick, { capture: true });
      };
    }
  }, [select]);
  useEffect(() => {
    !select.open && type === SelectionTypes.LIST && onChange && onChange({ name, ...select });
  }, [select, onChange, name, type]);

  const rePositionList = useCallback(() => {
    if (type !== SelectionTypes.LIST || !select.open) return;
    const selectListRect = selectListRef?.current?.getBoundingClientRect();
    const UListRect = UListRef?.current?.getBoundingClientRect();
    if (selectListRect === undefined) return;
    if (UListRect === undefined) return;

    if (selectListRect.x + UListRect.width > window.innerWidth) {
      setListPosition({
        right: 0,
        left: "unset",
      });
    } else {
      setListPosition({
        left: 0,
        right: "unset",
      });
    }
  }, [select.open, type]);

  useEffect(() => {
    window.addEventListener("scroll", rePositionList);
    window.addEventListener("resize", rePositionList);
    return (): void => {
      window.removeEventListener("scroll", rePositionList);
      window.removeEventListener("resize", rePositionList);
    };
  }, [rePositionList]);
  useEffect(() => {
    rePositionList();
  }, [rePositionList]);

  const handleOpenList = (): void => {
    if (type === SelectionTypes.LIST) {
      selectDispatcher({
        open: true,
      });
    } else if (type === SelectionTypes.DIALOG) {
      showListInDialog();
    }
  };
  const handleToggleList = (): void => {
    if (rest.disabled) return;
    if (type === SelectionTypes.LIST) {
      selectDispatcher({
        open: !select.open,
      });
    } else {
      showListInDialog();
    }
  };
  const showListInDialog = (): void => {
    const dialog: IDialogOptions = {
      title: label,
      content: <>{children}</>,
      flexible: false,
      footer: <></>,
      closable: true,
      onClose: (): void => {
        //
      },
      dialogKey: IDialogKeyEnums.SELECT_LIST,
    };
    dialogs.open(dialog);
  };

  const ListVariant = {
    Wrapper: StyledListWrapper,
  };

  return (
    <StyledSelectWrapper ref={selectListRef} data-test-id={testID + selectTestID}>
      <SelectDispatch.Provider value={selectDispatcher}>
        <SelectContext.Provider value={{ ...select }}>
          <InputConstructor
            {...rest}
            name={`${name}_text`}
            value={select.text}
            type={InputTypes.TEXT}
            label={label}
            selectionType={type}
            valid={valid}
            invalidWarning={invalidWarning}
            onClick={handleOpenList}
            readOnly={true}
          >
            <InputButton
              icon={
                <Icon
                  name={
                    type === SelectionTypes.LIST
                      ? select.open
                        ? IconNameEnums.CARET_UP
                        : IconNameEnums.CARET_DOWN
                      : IconNameEnums.ELLIPSIS_H
                  }
                  size={IconSizeEnums.SMALL}
                />
              }
              inverted={false}
              variant={ButtonVariantEnums.SECONDARY}
              type={"button"}
              onClick={handleToggleList}
              disabled={rest.disabled}
            />

            {select.open && (
              <ListVariant.Wrapper
                ref={UListRef}
                data-test-id={testID + selectTestID + selectListTestID}
                style={listPosition}
              >
                {children}
              </ListVariant.Wrapper>
            )}
          </InputConstructor>
        </SelectContext.Provider>
      </SelectDispatch.Provider>
    </StyledSelectWrapper>
  );
};
