import { Dispatch, FC, Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  CustomerState,
  EDataSyncLevel,
  EPlanningPage,
  EPrivatePathName,
  EPublicPathName,
  ILocalState,
  SelectClientState,
} from "../../@types/index.d";

import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import { ErrorPage } from "../Public/ErrorPage";
import { lazy } from "@loadable/component";
import { FadeIn } from "../../_lib/Transitions";
import { useDispatch, useSelector } from "react-redux";
import { useClientSelectorUI, useDataSyncByLevel, usePlans } from "../../_lib/hooks";
import Dashboard from "./Dashboard";
import config from "../../config";
import { useDownloadsListener } from "../../_lib/hooks/download/useDownloadsListener";
import { isEqual } from "lodash";
import { ESubscriptionStatus } from "@canei/app-components";
import {
  EAppStoreActionType,
  IAppStoreAction,
  ICurrentUserAction,
  IEventsAction,
  IEventsActionTypes,
  IEventsState,
  UserStateActionTypes,
} from "../../_lib/store/reducers";
import { useAppStoreHelper } from "./AppStore/useAppStoreHelper";
import { EventListenerCodes } from "../../_lib/hooks/useEventListener/types.event-listener";
import { useCustomization } from "../../_lib/hooks/customization/useCustomization";
// isReady dashboard lazy has negative performance effect, as it's always required after login.
// const Dashboard = lazy(() => import("./Dashboard"));
const LogOut = lazy(() => import("./Logout"));
const Kpi = lazy(() => import("./Kpi"));
const Planning = lazy(() => import("./Planning"));
const Reports = lazy(() => import("./Reporting"));
const Company = lazy(() => import("./Company"));
const Upload = lazy(() => import("./Upload"));
const Branding = lazy(() => import("./Customization"));
const Account = lazy(() => import("./Account"));
const Setup = lazy(() => import("./Setup"));

export const Private: FC = () => {
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const dataSync = useSelector(({ dataSync }: ILocalState) => dataSync, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const eventsState = useSelector(({ eventsState }: ILocalState) => eventsState, isEqual);
  const planningLoadingStatus = useSelector(
    ({ planning: { planningLoadingStatus } }: ILocalState) => planningLoadingStatus,
    isEqual
  );
  const { content, customerData, paymentWall } = useSelector(
    (state: ILocalState) => state.appStore
  );
  const history = useHistory();
  const { callGetPlans } = usePlans();
  const clientSelectorUI = useClientSelectorUI();
  const { getAppStoreContent } = useAppStoreHelper();
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const dispatchEventsState = useDispatch<Dispatch<IEventsAction>>();
  const customizationList = useSelector(
    ({ customization: { customizationList } }: ILocalState) => customizationList,
    isEqual
  );
  const { getCustomizations, customizationLoading } = useCustomization();

  // get the content for the selected menu items
  useEffect(() => {
    if (!content) getAppStoreContent();
  }, [content, getAppStoreContent]);

  // get the customization meta
  useEffect(() => {
    if (customizationList && customizationList.length) return;
    !customizationLoading && getCustomizations();
  }, [customizationList, customizationLoading, getCustomizations]);

  // to remove the successful transactions from event state and cancel the loading spinner
  useEffect(() => {
    if (!eventsState) return;
    const existingTransactions = Object.entries(eventsState as IEventsState);

    // to find any transaction that is succeeded or timed out
    for (const event of existingTransactions) {
      if (
        event[1] === EventListenerCodes.TRANSACTION_OK ||
        event[1] === EventListenerCodes.TIME_OUT
      ) {
        dispatchEventsState({
          type: IEventsActionTypes.REMOVE_EVENT,
          payload: {
            event: {
              transactionId: event[0],
              value: "" as EventListenerCodes,
            },
          },
        });

        dispatchUser({
          type: UserStateActionTypes.EVENT_IN_PROGRESS,
          payload: { eventInProgress: false },
        });
        const isThereSelectedClient =
          currentUser?.clients?.find(
            ({ client_id }) => client_id === currentUser?.selectedClient?.client_id
          ) !== undefined;

        // to allow customer to select any client if selectedClient is deleted
        if (!isThereSelectedClient) {
          dispatchUser({
            type: UserStateActionTypes.SET_SELECTED_CLIENT_SELECTABLE,
            payload: {},
          });
        }
      }
      // if the transaction response shows an error, cancel the loading spinner
      else if (event[1] !== EventListenerCodes.UNKNOWN) {
        dispatchUser({
          type: UserStateActionTypes.EVENT_IN_PROGRESS,
          payload: { eventInProgress: false },
        });
      }
    }
  }, [
    dispatchEventsState,
    eventsState,
    dispatchUser,
    currentUser?.selectedClient?.client_id,
    currentUser?.clients,
  ]);

  // useeffect to call getPlans to make the dashboard initially ready
  useEffect(() => {
    if (!plans && !planningLoadingStatus && history.location.pathname !== EPrivatePathName.SETUP) {
      const isThereSelectedClient =
        currentUser?.clients?.find(
          ({ client_id }) => client_id === currentUser?.selectedClient?.client_id
        ) !== undefined;
      isThereSelectedClient && callGetPlans(currentUser?.selectedClient?.client_id as string);
    }
  }, [
    callGetPlans,
    currentUser?.selectedClient?.client_id,
    planningLoadingStatus,
    plans,
    history.location.pathname,
    currentUser?.clients,
  ]);

  const paymentRequired = useMemo<boolean>(() => {
    if (!customerData) return false;
    const now = Date.now() / 1000;
    if (
      customerData?.status !== ESubscriptionStatus.ACTIVE &&
      customerData?.status !== ESubscriptionStatus.TRIALING
    ) {
      if (
        customerData?.status === ESubscriptionStatus.CANCELED &&
        customerData?.canceled_at &&
        customerData?.canceled_at > now
      ) {
        return false;
      }

      return true;
    }
    if (
      customerData?.features &&
      customerData?.features.find((feature) => feature.status === "payment_required")
    )
      return true;

    return false;
  }, [customerData]);

  // to open the payment page
  useEffect(() => {
    if (paymentRequired && !paymentWall) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_PAYMENT_WALL,
        payload: { paymentWall: true },
      });
    }

    if (!paymentRequired && paymentWall) {
      dispatchAppStore({
        type: EAppStoreActionType.SET_PAYMENT_WALL,
        payload: { paymentWall: false },
      });
    }
  }, [dispatchAppStore, paymentRequired, paymentWall]);

  useDownloadsListener();
  useDataSyncByLevel(EDataSyncLevel.CLIENT);
  useDataSyncByLevel(EDataSyncLevel.CUSTOMER);
  useEffect(() => {
    if (currentUser.appUser.user_id === "") return;
    if (dataSync.CLIENT.CLIENT.pending || dataSync.CUSTOMER.CLIENT.pending) return;
    if (currentUser.selectedClient.state === SelectClientState.SELECTABLE) {
      clientSelectorUI.show();
    } else {
      clientSelectorUI.hide();
    }
  }, [
    clientSelectorUI,
    currentUser.appUser.user_id,
    currentUser.selectedClient.client_id,
    currentUser.selectedClient.state,
    dataSync.CLIENT.CLIENT.pending,
    dataSync.CUSTOMER.CLIENT.pending,
  ]);

  if (currentUser.selectedClient.state === SelectClientState.SELECTABLE || customizationLoading) {
    return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  }

  if (
    currentUser.selectedClient.state === SelectClientState.DRAFT ||
    currentUser.selectedClient.state === SelectClientState.INITIAL ||
    (currentUser.state === CustomerState.READY && currentUser.appUser.clients.length === 0)
  ) {
    return (
      <Switch>
        <Route exact path={EPrivatePathName.SETUP}>
          <Setup />
        </Route>
        <Route exact path="*">
          <FadeIn>
            <Redirect to={EPrivatePathName.SETUP} />
          </FadeIn>
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={EPublicPathName.HOME}>
        <Dashboard />
      </Route>
      <Route exact path={EPrivatePathName.LOGOUT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <FadeIn>
            <LogOut />
          </FadeIn>
        </Suspense>
      </Route>
      <Route exact path={EPrivatePathName.DASHBOARD}>
        <Dashboard />
      </Route>
      <Route path={[`${EPrivatePathName.KPI}/:kpiId`]}>
        <Kpi />
      </Route>
      <Route exact path={EPrivatePathName.KPI}>
        <Redirect to={`${EPrivatePathName.KPI}/${config.webapp.kpi}`} />
      </Route>
      <Route exact path={EPrivatePathName.PLAN}>
        <Planning type={EPlanningPage.LIST} />
      </Route>
      <Route exact path={EPrivatePathName.PLAN_CREATE}>
        <Planning type={EPlanningPage.CREATE} />
      </Route>
      <Route path={`${EPrivatePathName.PLAN_PREVIEW}/:planId`}>
        <Planning type={EPlanningPage.PREVIEW} />
      </Route>
      <Route exact path={`${EPrivatePathName.PLAN}/:planId/:subPage`}>
        <Planning type={EPlanningPage.DETAILS} />
      </Route>
      <Route path={`${EPrivatePathName.REPORTS}/:subPage`}>
        <Reports />
      </Route>
      <Route path={`${EPrivatePathName.COMPANY}/:subPage`}>
        <Company />
      </Route>
      <Route exact path={EPrivatePathName.UPLOAD}>
        <Upload />
      </Route>
      <Route exact path={`${EPrivatePathName.UPLOAD}/:subPage`}>
        <Upload />
      </Route>
      <Route exact path={EPrivatePathName.CUSTOMIZATION}>
        <Branding />
      </Route>
      <Route exact path={EPrivatePathName.ACCOUNT}>
        <Account />
      </Route>
      <Route path={`${EPrivatePathName.ACCOUNT}/:subPage`}>
        <Account />
      </Route>
      <Route exact path={EPrivatePathName.SETUP}>
        {currentUser.selectedClient.state === SelectClientState.READY &&
        plans &&
        plans?.length > 0 ? (
          <Redirect to={EPrivatePathName.DASHBOARD} />
        ) : (
          <Setup />
        )}
      </Route>
      <Route exact path={EPublicPathName.LOGIN}>
        <Redirect to={EPrivatePathName.DASHBOARD} />
      </Route>
      <Route exact path="*">
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <ErrorPage
            title="404 - NOT FOUND!"
            message="The page you are looking for is not found!"
          />
        </Suspense>
      </Route>
    </Switch>
  );
};
