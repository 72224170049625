import styled, { css } from "styled-components/macro";
import { StyledDialog, StyledDialogTitle } from "./styled.dialog";

const Container = styled(StyledDialog)(({ theme }) => {
  return css`
    max-width: ${theme.rem(40)};
    display: grid;
    grid-template-areas:
      "tip-row headline"
      "tip-row content"
      "tip-row footer";
    grid-template-columns: min-content auto;
  `;
});
const Row = styled.div(({ theme }) => {
  return css`
    grid-area: tip-row;
    hyphens: manual;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.values.colors.global.primary.first};
    color: ${theme.values.colors.global.greys.first};
    padding: ${theme.rem(1)};
    font-size: ${theme.rem(3)};
    font-weight: ${theme.values.fontWeight.bolder};
  `;
});
const Headline = styled.div(({ theme }) => {
  return css`
    grid-area: headline;
    padding: ${theme.rem(1)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.values.colors.global.greys.fourth};
  `;
});
const Title = styled(StyledDialogTitle)(({ theme }) => {
  return css``;
});
const Content = styled.div(({ theme }) => {
  return css`
    grid-area: content;
    padding: ${theme.rem(1)};
  `;
});

export const StyledRichDialog = { Container, Row, Headline, Title, Content };
