import { FC } from "react";
import { ReactComponent as ArrowLeft } from "./arrow-left-solid.svg";
import { ReactComponent as ArrowRight } from "./arrow-right-solid.svg";
import { ReactComponent as ArrowUp } from "./arrow-up-solid.svg";
import { ReactComponent as ArrowDown } from "./arrow-down-solid.svg";
import { ReactComponent as DashBoard } from "./tachometer-alt-solid.svg";
import { ReactComponent as Eye } from "./eye-regular.svg";
import { ReactComponent as EyeSlash } from "./eye-slash-regular.svg";
import { ReactComponent as Kpi } from "./chart-bar-solid.svg";
import { ReactComponent as Plan } from "./sliders-h-solid.svg";
import { ReactComponent as Reports } from "./book-solid.svg";
import { ReactComponent as Company } from "./industry-solid.svg";
import { ReactComponent as CompanyData } from "./pencil-alt-solid.svg";
import { ReactComponent as CompanyUsers } from "./users-solid.svg";
import { ReactComponent as Upload } from "./cloud-upload-alt-solid.svg";
import { ReactComponent as ProfileImage } from "./user-solid.svg";
import { ReactComponent as SignOut } from "./sign-out-alt-solid.svg";
import { ReactComponent as Bars } from "./bars-solid.svg";
import { ReactComponent as Bell } from "./bell-regular.svg";
import { ReactComponent as Exclamation } from "./exclamation-triangle-solid.svg";
import { ReactComponent as InfoCircle } from "./info-circle-solid.svg";
import { ReactComponent as Calendar } from "./calendar-alt-solid.svg";
import { ReactComponent as ChevronDown } from "./chevron-down-solid.svg";
import { ReactComponent as ChevronUp } from "./chevron-up-solid.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left-solid.svg";
import { ReactComponent as ChevronRight } from "./chevron-right-solid.svg";
import { ReactComponent as CaretDown } from "./caret-down-solid.svg";
import { ReactComponent as CaretUp } from "./caret-up-solid.svg";
import { ReactComponent as CaretLeft } from "./caret-left-solid.svg";
import { ReactComponent as CaretRight } from "./caret-right-solid.svg";
import { ReactComponent as Check } from "./check-solid.svg";
import { ReactComponent as Blank } from "./blank.svg";
import { ReactComponent as Trash } from "./trash-alt-regular.svg";
import { ReactComponent as Times } from "./times-solid.svg";
import { ReactComponent as Plus } from "./plus-solid.svg";
import { ReactComponent as EllipsisV } from "./ellipsis-v-solid.svg";
import { ReactComponent as EllipsisH } from "./ellipsis-h-solid.svg";
import { ReactComponent as Image } from "./image-regular.svg";
import { ReactComponent as Question } from "./question-circle-regular.svg";
import { ReactComponent as LightBulb } from "./lightbulb-regular.svg";
import { ReactComponent as FileDOC } from "./file-word-regular.svg";
import { ReactComponent as FileXLS } from "./file-xls.svg";
import { ReactComponent as FileXLS2 } from "./file-excel-regular.svg";
import { ReactComponent as FileCSV } from "./file-csv.svg";
import { ReactComponent as FileTXT } from "./file-txt.svg";
import { ReactComponent as FilePDF } from "./file-pdf-regular.svg";
import { ReactComponent as FileDownload } from "./file-download-solid.svg";
import { ReactComponent as Refresh } from "./sync-alt-solid.svg";
import { ReactComponent as Edit } from "./edit-solid.svg";
import { ReactComponent as Copy } from "./copy-regular.svg";
import { ReactComponent as Undo } from "./undo-alt-solid.svg";
import { ReactComponent as Sort } from "./sort-solid.svg";
import { ReactComponent as SortUp } from "./sort-amount-up-solid.svg";
import { ReactComponent as SortDown } from "./sort-amount-down-solid.svg";
import { ReactComponent as CheckCircle } from "./check-circle-regular.svg";
import { ReactComponent as TimesCircle } from "./times-circle-regular.svg";
import { ReactComponent as Video } from "./videos.svg";
import styled from "styled-components/macro";
import { IconLoader } from "../LoadingIndicator/IconLoader";

export interface IIconProps extends IIconSet {
  size: IconSizeEnums;
}

export enum IconSizeEnums {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}

export enum IconNameEnums {
  ARROW_LEFT = "ARROW_LEFT",
  ARROW_RIGHT = "ARROW_RIGHT",
  ARROW_UP = "ARROW_UP",
  ARROW_DOWN = "ARROW_DOWN",
  DASHBOARD = "DASHBOARD",
  KPI = "KPI",
  PLAN = "PLAN",
  REPORTS = "REPORTS",
  COMPANY = "COMPANY",
  COMPANY_DATA = "COMPANY_DATA",
  COMPANY_USERS = "COMPANY_USERS",
  UPLOAD = "UPLOAD",
  PROFILE_IMAGE = "PROFILE_IMAGE",
  SIGN_OUT = "SIGN_OUT",
  BARS = "BARS",
  BELL = "BELL",
  EXCLAMATION = "EXCLAMATION",
  INFO_CIRCLE = "INFO_CIRCLE",
  CALENDAR = "CALENDAR",
  CHEVRON_DOWN = "CHEVRON_DOWN",
  CHEVRON_UP = "CHEVRON_UP",
  CHEVRON_LEFT = "CHEVRON_LEFT",
  CHEVRON_RIGHT = "CHEVRON_RIGHT",
  CARET_DOWN = "CARET_DOWN",
  CARET_UP = "CARET_UP",
  CARET_LEFT = "CARET_LEFT",
  CARET_RIGHT = "CARET_RIGHT",
  CHECK = "CHECK",
  BLANK = "BLANK",
  TRASH = "TRASH",
  TIMES = "TIMES",
  PLUS = "PLUS",
  ELLIPSIS_H = "ELLIPSIS_H",
  ELLIPSIS_V = "ELLIPSIS_V",
  IMAGE = "IMAGE",
  QUESTION = "QUESTION",
  BULB = "BULB",
  DOC = "DOC",
  XLS = "XLS",
  XLS2 = "XLS2",
  CSV = "CSV",
  TXT = "TXT",
  PDF = "PDF",
  FILE_DOWNLOAD = "FILE_DOWNLOAD",
  REFRESH = "REFRESH",
  EDIT = "EDIT",
  COPY = "COPY",
  UNDO = "UNDO",
  EYE = "EYE",
  EYE_SLASH = "EYE_SLASH",
  SORT = "SORT",
  SORT_UP = "SORT_UP",
  SORT_DOWN = "SORT_DOWN",
  CHECK_CIRCLE = "CHECK_CIRCLE",
  TIMES_CIRCLE = "TIMES_CIRCLE",
  VIDEO = "VIDEO",
}
interface IIconSet {
  name: IconNameEnums;
  loading?: boolean;
}

export const StyledIcon = styled.span`
  display: block;
  > svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledExtraSmallIcon = styled(StyledIcon)`
  font-size: ${({ theme }): string => theme.rem(0.9)};
  width: ${({ theme }): string => theme.rem(0.9)};
  min-width: ${({ theme }): string => theme.rem(0.9)};
  height: ${({ theme }): string => theme.rem(0.9)};
`;
const StyledSmallIcon = styled(StyledIcon)`
  font-size: ${({ theme }): string => theme.rem(1.15)};
  width: ${({ theme }): string => theme.rem(1.15)};
  min-width: ${({ theme }): string => theme.rem(1.15)};
  height: ${({ theme }): string => theme.rem(1.15)};
`;
const StyledMediumIcon = styled(StyledIcon)`
  font-size: ${({ theme }): string => theme.rem(1.75)};
  width: ${({ theme }): string => theme.rem(1.75)};
  min-width: ${({ theme }): string => theme.rem(1.75)};
  height: ${({ theme }): string => theme.rem(1.75)};
`;
const StyledLargeIcon = styled(StyledIcon)`
  font-size: ${({ theme }): string => theme.rem(4)};
  width: ${({ theme }): string => theme.rem(4)};
  min-width: ${({ theme }): string => theme.rem(4)};
  height: ${({ theme }): string => theme.rem(4)};
`;
const StyledXLargeIcon = styled(StyledIcon)`
  font-size: ${({ theme }): string => theme.rem(8)};
  min-width: ${({ theme }): string => theme.rem(8)};
  height: ${({ theme }): string => theme.rem(8)};
`;
const IconSet: FC<IIconSet> = ({ name, loading = false }) => {
  if (loading) {
    return <IconLoader />;
  }

  switch (name) {
    case IconNameEnums.ARROW_LEFT:
      return <ArrowLeft />;
    case IconNameEnums.ARROW_RIGHT:
      return <ArrowRight />;
    case IconNameEnums.ARROW_DOWN:
      return <ArrowDown />;
    case IconNameEnums.ARROW_UP:
      return <ArrowUp />;
    case IconNameEnums.DASHBOARD:
      return <DashBoard />;
    case IconNameEnums.EYE:
      return <Eye />;
    case IconNameEnums.EYE_SLASH:
      return <EyeSlash />;
    case IconNameEnums.KPI:
      return <Kpi />;
    case IconNameEnums.PLAN:
      return <Plan />;
    case IconNameEnums.REPORTS:
      return <Reports />;
    case IconNameEnums.COMPANY:
      return <Company />;
    case IconNameEnums.COMPANY_DATA:
      return <CompanyData />;
    case IconNameEnums.COMPANY_USERS:
      return <CompanyUsers />;
    case IconNameEnums.UPLOAD:
      return <Upload />;
    case IconNameEnums.PROFILE_IMAGE:
      return <ProfileImage />;
    case IconNameEnums.SIGN_OUT:
      return <SignOut />;
    case IconNameEnums.BARS:
      return <Bars />;
    case IconNameEnums.BELL:
      return <Bell />;
    case IconNameEnums.EXCLAMATION:
      return <Exclamation />;
    case IconNameEnums.INFO_CIRCLE:
      return <InfoCircle />;
    case IconNameEnums.CALENDAR:
      return <Calendar />;
    case IconNameEnums.CHEVRON_DOWN:
      return <ChevronDown />;
    case IconNameEnums.CHEVRON_UP:
      return <ChevronUp />;
    case IconNameEnums.CHEVRON_LEFT:
      return <ChevronLeft />;
    case IconNameEnums.CHEVRON_RIGHT:
      return <ChevronRight />;
    case IconNameEnums.CARET_DOWN:
      return <CaretDown />;
    case IconNameEnums.CARET_UP:
      return <CaretUp />;
    case IconNameEnums.CARET_LEFT:
      return <CaretLeft />;
    case IconNameEnums.CARET_RIGHT:
      return <CaretRight />;
    case IconNameEnums.CHECK:
      return <Check />;
    case IconNameEnums.BLANK:
      return <Blank />;
    case IconNameEnums.TRASH:
      return <Trash />;
    case IconNameEnums.TIMES:
      return <Times />;
    case IconNameEnums.PLUS:
      return <Plus />;
    case IconNameEnums.ELLIPSIS_H:
      return <EllipsisH />;
    case IconNameEnums.ELLIPSIS_V:
      return <EllipsisV />;
    case IconNameEnums.IMAGE:
      return <Image />;
    case IconNameEnums.QUESTION:
      return <Question />;
    case IconNameEnums.BULB:
      return <LightBulb />;
    case IconNameEnums.DOC:
      return <FileDOC />;
    case IconNameEnums.XLS:
      return <FileXLS />;
    case IconNameEnums.XLS2:
      return <FileXLS2 />;
    case IconNameEnums.CSV:
      return <FileCSV />;
    case IconNameEnums.TXT:
      return <FileTXT />;
    case IconNameEnums.PDF:
      return <FilePDF />;
    case IconNameEnums.FILE_DOWNLOAD:
      return <FileDownload />;
    case IconNameEnums.REFRESH:
      return <Refresh />;
    case IconNameEnums.EDIT:
      return <Edit />;
    case IconNameEnums.COPY:
      return <Copy />;
    case IconNameEnums.UNDO:
      return <Undo />;
    case IconNameEnums.SORT:
      return <Sort />;
    case IconNameEnums.SORT_UP:
      return <SortUp />;
    case IconNameEnums.SORT_DOWN:
      return <SortDown />;
    case IconNameEnums.CHECK_CIRCLE:
      return <CheckCircle />;
    case IconNameEnums.TIMES_CIRCLE:
      return <TimesCircle />;
    case IconNameEnums.VIDEO:
      return <Video />;
    default:
      return <>{name}</>;
  }
};
const XSmallIcon: FC<IIconProps> = ({ name, loading, ...props }) => {
  return (
    <StyledExtraSmallIcon {...props}>
      <IconSet name={name} loading={loading} />
    </StyledExtraSmallIcon>
  );
};
const SmallIcon: FC<IIconProps> = ({ name, loading, ...props }) => {
  return (
    <StyledSmallIcon {...props}>
      <IconSet name={name} loading={loading} />
    </StyledSmallIcon>
  );
};
const MediumIcon: FC<IIconProps> = ({ name, loading, ...props }) => {
  return (
    <StyledMediumIcon {...props}>
      <IconSet name={name} loading={loading} />
    </StyledMediumIcon>
  );
};
const LargeIcon: FC<IIconProps> = ({ name, loading, ...props }) => {
  return (
    <StyledLargeIcon {...props}>
      <IconSet name={name} loading={loading} />
    </StyledLargeIcon>
  );
};
const XLargeIcon: FC<IIconProps> = ({ name, loading, ...props }) => {
  return (
    <StyledXLargeIcon {...props}>
      <IconSet name={name} loading={loading} />
    </StyledXLargeIcon>
  );
};
export const Icon: FC<IIconProps> = (props) => {
  switch (props.size) {
    case IconSizeEnums.XSMALL:
      return <XSmallIcon {...props} />;
    case IconSizeEnums.SMALL:
      return <SmallIcon {...props} />;
    case IconSizeEnums.MEDIUM:
      return <MediumIcon {...props} />;
    case IconSizeEnums.LARGE:
      return <LargeIcon {...props} />;
    case IconSizeEnums.XLARGE:
      return <XLargeIcon {...props} />;
  }
};
