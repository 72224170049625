/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://1t2v6xxdml.execute-api.eu-central-1.amazonaws.com/dev/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://1t2v6xxdml.execute-api.eu-central-1.amazonaws.com/dev/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_Ga77DJSEP",
    APP_CLIENT_ID: "541e29e56sjmrkksrm3802j0lj",
    IDENTITY_POOL_ID: "eu-central-1:878f2786-6df8-4641-ad33-9c3ed83ef3d2",
  },
  i18n: {
    host: "locales.digital-dev-sb.dev.canei.io",
  },
  assets: {
    host: "assets.digital-dev-sb.dev.canei.io",
  },
  payment: {
    publicKey: "pk_test_51Kb2CFJafEP8n6QGsNJNLK9iljof2ilJpV8XPLHv9Q5nRP4vtaY8fvpevUs2wGAb83ZgiREDXqucNGsrm2HthSTf00Jy8h3kW8",
  },
  registration: {
    FORM_POST: "https://support.digital-dev-sb.dev.canei.io/v1/forms",
    NEXT_PAGE: "https://app.digital-dev-sb.dev.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
};

export default awsConfig;
