import { FC, memo, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import Highcharts, { Chart, Point } from "highcharts";
import HighchartsReact from "highcharts-react-official";

import solidGauge from "highcharts/modules/solid-gauge";
import draggablePoints from "highcharts/modules/draggable-points";
import HC_more from "highcharts/highcharts-more";

import { useTranslation } from "react-i18next";
import { formatNumber } from "../../_lib/common";
import { usePlanningHelper } from "../../_lib/hooks";
import { isEqual } from "lodash";
import { ILocalState } from "../../@types";
import { useSelector } from "react-redux";

export type ChartRef = { chart: Chart; container: RefObject<HTMLDivElement> };
export interface IRevenueChartProps {
  data: (number | Point)[];
  sector: string;
  zoom: ERevenueZoom;
  name: string;
  forwardRef: RefObject<ChartRef>;
  fixed?: boolean; // to make the graph uneditable in plannin revenue subpage and also custumize the styling
}

export enum ERevenueZoom {
  MIN = 15,
  MED = 50,
  MAX = 100,
}

solidGauge(Highcharts);
draggablePoints(Highcharts);
HC_more(Highcharts);

const RC: FC<IRevenueChartProps> = ({ data, sector, zoom, forwardRef, fixed }) => {
  const { t } = useTranslation();
  const { FYMonths } = usePlanningHelper();
  const brandedColors = useSelector(
    ({
      themeOptions: {
        branded: { colors },
      },
    }: ILocalState) => colors,
    isEqual
  );
  const [optionsReady, setOptionsReady] = useState<boolean>(false);

  const colors = {
    line: brandedColors.secondary,
    dot: brandedColors.primary,
  };
  const getDataSummary = (_dataPoint: (number | Point)[]): number => {
    return _dataPoint.reduce((prev: number, cur: number | Point) => {
      const currentY = typeof cur === "number" ? cur : cur.y || 0;
      return parseFloat((prev + currentY).toFixed(2));
    }, 0);
  };
  const recalculate = useCallback(
    function (point: Point) {
      if (point?.y === undefined) return false;

      const series = forwardRef?.current?.chart.series[0];
      const _data = data.map((_point) =>
        parseFloat((typeof _point === "number" ? _point : _point.y || 0).toFixed(2))
      );

      if (!series) return;
      if (!series) return false;

      if (_data[point.x] >= 100) {
        _data[point.x] = 100;
      }
      if (_data[point.x] < 1) {
        _data[point.x] = 1;
      }

      const sum = getDataSummary(_data);

      const diff = parseFloat(((sum - 100) / (_data.length - 1)).toFixed(2));
      if (diff !== 0) {
        let rest = 0;
        _data.forEach((currentY, i) => {
          if (i !== point.x) {
            let newValue = parseFloat((currentY - diff + rest).toFixed(2));

            if (newValue > 100) {
              rest += newValue - 100;
              newValue = 100;
            }
            if (newValue < 0) {
              rest += newValue;
              newValue = 0;
            }

            _data[i] = newValue;
            // rest = 0;
          }
        });
      }

      const newData = data.map((point, i) => {
        if (typeof point === "number") {
          point = _data[i];
        } else {
          point.y = _data[i];
        }

        return point;
      });

      series.setData(newData, true);
      // if (point.y === 0 || point.y === 100) return false;
    },
    [data, forwardRef]
  );

  /*
   **************************************************
   * Custom Styling of Highcharts using css is possible.
   * If it's decided to do so. Useful example : 💣
   * https://codesandbox.io/s/highcharts-react-demo-vosjm?file=/demo.jsx
   **************************************************
   */
  const options: Highcharts.Options = useMemo(() => {
    return {
      lang: {
        decimalPoint: t("misc.decimalPoint"),
      },
      chart: {
        animation: true,
        styledMode: false,
      },
      title: {
        text: sector,
      },
      tooltip: {
        shared: true,
        formatter: function (): string {
          return `${this.x}<br /><b>${formatNumber(this.y)} %</b>`;
        },
      },
      xAxis: {
        // IF FIXED, render the graph only for revenue subpage in planning
        categories: fixed ? FYMonths : t("misc.months.long", { returnObjects: true }),
        labels: {
          rotation: fixed ? 0 : -60,
          style: {
            fontSize: "10",
          },
        },
      },
      yAxis: {
        min: 1,
        max: zoom,
        title: {
          text: t("company.revenue_data.y_axis"),
          style: {
            fontSize: "10",
          },
        },
      },
      plotOptions: {
        series: {
          animation: false,
          point: {
            events: {
              drag: function (): undefined | boolean {
                return fixed ? false : recalculate(this);
              },
              drop: function (): undefined | boolean {
                return fixed ? false : recalculate(this);
              },
            },
          },
          stickyTracking: false,
        },
        column: {
          stacking: "normal",
        },
        line: {
          cursor: "ns-resize",
        },
      },

      series: [
        {
          showInLegend: false,
          color: colors.line,
          marker: {
            fillColor: colors.dot,
          },
          data: data,
          type: "spline",
          cursor: "ns-resize",
          dragDrop: { draggableY: true },
        },
      ],
    };
  }, [colors.dot, colors.line, data, t, recalculate, sector, zoom, fixed, FYMonths]);

  useEffect(() => {
    let mounted = true;
    const delay = window.setTimeout(() => {
      if (mounted && !optionsReady) setOptionsReady(true);
    });

    return (): void => {
      window.clearTimeout(delay);
      mounted = false;
    };
  }, [optionsReady]);

  if (!options) return null;

  return (
    <HighchartsReact
      constructorType={"chart"}
      highcharts={Highcharts}
      options={options}
      immutable={true}
      ref={forwardRef}
    />
  );
};

export const RevenueRatiosChart = memo(RC, (prev, cur) => {
  return (
    prev.sector === cur.sector &&
    JSON.stringify(prev.data) === JSON.stringify(cur.data) &&
    prev.zoom === cur.zoom
  );
});
