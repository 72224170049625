import { FC, ReactElement, useContext, useEffect } from "react";
import { FormContext, FormDispatch, IFormState } from "../Form";
import { ButtonVariantEnums } from "../Button";
import { StyledSwitch, StyledSwitchItem } from "./styled.switch";

export interface ISwitchProps {
  name: string;
  /** First option */
  firstItem: ISwitchItemOptions;
  /** Second option */
  secondItem: ISwitchItemOptions;
  /** variant  */
  variant: ButtonVariantEnums | ButtonVariantEnums;
  size?: ISwitchSizeEnums;
  disabled?: boolean;
  onChange?: (val: IFormState) => void;
}
export interface ISwitchItemOptions {
  text: ReactElement;
  value: string;
  isSelected: boolean;
}
export interface ISwitchItem extends ISwitchItemOptions {
  size?: ISwitchSizeEnums;
  disabled?: boolean;
}

export enum ISwitchSizeEnums {
  NORMAL = "NORMAL",
  ON_OFF = "ON_OFF",
}
/**
 * Form Comp which can select one of two values.
 *
 * **PS** Works only in **Form** Comp
 */
export const Switch: FC<ISwitchProps> = ({
  name,
  firstItem,
  secondItem,
  variant,
  disabled,
  size = ISwitchSizeEnums.NORMAL,
  onChange,
}: ISwitchProps) => {
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);

  const handleSwitch = (itemKey: number): void => {
    if (disabled) return;
    let value;
    switch (size) {
      case ISwitchSizeEnums.ON_OFF:
        if (itemKey === 1) {
          value =
            formContext[name] !== firstItem.value
              ? { [name]: firstItem.value }
              : { [name]: secondItem.value };
        }
        if (itemKey === 2) {
          value =
            formContext[name] !== secondItem.value
              ? { [name]: secondItem.value }
              : { [name]: firstItem.value };
        }
        break;
      default:
        const item = itemKey === 1 ? firstItem : secondItem;
        value = { [name]: item.value };
    }

    value && formDispatch(value);
    if (onChange && value) {
      onChange(value);
    }
  };
  useEffect(() => {
    if (!formContext[name]) {
      const selectedValue = firstItem.isSelected
        ? firstItem.value
        : secondItem.isSelected
        ? secondItem.value
        : undefined;
      if (formContext[name] !== selectedValue) {
        formDispatch({ [name]: selectedValue || "" });
      }
    }
  });
  const firstItemSelected = formContext[name] === firstItem.value;
  const secondItemSelected = formContext[name] === secondItem.value;
  return (
    <StyledSwitch variant={variant} size={size}>
      <StyledSwitchItem
        {...firstItem}
        size={size}
        isSelected={firstItemSelected}
        variant={variant}
        disabled={disabled}
        onClick={(): void => handleSwitch(1)}
      >
        {firstItem.text}
      </StyledSwitchItem>
      <StyledSwitchItem
        {...secondItem}
        size={size}
        isSelected={secondItemSelected}
        variant={variant}
        disabled={disabled}
        onClick={(): void => handleSwitch(2)}
      >
        {secondItem.text}
      </StyledSwitchItem>
    </StyledSwitch>
  );
};
