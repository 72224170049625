import { FC, ReactNode } from "react";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import { StyledOptions, StyledOptionText } from "./styled.option";

export interface IOptionProps {
  /**Option's content */
  children: ReactNode;
  /** Event handler to be called on selecting option*/
  onSelect: (value: string) => void;
  /** Define each option selected state. */
  selected?: boolean;
  /** Value of the option, which will be used for onSelect*/
  value?: string;
}

/**
 * Options of DropDownButton
 */
export const Option: FC<IOptionProps> = ({
  children,
  selected = false,
  onSelect,
  value = "",
}: IOptionProps) => {
  return (
    <StyledOptions onClick={(): void => onSelect(value)}>
      <StyledOptionText selected={selected}>{children}</StyledOptionText>
      <Icon
        name={selected ? IconNameEnums.CHECK : IconNameEnums.BLANK}
        size={IconSizeEnums.SMALL}
      />
    </StyledOptions>
  );
};
