import { createContext, Dispatch, FC, Suspense, useEffect, useMemo, useReducer } from "react";
import { SetupLayout } from "../../../_lib/Layout";
import { lazy } from "@loadable/component";
import { LoaderTypeEnums, LoadingIndicator } from "../../../components/LoadingIndicator";
import initialData from "../../../_lib/store/initialState";
import { useSelector } from "react-redux";
import { DraftClient, DraftClientSetup, ILocalState } from "../../../@types/index.d";
import { StyledSetupPage } from "./styled.setup-content";
import { ClientSetupNav } from "./ClientSetupNav";
import { FadeIn } from "../../../_lib/Transitions";
import { setupStepRegisterTestID } from "./SetupContent";
import { isEqual } from "lodash";

const SetupContent = lazy(() => import("./SetupContent"));

export const ClientSetupContext = createContext(initialData.draftClient);
export const ClientSetupDispatch = createContext<Dispatch<Partial<DraftClient>>>(() => {
  // initial dispatch function
});

const Setup: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const eventInProgress = useSelector(
    ({ currentUser: { eventInProgress } }: ILocalState) => eventInProgress,
    isEqual
  );

  const reduceSetupClient = (state: DraftClient, action: Partial<DraftClient>): DraftClient => {
    return {
      ...state,
      ...action,
    };
  };

  const selectedClient = currentUser.clients.find(
    (client) => client.client_id === currentUser.selectedClient.client_id
  );
  const getCategoryType = (category: string): string => {
    // category code of industries starts with IND and sub_industries starts with DES.
    return category.startsWith("IND") ? "industries" : "sub_industries";
  };

  const initialSetupClient = useMemo(() => {
    return selectedClient
      ? {
          ...initialData.draftClient,
          state: DraftClientSetup.UPLOAD,
          customer_id: currentUser.appUser.customer_id,
          client_id: currentUser.selectedClient.client_id,
          name: selectedClient.name,
          mapping: selectedClient.mapping,
          category: selectedClient.category,
          fiscal_year: selectedClient.fiscal_year,
          category_type: getCategoryType(selectedClient.category),
          customization: selectedClient.customization,
        }
      : initialData.draftClient;
  }, [currentUser.appUser.customer_id, currentUser.selectedClient.client_id, selectedClient]);
  const [setupClient, setupClientDispatcher] = useReducer(reduceSetupClient, initialSetupClient);
  useEffect(() => {
    setupClientDispatcher(initialSetupClient);
  }, [initialSetupClient]);
  return (
    <SetupLayout>
      <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.COMPONENT} />}>
        <ClientSetupDispatch.Provider value={setupClientDispatcher}>
          <ClientSetupContext.Provider value={setupClient}>
            <StyledSetupPage data-test-id={setupStepRegisterTestID}>
              <ClientSetupNav />
              <FadeIn>
                <SetupContent />
              </FadeIn>
            </StyledSetupPage>
            {(setupClient.state === DraftClientSetup.DATA_PROGRESS ||
              setupClient.state === DraftClientSetup.UPLOAD_PROGRESS ||
              eventInProgress) && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
          </ClientSetupContext.Provider>
        </ClientSetupDispatch.Provider>
      </Suspense>
    </SetupLayout>
  );
};
export default Setup;
