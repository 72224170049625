import { FC } from "react";
import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import awsConfig from "../../awsConfig";
// import typeDefs from "../../_graphql/schema";
import { ApolloProvider } from "@apollo/client";

import ErrorBoundary from "../ErrorBoundry";
// import { GraphQLError } from "graphql/error";
// import { StyledApolloError } from "./styled.apollo-error";

export const Apollo: FC = ({ children }) => {
  const cache = new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          getClients: {
            merge: false, // Always overwrite cache data from server for this query
          },
          getAllSuSasOfClient: {
            merge: false,
          },
          getEvaluationByClientId: {
            merge: false,
          },
          getReports: {
            merge: false,
          },
        },
      },
    },
  });
  // const [apolloErrors, setApolloErrors] = useState<(GraphQLError | Error)[]>([]);

  const uri =
    process.env.REACT_APP_USE_LOCAL_GRAPHQL_SERVER === "true"
      ? "http://localhost:4000/dev/graphql"
      : awsConfig.gqlGateway.URL;
  // Middlewares for Link:
  const httpLink = new HttpLink({
    uri,
    credentials: "omit",
  });
  const observableLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // TODO: sendToLoggingService(graphQLErrors);
      // console.error("graphQLErrors", graphQLErrors);
      // setApolloErrors([...apolloErrors, ...graphQLErrors]);
    }
    if (networkError) {
      // TODO logoutUser();
      // console.error("networkError", networkError);
      // setApolloErrors([...apolloErrors, networkError]);
    }
  });
  //TODO: Authorization required for GraphQL Server can be implemented as follows:
  //- A useful documentation and a working example can be found here:
  //    https://www.serverless.com/blog/strategies-implementing-user-authentication-serverless-applications/
  //    https://github.com/awslabs/aws-apigateway-lambda-authorizer-blueprints/blob/master/blueprints/nodejs/index.js
  //
  // const authMiddleware = new ApolloLink((operation, forward) => {
  //   operation.setContext(({ headers = {} }) => ({
  //     headers: { ...headers, authorization: "key" },
  //   }));
  //
  //   return forward(operation);
  // });
  const client = new ApolloClient({
    link: from([observableLink, httpLink]),
    // typeDefs,
    cache,
  });

  // if (apolloErrors.length > 0) {
  //   return (
  //     <StyledApolloError>
  //       <div>
  //         <h2>Something went wrong on our end. We are currently trying to fix this problem</h2>
  //         {apolloErrors.map((error: GraphQLError | Error, index: number) => (
  //           <div key={index}>
  //             <p>{error.message}</p>
  //           </div>
  //         ))}
  //         Click <a href="/">here</a> to try again.
  //       </div>
  //     </StyledApolloError>
  //   );
  // }

  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ApolloProvider>
  );
};
