/*          portrait    landscape
  mini             > 0          > 0
  small            > 414        > 812
  medium           > 720        > 1080
  large            > 864        > 1280
  big              > 1080       > 1440
  wide             > 1440       > 1920
 */

import { useEffect, useMemo, useState } from "react";
import { IDevice, OrientationEnums, PortraitBreakPointEnums } from "../../@types/index.d";
import { useMediaQuery } from "react-responsive";

export const useDevice = (): IDevice => {
  const [responsiveLayout, setResponsiveLayout] = useState<IDevice>({
    isMini: true,
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    // isWide: false,
    orientation: OrientationEnums.PORTRAIT,
    isPortrait: true,
    isLandscape: false,
    isRetina: false,
  });

  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const orientation = isPortrait ? OrientationEnums.PORTRAIT : OrientationEnums.LANDSCAPE;
  const isRetina = useMediaQuery({ minResolution: "2dppx" });

  const isMini = useMediaQuery({
    minWidth: PortraitBreakPointEnums.MINI,
  });

  const isSmall = useMediaQuery({
    minWidth: PortraitBreakPointEnums.SMALL,
  });

  const isMedium = useMediaQuery({
    minWidth: PortraitBreakPointEnums.MEDIUM,
  });

  const isLarge = useMediaQuery({
    minWidth: PortraitBreakPointEnums.LARGE,
  });

  const isXLarge = useMediaQuery({
    minWidth: PortraitBreakPointEnums.XLARGE,
  });

  const deviceInfo = useMemo(() => {
    return {
      isMini,
      isSmall,
      isMedium,
      isLarge,
      isXLarge,
      orientation,
      isPortrait,
      isLandscape,
      isRetina,
    };
  }, [
    isMini,
    isSmall,
    isMedium,
    isLarge,
    isXLarge,

    orientation,
    isPortrait,
    isLandscape,
    isRetina,
  ]);
  useEffect(() => {
    setResponsiveLayout(deviceInfo);
  }, [deviceInfo]);

  return responsiveLayout;
};
