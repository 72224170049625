import { FC, InputHTMLAttributes, ReactElement } from "react";
import { ISelectItemProps } from "../components/formElements/Select";
export interface InputProps<T> extends InputHTMLAttributes<T> {
  name: string;
  value: string;
  label?: string;
  labelPosition?: IInputLabelPosition;
  placeholder?: string;
  type?: InputTypes;
  variant?: IInputVariantEnums;
  valid?: boolean;
  invalidWarning?: string;
  format?: InputFormats;
  noBorder?: boolean;
  center?: boolean;
  table?: boolean;
  withUnit?: string; // to set the items like %, € after input field
  alignText?: "left" | "center" | "right";
  level?: "title" | "upper" | "subTitle" | "subSubTitle" | "normal";
}
export interface IInputConstructorProps
  extends InputProps<HTMLInputElement>,
    Partial<Omit<ISelectProps, "text">> {
  selectionContent?: ReactElement | ReactElement[];
  selectionType?: SelectionTypes;
  selectionValue?: string;
  selectionOpen?: boolean;
}

export enum IInputLabelPosition {
  LEFT,
  TOP,
  TOPLEFT,
}
export interface ISelectProps extends IInputConstructorProps {
  text: string;
  children: ReactElement<FC<ISelectItemProps>> | ReactElement<FC<ISelectItemProps>>[];
  type: SelectionTypes;
  isOpen?: boolean;
  onChange?: (e: ISelectValue) => void;
  testID?: string;
}
export interface ISelectValue<V> {
  name: string;
  text: string;
  value: V;
}
export interface IFileUploaderProps extends IInputConstructorProps {
  value?: never;
  accept?: string;
  convertTo?: IFileConvertToImage;
}
export interface IFileConvertToImage {
  maxWidth: number;
  quality: number;
}
export enum InputTypes {
  TEXT = "text",
  PASSWORD = "password",
  EMAIL = "email",
  HIDDEN = "hidden",
  NUMBER = "number",
  TABLENUM = "tablenum",
  COLOR = "color",
  FORMATTEDNUM = "formattednum",
  RANGE = "range",
}
export enum SelectionTypes {
  LIST = "LIST",
  DIALOG = "DIALOG",
}
export enum IInputVariantEnums {
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
}
