import {
  createContext,
  Dispatch,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import {
  StyledExpandable,
  StyledHelp,
  StyledPanel,
  StyledPanelBody,
  StyledPanelFooter,
  StyledPanelTitle,
  StyledPanelTitleHeadline,
  StyledPanelTitleOptionsContainer,
} from "./styled.panel";
import { ButtonVariantEnums, IconButton } from "../../components/formElements/Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../components/Icon";

export interface IPanelTitleProps {
  /**
   * Additional componnet like button or MonthPicker to be shown in title
   */
  children?: ReactNode;
  /**
   * Will be first line of the title
   */
  headline: string;
  /**
   * If defined will be second line of the title
   */
  subline?: string | ReactElement;
  /**
   * key of help text. TODO: update doc when help pop up implemented
   */
  help?: string | null;
  /**
   * Align contents
   */
  align?: EPanelTitleAlignment;
  optionsWidth?: string;
}

export enum EPanelTitleAlignment {
  LEFT,
  CENTER,
  RIGHT,
}
interface IPanelBodyProps {
  children?: ReactNode;
  ref?: (node?: Element | null | undefined) => void;
}
interface IPanelFooterProps {
  children?: ReactNode;
}
export interface IPanelProps {
  expandable?: boolean;
  expanded?: boolean;
  disableExpand?: boolean;
  shadow?: boolean;
}
const PanelContext = createContext<IPanelProps>({ expandable: false });
const PanelDispatch = createContext<Dispatch<IPanelProps>>((state: IPanelProps) => state);
const fnPanelDispatch = (state: IPanelProps, action: Partial<IPanelProps>): IPanelProps => ({
  ...state,
  ...action,
});
export const Panel: FC<IPanelProps> = ({
  children,
  disableExpand = true,
  expandable = false,
  expanded = true,
  shadow = true,
}) => {
  const [panelContext, panelDispatcher] = useReducer(fnPanelDispatch, {
    disableExpand,
    expanded,
    expandable,
  });
  return (
    <PanelDispatch.Provider value={panelDispatcher}>
      <PanelContext.Provider value={panelContext}>
        <StyledPanel shadow={shadow}>{children}</StyledPanel>
      </PanelContext.Provider>
    </PanelDispatch.Provider>
  );
};
export const PanelTitle: FC<IPanelTitleProps> = ({
  children,
  headline,
  subline,
  help,
  align,
  optionsWidth,
}: IPanelTitleProps) => {
  const panelContext = useContext(PanelContext);
  const panelDispatch = useContext(PanelDispatch);
  const handleExpandCollapse = (): void => {
    panelDispatch({ expanded: !panelContext.expanded });
  };
  return (
    <StyledPanelTitle>
      <StyledPanelTitleHeadline>
        <h2>{headline}</h2>
        {subline && <h3>{subline}</h3>}
      </StyledPanelTitleHeadline>

      <StyledPanelTitleOptionsContainer align={align} optionsWidth={optionsWidth}>
        {isValidElement(children) ? <div>{children}</div> : <> </>}
      </StyledPanelTitleOptionsContainer>

      {help && (
        <StyledHelp>
          <IconButton
            icon={<Icon name={IconNameEnums.QUESTION} size={IconSizeEnums.SMALL} />}
            inverted={false}
            variant={ButtonVariantEnums.SECONDARY}
            type={"button"}
          />
        </StyledHelp>
      )}
      {panelContext.expandable && (
        <StyledExpandable>
          <IconButton
            icon={
              <Icon
                name={panelContext.expanded ? IconNameEnums.CHEVRON_UP : IconNameEnums.CHEVRON_DOWN}
                size={IconSizeEnums.SMALL}
              />
            }
            inverted={false}
            variant={ButtonVariantEnums.SECONDARY}
            type={"button"}
            onClick={handleExpandCollapse}
          />
        </StyledExpandable>
      )}
    </StyledPanelTitle>
  );
};
export const PanelBody: FC<IPanelBodyProps> = ({ children }) => {
  const panelContext = useContext(PanelContext);
  return <StyledPanelBody>{panelContext.expanded ? children : <></>}</StyledPanelBody>;
};
export const PanelFooter: FC<IPanelFooterProps> = ({ children }) => {
  return <StyledPanelFooter>{children}</StyledPanelFooter>;
};
