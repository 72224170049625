import { FC } from "react";
import { StyledDashboardOptions } from "./styled.dashboard-options";

import { Button, ButtonVariantEnums } from "../../../../components/formElements/Button";
import { useTranslation } from "react-i18next";
import { useDialogs } from "../../../../_lib/hooks";
import { EPrivatePathName, IDialogKeyEnums, ILocalState } from "../../../../@types/index.d";
import { FinancialGrade } from "../FinancialGrade";

import { CurrentGrade } from "./CurrentGrade";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";

export const DashboardOptions: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialogs();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { pending } = currentUser.selectedClient.evaluation || {};
  const history = useHistory();
  const handleShowCompanyGradeReport = (): void => {
    dialog.open({
      dialogKey: IDialogKeyEnums.SIMPLE,
      content: <FinancialGrade />,
      closable: true,
      title: t("dashboard.financial_grade.dialog_title"),
      flexible: false,
    });
  };
  const handleCreateReportRedirect = (): void => {
    history.push(EPrivatePathName.REPORTS_CURRENT);
  };
  const areGradesReady = currentUser.selectedClient.evaluation?.currentGrade;

  return (
    <StyledDashboardOptions.Container>
      <StyledDashboardOptions.Column>
        <Button
          variant={ButtonVariantEnums.SECONDARY}
          inverted={false}
          type={"button"}
          inline={true}
          onClick={handleCreateReportRedirect}
        >
          {t("dashboard.options.prepare_report")}
        </Button>
      </StyledDashboardOptions.Column>
      <StyledDashboardOptions.Column>
        <StyledDashboardOptions.ReportSummary pending={pending}>
          <CurrentGrade />
        </StyledDashboardOptions.ReportSummary>

        <Button
          variant={ButtonVariantEnums.SECONDARY}
          inverted={false}
          type={"button"}
          inline={true}
          onClick={handleShowCompanyGradeReport}
          disabled={!areGradesReady}
        >
          {t("dashboard.options.company_report")}
        </Button>
      </StyledDashboardOptions.Column>
    </StyledDashboardOptions.Container>
  );
};
