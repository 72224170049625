import { useContext } from "react";
import { IDialogKeyEnums, IDialogOptions, TDialogData } from "../../@types/index.d";
import { DialogDispatch, DialogContext } from "../../components/Dialog";

export interface UseDialogsRetVal {
  open: (dialog: IDialogOptions) => void;
  close: (dialogKey: IDialogKeyEnums) => void;
  hide: (dialogKey: IDialogKeyEnums) => void;
  show: (dialogKey: IDialogKeyEnums) => void;
  save: (dialogKey: IDialogKeyEnums) => void;
  saveAndClose: (dialogKey: IDialogKeyEnums) => void;
  setData: <T>(dialogKey: IDialogKeyEnums, data: TDialogData<T>) => void;
  closeAll: () => void;
}
export const useDialogs = (): UseDialogsRetVal => {
  const dispatch = useContext(DialogDispatch);

  return {
    open: (dialog): void => {
      dispatch({ type: "open", dialog });
    },
    close: (dialogKey): void => {
      dispatch({ type: "close", dialogKey });
    },
    hide: (dialogKey): void => {
      dispatch({ type: "hide", dialogKey, hidden: true });
    },
    show: (dialogKey): void => {
      dispatch({ type: "hide", dialogKey, hidden: false });
    },
    closeAll: (): void => {
      dispatch({ type: "closeAll" });
    },
    save: (dialogKey): void => {
      dispatch({ type: "save", dialogKey });
    },
    saveAndClose: (dialogKey): void => {
      dispatch({ type: "saveAndClose", dialogKey });
    },
    setData: (dialogKey, data): void => {
      dispatch({ type: "setData", dialogKey, data });
    },
  };
};

export const useDialogContext = <T>(dialogKey: IDialogKeyEnums, initialValue: T): T => {
  const context = useContext(DialogContext);

  const contextData: T = context.find((item) => item.dialogKey === dialogKey)?.data;
  return contextData ? contextData : initialValue;
};
