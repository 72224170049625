import styled, { css } from "styled-components/macro";
export interface SubSectionProps {
  isLoading: boolean;
}

export const StyledSubSection = styled.div<SubSectionProps>(({ theme, isLoading = false }) => {
  return css`
    opacity: ${isLoading ? 0.7 : 1};
    transition: all 300ms;
    & > section {
      min-height: 100%;
    }
  `;
});
