import styled, { css } from "styled-components/macro";
import { StyledInnerWrapper } from "../Input/styled.input";
import { IInputVariantEnums } from "../../../@types/index.d";
import { ITextAreaProps } from "./index";
const Wrapper = styled(StyledInnerWrapper)(({ theme, variant = IInputVariantEnums.SECONDARY }) => {
  const { borderColor, radius } = theme.branded.inputs[variant];
  return css`
    border: 1px solid ${borderColor};
    border-radius: ${radius};
    position: relative;
  `;
});
const Element = styled.textarea<Partial<ITextAreaProps>>(({ theme }) => {
  return css`
    flex-grow: 1;
    border: none;
    font-family: inherit;
    font-size: ${theme.rem(1)};
    padding: ${theme.rem(0.25)} ${theme.rem(1)};
    font-weight: ${theme.values.fontWeight.normal};
    resize: vertical;
  `;
});

const RestCounter = styled.span(({ theme }) => {
  return css`
    position: absolute;
    right: 0;
    bottom: ${theme.rem(-1)};
    color: #00000050;
    font-size: ${theme.rem(0.75)};
    font-weight: ${theme.values.fontWeight.bold};
  `;
});

export const StyledTextArea = { Wrapper, Element, RestCounter };
