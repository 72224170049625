import styled, { css } from "styled-components/macro";

const Container = styled.section(() => {
  return css`
    display: flex;
    height: 100%;
  `;
});
const Column = styled.div(({ theme }) => {
  return css`
    display: flex;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    border-right: 1px solid transparent;
    &:not(:last-child) {
      border-right-color: ${theme.values.component.borderColors.basic};
    }
  `;
});

export const StyledViewOptions = {
  Container,
  Column,
};
