import styled, { css } from "styled-components/macro";

export const StyledPageFooter = styled.section(
  ({ theme }) => css`
    display: block;
    grid-area: footer;
    text-align: right;
    background-color: ${theme.values.colors.global.greys.first};
    padding: ${theme.rem(1)} ${theme.rem(1.25)};
  `
);
