import {
  DataSyncStateActionTypes,
  EDataSyncRefreshRate,
  IDataSyncAction,
  IDataSyncOptions,
  ILocalState,
} from "../../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

export type TUseSyncEvents = () => TUseSyncEventsReturnValue;
export type TUseSyncEventsReturnValue = {
  startSync: (syncOptions: Omit<IDataSyncOptions, "retryUntil">, retryCount?: number) => void;
  stopSync: (syncOptions: Pick<IDataSyncOptions, "scope" | "level">, retryCount?: number) => void;
};
export const useSyncEvents: TUseSyncEvents = () => {
  const dataSync = useSelector(({ dataSync }: ILocalState) => dataSync, isEqual);
  const dispatchDataSync = useDispatch<Dispatch<IDataSyncAction>>();

  const stopSync = (syncOptions: Pick<IDataSyncOptions, "scope" | "level">): void => {
    if (dataSync[syncOptions.level][syncOptions.scope].refreshRate === EDataSyncRefreshRate.IDLE)
      return;
    const cachedState = dataSync[syncOptions.level][syncOptions.scope];
    const alreadyStopped = cachedState.retryUntil === 0 && !cachedState.timeout;
    if (alreadyStopped) return;

    dispatchDataSync({
      type: DataSyncStateActionTypes.SET_REFRESH_RATE,
      payload: {
        scope: syncOptions.scope,
        level: syncOptions.level,
        refreshRate: EDataSyncRefreshRate.IDLE,
        retryUntil: 0,
        state: {
          timeout: false,
        },
      },
    });
  };
  const startSync = (syncOptions: IDataSyncOptions, retryCount = 1): void => {
    const currentRefreshRateOfScope = dataSync[syncOptions.level][syncOptions.scope].refreshRate;
    const retryUntil = Date.now() + retryCount * (syncOptions?.refreshRate || 1);

    if (currentRefreshRateOfScope !== syncOptions.refreshRate) {
      dispatchDataSync({
        type: DataSyncStateActionTypes.SET_REFRESH_RATE,
        payload: {
          ...syncOptions,
          retryUntil,
        },
      });
    }
  };
  return { startSync, stopSync };
};
