import { FC } from "react";
import styled, { css } from "styled-components/macro";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types/index.d";
import { useResponsive } from "../../../../_lib/hooks/useResponsive";
import { isEqual } from "lodash";

const StyledSetupLogo = styled.h1(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        font-family: "HaufeMerriweatherSans", sans-serif;
        font-size: ${theme.rem(0.75)};
        line-height: 1.5;
        width: 100px;
      `,
    },
    MEDIUM: {
      style: css`
        width: 120px;
      `,
    },
  });
});
const StyledSubTitle = styled.h2`
  font-family: "HaufeMerriweatherSans", sans-serif;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
`;
const StyledLogo = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;
export const SetupLogo: FC = () => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  return (
    <>
      <StyledSetupLogo>
        <StyledLogo src={themeOptions.branded.logo.normal} alt="" />
      </StyledSetupLogo>
    </>
  );
};
export const SubTitle: FC = ({ children }) => {
  return <StyledSubTitle>{children}</StyledSubTitle>;
};
