import { useApolloClient } from "@apollo/client";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { IPlanningAction, IPlanningActionTypes } from "../../store/reducers";
import { GET_PLAN_DETAILS_CONFIG } from "./dataStore-queries";
import { IUsePlanConfig } from "./dataStore-types";

interface IUsePlanDetailsConfigRetVal {
  fillPlanningDataStore: () => void;
}

export const usePlanDetailsConfig = (): IUsePlanDetailsConfigRetVal => {
  const dispatchPlanning = useDispatch<Dispatch<IPlanningAction>>();
  const apolloClient = useApolloClient();

  const fillPlanningDataStore = (): void => {
    apolloClient
      .query<IUsePlanConfig>({
        query: GET_PLAN_DETAILS_CONFIG,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.result)
          dispatchPlanning({
            type: IPlanningActionTypes.SET_DATA_STORE,
            payload: {
              dataStore: { ...data.result },
            },
          });
      });
  };

  return { fillPlanningDataStore };
};
