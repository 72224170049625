import { FC } from "react";
import { StyledPageFooter } from "./styled.page-footer";
import { useTranslation } from "react-i18next";

export const PageFooter: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledPageFooter>
      <div>{t("app.name")}</div>
    </StyledPageFooter>
  );
};
