import { FC } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { rgba } from "polished";
export const svgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;
export const circleAnimation = keyframes`
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;
//TODO: zIndex Function
export const StyledProgressIndicator = styled.section(({ theme }) => {
  const bgColor = rgba(theme.values.colors.global.secondary._100, 0.5);
  return css`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: ${bgColor};
    svg {
      animation: 2s linear infinite ${svgAnimation};
      max-width: 300px;
    }

    > div {
      width: 300px;
      height: 300px;
    }

    circle {
      animation: 1.4s ease-in-out infinite both ${circleAnimation};
      display: block;
      fill: transparent;
      stroke: ${theme.values.colors.global.primary._100};
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      transform-origin: 50% 50%;
    }
  `;
});

export const ProgressIndicator: FC = () => {
  return (
    <StyledProgressIndicator>
      <div>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" />
        </svg>
      </div>
    </StyledProgressIndicator>
  );
};
