import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { Dispatch, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDialogKeyEnums, ILocalState } from "../../../@types/index.d";

import {
  GET_ALL_PRICINGS,
  GET_COUPONS_LIST,
  GET_CUSTOMER_STATUS,
  GET_FEATURES_DATA,
  GET_PROMOTIONS_DATA,
  GET_SUBSCRIPTIONS,
  UPDATE_BILLING_ADDRESS,
} from "./appStore-queries";
import {
  IGetAllPricings,
  IGetCouponList,
  IGetCustomerStatus,
  IGetFeaturesData,
  IGetPromotionsData,
  IPromotionsDataRetVal,
  ISubscriptionData,
  IUpdateBillingAddressRetVal,
  IUpdateBillingAddressVars,
} from "./appStoreTypes";
import { EAppStoreActionType, IAppStoreAction } from "../../store/reducers/appStore";
import { useDialogs } from "../useDialogs";

export interface IUseAppStoreInfoRetVal {
  getSubscriptions: (customer_id: string) => void;
  getCustomerStatus: (customer_id: string) => void;
  getAllPricings: (product_id: string) => void;
  getCouponList: (product_id: string) => void;
  getFeaturesData: () => void;
  getPromotionsData: (customer_id: string) => void;
  loading?: boolean;
  updateBillingAddress: (
    options?:
      | MutationFunctionOptions<
          IUpdateBillingAddressRetVal,
          IUpdateBillingAddressVars,
          DefaultContext,
          ApolloCache<IUpdateBillingAddressRetVal>
        >
      | undefined
  ) => Promise<FetchResult>;
}

export const useAppStoreInfo = (): IUseAppStoreInfoRetVal => {
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const apolloClient = useApolloClient();
  const dialogs = useDialogs();
  const { promotionsData } = useSelector((state: ILocalState) => state.appStore);
  const promotionsDataRef = useRef(promotionsData);
  promotionsDataRef.current = promotionsData;

  const updateBillingFnOnCompleted = (data: IUpdateBillingAddressRetVal): void => {
    if (!data) return;
    dispatchAppStore({
      type: EAppStoreActionType.SET_BILLING_ADDRESS,
      payload: { billingAddress: data.updateBillingAddress },
    });
  };

  // patch request to update billing details in billing
  const [updateBillingAddress] = useMutation<
    IUpdateBillingAddressRetVal,
    IUpdateBillingAddressVars
  >(UPDATE_BILLING_ADDRESS, {
    fetchPolicy: "network-only",
    onCompleted: updateBillingFnOnCompleted,
  });

  // to fill redux  with features data
  const getFeaturesData = (): void => {
    apolloClient
      .query<IGetFeaturesData>({
        query: GET_FEATURES_DATA,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.getFeaturesData) {
          dispatchAppStore({
            type: EAppStoreActionType.SET_FEATURES_DATA,
            payload: { featuresData: data.getFeaturesData },
          });
        }
      });
  };

  // to fill redux  with promotions data
  const getPromotionsData = async (customer_id: string): Promise<IGetPromotionsData[]> => {
    return apolloClient
      .query<IPromotionsDataRetVal>({
        query: GET_PROMOTIONS_DATA,
        variables: { customer_id },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.promoFeatures) {
          dispatchAppStore({
            type: EAppStoreActionType.SET_PROMOTIONS_DATA,
            payload: { promotionsData: data.promoFeatures },
          });
        }
        return data.promoFeatures;
      });
  };

  // to fill redux  with coupon info
  const getCouponList = (product_id: string): void => {
    if (!product_id) return;
    apolloClient
      .query<IGetCouponList, { product_id: string }>({
        query: GET_COUPONS_LIST,
        variables: { product_id },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.getCouponList.campaigns) {
          dispatchAppStore({
            type: EAppStoreActionType.SET_COUPON_LIST,
            payload: { couponList: data.getCouponList.campaigns },
          });
        }
      });
  };

  // to fill redux  with subscription info before rendering the dashboard (in useAuthState hook)
  const getSubscriptions = (customer_id: string): void => {
    if (!customer_id) return;
    apolloClient
      .query<ISubscriptionData, { customer_id: string }>({
        query: GET_SUBSCRIPTIONS,
        variables: { customer_id },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.result) {
          dispatchAppStore({
            type: EAppStoreActionType.SET_SUBSCRIPTION_DATA,
            payload: { subscriptions: data.result },
          });
        }
      });
  };

  // to fill redux  with getCustomer-appStore info including features array before rendering the dashboard (in useAuthState hook)
  const getCustomerStatus = (customer_id: string): void => {
    if (!customer_id) return;
    // call get promo features from marketing backend
    getPromotionsData(customer_id)
      .then(() => {
        return apolloClient.query<IGetCustomerStatus, { customer_id: string }>({
          query: GET_CUSTOMER_STATUS,
          variables: { customer_id },
          fetchPolicy: "network-only",
        });
      })
      .then(({ data }) => {
        const customerData = data && data.customerData;

        // dispatch customer data to redux
        dispatchAppStore({
          type: EAppStoreActionType.SET_CUSTOMER_DATA,
          payload: {
            customerData: {
              customer_id: customerData.customer_id,
              product_id: customerData.product_id,
              pricing_id: customerData.pricing_id,
              status: customerData.status,
              start_date: customerData.start_date,
              trial_end: customerData.trial_end,
              canceled_at: customerData.canceled_at,
              features: customerData.features,
              payment_method: customerData.payment_method,
            },
          },
        });

        // generate an array of feature ids to be used in render rules

        dispatchAppStore({
          type: EAppStoreActionType.SET_BILLING_ADDRESS,
          payload: { billingAddress: customerData.billing_address },
        });

        // to be able to close the confirm dialog at the correct moment when a feature is booked or canceled
        dialogs.close(IDialogKeyEnums.CONFIRM_PAYMENT);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // to fill redux  with pricing info before rendering the dashboard (in useAuthState hook)
  const getAllPricings = (product_id: string): void => {
    if (!product_id) return;
    apolloClient
      .query<IGetAllPricings, { product_id: string }>({
        query: GET_ALL_PRICINGS,
        variables: { product_id: product_id },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.pricings) {
          dispatchAppStore({
            type: EAppStoreActionType.SET_PRICINGS,
            payload: { pricings: data.pricings },
          });
        }
      });
  };

  return {
    getSubscriptions,
    getCustomerStatus,
    getAllPricings,
    getCouponList,
    getFeaturesData,
    getPromotionsData,
    updateBillingAddress,
  };
};
