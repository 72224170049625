import "styled-components/macro";

import { SimpleInterpolation } from "styled-components/macro";
export interface IColor {
  color: string;
  background: string;
  inverted: {
    color: string;
    background: string;
  };
}
export interface IFontWeight {
  normal: number;
  bold: number;
  bolder: number;
}
/**
 * Static values required to build a dynamic Theme
 */
export interface IThemeValues {
  branded: IBrandedValues;
  baseFontSize: number;
  fontWeight: IFontWeight;
  fontFamily: {
    headline: string;
    copy: {
      primary: string;
      secondary: string;
    };
  };
  webFontsConfig: {
    google?: { families: string[] };
    custom: {
      families: string[];
      urls: string[];
    };
    classes?: boolean;
  };
  colors: {
    global: IColors;
  };
  // contentNav: { backgroundColor: string; color: string };
  // contentBody: { backgroundColor: string; color: string };

  component: {
    color: string;
    backgroundColor: string;
    header: {
      color: string;
      backgroundColor: string;
    };
    title: { color: string; backgroundColor: string; fontWeight: number };
    subTitle: { color: string; backgroundColor: string; fontWeight: number };
    content: { color: string; backgroundColor: string };
    borderColors: { basic: string };
  };
}

export interface IColorStates {
  //__typename: TypeNameEnums.THEME_OPTIONS_COLORS;
  base: IColor;
  hover: IColor;
  disabled: IColor;
}

export interface IColors {
  primary: {
    [key: string]: string;
  };
  secondary: {
    [key: string]: string;
  };
  greys: {
    [key: string]: string;
  };
  validation: {
    [key: string]: string;
  };
  diagram: {
    [key: string]: string;
  };
}

export enum EProductEnums {
  DIGITAL = "DIGITAL",
  GOLD = "GOLD",
}
export interface IBrandedLogoTypes {
  light: string;
  dark: string;
}

export enum EAssetType {
  APP_LOGO = "app_logo",
  APP_BACKGROUND_IMAGE = "app_background_image",
  LOGIN_LOGO = "login_logo",
  LOGIN_BACKGROUND_IMAGE = "login_background_image",
}

export interface IAsset {
  type: EAssetType;
  href: string;
  default?: boolean;
}

export type TLogo = Record<EProductEnums, IBrandedLogoTypes>;

export interface IBrandedValues {
  //__typename: TypeNameEnums.THEME_OPTIONS_BRANDED;
  colors: IBrandedColors;
  newLogo: TLogo;
  logo: {
    //__typename: TypeNameEnums.THEME_OPTIONS_LOGO;
    normal: string;
    inverted: string;
  };
  assets: IBrandedAssets;
  buttons: Record<string, IBrandedButtonsOptions>;
  inputs: Record<string, IBrandedInputsOptions>;
}

export interface IBrandedColors {
  app_opacity: string;
  primary: string;
  secondary: string;
  app_background_color: string;
  nav_background_color: string;
  nav_text_color: string;
  login_background_color: string;
}

export interface IBrandedAssets {
  app_logo: IAsset;
  app_background_image: IAsset;
  login_logo: IAsset;
  login_background_image: IAsset;
}

export interface IBrandedButtonsOptions {
  color: IColorStates;
  radius: string;
}

export interface IBrandedInputsOptions {
  borderColor: string;
  radius: string;
  invalidText: string;
  backgroundColor: string;
}

export interface IBreakPoints {
  mini: IBreakPointState;
  small?: IBreakPointState;
  medium?: IBreakPointState;
  large?: IBreakPointState;
  xlarge?: IBreakPointState;
  // wide?: IBreakPointState;
}

export interface IBreakPointState {
  basic?: SimpleInterpolation;
  portrait?: SimpleInterpolation;
  landscape?: SimpleInterpolation;
  retina?: SimpleInterpolation;
}

export interface IDevice {
  isMini: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  // isWide: boolean;
  orientation: OrientationEnums;
  isPortrait: boolean;
  isLandscape: boolean;
  isRetina: boolean;
}

export enum LayoutEnums {
  SIMPLE, // ex: login page
  COMMON, // ex: dashboard
  SETUP, // ex: client management pages
}
export enum PortraitBreakPointEnums {
  MINI = 0,
  SMALL = 375,
  MEDIUM = 768,
  LARGE = 1024,
  XLARGE = 1440,
  // WIDE = 1440,
}

export enum LandscapeBreakPointEnums {
  MINI = 0,
  SMALL = 812,
  MEDIUM = 1024,
  LARGE = 1366,
  XLARGE = 1920,
  // WIDE = 1920,
}

export enum OrientationEnums {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
}

/**
 * Extend ``DefaultTheme`` from ``styled-components`` to use internally as planContext
 * @ignore
 */
export declare module "styled-components/macro" {
  export declare class DefaultTheme {
    values: IThemeValues;
    branded: IBrandedValues;
    device: IDevice;
    layout: LayoutEnums;
    sideNavExpanded: boolean;
    rem(val: number): string;
  }
}
