import styled, { css } from "styled-components/macro";
import {
  IInputConstructorProps,
  IInputLabelPosition,
  IInputVariantEnums,
} from "../../../@types/index.d";

export const FullWidthContainer = styled.div(() => {
  return css`
    width: 100%;
  `;
});
export const ItemWrapper = styled.div<Pick<IInputConstructorProps, "labelPosition">>(
  ({ theme, labelPosition }) => {
    return css`
      display: flex;
      flex-wrap: ${labelPosition === IInputLabelPosition.TOP || IInputLabelPosition.TOPLEFT
        ? "wrap"
        : "unset"};
      flex-basis: 100%;
      margin-bottom: ${theme.rem(0.25)};
    `;
  }
);

/**
 * Styled Label
 */
export const Label = styled.label<Pick<IInputConstructorProps, "labelPosition" | "level">>(
  ({ theme, labelPosition, level }) => {   
    const posLeft = css`
      text-align: right;
      flex-basis: ${100 / 3}%;
    `;
    const posTop = css`
      text-align: center;
      flex-basis: 100%;
    `;

    const posTopLeft = css`
      text-align: left;
      flex-basis: 40%;
    `;

    return css`
      ${labelPosition === IInputLabelPosition.LEFT && posLeft}
      ${labelPosition === IInputLabelPosition.TOP && posTop}
      ${labelPosition === IInputLabelPosition.TOPLEFT && posTopLeft}
      align-self: unset;
      max-width: 100%;
      overflow: hidden;
      padding-top: ${theme.rem(0.25)};
      padding-right: ${theme.rem(1)};
      margin-bottom: ${theme.rem(0.25)};
      font-weight: ${theme.values.fontWeight.bolder};
      ${level === "upper" && "text-transform: uppercase;"}
    `;
  }
);

export const Warning = styled.div<Pick<IInputConstructorProps, "variant">>(
  ({ theme, variant = IInputVariantEnums.SECONDARY }) => {
    return css`
      text-align: right;
      color: ${theme.branded.inputs[variant].invalidText};
      font-size: ${theme.rem(0.75)};
    `;
  }
);

export const BrandedItemWrapper = styled.div<Pick<IInputConstructorProps, "labelPosition">>`
  width: 100%;
`;

export const StyledBrandedLabel = styled.label<Pick<IInputConstructorProps, "labelPosition">>(
  ({ theme, labelPosition }) => {
    return css`
      display: inline-block;
      max-width: 100%;
      margin-bottom: ${theme.rem(0.25)};
      font-weight: ${theme.values.fontWeight.bolder};
      flex-basis: 40%;
    `;
  }
);

export const StyledFormElementContainer = {
  FullWidthContainer,
  ItemWrapper,
  Label,
  Warning,
};

export const StyledBrandedFormElementContainer = {
  FullWidthContainer,
  ItemWrapper: BrandedItemWrapper,
  Label: StyledBrandedLabel,
  Warning,
};
