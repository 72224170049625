import styled, { css, DefaultTheme, SimpleInterpolation } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
interface ITextEditorStyleOptions {
  remove: string;
  save: string;
  edit: string;
  theme: DefaultTheme;
}
type TTextEditorStyle = (options: ITextEditorStyleOptions) => SimpleInterpolation;
const TextEditorStyle: TTextEditorStyle = ({ remove, save, edit, theme }) => {
  return css`
    .ql-snow .ql-tooltip a.ql-remove::before {
      content: "${remove}";
    }

    .ql-snow .ql-tooltip a.ql-action::after {
      content: "${edit}";
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      content: "${save}";
    }

    .ql-container {
      font-family: ${theme.values.fontFamily.copy.primary};
      font-size: ${theme.rem(1)};
    }

    .ql-container.ql-snow {
      border: none;
      height: ${theme.rem(10)};
    }

    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid #ccc;
    }
    .ql-snow .ql-tooltip {
      white-space: unset;
    }
  `;
};

export const StyledTextEditor = styled.div(({ theme }) => {
  const { t } = useTranslation();
  const snow = TextEditorStyle({
    remove: t("misc.remove"),
    save: t("misc.save"),
    edit: t("misc.edit"),
    theme,
  });
  return css`
    width: 100%;
    & * {
      ${snow}
    }
  `;
});
