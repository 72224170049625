import { Children, FC } from "react";
import { StyledButtonBranded, StyledBrandedButtonText } from "./styled.button-branded";
import { ButtonVariantEnums, IButtonProps } from "./Button";

export interface IButtonBrandedProps extends Omit<IButtonProps, "variant"> {
  /** Visual type of the button */
  variant: ButtonVariantEnums;
}
/**
 * Themeable Buttons extends App Buttons, with it's own variants.
 *
 */
export const ButtonBranded: FC<IButtonBrandedProps> = (props: IButtonBrandedProps) => {
  return (
    <>
      <StyledButtonBranded {...props}>
        {props.icon}
        {Children.count(props.children) > 0 && (
          <StyledBrandedButtonText icon={props.icon}>{props.children}</StyledBrandedButtonText>
        )}
      </StyledButtonBranded>
    </>
  );
};
