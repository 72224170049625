import { EPlanningSessionStorage } from "../../@types/index.d";

interface IUsePlanningHelperRetVal {
  setSessionStorage: (type: EPlanningSessionStorage, data: string) => void;
  clearSessionStorage: () => void;
}

export const useSessionStorage = (): IUsePlanningHelperRetVal => {
  // function to set session storage
  const setSessionStorage = (key: EPlanningSessionStorage, newValue: string): void => {
    // get old value
    const oldValue = sessionStorage.getItem(key);

    // set new value
    sessionStorage.setItem(key, newValue);

    // construct event
    const event = new StorageEvent("storage", {
      key,
      oldValue,
      newValue,
    });

    // dispatch event
    window.dispatchEvent(event);
  };

  // function to clear session storage of custom keys
  const clearSessionStorage = (): void => {
    // loop through planning keys and remove them
    for (const key of Object.values(EPlanningSessionStorage)) {
      sessionStorage.removeItem(key);
    }
  };

  return {
    setSessionStorage,
    clearSessionStorage,
  };
};
