import { FC } from "react";
import { AlignmentEnums, IDialogKeyEnums, IDialogOptions } from "../../@types/index.d";
import { useTranslation } from "react-i18next";
import { useDialogs } from "../../_lib/hooks";
import { StyledRichDialog } from "./styled.rich-dialog";
import { Icon, IconNameEnums, IconSizeEnums } from "../Icon";
import { Button, ButtonVariantEnums, IconButton } from "../formElements/Button";
import { StyledDialogFooter, StyledDialogTitleClose } from "./styled.dialog";
import { FormRow } from "../formElements/Form";

const PracticalTipsIcon: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Icon name={IconNameEnums.BULB} size={IconSizeEnums.LARGE} />
      <span>{t("practical_tips.label")}</span>
    </>
  );
};
const PracticalTipsClose: FC<Partial<IDialogOptions>> = ({ onClose }) => {
  const { t } = useTranslation();
  const dialogs = useDialogs();
  return (
    <>
      <FormRow align={AlignmentEnums.RIGHT}>
        <Button
          variant={ButtonVariantEnums.SECONDARY}
          inverted={false}
          type={"button"}
          inline={true}
          onClick={(): void => {
            dialogs.close(IDialogKeyEnums.PRACTICAL_TIPS);
            onClose && onClose();
          }}
          data-test-id={IDialogKeyEnums.PRACTICAL_TIPS}
        >
          {t("misc.close")}
        </Button>
      </FormRow>
    </>
  );
};

const WarningIcon: FC = () => <Icon name={IconNameEnums.EXCLAMATION} size={IconSizeEnums.LARGE} />;

export const RichDialog: FC<IDialogOptions> = ({
  title,
  closable,
  dialogKey,
  onClose,
  content,
  footer,
}) => {
  const dialogs = useDialogs();

  return (
    <StyledRichDialog.Container>
      <StyledRichDialog.Row>
        {dialogKey === IDialogKeyEnums.PRACTICAL_TIPS && <PracticalTipsIcon />}
        {dialogKey === IDialogKeyEnums.WARNING && <WarningIcon />}
        {dialogKey === IDialogKeyEnums.NO_PLAN_WARNING && <WarningIcon />}
        {dialogKey === IDialogKeyEnums.DELETE_REPORT && <WarningIcon />}
      </StyledRichDialog.Row>
      <StyledRichDialog.Headline>
        <StyledRichDialog.Title>{title}</StyledRichDialog.Title>
        {closable && (
          <StyledDialogTitleClose>
            <IconButton
              icon={<Icon name={IconNameEnums.TIMES} size={IconSizeEnums.SMALL} />}
              variant={ButtonVariantEnums.PRIMARY}
              type={"button"}
              inverted={false}
              onClick={(): void => {
                dialogs.close(dialogKey);
                onClose && onClose();
              }}
            />{" "}
          </StyledDialogTitleClose>
        )}
      </StyledRichDialog.Headline>
      <StyledRichDialog.Content>
        {content}
        {dialogKey === IDialogKeyEnums.PRACTICAL_TIPS && <PracticalTipsClose onClose={onClose} />}
      </StyledRichDialog.Content>
      {footer && <StyledDialogFooter>{footer}</StyledDialogFooter>}
    </StyledRichDialog.Container>
  );
};
