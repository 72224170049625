import { FC, ReactElement } from "react";
import { IIconProps } from "../../Icon";
import { StyledIconButton } from "./styled.icon-button";
import { ButtonVariantEnums } from "./Button";

export interface IconButtonProps {
  /** name of element which is used to identify in form planContext*/
  name?: string;
  /** Icon element to be rendered inside the button */
  icon: ReactElement<FC<IIconProps>>;
  /** Inverts Color and Background IColor */
  inverted: boolean;
  /** Visual type of the button */
  variant: ButtonVariantEnums;
  /** type of button element*/
  type: "submit" | "reset" | "button";
  /** disabled state of the button*/
  disabled?: boolean;
  /** Function to be called on click event */
  onClick?: <P>(params: P) => unknown;
}

/**
 * Icon Only Button
 */
export const IconButton: FC<IconButtonProps> = ({ children, icon, ...props }) => {
  return <StyledIconButton {...props}>{icon}</StyledIconButton>;
};
