import { FC } from "react";
import { ComponentLoader } from "./ComponentLoader";
import { IPageLoaderProps, PageLoader } from "./PageLoader";
import { ProgressIndicator } from "./ProgressIndicator";
import { IInputLoader, IInputLoaderProps } from "./InputLoader";
import { ITextLoaderProps, TextLoader } from "./TextLoader";

export interface LoadingIndicatorProps {
  type: LoaderTypeEnums;
}
export enum LoaderTypeEnums {
  COMPONENT = "COMPONENT",
  PAGE = "PAGE",
  PROGRESS = "PROGRESS",
  INPUT_FIELD = "INPUT_FIELD",
  TEXT = "TEXT",
}
export const LoadingIndicator: FC<
  LoadingIndicatorProps & IPageLoaderProps & IInputLoaderProps & ITextLoaderProps
> = ({ type, ...props }) => {
  switch (type) {
    case LoaderTypeEnums.PAGE:
      return <PageLoader {...props} />;
    case LoaderTypeEnums.COMPONENT:
      return <ComponentLoader {...props} />;
    case LoaderTypeEnums.PROGRESS:
      return <ProgressIndicator {...props} />;
    case LoaderTypeEnums.INPUT_FIELD:
      return <IInputLoader {...props} />;
    case LoaderTypeEnums.TEXT:
      return <TextLoader {...props} />;
    default:
      return <ComponentLoader />;
  }
};
