import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";

import initialData from "../initialState";
import { EventListenerCodes } from "../../hooks/useEventListener/types.event-listener";

export enum IEventsActionTypes {
  UPDATE_EVENTS_STATE = "UPDATE_EVENTS_STATE",
  REMOVE_EVENT = "REMOVE_EVENT",
  RESET_EVENTS_STATE = "RESET_EVENTS_STATE",
  EVENT_IN_PROGRESS = "EVENT_IN_PROGRESS",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  IEventsActionTypes,
  ActionCreatorWithPayload<Partial<IEventStatePayload>, IEventsActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(IEventsActionTypes) as Array<keyof typeof IEventsActionTypes>) {
  actionFunctions[IEventsActionTypes[key]] = createAction(IEventsActionTypes[key]);
}

export interface IEventsAction {
  type: IEventsActionTypes;
  payload?: Partial<IEventStatePayload>;
}

export interface IEvent {
  transactionId: string;
  value: EventListenerCodes;
}

export interface IEventsState {
  [key: string]: EventListenerCodes | IEvent;
}

export interface IEventStatePayload extends IEventsState {
  event: IEvent;
}

const eventsState = createReducer(initialData.eventsState, (builder) => {
  builder
    .addCase(actionFunctions[IEventsActionTypes.UPDATE_EVENTS_STATE], (state, action) => {
      if (!action.payload.event) return state;
      const event = action.payload.event;
      state[event.transactionId] = event.value;
    })
    .addCase(actionFunctions[IEventsActionTypes.REMOVE_EVENT], (state, action) => {
      if (!action.payload.event) return state;
      const event = action.payload.event;
      delete state[event.transactionId];
    })
    .addCase(actionFunctions[IEventsActionTypes.RESET_EVENTS_STATE], (state, action) => {
      state = initialData.eventsState;

      return state;
    });
});

export default eventsState;
