import { gql, QueryLazyOptions, useLazyQuery } from "@apollo/client";
import {
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  IActualSuSa,
  ILocalState,
} from "../../@types/index.d";
import { useSelectedClientData } from "./useSelectedClientData";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction, UserStateActionTypes } from "../store/reducers";
import { isEqual } from "lodash";
import { EventListenerCodes } from "./useEventListener/types.event-listener";
import { useEventListener } from "./useEventListener";

export interface IUploadedSuSa extends IActualSuSa {
  plans_count: number;
  uploaded_at: string;
}
interface IGetUploadsListVariables {
  client_id: string;
}
const GET_UPLOADS_LIST = gql`
  query getUploads($client_id: ID!) {
    getAllSuSasOfClient(client_id: $client_id, withPlans: true) {
      date
      format
      plans_count
      uploaded_at
      susa_id
    }
  }
`;
const DELETE_SUSA = gql`
  query deleteSuSa($susa_id: ID!, $links: [RelationLinkInput]!) {
    deleteSuSa(susa_id: $susa_id, links: $links)
  }
`;

// returns the scope value which will be used in transaction call for susa deletion
const getDeleteTransactionScopeVal = (
  susaId: string,
  list: IUploadedSuSa[] | undefined
): EDataSyncScope | undefined => {
  const isThereAttachedPlan =
    list && !!list.find(({ susa_id }) => susaId === susa_id)?.plans_count ? true : false; // to check whether the new susa date has attached plan - if the one above variable is true

  if (isThereAttachedPlan) {
    return EDataSyncScope.PLAN;
  } else {
    return EDataSyncScope.SUSA;
  }
};

export interface IUseEditUploadListReturnValue {
  deleteInProgress: string[];
  deleteUploadBySuSaId: TDeleteUploadsBySuSaId;
}
type TDeleteUploadsBySuSaId = (susa_id: string) => void;
export const useEditUploadList = (): IUseEditUploadListReturnValue => {
  const eventsState = useSelector(({ eventsState }: ILocalState) => eventsState, isEqual);
  const selectedClient = useSelector(
    ({ currentUser: { selectedClient } }: ILocalState) => selectedClient,
    isEqual
  );

  const [susaIdTobeDeleted, setSusaIdTobeDeleted] = useState<string | undefined>(undefined);
  const [deleteInProgress, setDeleteInProgress] = useState<string[]>([]);

  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const { getUploads } = useUpdateUploadsList();
  const { startEventSync } = useEventListener();
  const selectedClientData = useSelectedClientData();

  // in case of an error, to cancel the loading spinner
  const onErrorDeleteSusa = (): void => {
    dispatchUser({
      type: UserStateActionTypes.EVENT_IN_PROGRESS,
      payload: { eventInProgress: false },
    });
  };

  // to send the transaction when the deletion is done and to start the loading spinner
  const onCompletedDeleteSusa = (data: { deleteSuSa: string }): void => {
    if (selectedClientData === false || !data) return;
    const transactionId = data.deleteSuSa as string;
    if (!transactionId) return;
    dispatchUser({
      type: UserStateActionTypes.EVENT_IN_PROGRESS,
      payload: { eventInProgress: true },
    });
    startEventSync({
      transactionId,
      accountId: selectedClientData.client_id,
      level: EDataSyncLevel.CLIENT,
      type: EDataSyncTypes.DELETE,
      scope: getDeleteTransactionScopeVal(deleteInProgress[0], selectedClient?.uploads?.list),
    });
    // setUpdateListAfterDelete(true);
  };

  const [deleteSuSa, { data: deletedCovid }] = useLazyQuery(DELETE_SUSA, {
    fetchPolicy: "network-only",
    onError: onErrorDeleteSusa,
    onCompleted: onCompletedDeleteSusa,
  });

  useEffect(() => {
    if (selectedClientData === false) return;
    if (susaIdTobeDeleted) {
      setDeleteInProgress([...deleteInProgress, susaIdTobeDeleted]);
      deleteSuSa({
        variables: {
          susa_id: susaIdTobeDeleted,
          links: selectedClientData.links,
        },
      });
      setSusaIdTobeDeleted(undefined);
    }
  }, [selectedClientData, susaIdTobeDeleted, deleteSuSa, deleteInProgress, dispatchUser]);

  useEffect(() => {
    const transactionId = deletedCovid?.deleteSuSa as string;
    if (!transactionId || Object.keys(eventsState).length === 0) return;
    if (
      (eventsState[transactionId] === EventListenerCodes.TRANSACTION_OK ||
        eventsState[transactionId] === EventListenerCodes.TIME_OUT) &&
      selectedClientData !== false
    ) {
      getUploads({ variables: { client_id: selectedClientData.client_id } });
    }
  }, [eventsState, getUploads, selectedClientData, deletedCovid?.deleteSuSa]);

  const deleteUploadBySuSaId: TDeleteUploadsBySuSaId = (susa_id: string) => {
    if (selectedClientData === false) return;
    setSusaIdTobeDeleted(susa_id);
  };

  return {
    deleteInProgress,
    deleteUploadBySuSaId,
  };
};

export interface IUseUpdateUploadsListReturnValue {
  getUploads: (options?: QueryLazyOptions<IGetUploadsListVariables>) => void;
}

export const useUpdateUploadsList = (): IUseUpdateUploadsListReturnValue => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const [getUploads, { data, loading: listLoading, called: listCalled }] = useLazyQuery(
    GET_UPLOADS_LIST,
    {
      fetchPolicy: "network-only",
    }
  );

  const uploadsList: IUploadedSuSa[] = data?.getAllSuSasOfClient;
  useEffect(() => {
    if (uploadsList) return;
    if (currentUser.selectedClient.uploads?.loading !== listLoading && listCalled) {
      dispatchUser({
        type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
        payload: {
          selectedClient: {
            uploads: {
              loading: listLoading,
            },
          },
        },
      });
    }
  }, [currentUser.selectedClient.uploads, dispatchUser, listLoading, listCalled, uploadsList]);

  useEffect(() => {
    if (!uploadsList) return;
    dispatchUser({
      type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
      payload: {
        selectedClient: {
          uploads: {
            loading: false,
            list: uploadsList,
          },
        },
      },
    });
  }, [dispatchUser, uploadsList, listLoading]);

  return {
    getUploads,
  };
};
