import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import { IReportEntryItem } from "../../../@types";
import initialData from "../initialState";

export enum IReportsActionTypes {
  SET_REPORTS = "SET_REPORTS",
  SET_SELECTED_REPORT = "SET_SELECTED_REPORT",
  SET_REPORTING_LOADING_STATUS = "SET_REPORTING_LOADING_STATUS",
  // UPDATE_REPORTS = "UPDATE_REPORTS",
}

export interface IReportsContext {
  reports: IReportEntryItem[];
  reportingLoadingStatus: boolean;
  // updateReports: IReportEntryItem;
  selectedReport: Partial<IReportEntryItem> | undefined;
  selectedReportId: string | undefined;
}

// initialize empty functions object ([IReportsActionTypes]: function())
const actionFunctions = {} as Record<
  IReportsActionTypes,
  ActionCreatorWithPayload<Partial<IReportsContext>, IReportsActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(IReportsActionTypes) as Array<keyof typeof IReportsActionTypes>) {
  actionFunctions[IReportsActionTypes[key]] = createAction(IReportsActionTypes[key]);
}

export interface IReportsAction {
  payload: Partial<IReportsContext>;
  type: IReportsActionTypes;
}

const reporting = createReducer(initialData.reporting, (builder) => {
  builder
    .addCase(actionFunctions[IReportsActionTypes.SET_REPORTS], (state, action) => {
      if (!action.payload.reports) return state;

      state.reports = action.payload.reports;

      // to be able to stop loading spinner
      if (state.reportingLoadingStatus) {
        state.reportingLoadingStatus = false;
      }
    })
    .addCase(actionFunctions[IReportsActionTypes.SET_SELECTED_REPORT], (state, action) => {
      state.selectedReport = action.payload.selectedReport;
      state.selectedReportId = action.payload.selectedReport?.report_id;
    })
    .addCase(actionFunctions[IReportsActionTypes.SET_REPORTING_LOADING_STATUS], (state, action) => {
      if (action.payload?.reportingLoadingStatus === undefined) return state;
      state.reportingLoadingStatus = action.payload.reportingLoadingStatus;
    })
    // .addCase(actionFunctions[IReportsActionTypes.UPDATE_REPORTS], (state, action) => {
    //   if (!action.payload.updateReports) return state;

    //   const currentState = cloneDeep(current(state));
    //   if (!currentState || !currentState.reports || !state.reports) return state;
    //   const editedReportIndex = currentState.reports.findIndex(
    //     (report) => report.report_id === action.payload.updateReports?.report_id
    //   );
    //   // update edited report in state.reports
    //   state.reports[editedReportIndex] = action.payload.updateReports;

    //   // if there is selectedReportId, set the report id too, this action field is optional
    //   if (action.payload.selectedReportId) {
    //     state.selectedReportId = action.payload.selectedReportId;
    //   }

    //   // save new report
    //   state.selectedReport = action.payload.updateReports;
    // })
    .addDefaultCase((state) => state);
});

export default reporting;
