import styled, { css } from "styled-components/macro";
import { StyledViewOptions } from "../../../../StyledComponents";
export interface ICurrentGradeOptions {
  pending?: boolean;
  currentGrade?: number;
  prevGrade?: number;
}

export const ReportSummary = styled.div<Partial<ICurrentGradeOptions>>(({ theme, pending }) => {
  return css`
    padding: 0 ${theme.rem(1.5)};
    text-align: center;
    line-height: ${theme.rem(1.5)};
    transition: opacity 300ms;
    opacity: ${pending ? 0.1 : 1};
    & > span:not([class]) {
      font-size: ${theme.rem(2)};
      font-weight: ${theme.values.fontWeight.bold};
    }
  `;
});

export const Report = styled.div(() => {
  return css`
    display: block;
  `;
});

export const StyledDashboardOptions = {
  ...StyledViewOptions,
  Report,
  ReportSummary,
};
