import styled, { css } from "styled-components/macro";
import { IInputConstructorProps, IInputVariantEnums } from "../../../@types/index.d";

export const StyledInputButton = styled.div<Pick<IInputConstructorProps, "variant">>(
  ({ theme, variant = IInputVariantEnums.SECONDARY }) => {
    const { borderColor } = theme.branded.inputs[variant];
    const color = theme.branded.buttons[variant].color.base.background;
    return css`
      width: ${theme.rem(2)};
      border-left: 1px solid ${borderColor};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      align-self: stretch;
      color: ${color};
      > button {
        padding: 0;
      }
    `;
  }
);

export const StyledBrandedInputButton = styled.div<Pick<IInputConstructorProps, "variant">>(
  ({ theme, variant = IInputVariantEnums.SECONDARY }) => {
    const color = theme.branded.buttons[variant].color.base.background;
    return css`
      width: ${theme.rem(2)};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      align-self: stretch;
      color: ${color};
    `;
  }
);
