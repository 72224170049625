import { FC } from "react";
import { Client, IDialogKeyEnums, ILocalState, SelectClientState } from "../../@types/index.d";
import {
  StyledClientList,
  StyledClientListItem,
  StyledFreeContingentItem,
  StyledGetMoreItem,
} from "./styled.client-list";
import { Button, ButtonVariantEnums } from "../../components/formElements/Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../components/Icon";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { P } from "../../components/Typography";
import { useDialogs, usePlans, useSwitchClient } from "../../_lib/hooks";

import { useSelector } from "react-redux";
import { ExistingClient } from "./ExistingClient";
import { isEqual } from "lodash";

interface IClientsList {
  testID?: string;
}
interface IFreeContingent {
  testID: string;
}
interface IGetMoreClients {
  testID: string;
}
export const clientsListTestID = "ClientsList";
export const existingClientTestID = "ExistingClient";
export const freeContingentTestID = "FreeContingent";
export const getMoreClientsTestID = "GetMoreClients";

const FreeContingent: FC<IFreeContingent> = ({ testID }) => {
  const { t } = useTranslation();

  const dialogs = useDialogs();
  const { switchClient } = useSwitchClient();

  const handleAddNewClient = (e: React.MouseEvent<HTMLAnchorElement | HTMLInputElement>): void => {
    e.preventDefault();
    dialogs.close(IDialogKeyEnums.CLIENT_MANAGEMENT_DIALOG);
    switchClient(null, SelectClientState.INITIAL);
  };
  return (
    <>
      <StyledFreeContingentItem to="#" onClick={handleAddNewClient} data-test-id={testID}>
        <Icon name={IconNameEnums.PLUS} size={IconSizeEnums.XLARGE} />
        <h4>{t("dialogs.client_management.add_new")}</h4>
        <span data-expires="">
          <Trans i18nKey={"dialogs.client_management.expires_at"} values={{ date: "01.01.2021" }} />
        </span>
      </StyledFreeContingentItem>
    </>
  );
};
const GetMoreClients: FC<IGetMoreClients> = ({ testID }) => {
  return (
    <>
      <StyledGetMoreItem to="#" data-new-abo="" data-test-id={testID}>
        <P>
          <Trans i18nKey={"dialogs.client_management.info_need_more"} />
        </P>

        <Button type={"button"} variant={ButtonVariantEnums.SECONDARY} inverted={false}>
          <Trans
            i18nKey={"dialogs.client_management.add_more_clients"}
            values={{ date: "01.01.2021" }}
          />
        </Button>
      </StyledGetMoreItem>
    </>
  );
};

export const ClientsList: FC<IClientsList> = ({ testID = clientsListTestID }) => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const clientsLimit = currentUser.clients.length + 5;
  const freeContingent = clientsLimit - currentUser.clients.length;
  const { callGetPlans } = usePlans();
  const dialogs = useDialogs();

  return (
    <StyledClientList data-test-id={testID}>
      {currentUser.clients.map((client: Client, index: number) => (
        <StyledClientListItem key={client.client_id}>
          <ExistingClient
            index={index}
            total={clientsLimit}
            name={client.name}
            onSelect={(): void => {
              // to get the plans of the selected client
              callGetPlans(client.client_id);
              dialogs.close(IDialogKeyEnums.CLIENT_MANAGEMENT_DIALOG);
            }}
            client_id={client.client_id}
            customer_id={currentUser.appUser.customer_id}
            testID={`${testID}${existingClientTestID}`}
          />
        </StyledClientListItem>
      ))}
      {new Array(freeContingent)
        .fill(<FreeContingent testID={`${testID}${freeContingentTestID}`} />, 0)
        .map((free, index) => (
          <StyledClientListItem key={`free-${index}`}>{free}</StyledClientListItem>
        ))}
      <StyledClientListItem key={"last-item"}>
        <GetMoreClients testID={`${testID}${getMoreClientsTestID}`} />
      </StyledClientListItem>
    </StyledClientList>
  );
};
export const ClientsListFooter: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  return (
    <div>
      <P>
        <Trans
          i18nKey={`dialogs.client_management.footnotes.booked`}
          values={{ total: 10, used: currentUser.clients.length }}
        />
        {/*TODO: total booked currently hardcoded. replace with real values*/}
      </P>
      <P>
        <Trans i18nKey={`dialogs.client_management.footnotes.link`}>
          <Link to="#">go to client management</Link>
        </Trans>
      </P>
    </div>
  );
};
