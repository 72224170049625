import React, { ErrorInfo, ReactElement } from "react";
import { Alert } from "../../components/Alert";
import { AlertTypeEnums, IErrorBoundaryState } from "../../@types/index.d";

export default class ErrorBoundary extends React.Component {
  state: { hasError: boolean; error: Error; errorInfo: ErrorInfo };
  constructor(props: IErrorBoundaryState) {
    super(props);

    this.state = { hasError: false, error: props.error, errorInfo: props.errorInfo };
  }

  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, errorInfo };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render(): ReactElement {
    if (this.state.hasError) {
      return (
        <Alert type={AlertTypeEnums.ERROR}>
          <div>Something went wrong.</div>

          <div>{this.state.error.message || this.state.error.toString()}</div>
        </Alert>
      );
    }

    return <>{this.props.children}</>;
  }
}
