import { isEqual } from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types/index.d";

export const UserOptions: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const clientInfo = currentUser.clients.find(
    (client) => client.client_id === currentUser.selectedClient.client_id
  );

  return (
    <div>
      <span>{currentUser.appUser.alias}</span>
      <br />
      <strong>{clientInfo?.name || ""}</strong>
    </div>
  );
};
