import styled, { css, keyframes } from "styled-components/macro";
import { IDialogOptions } from "../../@types/index.d";
export interface IStyledDialogWrapperOptions {
  hidden: boolean;
}
export const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
  transform: translateY(0%);
    opacity: 1;
  }
`;

//TODO : add SlideOut Animation
export const StyledDialogOverlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 100;
  `
);

export const StyledDialogWrapper = styled(StyledDialogOverlay)<IStyledDialogWrapperOptions>(
  ({ hidden }) => {
    return css`
      background-color: unset;
      display: ${hidden ? "none" : "flex"};
      justify-content: center;
      align-items: center;
    `;
  }
);

export const StyledDialog = styled.div(({ theme }) => {
  return css`
    position: relative;
    background-color: #ffffff;
    animation: ${slideIn} 300ms;
    animation-iteration-count: 1;
    border-radius: ${theme.rem(0.4)};
    display: grid;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    grid-template-areas:
      "title close"
      "content content"
      "footer footer";
    grid-template-columns: 1fr ${theme.rem(3)};
    grid-template-rows: ${theme.rem(4)} auto auto;
    z-index: 101;
  `;
});

export const StyledDialogTitle = styled.h4(({ theme }) => {
  return css`
    grid-area: title;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.rem(0.5)} ${theme.rem(1)};
  `;
});

export const StyledDialogTitleClose = styled.div(({ theme }) => {
  return css`
    grid-area: close;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});

export const StyledDialogContent = styled.div<Pick<IDialogOptions, "flexible">>(
  ({ theme, flexible = true }) => {
    const basic = css`
      grid-area: content;
      border-top: 1px solid ${theme.values.colors.global.greys.fourth};
      padding: ${theme.rem(0.5)} ${theme.rem(1)};
    `;
    const flexi = flexible
      ? css`
          max-height: 50vh;
          overflow-y: scroll;
        `
      : css`
          overflow: unset;
        `;
    return css`
      ${basic}
      ${flexi}
    `;
  }
);

export const StyledDialogFooter = styled.div(({ theme }) => {
  return css`
    grid-area: footer;
    border-top: 1px solid ${theme.values.colors.global.greys.fourth};
    padding: ${theme.rem(0.5)} ${theme.rem(1)};
  `;
});
