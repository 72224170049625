import { FC } from "react";
import styled, { css, keyframes } from "styled-components/macro";
const loading = keyframes`
  0% {
    left: 0;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 100%;
  }

`;

const StyledComponentLoader = styled.div(({ theme }) => {
  const indicatorColor = theme.values ? theme.values.colors.global.secondary._100 : "#cccccc";
  return css`
    display: block;
    width: 100%;
    height: 4px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    &:before {
      content: "";
      width: 25%;
      height: 100%;

      animation: ${loading} 1s infinite;
      position: absolute;
      left: 0;
      top: 0;
      background-image: linear-gradient(90deg, transparent, ${indicatorColor}, transparent);
    }
  `;
});

const StyledComponentLoaderWrapper = styled.div(() => {
  return css`
    position: relative;
    transform: translateX(4px);
  `;
});
export const ComponentLoader: FC = () => {
  return (
    <StyledComponentLoaderWrapper>
      <StyledComponentLoader />
    </StyledComponentLoaderWrapper>
  );
};
