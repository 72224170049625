import {
  EAssetType,
  IBrandedButtonsOptions,
  IBrandedValues,
  IColors,
  IColorStates,
  IThemeValues,
} from "../../@types/index.d";
import { rgba } from "polished";

export const colors: IColors = {
  primary: {
    first: "#00ABF7",
    second: "#075BFA",
    third: "#003F71",
    _010: "#FFEDF0",
    _025: "#FFD2D9",
    _040: "#FFB7C3",
    _055: "#FF9DAD",
    _070: "#FF8196",
    _085: "#FF677F",
    _100: "#FF4C69",
  },
  secondary: {
    first: "#A60142",
    second: "#ED1E79",
    third: "#FE85A4",
    fourth: "#008288",
    fifth: "#03B9B6",
    sixth: "#00E8E0",
    _005: "#F5F5F6",
    _010: "#EAEBED",
    _025: "#CCCDD3",
    _040: "#ADAFB9",
    _055: "#8F919F",
    _070: "#707285",
    _085: "#51546B",
    _100: "#333651",
  },
  greys: {
    first: "#FFFFFF",
    second: "#F7F7F8",
    third: "#E7E7E8",
    fourth: "#D2D4D6",
    fifth: "#8F9299",
    sixth: "#636871",
    seventh: "#232A36",
    _000: "#FFFFFF",
    _010: "#EAEAEA",
    _025: "#33333325",
    _040: "#ADADAD",
    _055: "#8F8F8F",
    _070: "#707070",
    _085: "#515151",
    _100: "#333333",
  },
  validation: {
    first: "#E81546",
    second: "#318551",
    third: "#2CD96F",
    fourth: "#F4A730",
    fifth: "#FFC263",
    success: "#28B73A",
    error: "#A8001B",
    info: "#FF7600",
    graph: "#56AAA0",
    action_level_low: "#3d7ea3",
    action_level_middle: "#ec971f",
    action_level_high: "#940203",
  },
  diagram: { _055: "#88BFB9", _070: "#67AEA5", _085: "#479C92", _100: "#278B7F" },
};

interface IGetButtons {
  primary: string;
  secondary: string;
}
interface IGetButtonColorOptions {
  textColor: string;
  backgroundColor: string;
}

export const getButtons = ({
  primary,
  secondary,
}: IGetButtons): Record<string, IBrandedButtonsOptions> => {
  return {
    primary: {
      radius: "5px",
      color: getButtonColorSet({
        textColor: colors.greys._000,
        backgroundColor: primary,
      }),
    },
    secondary: {
      radius: "5px",
      color: getButtonColorSet({
        textColor: colors.greys._000,
        backgroundColor: secondary,
      }),
    },
  };
};

export const getButtonColorSet = ({
  textColor,
  backgroundColor,
}: IGetButtonColorOptions): IColorStates => {
  return {
    base: {
      color: textColor,
      background: backgroundColor,
      inverted: {
        color: backgroundColor,
        background: textColor,
      },
    },
    hover: {
      color: rgba(textColor, 0.85),
      background: rgba(backgroundColor, 0.85),
      inverted: {
        color: rgba(backgroundColor, 0.85),
        background: rgba(textColor, 0.85),
      },
    },
    disabled: {
      color: rgba(textColor, 0.75),
      background: rgba(backgroundColor, 0.75),
      inverted: {
        color: rgba(backgroundColor, 0.75),
        background: rgba(textColor, 0.75),
      },
    },
  };
};
export const brandedValues: IBrandedValues = {
  //__typename: TypeNameEnums.THEME_OPTIONS_BRANDED,
  colors: {
    app_opacity: "100",
    primary: colors.secondary._100,
    secondary: colors.primary._100,
    app_background_color: colors.greys.third,
    nav_background_color: colors.secondary._100,
    nav_text_color: colors.greys.first,
    login_background_color: colors.secondary._100,
  },
  assets: {
    //__typename: TypeNameEnums.THEME_OPTIONS_ASSETS,
    app_logo: {
      type: EAssetType.APP_LOGO,
      href: "/assets/digital_logo_white.png",
      default: true,
    },
    app_background_image: {
      type: EAssetType.APP_BACKGROUND_IMAGE,
      href: "",
    },
    login_logo: {
      type: EAssetType.LOGIN_LOGO,
      href: "/assets/digital_logo_dark.png",
      default: true,
    },
    login_background_image: {
      type: EAssetType.LOGIN_BACKGROUND_IMAGE,
      href: "",
    },
  },
  newLogo: {
    //__typename: TypeNameEnums.THEME_OPTIONS_LOGO,
    DIGITAL: {
      light: "/assets/digital_logo_white.png",
      dark: "/assets/digital_logo_dark.png",
    },
    GOLD: {
      light: "/assets/logo-gold-light.svg",
      dark: "/assets/logo-gold-dark.svg",
    },
  },
  logo: {
    //__typename: TypeNameEnums.THEME_OPTIONS_LOGO,
    normal: "/assets/digital_logo_dark.png",
    inverted: "/assets/digital_logo_white.png",
  },
  buttons: getButtons({ primary: colors.secondary._100, secondary: colors.primary._100 }),

  inputs: {
    //__typename: TypeNameEnums.THEME_OPTIONS_INPUT,
    default: {
      //__typename: TypeNameEnums.THEME_OPTIONS_INPUT_DEFAULT,
      borderColor: colors.greys._040,
      radius: "15px",
      invalidText: colors.validation.error,
      backgroundColor: colors.secondary._005,
    },
    primary: {
      //__typename: TypeNameEnums.THEME_OPTIONS_INPUT_DEFAULT,
      borderColor: colors.greys._040,
      radius: "15px",
      invalidText: colors.validation.error,
      backgroundColor: colors.secondary._005,
    },
    secondary: {
      //__typename: TypeNameEnums.THEME_OPTIONS_INPUT_DEFAULT,
      borderColor: colors.greys._040,
      radius: "5px",
      invalidText: colors.validation.error,
      backgroundColor: colors.secondary._005,
    },
  },
};

/**
 * Static Theme values as Default.
 */

const defaultThemeValues: IThemeValues = {
  branded: {
    ...brandedValues,
  },
  baseFontSize: 15,
  fontWeight: { normal: 400, bold: 700, bolder: 700 },
  fontFamily: {
    headline: "Lato,sans-serif",
    copy: {
      primary: "Lato,sans-serif",
      secondary: "Lato,sans-serif",
    },
  },

  webFontsConfig: {
    custom: {
      families: ["Lato:n4,n7,i4,i7"],
      urls: ["/fonts.css"],
    },
    classes: false,
  },
  colors: {
    global: colors,
  },
  component: {
    color: colors.greys.seventh,
    backgroundColor: colors.greys.third,
    header: {
      color: colors.primary.third,
      backgroundColor: colors.greys.second,
    },
    title: {
      color: colors.secondary._100,
      backgroundColor: colors.greys.first,
      fontWeight: 400,
    },
    subTitle: {
      color: colors.primary._100,
      backgroundColor: colors.greys.first,
      fontWeight: 400,
    },
    content: {
      color: colors.greys.seventh,
      backgroundColor: colors.greys.first,
    },
    borderColors: {
      basic: colors.greys.third,
    },
  },
};
export default defaultThemeValues;
