import { FC } from "react";
import { Link } from "react-router-dom";

export const Footer: FC = () => {
  return (
    <>
      <Link to="/impressum">Impressum</Link> |<Link to="/privacy">Datenschutz</Link> |
      <Link to="/terms">AGB</Link>
    </>
  );
};
