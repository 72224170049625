import { FC } from "react";
import styled, { css } from "styled-components/macro";
const Container = styled.section(({ theme }) => {
  return css`
    border: 1px solid ${theme.values.component.borderColors.basic};
    border-radius: ${theme.rem(1.125)};
    margin-bottom: ${theme.rem(1)};
    padding: ${theme.rem(1)};
    position: relative;
  `;
});

export const ChartContainer: FC = ({ children }) => {
  return <Container>{children}</Container>;
};
