import { FC } from "react";
import { Icon, IconNameEnums, IconSizeEnums } from "../../components/Icon";
import { StyledUseBadgeText, StyledUserBadge, StyledUserBadgeIcon } from "./styled.user-badge";
import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import { Trans } from "react-i18next";
import { useDevice } from "../../_lib/hooks";
import { isEqual } from "lodash";
export const UserBadge: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const { isLarge } = useDevice();
  if (!themeOptions.sideNavExpanded || !isLarge) return <></>;
  // render only in expanded SideNav
  return (
    <StyledUserBadge>
      <StyledUserBadgeIcon>
        <Icon name={IconNameEnums.PROFILE_IMAGE} size={IconSizeEnums.MEDIUM} />
      </StyledUserBadgeIcon>
      <StyledUseBadgeText>
        {currentUser.appUser.alias === "" ? (
          <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
        ) : (
          <Trans i18nKey={`misc.welcome`} values={{ userName: currentUser.appUser.alias }} />
        )}
      </StyledUseBadgeText>
    </StyledUserBadge>
  );
};
