import { FC } from "react";
import { Link } from "react-router-dom";
import { StyledLogo, StyledLogoImage } from "./styled.nav-logo";
import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";
import { isEqual } from "lodash";

export const NavLogo: FC = () => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);

  return (
    <StyledLogo>
      <Link to="/">
        <StyledLogoImage src={themeOptions.branded.assets.app_logo.href} />
      </Link>
    </StyledLogo>
  );
};
