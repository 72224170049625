import { MutationFunctionOptions, useMutation } from "@apollo/client";
import {
  AlertTypeEnums,
  ICreateRevenueLineVariables,
  ICreateRevenueLine,
  IDialogKeyEnums,
  ICreatePersonnelLineVariables,
  ICreatePersonnelLine,
  ICreateCostLine,
  ICreateCostLineVariables,
  ICreateInvestmentLine,
  ICreateInvestmentLineVariables,
  ICreateFinancingLine,
  ICreateFinancingLineVariables,
  IEditPlanningLineTitleVariables,
  IDeletePlanningLineRetVal,
  IDeletePlanningLineVariables,
  IEditPlanningLineTitleRetVal,
  EPlanningSubPage,
  IUpdateTemplatesVariables,
  IUpdateTemplatesRetVal,
} from "../../../@types/index.d";
import { useCallback } from "react";
import {
  CREATE_PERSONNEL_LINE,
  CREATE_REVENUE_LINE,
  CREATE_COST_LINE,
  CREATE_INVESTMENT_LINE,
  CREATE_FINANCING_LINE,
  EDIT_PLANNING_LINE_TITLE,
  DELETE_PLANNING_LINE,
  UPDATE_TEMPLATES,
} from "./plan-queries";
import { useDispatch } from "react-redux";
import { useSystemMessages } from "../useSystemMessages";
import { EAutoCloseTimeOut, IPlanningAction, IPlanningActionTypes } from "../../store/reducers";
import { Dispatch } from "@reduxjs/toolkit";
import { useDialogs } from "../useDialogs";

// to shorten ts definitions of the below mutation requests
export type TMutationFns<R, V> = (options?: MutationFunctionOptions<R, V>) => void;
interface IUseEditTemplatesReturnValue {
  createRevenueLineFn: TMutationFns<ICreateRevenueLine, ICreateRevenueLineVariables>;
  createRevenueLineLoading: boolean;
  createPersonnelLineFn: TMutationFns<ICreatePersonnelLine, ICreatePersonnelLineVariables>;
  createPersonnelLineLoading: boolean;
  createCostLineFn: TMutationFns<ICreateCostLine, ICreateCostLineVariables>;
  createCostLineLoading: boolean;
  createInvestmentLineFn: TMutationFns<ICreateInvestmentLine, ICreateInvestmentLineVariables>;
  createInvestmentLineLoading: boolean;
  createFinancingLineFn: TMutationFns<ICreateFinancingLine, ICreateFinancingLineVariables>;
  createFinancingLineLoading: boolean;
  updateTemplatesFn: TMutationFns<IUpdateTemplatesRetVal, IUpdateTemplatesVariables>;
  updateTemplatesFnLoading: boolean;
  editPlanningLineTitleFn: TMutationFns<
    IEditPlanningLineTitleRetVal,
    IEditPlanningLineTitleVariables
  >;
  editPlanningLineTitleLoading: boolean;
  deletePlanningLineFn: TMutationFns<IDeletePlanningLineRetVal, IDeletePlanningLineVariables>;
  deletePlanningLineLoading: boolean;
}

type TUseEditTemplates = () => IUseEditTemplatesReturnValue;

export const useEditTemplates: TUseEditTemplates = () => {
  const { addSystemMessage } = useSystemMessages();
  const dialogs = useDialogs();
  const dispatchPlanning = useDispatch<Dispatch<IPlanningAction>>();

  const errorMessageCallback = useCallback(
    (err) => {
      addSystemMessage({
        type: AlertTypeEnums.ERROR,
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        identifier: err.code,
        code: err.message,
        closable: false,
      });
    },
    [addSystemMessage]
  );

  const [updateTemplatesFn, { loading: updateTemplatesFnLoading }] = useMutation<
    IUpdateTemplatesRetVal,
    IUpdateTemplatesVariables
  >(UPDATE_TEMPLATES, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.updateTemplates) {
        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.updateTemplates,
          },
        });
        dialogs.close(IDialogKeyEnums.SIMPLE);
      }
    },
    fetchPolicy: "no-cache",
  });

  const [createRevenueLineFn, { loading: createRevenueLineLoading }] = useMutation<
    ICreateRevenueLine,
    ICreateRevenueLineVariables
  >(CREATE_REVENUE_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.createRevenueLine) {
        const lines = data.createRevenueLine.templates.revenue?.lines;
        const selectedRevenueLineId = lines && lines[lines.length - 1].line_id;

        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_LINE_ID,
          payload: {
            selectedLineId: selectedRevenueLineId,
          },
        });

        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.createRevenueLine,
          },
        });
        dialogs.close(IDialogKeyEnums.SIMPLE);
      }
    },
    fetchPolicy: "no-cache",
  });

  const [createPersonnelLineFn, { loading: createPersonnelLineLoading }] = useMutation<
    ICreatePersonnelLine,
    ICreatePersonnelLineVariables
  >(CREATE_PERSONNEL_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.createPersonnelLine) {
        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.createPersonnelLine,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  const [createCostLineFn, { loading: createCostLineLoading }] = useMutation<
    ICreateCostLine,
    ICreateCostLineVariables
  >(CREATE_COST_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.createCostLine) {
        // to be able to get the title of the newly created cost line
        const titleOfLastCreatedLine = sessionStorage.getItem("costLineTitle");
        sessionStorage.removeItem("costLineTitle");
        const lines = data.createCostLine.templates.costs?.lines;
        const selectedCostLineId =
          lines && lines.find(({ title }) => title === titleOfLastCreatedLine)?.line_id;

        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_LINE_ID,
          payload: {
            selectedLineId: selectedCostLineId,
          },
        });

        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.createCostLine,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  const [createInvestmentLineFn, { loading: createInvestmentLineLoading }] = useMutation<
    ICreateInvestmentLine,
    ICreateInvestmentLineVariables
  >(CREATE_INVESTMENT_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.createInvestmentLine) {
        const lines = data.createInvestmentLine.templates.capex?.lines;
        const selectedInvestmentLineId = lines && lines[lines.length - 1].line_id;

        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_LINE_ID,
          payload: {
            selectedLineId: selectedInvestmentLineId,
          },
        });

        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.createInvestmentLine,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  const [createFinancingLineFn, { loading: createFinancingLineLoading }] = useMutation<
    ICreateFinancingLine,
    ICreateFinancingLineVariables
  >(CREATE_FINANCING_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.createFinancingLine) {
        const lines = data.createFinancingLine.templates.financing?.lines;
        const selectedFinancingLineId = lines && lines[lines.length - 1].line_id;

        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_LINE_ID,
          payload: {
            selectedLineId: selectedFinancingLineId,
          },
        });

        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.createFinancingLine,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  const [editPlanningLineTitleFn, { loading: editPlanningLineTitleLoading }] = useMutation<
    IEditPlanningLineTitleRetVal,
    IEditPlanningLineTitleVariables
  >(EDIT_PLANNING_LINE_TITLE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.editPlanningLineTitle) {
        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.editPlanningLineTitle,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  const [deletePlanningLineFn, { loading: deletePlanningLineLoading }] = useMutation<
    IDeletePlanningLineRetVal,
    IDeletePlanningLineVariables
  >(DELETE_PLANNING_LINE, {
    onError: errorMessageCallback,
    onCompleted: (data) => {
      if (data && data.deletePlanningLine && data.deletePlanningLine.data) {
        // to get the correct sub page of planning, Ex: "revenue" or "costs"
        const lineKey = `${data.deletePlanningLine.lineType}` as EPlanningSubPage;
        const lines = data.deletePlanningLine.data.templates[`${lineKey}`].lines;

        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_LINE_ID,
          payload: {
            selectedLineId: lines && lines.length > 0 ? lines[lines.length - 1].line_id : undefined,
          },
        });

        dispatchPlanning({
          type: IPlanningActionTypes.UPDATE_PLANS,
          payload: {
            updatePlans: data.deletePlanningLine.data,
            lineId: lines && lines.length > 0 ? lines[lines.length - 1].line_id : undefined,
            lineKey,
          },
        });
      }
      dialogs.close(IDialogKeyEnums.SIMPLE);
    },
    fetchPolicy: "no-cache",
  });

  return {
    createRevenueLineFn,
    createRevenueLineLoading,
    createPersonnelLineFn,
    createPersonnelLineLoading,
    createCostLineFn,
    createCostLineLoading,
    createInvestmentLineFn,
    createInvestmentLineLoading,
    createFinancingLineFn,
    createFinancingLineLoading,
    updateTemplatesFn,
    updateTemplatesFnLoading,
    editPlanningLineTitleFn,
    editPlanningLineTitleLoading,
    deletePlanningLineFn,
    deletePlanningLineLoading,
  };
};
