import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import initialData from "../initialState";
import { EAssetType, ICustomizationListItem, ICustomizationRedux } from "../../../@types/index.d";

export enum CustomizationActionTypes {
  SET_CUSTOMIZATION = "SET_CUSTOMIZATION",
  SET_CUSTOMIZATION_LIST = "SET_CUSTOMIZATION_LIST",
  UPDATE_CUSTOMIZATION_LIST = "UPDATE_CUSTOMIZATION_LIST",
  SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT",
  SET_SELECTED_ASSET = "SET_SELECTED_ASSET",
  SET_LOADING = "SET_LOADING",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  CustomizationActionTypes,
  ActionCreatorWithPayload<Partial<ICustomizationPayload>, CustomizationActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(CustomizationActionTypes) as Array<
  keyof typeof CustomizationActionTypes
>) {
  actionFunctions[CustomizationActionTypes[key]] = createAction(CustomizationActionTypes[key]);
}

export interface ICustomizationPayload extends ICustomizationRedux {
  customization: ICustomizationListItem;
  loading: boolean;
}

export interface ICustomizationAction {
  type: CustomizationActionTypes;
  payload?: Partial<ICustomizationPayload>;
}

const customization = createReducer(initialData.customization, (builder) => {
  builder
    .addCase(actionFunctions[CustomizationActionTypes.SET_CUSTOMIZATION], (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(actionFunctions[CustomizationActionTypes.SET_CUSTOMIZATION_LIST], (state, action) => {
      if (!action.payload.customizationList) return state;
      state.customizationList = action.payload.customizationList;
    })
    .addCase(
      actionFunctions[CustomizationActionTypes.UPDATE_CUSTOMIZATION_LIST],
      (state, action) => {
        if (!action.payload.customization) return state;
        const customizationInd = state.customizationList.findIndex(
          (customization) => customization.account_id === action.payload.customization?.account_id
        );

        if (customizationInd < 0) state.customizationList.push(action.payload.customization);
        else state.customizationList[customizationInd] = action.payload.customization;
      }
    )
    .addCase(actionFunctions[CustomizationActionTypes.SET_SELECTED_ACCOUNT], (state, action) => {
      if (!action.payload.selectedAccount) return state;
      state.selectedAsset = EAssetType.APP_LOGO;
      state.selectedAccount = action.payload.selectedAccount;
    })
    .addCase(actionFunctions[CustomizationActionTypes.SET_SELECTED_ASSET], (state, action) => {
      if (!action.payload.selectedAsset) return state;
      state.selectedAsset = action.payload.selectedAsset;
    });
});

export default customization;
