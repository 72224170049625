import { FC } from "react";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types";
import { EAppStoreMenuItem } from "../useAppStore";
import { Trans } from "react-i18next";
import { useAppStoreHelper } from "../useAppStoreHelper";
import { StyledAppStoreMenu } from "./styled.appStore-menu";
import { isEqual } from "lodash";

interface IAppStoreMenuItemProps {
  contentItem: EAppStoreMenuItem;
  // handleClick: () => void;
  showSubFeatures: boolean;
}

export const AppStoreMenuItem: FC<IAppStoreMenuItemProps> = ({
  contentItem,
  // handleClick,
  showSubFeatures,
}) => {
  const content = useSelector(({ appStore: { content } }: ILocalState) => content, isEqual);
  const selectedMenuItem = useSelector(
    ({ appStore: { selectedMenuItem } }: ILocalState) => selectedMenuItem,
    isEqual
  );
  const { handleSelected } = useAppStoreHelper();
  if (!content || content[contentItem].disabled) return null;

  return (
    <StyledAppStoreMenu.MenuItem
      selected={contentItem === selectedMenuItem}
      subFeature={
        content[contentItem as EAppStoreMenuItem].feature_id &&
        content[contentItem as EAppStoreMenuItem].sub_feature
      }
      // onClick={handleClick}
      showSubFeatures={showSubFeatures}
    >
      <StyledAppStoreMenu.MenuItemLabel onClick={handleSelected} id={contentItem}>
        <Trans components={{ sup: <sup /> }}>
          {content[contentItem as EAppStoreMenuItem].title}
        </Trans>
      </StyledAppStoreMenu.MenuItemLabel>
    </StyledAppStoreMenu.MenuItem>
  );
};
