import { FC, ReactElement } from "react";
import styled, { css, keyframes } from "styled-components/macro";

export interface ITextLoaderProps {
  length?: number;
  placeholder?: string | ReactElement;
}
const animateBlur = keyframes`
  0% {
    text-shadow: -2px 0 8px #b5b5b5;

  }
  50% {
    text-shadow: 2px 0 6px #f5f5f5;

  }
  100% {
    text-shadow: -2px 0 8px #b5b5b5;

  }
`;
export const StyledTextLoader = styled.span(() => {
  const randomDelay = Math.floor(Math.random() * 10) * 100;
  return css`
    color: transparent;
    text-shadow: 0 0 12px #b5b5b5;
    animation: ${animateBlur} 2s infinite;
    animation-delay: ${randomDelay}ms;
  `;
});
export const TextLoader: FC<ITextLoaderProps> = ({ length = 1, placeholder }) => {
  const getRandomLetter = (): string => {
    const result = new Array(length).fill("");

    return result
      .map((l: string) => String.fromCharCode(Math.floor(Math.random() * (122 - 65)) + 65))
      .join("");
  };
  return placeholder ? (
    <StyledTextLoader>{placeholder}</StyledTextLoader>
  ) : (
    <StyledTextLoader>{getRandomLetter()}</StyledTextLoader>
  );
};
