import { FC, Suspense, useEffect } from "react";
import { StyledCommonLayout } from "./styled.common";
import { PageHeadline } from "../../containers/PageHeadline";
import { PageContent } from "../../containers/PageContent";
import { PageFooter } from "../../containers/PageFooter";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";

import { ILocalState, IThemeOptions, LayoutEnums } from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { IThemeOptionsAction, ThemeStateActionTypes } from "../store/reducers/themeOptions";
import { isEqual } from "lodash";

export const SetupLayout: FC = ({ children }) => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();
  useEffect(() => {
    if (themeOptions.layout !== LayoutEnums.SETUP) {
      dispatch({
        type: ThemeStateActionTypes.SET_LAYOUT,
        payload: { layout: LayoutEnums.SETUP } as Partial<IThemeOptions>,
      });
    }
  });
  return (
    <StyledCommonLayout>
      <PageHeadline />
      <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
        <PageContent title={""} subtitle={""}>
          {children}
        </PageContent>
      </Suspense>
      <PageFooter />
    </StyledCommonLayout>
  );
};
