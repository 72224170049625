import { FC, useEffect } from "react";
import { StyledAppStore } from "./styled.appStore-page";
import { IAppStoreContext, IAppStoreProps } from "./appStoreTypes";
import { useAppStoreHelper } from "./useAppStoreHelper";
import { IDialogKeyEnums, ILocalState } from "../../../@types/index.d";
import { useSelector } from "react-redux";
import { AppStoreMenu } from "./Menu";
import { AppStoreContent } from "./Content";
import { useDialogContext, useDialogs } from "../../../_lib/hooks";
import { isEqual } from "lodash";

export enum EAppStoreMenuItem {
  OVERVIEW = "overview", // Übersicht
  DIGITAL = "canei_digital", // CANEI.digital
  PAYMENT = "payment", // Zahlungsdaten
}

export const Content: FC = () => {
  const content = useSelector(({ appStore: { content } }: ILocalState) => content, isEqual);
  const { handleLocation } = useAppStoreHelper();
  const dialogs = useDialogs();
  const dialogContext = useDialogContext<IAppStoreContext | undefined>(
    IDialogKeyEnums.APP_STORE,
    undefined
  );

  // set the initial location when the dialog is opened
  useEffect(() => {
    if (!content) return;
    if (!dialogContext) return;
    if (!dialogContext?.location) return;
    handleLocation(dialogContext.location);
    dialogs.setData(IDialogKeyEnums.APP_STORE, { data: { ...dialogContext, location: undefined } });
  }, [content, dialogContext, dialogs, handleLocation]);

  return (
    <>
      {content && (
        <StyledAppStore.Wrapper>
          <AppStoreMenu />
          <AppStoreContent />
        </StyledAppStore.Wrapper>
      )}
    </>
  );
};

interface IUseAppStoreRetVal {
  open: (storeProps: IAppStoreProps) => void;
}

export const useAppStore = (): IUseAppStoreRetVal => {
  const dialogs = useDialogs();
  const { enrichAppStoreContent } = useAppStoreHelper();

  return {
    open: (storeProps): void => {
      enrichAppStoreContent();
      dialogs.open({
        dialogKey: IDialogKeyEnums.APP_STORE,
        closable: storeProps.closable,
        content: <Content />,
        flexible: false,
        data: { ...storeProps },
      });
    },
  };
};
