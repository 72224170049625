import { FC } from "react";
import { ButtonVariantEnums } from "../../../../../components/formElements/Button";
import { useTranslation } from "react-i18next";
import { DropDown, Option } from "../../../../../components/formElements/DropDown";
import { Client, ILocalState, SelectClientState } from "../../../../../@types/index.d";
import { useClientSelectorUI, usePlans, useSwitchClient } from "../../../../../_lib/hooks/";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

export const clientManagementTestID = "ClientManagement";

export const ClientManagement: FC = () => {
  const { switchClient } = useSwitchClient();
  const { t } = useTranslation();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const clientSelectorUI = useClientSelectorUI();
  const { callGetPlans } = usePlans();

  const isDropDownDisabled =
    currentUser.selectedClient.state === SelectClientState.DRAFT ||
    currentUser.selectedClient.state === SelectClientState.INITIAL;

  const handleClientsListDialogOpen = (): void => {
    clientSelectorUI.show();
  };
  const handleOnChange = (): void => {
    //
  };
  const handleNavigateToSetupPage = (): void => {
    switchClient(null, SelectClientState.INITIAL);
  };

  return (
    <>
      <DropDown
        text={t("content_nav.clients")}
        inverted={false}
        variant={ButtonVariantEnums.SECONDARY}
        onChange={handleOnChange}
        disabled={isDropDownDisabled}
        isOpen={false}
        onClick={handleClientsListDialogOpen}
        testID={clientManagementTestID}
      >
        <Option onSelect={handleNavigateToSetupPage} value={currentUser.attributes.customer_id}>
          {t("content_nav.add_client")}
        </Option>

        {currentUser.clients.map((client: Client, index: number) => (
          <Option
            onSelect={(clientId: string): void => {
              switchClient(clientId, SelectClientState.UNKNOWN);
              // to get the plans of the selected client
              callGetPlans(clientId);
            }}
            key={index}
            value={client.client_id}
            selected={client.client_id === currentUser.selectedClient.client_id}
          >
            {client.name}
          </Option>
        ))}
      </DropDown>
    </>
  );
};
