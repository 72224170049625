import { createContext, Dispatch, FC, Reducer, useEffect, useReducer, useRef } from "react";
import { StyledUploadsContainer, StyledUploadSteps } from "./styled.uploaders";
import { Panel, PanelBody, PanelFooter, PanelTitle } from "../Panel";
import { Trans, useTranslation } from "react-i18next";
import {
  AlertTypeEnums,
  FileProps,
  FileUploadFormat,
  FileUploadVariant,
  ILocalState,
  IUploadSettings,
} from "../../@types/index.d";
import { Link } from "react-router-dom";
import { P } from "../../components/Typography";

import { Alert } from "../../components/Alert";

import { FileUpload } from "./FileUpload";
import { ManualEntry } from "./ManualEntry";
import { DatevUpload } from "./DatevUpload";
import { SelectUploadMethod } from "./SelectUploadMethod";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import { EventListenerCodes } from "../../_lib/hooks/useEventListener/types.event-listener";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

export interface IUploadProgress {
  transactionId?: string;
  transactionState?: EventListenerCodes;
  state: EFileUploadState;
}

export enum EFileUploadState {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  ERRORED = "ERRORED",
}

export interface PreviewData {
  [key: string]: string | number;
}

export enum EPreviewErrorCodes {
  OK = 200,
  UNKNOWN_PARAM = 400,
  NO_DATA = 404,
  MISSING_PARAM = 422,
  SERVER_ERROR = 500,
}

export interface IUploadersProps {
  isInitial: boolean;
}

export interface IFileUploadState {
  client_id: string;
  tableData?: PreviewData[];
  guid?: string;
  previewLoading?: boolean;
  arrangementDone: boolean;
  previewErrorCode: EPreviewErrorCodes;
  fileSelected: boolean | null;
  dateSelected: boolean | null;
  isReady: boolean;
  file: FileProps;
  files: Record<string, FileProps>;
  fileUploadState: Record<string, IUploadProgress>;
  savePreferences: boolean;
  settings: IUploadSettings;
  upload_format?: FileUploadFormat;
  multipleUpload: boolean;
}

export interface IUploadersContext {
  isInitial: boolean;
  uploadMethod?: IUploadMethods;
  sharedState: IFileUploadState;
}

export enum IUploadMethods {
  DATEV = "DATEV",
  EXCEL = "EXCEL",
  MANUAL = "MANUAL",
}

export interface IUploadersActions {
  type: EUploadersActionTypes;
  payload: FileUploadActionPayload;
}

export interface FileUploadActionPayload {
  isInitial?: boolean;
  uploadMethod?: IUploadMethods;
  data?: Partial<IFileUploadState>;
  settings?: Partial<IUploadSettings>;
  file?: Partial<FileProps>;
  year?: string;
  name?: string;
  date?: string;
  uploadState?: Partial<IUploadProgress>;
}

export enum EUploadersActionTypes {
  SET_IS_UPLOADER_INITIAL = "SET_IS_UPLOADER_INITIAL",
  SET_UPLOADERS_METHOD = "SET_UPLOADERS_METHOD",
  SET_STATE = "SET_STATE",
  SET_UPLOAD_TYPE = "SET_UPLOAD_TYPE",
  UPSERT_FILE = "UPSERT_FILE",
  REMOVE_FILE = "REMOVE_FILE",
  SET_FILE_DATE = "SET_FILE_DATE",
  SET_UPLOAD_STATE = "SET_UPLOAD_STATE",
  REMOVE_ALL = "REMOVE_ALL",
  RESET = "RESET",
}
export const initialUploadersContext: IUploadersContext = {
  isInitial: true,
  uploadMethod: undefined,
  sharedState: {
    client_id: "",
    previewLoading: false,
    arrangementDone: false,
    isReady: false,
    savePreferences: false,
    fileSelected: null,
    dateSelected: null,
    previewErrorCode: EPreviewErrorCodes.OK,
    multipleUpload: false,
    settings: {
      format: null,
      variant_type: FileUploadVariant.UNKNOWN,
    },
    file: {
      client_id: "",
      name: "",
      lastModified: "",
      size: 0,
      type: "",
      links: [],
      date: null,
      content: "",
    },
    files: {},
    fileUploadState: {},
  },
};
export const UploadersContext = createContext<IUploadersContext>(initialUploadersContext);
export const UploadersDispatch = createContext<Dispatch<IUploadersActions>>((state) => state);
export const uploadersTestID = "Uploaders";
export const uploadersSelectDatevTestID = `${uploadersTestID}-datev-selector`;
export const uploadersSelectXlsTestID = `${uploadersTestID}-xls-selector`;
export const uploadersSelectManualTestID = `${uploadersTestID}-manual-selector`;

const fnUploadersReducer = (
  state: IUploadersContext,
  action: IUploadersActions
): IUploadersContext => {
  switch (action.type) {
    case EUploadersActionTypes.SET_IS_UPLOADER_INITIAL:
      return { ...state, isInitial: action.payload?.isInitial !== false };
    case EUploadersActionTypes.SET_UPLOADERS_METHOD:
      return { ...state, uploadMethod: action.payload?.uploadMethod };
    case EUploadersActionTypes.SET_STATE:
      const data: IFileUploadState = {
        ...state.sharedState,
        ...action.payload.data,
      };

      const settings: IUploadSettings = {
        ...state.sharedState.settings,
        ...action.payload.settings,
      };

      const file: FileProps = {
        ...state.sharedState.file,
        client_id: state.sharedState.client_id,
        ...action.payload.file,
      };

      const arrangementDone = settings.mapping
        ? Object.values(settings.mapping).length > 0 &&
          Object.values(settings.mapping).filter((val) => val === "").length === 0
        : false;

      const isReady: boolean = arrangementDone && !!settings.variant_type;

      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          ...data,
          file,
          settings,
          arrangementDone,
          isReady,
        },
      };
    case EUploadersActionTypes.SET_UPLOAD_TYPE:
      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          multipleUpload: action.payload.data?.multipleUpload || false,
        },
      };
    case EUploadersActionTypes.UPSERT_FILE: {
      if (!action.payload) return state;
      const { name, file } = action.payload;
      if (name === undefined || file === undefined) return state;

      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          files: {
            ...state.sharedState.files,
            [name]: {
              ...initialUploadersContext.sharedState.file,
              ...state.sharedState.files[name],
              ...file,
              date: `${name}`,
            },
          },
        },
      };
    }
    case EUploadersActionTypes.REMOVE_FILE: {
      const newState = { ...state };
      if (action.payload?.name) {
        delete newState.sharedState.files[action.payload.name];
      }
      return newState;
    }
    case EUploadersActionTypes.SET_FILE_DATE: {
      if (!action.payload) return state;
      const { year } = action.payload;
      if (year) return state;
      const files = Object.entries(state.sharedState.files).reduce(
        (p, [key, val]) => ({ ...p, [key]: { ...val, date: `${key}/${year}` } }),
        {}
      );
      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          files,
        },
      };
    }
    case EUploadersActionTypes.SET_UPLOAD_STATE: {
      const { date, uploadState } = action.payload;
      if (!date) return state;

      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          fileUploadState: {
            ...state.sharedState.fileUploadState,
            [date]: {
              ...state.sharedState.fileUploadState[date],
              ...uploadState,
            },
          },
        },
      };
    }
    case EUploadersActionTypes.REMOVE_ALL: {
      return {
        ...state,
        sharedState: {
          ...state.sharedState,
          files: {},
          fileUploadState: {},
          dateSelected: false,
          multipleUpload: false,
          isReady: false,
        },
      };
    }
    case EUploadersActionTypes.RESET:
      return initialUploadersContext;
    default:
      return state;
  }
};

export const Uploaders: FC<IUploadersProps> = ({ isInitial }) => {
  const { t } = useTranslation();
  const eventInProgress = useSelector(
    ({ currentUser: { eventInProgress } }: ILocalState) => eventInProgress,
    isEqual
  );

  const [context, dispatch] = useReducer<Reducer<IUploadersContext, IUploadersActions>>(
    fnUploadersReducer,
    initialUploadersContext
  );

  const refStep2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInitial !== context.isInitial) {
      dispatch({
        type: EUploadersActionTypes.SET_IS_UPLOADER_INITIAL,
        payload: {
          isInitial,
        },
      });
    }
  }, [context.isInitial, isInitial]);

  return (
    <UploadersDispatch.Provider value={dispatch}>
      <UploadersContext.Provider value={context}>
        {eventInProgress && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
        <StyledUploadsContainer data-test-id={uploadersTestID}>
          <StyledUploadSteps>
            <Panel>
              <PanelTitle
                headline={t("setup.upload_form_1.headline")}
                subline={t("setup.upload_form_1.subline")}
                help={context.isInitial ? "0" : null}
              />
              <PanelBody>
                <P>{t("setup.upload_form_1.select_upload_type", { step: "1" })}</P>
              </PanelBody>
              <SelectUploadMethod forwardRef={refStep2} />

              {context.isInitial && (
                <PanelFooter>
                  <Alert type={AlertTypeEnums.INFO}>
                    <Trans i18nKey={"setup.upload_form_1.more_info"}>
                      <Link to="#">here</Link>
                    </Trans>
                  </Alert>
                </PanelFooter>
              )}
            </Panel>
          </StyledUploadSteps>
          <StyledUploadSteps ref={refStep2}>
            <>
              {context.uploadMethod === IUploadMethods.DATEV && <DatevUpload />}
              {context.uploadMethod === IUploadMethods.EXCEL && <FileUpload />}
              {context.uploadMethod === IUploadMethods.MANUAL && <ManualEntry />}
            </>
          </StyledUploadSteps>
        </StyledUploadsContainer>
      </UploadersContext.Provider>
    </UploadersDispatch.Provider>
  );
};

export default Uploaders;
