import { FC } from "react";
import { NavLogo } from "./NavLogo";

import { UserBadge } from "./UserBadge";

import { Menu } from "./Menu";
import { StyledSideNav } from "./styled.side-nav";
import { EAppStoreMenuItem, useAppStore } from "../../views/Private/AppStore";

export const SideNav: FC = (props) => {
  const { open: handleAppStoreDialog } = useAppStore();
  const handleToAppStore = (): void => {
    handleAppStoreDialog({
      location: EAppStoreMenuItem.OVERVIEW,
      closable: true,
    });
  };
  return (
    <StyledSideNav {...props}>
      <NavLogo {...props} />
      <UserBadge />
      <Menu />
      <div style={{ cursor: "pointer" }} onClick={handleToAppStore}>
        App Store
      </div>
    </StyledSideNav>
  );
};
