import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import { IBrandedAssets, IBrandedColors, IThemeOptions } from "../../../@types/index.d";
import initialData from "../initialState";
import { getButtons } from "../../Theme/defaultThemeValues";

export enum ThemeStateActionTypes {
  SET_BRANDED_COLORS = "SET_BRANDED_COLORS",
  SET_BRANDED_ASSETS = "SET_BRANDED_ASSETS",
  SET_THEME = "SET_THEME",
  SET_LAYOUT = "SET_LAYOUT",
  SIDENAV_EXPAND = "SIDENAV_EXPAND",
  SIDENAV_COLLAPSE = "SIDENAV_COLLAPSE",
  CLEAR_STORE = "CLEAR_STORE",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  ThemeStateActionTypes,
  ActionCreatorWithPayload<Partial<IThemePayload>, ThemeStateActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(ThemeStateActionTypes) as Array<keyof typeof ThemeStateActionTypes>) {
  actionFunctions[ThemeStateActionTypes[key]] = createAction(ThemeStateActionTypes[key]);
}

export interface IThemePayload extends IThemeOptions {
  colors: IBrandedColors;
  assets: IBrandedAssets;
}

export interface IThemeOptionsAction {
  type: ThemeStateActionTypes;
  payload?: Partial<IThemePayload>;
}

const themeOptions = createReducer(initialData.themeOptions, (builder) => {
  builder
    .addCase(actionFunctions[ThemeStateActionTypes.SET_BRANDED_COLORS], (state, action) => {
      if (!action.payload) {
        state.branded.colors = initialData.themeOptions.branded.colors;
        state.branded.buttons = getButtons({
          primary: initialData.themeOptions.branded.colors.primary,
          secondary: initialData.themeOptions.branded.colors.secondary,
        });
        return state;
      }
      if (!action.payload.colors) {
        state.branded.colors = initialData.themeOptions.branded.colors;
        return state;
      }
      state.branded.colors = action.payload.colors;
      state.branded.buttons = getButtons({
        primary: action.payload.colors.primary,
        secondary: action.payload.colors.secondary,
      });
    })
    .addCase(actionFunctions[ThemeStateActionTypes.SET_BRANDED_ASSETS], (state, action) => {
      if (!action.payload) {
        state.branded.assets = initialData.themeOptions.branded.assets;
        return state;
      }
      if (!action.payload.assets || !Object.keys(action.payload.assets).length) {
        state.branded.assets = initialData.themeOptions.branded.assets;
        return state;
      }
      state.branded.assets = { ...state.branded.assets, ...action.payload.assets };
    })
    .addCase(actionFunctions[ThemeStateActionTypes.SET_THEME], (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(actionFunctions[ThemeStateActionTypes.SET_LAYOUT], (state, action) => {
      return {
        ...state,
        layout: action.payload.layout || state.layout,
      };
    })
    .addCase(actionFunctions[ThemeStateActionTypes.SIDENAV_EXPAND], (state, action) => {
      return {
        ...state,
        sideNavExpanded: true,
      };
    })
    .addCase(actionFunctions[ThemeStateActionTypes.SIDENAV_COLLAPSE], (state, action) => {
      return {
        ...state,
        sideNavExpanded: false,
      };
    })
    .addCase(actionFunctions[ThemeStateActionTypes.CLEAR_STORE], (state, action) => {
      return initialData.themeOptions;
    });
});

export default themeOptions;
