import { ChangeEvent, FC, useContext } from "react";
import {
  StyledFileRequiredFields,
  StyledUploadExcel,
  StyledUploadFileIcon,
  StyledUploadFileIcons,
} from "../styled.uploaders";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormRow,
  IFormState,
  Button,
  ButtonVariantEnums,
  FileUploader,
  Input,
} from "../../../components/formElements";
import {
  AlignmentEnums,
  FileProps,
  FileUploadFormat,
  InputTypes,
  IUploadSettings,
} from "../../../@types/index.d";

import { Icon, IconNameEnums, IconSizeEnums } from "../../../components/Icon";
import { P } from "../../../components/Typography";
import { useSelectedClientData, useUploadPreview, getFileContent } from "../../../_lib/hooks";
import { useRelationLinks } from "../../../_lib/hooks/useRelationLinks";
import { SingleSuSaDate } from "../UploadersSharedContext";
import { PlanPeriod } from "../PlanPeriod";
import {
  EPreviewErrorCodes,
  EUploadersActionTypes,
  IFileUploadState,
  UploadersContext,
  UploadersDispatch,
} from "../index";

export const FileUploadForm: FC = () => {
  const { t } = useTranslation();
  const selectedClientData = useSelectedClientData();
  const uploadersDispatch = useContext(UploadersDispatch);
  const uploadersContext = useContext(UploadersContext);
  const [uploadPreview, { loading: previewLoading }] = useUploadPreview();
  const { getRelationLinks } = useRelationLinks();
  const { isInitial } = useContext(UploadersContext);
  const fileType = uploadersContext.sharedState.settings.format;
  const dateOfFile = uploadersContext.sharedState.file.date;
  const { fileSelected, dateSelected } = uploadersContext.sharedState;

  const previewReady =
    uploadersContext.sharedState.fileSelected && uploadersContext.sharedState.dateSelected;

  if (selectedClientData === false) return null;

  const getFileTypeFromFile = (file: string): FileUploadFormat | undefined => {
    switch (file) {
      case "text/csv":
        return FileUploadFormat.CSV;
      case "text/plain":
        return FileUploadFormat.TXT;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return FileUploadFormat.XLS;
      default:
        return undefined;
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const fileList = e.target.files;
    if (!fileList) return;
    const selectedFileFormat = fileList.length > 0 ? getFileTypeFromFile(fileList[0].type) : null;
    const preferences = selectedClientData?.customization?.upload_settings as IUploadSettings;

    const hasPreferences: boolean =
      !!selectedFileFormat && preferences && selectedFileFormat === preferences.format;

    const settings: Partial<IUploadSettings> = hasPreferences
      ? preferences
      : { format: selectedFileFormat };
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        data: { fileSelected: true, savePreferences: hasPreferences },
        settings,
      },
    });
  };

  const handleSheetNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        settings: { sheet_name: e.target.value },
      },
    });
  };
  const handleDelimiterChange = (e: ChangeEvent<HTMLInputElement>): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        settings: { delimiter: e.target.value },
      },
    });
  };
  const handleQuoteChange = (e: ChangeEvent<HTMLInputElement>): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        settings: { quote: e.target.value },
      },
    });
  };
  const handlePreviewSubmit = (previewForm: IFormState): void => {
    const data: Partial<IFileUploadState> = previewReady
      ? {
          previewLoading: true,
        }
      : {
          fileSelected: uploadersContext.sharedState.fileSelected === true,
          dateSelected: uploadersContext.sharedState.dateSelected === true,
        };

    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        data,
      },
    });

    if (!previewReady) return;
    const fileContent: FileProps = {
      ...uploadersContext.sharedState.file,
      ...getFileContent("textFile", previewForm),
      links: getRelationLinks(selectedClientData.client_id),
      client_id: "",
    };

    const formSettings: IUploadSettings =
      fileType === FileUploadFormat.XLS
        ? {
            ...uploadersContext.sharedState.settings,
            format: FileUploadFormat.XLS,
            sheet_name: previewForm.sheet_name,
            delimiter: undefined,
            quote: undefined,
          }
        : {
            ...uploadersContext.sharedState.settings,
            format: FileUploadFormat.CSV,
            delimiter: previewForm.delimiter,
            quote: previewForm.quote,
            sheet_name: undefined,
          };
    if (!formSettings.format) throw Error("");
    if (!fileContent.date) throw Error("");
    const filePreviewProps: IFileUploadState = {
      ...uploadersContext.sharedState,
      client_id: "",
      file: {
        ...uploadersContext.sharedState.file,
        ...fileContent,
      },
      settings: formSettings,
    };

    uploadPreview(filePreviewProps, ({ data }) => {
      if (!data || !data.preview) return;

      const { data: tableData, error, guid } = JSON.parse(data.preview);

      if (error && error.statusCode) {
        uploadersDispatch({
          type: EUploadersActionTypes.SET_STATE,
          payload: {
            data: {
              previewLoading: false,
              previewErrorCode: error.statusCode as EPreviewErrorCodes,
            },
          },
        });
        return;
      }
      uploadersDispatch({
        type: EUploadersActionTypes.SET_STATE,
        payload: {
          data: {
            previewLoading: false,
            tableData,
          },
          /*File is already uploaded and available on S3, adjust uploaded file info so it can be
           * calculated without re-uploading*/
          file: {
            ...fileContent,
            ...formSettings,
            content: "", // no need to send file content again
            name: guid, // set filename from guid
            links: getRelationLinks(selectedClientData.client_id),
            date: dateOfFile,
          },
        },
      });
    });
  };

  return (
    <>
      <StyledUploadExcel>
        <StyledFileRequiredFields>
          <Form onSubmitHandler={handlePreviewSubmit}>
            <FormRow align={AlignmentEnums.STRETCH}>
              <P>{t("setup.upload_form_2.excel.select_file", { step: 2 })}</P>
            </FormRow>
            <FormRow align={AlignmentEnums.STRETCH}>
              <FileUploader
                name={"textFile"}
                type={InputTypes.TEXT}
                label={t("setup.upload_form_2.excel.placeholder.file")}
                placeholder={t("setup.upload_form_2.excel.placeholder.file")}
                onChange={handleFileSelect}
                valid={fileSelected !== false}
                invalidWarning={t("misc.required_field")}
                disabled={previewLoading}
                accept="text/csv,
                text/plain,
                application/vnd.ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </FormRow>
            <FormRow align={AlignmentEnums.STRETCH}>
              <SingleSuSaDate
                data={[]}
                name="deadline"
                label={t("setup.upload_form_2.excel.label.deadline")}
                placeholder={t("setup.upload_form_2.excel.placeholder.deadline")}
                invalidWarning={t("misc.required_field")}
                valid={dateSelected !== false}
                disabled={previewLoading}
              />
            </FormRow>
            {isInitial && (
              <FormRow align={AlignmentEnums.STRETCH}>
                <PlanPeriod disabled={previewLoading} />
              </FormRow>
            )}
            {fileType === FileUploadFormat.XLS && (
              <FormRow align={AlignmentEnums.STRETCH}>
                <Input
                  name={"sheet_name"}
                  value={uploadersContext.sharedState.settings?.sheet_name || ""}
                  label={t("setup.upload_form_2.excel.label.sheet_name")}
                  placeholder={t("setup.upload_form_2.excel.placeholder.sheet_name")}
                  disabled={previewLoading}
                  onChange={handleSheetNameChange}
                />
              </FormRow>
            )}

            {(fileType === FileUploadFormat.CSV || fileType === FileUploadFormat.TXT) && (
              <>
                <FormRow align={AlignmentEnums.LEFT}>
                  <Input
                    name={"delimiter"}
                    value={""}
                    disabled={previewLoading}
                    label={t("setup.upload_form_2.excel.label.delimiter")}
                    placeholder={t("setup.upload_form_2.excel.placeholder.delimiter")}
                    onChange={handleDelimiterChange}
                  />
                </FormRow>
                <FormRow align={AlignmentEnums.LEFT}>
                  <Input
                    name={"quote"}
                    value={""}
                    disabled={previewLoading}
                    label={t("setup.upload_form_2.excel.label.quote")}
                    placeholder={t("setup.upload_form_2.excel.placeholder.quote")}
                    onChange={handleQuoteChange}
                  />
                </FormRow>
              </>
            )}

            <FormRow align={AlignmentEnums.LEFT}>
              <Button
                variant={ButtonVariantEnums.SECONDARY}
                inverted={false}
                type={"submit"}
                inline={true}
                disabled={previewLoading}
              >
                {t("setup.upload_form_2.excel.submit.pre_upload")}
              </Button>
            </FormRow>
          </Form>
        </StyledFileRequiredFields>
        <StyledUploadFileIcons>
          <StyledUploadFileIcon selected={fileType === FileUploadFormat.XLS}>
            <Icon name={IconNameEnums.XLS} size={IconSizeEnums.LARGE} />
          </StyledUploadFileIcon>
          <StyledUploadFileIcon selected={fileType === FileUploadFormat.CSV}>
            <Icon name={IconNameEnums.CSV} size={IconSizeEnums.LARGE} />
          </StyledUploadFileIcon>
          <StyledUploadFileIcon selected={fileType === FileUploadFormat.TXT}>
            <Icon name={IconNameEnums.TXT} size={IconSizeEnums.LARGE} />
          </StyledUploadFileIcon>
        </StyledUploadFileIcons>
      </StyledUploadExcel>
    </>
  );
};
