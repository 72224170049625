import { formatNumber } from "./formatNumber";

export const formatPercentage = (value: number | string): string => {
  const formattedNumber = formatNumber(value, {
    maximumFractionDigits: 1,
    absolute: true,
  });
  const asParsed = typeof value === "string" ? parseFloat(value) : value;

  if (asParsed === 0) return "-";
  if (asParsed > 100) return "> 100";
  return formattedNumber;
};
