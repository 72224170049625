import styled, { css } from "styled-components/macro";
import { useResponsive } from "../../../../_lib/hooks/useResponsive";
import { EEvaluationTrend } from "../../../../@types/index.d";

export interface IEntryTrendProps {
  trend?: EEvaluationTrend;
}

const Container = styled.section(({ theme }) => {
  return css`
    padding: ${theme.rem(0.75)};
    box-shadow: 0 0 ${theme.rem(1)} ${theme.rem(0.25)} ${theme.values.component.borderColors.basic};
    margin: 0 ${theme.rem(1)} ${theme.rem(3)};
  `;
});

const ContainerInner = styled.div(({ theme }) => {
  return css`
    border: 4px solid ${theme.values.colors.global.primary.third};
    margin: ${theme.rem(1)};
    padding: ${theme.rem(0.75)};
  `;
});

const ReportDate = styled.div(() => {
  return css`
    font-style: italic;
  `;
});

const ReportCaption = styled.h2(({ theme }) => {
  return css`
    &:first-line {
      font-size: ${theme.rem(3)};
      font-weight: ${theme.values.fontWeight.bold};
      line-height: 2;
    }

    font-size: ${theme.rem(1.5)};
    line-height: 1.5;
    text-align: center;
    border-bottom: 2px solid;
    max-width: 75%;
    padding-bottom: ${theme.rem(1)};
    margin: 0 auto ${theme.rem(2)};
  `;
});

const ReportBody = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        justify-content: space-between;
        margin-bottom: ${theme.rem(4)};
        flex-wrap: wrap;
      `,
    },
    MEDIUM: {
      style: css`
        flex-wrap: nowrap;
      `,
    },
  });
});

const ReportFooter = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1.25)};
    font-weight: ${theme.values.fontWeight.bolder};
    margin-bottom: ${theme.rem(8)};
  `;
});
const ReportColumn = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        flex-basis: 100%;
        margin: 0 ${theme.rem(1)} ${theme.rem(0.5)} ${theme.rem(0.5)};
        flex-wrap: wrap;
        display: flex;
        align-content: space-between;
      `,
    },
    MEDIUM: {
      style: css`
        flex-basis: 50%;
      `,
    },
  });
});

const ReportGroup = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
  `;
});

const ReportGroupCaption = styled.div(({ theme }) => {
  return css`
    border-bottom: 1px solid;
    text-align: center;
    text-transform: uppercase;
    font-weight: ${theme.values.fontWeight.bolder};
    margin-bottom: ${theme.rem(1)};
    font-size: ${theme.rem(1.125)};
    line-height: 1.5;
  `;
});

const ReportGroupSection = styled.div(({ theme }) => {
  return css`
    padding: 0 ${theme.rem(1)};
    margin-bottom: ${theme.rem(1)};
  `;
});

const ReportGroupSectionCaption = styled.div(({ theme }) => {
  return css`
    display: flex;
    padding: ${theme.rem(0.25)} ${theme.rem(0.5)};
    justify-content: center;
    color: ${theme.values.colors.global.greys.sixth};
    border-bottom: 1px solid;
  `;
});

const ReportGroupSectionCaptionDescription = styled.div(({ theme }) => {
  return css`
    flex-grow: 1;
    text-transform: uppercase;
  `;
});

const ReportGroupSectionCaptionWeight = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(0.75)};
  `;
});

const ReportGroupSectionEntry = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-wrap: nowrap;
    padding: ${theme.rem(0.25)} ${theme.rem(0.5)};
  `;
});

const ReportGroupSectionEntrySum = styled(ReportGroupSectionEntry)(({ theme }) => {
  return css`
    border-top: 1px solid;
    font-weight: ${theme.values.fontWeight.bolder};
    color: ${theme.values.colors.global.greys.sixth};
  `;
});

const ReportGroupSummary = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: ${theme.rem(1)} 0 0 0;
    border-top: 1px solid;
    align-items: baseline;
  `;
});

const ReportGroupSummaryNote = styled.div(({ theme }) => {
  return css`
    font-weight: ${theme.values.fontWeight.bold};
  `;
});
const ReportGroupSummaryWeight = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(0.75)};
  `;
});

const EntryDescription = styled.div(({ theme }) => {
  return css`
    flex-basis: calc(100% - ${theme.rem(6)});
    overflow-x: hidden;
    padding-left: ${theme.rem(1)};
  `;
});

const EntryNote = styled.div(({ theme }) => {
  return css`
    flex-basis: ${theme.rem(4)};
    background-color: ${theme.values.component.backgroundColor};
    text-align: center;
    align-self: center;
    padding: ${theme.rem(0.25)} 0;
  `;
});

const EntryTrend = styled.div<IEntryTrendProps>(({ theme, trend = EEvaluationTrend.unchanged }) => {
  const better = css`
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
  `;
  const worse = css`
    transform-origin: 50% 50%;
    transform: rotate(45deg);
  `;

  return css`
    flex-basis: ${theme.rem(2)};
    align-self: center;
    display: flex;
    justify-content: center;
    color: ${theme.values.colors.global.greys.sixth};
    ${trend === EEvaluationTrend.better && better}
    ${trend === EEvaluationTrend.worse && worse}
  `;
});

const SubSectionDescription = styled(EntryDescription)(() => {
  return css`
    text-transform: uppercase;
  `;
});
const SubSectionNote = styled(EntryNote)(() => {
  return css`
    background-color: transparent;
  `;
});

export const StyledFinancialGradeReport = {
  Container,
  ContainerInner,
  ReportDate,
  ReportCaption,
  ReportBody,
  ReportFooter,
  ReportColumn,
  ReportGroupSummary,
  ReportGroupSummaryNote,
  ReportGroupSummaryWeight,
  ReportGroup,
  ReportGroupCaption,
  ReportGroupSection,
  ReportGroupSectionCaption,
  ReportGroupSectionCaptionDescription,
  ReportGroupSectionCaptionWeight,
  ReportGroupSectionEntry,
  ReportGroupSectionEntrySum,
  EntryDescription,
  EntryNote,
  EntryTrend,
  SubSectionDescription,
  SubSectionNote,
};
