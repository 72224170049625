import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ICheckedRecommendationRetVal,
  ICheckedRecommendationsRetVal,
  ICheckedRecommendationsVar,
  IGetRecommendationsCallRet,
  IInterpretationsJSON,
  IKpiVisibleDataRetVal,
  ILocalState,
  IRecommendationsJSON,
  ISetRecommendationCheckedVars,
  TCallGetCheckedRecommendationsRetVal,
  TKpiGraphList,
  TKpiInterpretationList,
  TKpiRecommendationList,
} from "../../..//@types/index.d";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { TMutationFns } from "../planning";

export const GET_RECOMMENDATIONS = gql`
  query getRecommendations($client_id: ID!) {
    getRecommendations(client_id: $client_id) {
      check_id
      kpi_ids
      interpretations
      recommendations
    }
  }
`;

export const GET_CHECKED_RECOMMENDATIONS = gql`
  query getCheckedRecommendations($client_id: ID!) {
    getCheckedRecommendations(client_id: $client_id) {
      plan_id
      recommendations {
        recommendation_id
        checked_at
      }
    }
  }
`;

export const SET_RECOMMENDATION_CHECKED = gql`
  mutation setRecommendationChecked($data: SetRecommendationCheckedInput!) {
    setRecommendationChecked(data: $data) {
      plan_id
      recommendations {
        recommendation_id
        checked_at
      }
    }
  }
`;

const GET_DEFAULT_KPI_VISIBLE_DATA = gql`
  query {
    results: getDefaultKpiVisibleData {
      value
      visible
    }
  }
`;

interface IUseKpiRecommendationsRetVal {
  dashboardToDoList: IRecommendationsJSON[];
  getRecommendedActionList: TKpiRecommendationList;
  getInterpretationsList: TKpiInterpretationList;
  getKpiListForDialogGraph: TKpiGraphList;
  recommendationsLoading: boolean;
  callGetCheckedRecommendations: (client_id: string) => TCallGetCheckedRecommendationsRetVal;
  setRecommendationCheckedFn: TMutationFns<
    ICheckedRecommendationRetVal,
    ISetRecommendationCheckedVars
  >;
  setRecommendationCheckedLoading: boolean;
}

export const useKpiRecommendations = (): IUseKpiRecommendationsRetVal => {
  const { t } = useTranslation(["digital/interpretations", "digital/recommendations"]);
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const selectedClient = useSelector(
    ({ currentUser: { selectedClient } }: ILocalState) => selectedClient,
    isEqual
  );
  const apolloClient = useApolloClient();

  // to call getCheckedRecommendations once again after setting an action to checked or not checked, to render the correct recommended actions list
  const callGetCheckedRecommendations = (
    client_id: string
  ): TCallGetCheckedRecommendationsRetVal => {
    return apolloClient.query<ICheckedRecommendationsRetVal, ICheckedRecommendationsVar>({
      query: GET_CHECKED_RECOMMENDATIONS,
      variables: { client_id },
      fetchPolicy: "network-only",
    });
  };

  // to get all recommendations
  const {
    data,
    loading: recommendationsLoading,
    refetch: refetchRecommendations,
  } = useQuery<IGetRecommendationsCallRet>(GET_RECOMMENDATIONS, {
    variables: { client_id: selectedClient?.client_id || "" },
  });

  // to get default kpis only with the kpi id and visible value
  const { data: defaultKpisVisibleData } = useQuery<IKpiVisibleDataRetVal>(
    GET_DEFAULT_KPI_VISIBLE_DATA,
    {
      variables: { client_id: selectedClient?.client_id || "" },
    }
  );

  // to get checked recommendations
  const { data: checkedData, refetch: refetchCheckedRecommendations } = useQuery<{
    getCheckedRecommendations: ICheckedRecommendationRetVal;
  }>(GET_CHECKED_RECOMMENDATIONS, {
    variables: { client_id: selectedClient?.client_id || "" },
  });

  // to get new recommendations if the user changes, deletes or make a new active plan
  useEffect(() => {
    if (!plans || plans.length === 0) return;
    if (recommendationsLoading) return;
    const activePlanId = plans.find(({ active }) => active === true)?.plan_id;
    if (checkedData?.getCheckedRecommendations?.plan_id === activePlanId) return;
    refetchRecommendations &&
      refetchRecommendations({ client_id: selectedClient?.client_id || "" });
    refetchCheckedRecommendations &&
      refetchCheckedRecommendations({ client_id: selectedClient?.client_id || "" });
  }, [
    checkedData?.getCheckedRecommendations?.plan_id,
    plans,
    recommendationsLoading,
    refetchRecommendations,
    refetchCheckedRecommendations,
    selectedClient?.client_id,
  ]);

  // to set the action to checked or not checked, to be used in dashboard and kpi pages
  const [setRecommendationCheckedFn, { loading: setRecommendationCheckedLoading }] = useMutation<
    ICheckedRecommendationRetVal,
    ISetRecommendationCheckedVars
  >(SET_RECOMMENDATION_CHECKED, {
    onCompleted: (): TCallGetCheckedRecommendationsRetVal =>
      callGetCheckedRecommendations(selectedClient?.client_id || ""),
    fetchPolicy: "network-only",
  });

  const interpretationsJSON: IInterpretationsJSON[] = t("digital/interpretations:data.items", {
    returnObjects: true,
  });

  const recommendationsJSON: Record<string, IRecommendationsJSON> = t(
    "digital/recommendations:action_details",
    {
      returnObjects: true,
    }
  );

  // returns to do list for dashboard
  const dashboardToDoList = useMemo<IRecommendationsJSON[]>(() => {
    if (!data || !recommendationsJSON) return [];
    let recommendationIds: string[] = [];
    for (const item of data.getRecommendations) {
      recommendationIds.push(...item.recommendations);
    }
    // to delete the duplicates
    recommendationIds = [...new Set(recommendationIds)];

    // to get the relevant data of the recommendation
    let recommendationObjList: IRecommendationsJSON[] = [];
    for (const recommendationObj of Object.values(recommendationsJSON)) {
      for (const id of recommendationIds) {
        id === recommendationObj.value && recommendationObjList.push(recommendationObj);
      }
    }

    // to see whether any recommended action is checked
    const isThereCheckedAction = !!checkedData?.getCheckedRecommendations?.recommendations?.length;
    let checkedActionsIds: string[] = [];
    if (isThereCheckedAction)
      checkedActionsIds = checkedData?.getCheckedRecommendations?.recommendations.map(
        ({ recommendation_id }) => recommendation_id
      );

    // to enrich recommendationObjList with checked values
    recommendationObjList = recommendationObjList.map((item) => ({
      ...item,
      checked: checkedActionsIds.includes(item.value),
    }));

    return recommendationObjList;
  }, [data, recommendationsJSON, checkedData?.getCheckedRecommendations?.recommendations]);

  // returns actions list for the chosen kpi (in kpi page)
  const getRecommendedActionList = useCallback<TKpiRecommendationList>(
    (kpiId: string) => {
      if (!data || !recommendationsJSON) return [];
      let recommendedActionIds: string[] = [];
      for (const recommendationObj of data.getRecommendations) {
        recommendationObj.kpi_ids.includes(kpiId) &&
          recommendedActionIds.push(...recommendationObj.recommendations);
      }

      // to delete the duplicates
      recommendedActionIds = [...new Set(recommendedActionIds)];

      // to get the relevant data of the recommendation
      let recommendedActionsList: IRecommendationsJSON[] = [];
      for (const recommendationObj of Object.values(recommendationsJSON)) {
        for (const id of recommendedActionIds) {
          id === recommendationObj.value && recommendedActionsList.push(recommendationObj);
        }
      }

      // to see whether any recommended action is checked
      const isThereCheckedAction =
        !!checkedData?.getCheckedRecommendations?.recommendations?.length;

      if (isThereCheckedAction) {
        const checkedRecommendationsids =
          checkedData?.getCheckedRecommendations?.recommendations.map(
            ({ recommendation_id }) => recommendation_id
          );

        // to enrich recommendationObjList with checked values
        recommendedActionsList = recommendedActionsList.map((item) => ({
          ...item,
          checked: checkedRecommendationsids.includes(item.value),
        }));
      }

      return recommendedActionsList;
    },
    [data, recommendationsJSON, checkedData?.getCheckedRecommendations?.recommendations]
  );

  // returns interpretations list for the chosen kpi (in kpi page)
  const getInterpretationsList = useCallback<TKpiInterpretationList>(
    (kpiId: string) => {
      if (!data || !interpretationsJSON) return [];
      let interpretationIds: string[] = [];
      for (const recommendationObj of data.getRecommendations) {
        recommendationObj.kpi_ids.includes(kpiId) &&
          interpretationIds.push(...recommendationObj.interpretations);
      }

      // to delete the duplicates
      interpretationIds = [...new Set(interpretationIds)];

      // to get the relevant data of the interpretation
      const interpretationsList: IInterpretationsJSON[] = [];
      for (const interpretationObj of interpretationsJSON) {
        for (const id of interpretationIds) {
          id === interpretationObj.value && interpretationsList.push(interpretationObj);
        }
      }

      return interpretationsList;
    },
    [data, interpretationsJSON]
  );

  // returns kpi list related to the action, to be used in kpi details dialog
  const getKpiListForDialogGraph = useCallback<TKpiGraphList>(
    (actionId: string) => {
      if (!data) return { kpiIds: [], interpretationsList: [] };

      let kpiIds: string[] = [];
      const visibleKpiIds: string[] = [];
      let interpretationsIds: string[] = [];
      // if there is a match for action ID, push the interpretations and kpi ids
      for (const recommendationObj of data.getRecommendations) {
        if (recommendationObj.recommendations.includes(actionId)) {
          kpiIds.push(...recommendationObj.kpi_ids);
          interpretationsIds.push(...recommendationObj.interpretations);
        }
      }

      // to delete the duplicates
      kpiIds = [...new Set(kpiIds)];
      interpretationsIds = [...new Set(interpretationsIds)];

      // to get the relevant data of the interpretation
      const interpretationsList: IInterpretationsJSON[] = [];
      for (const interpretationObj of interpretationsJSON) {
        for (const id of interpretationsIds) {
          id === interpretationObj.value && interpretationsList.push(interpretationObj);
        }
      }

      // to check whether the kpi visible value is true, if so render it in the dialog (as kpi graph)
      if (
        defaultKpisVisibleData &&
        defaultKpisVisibleData.results &&
        defaultKpisVisibleData.results.length > 0
      ) {
        for (const defaultKpiObj of defaultKpisVisibleData.results) {
          for (const kpiId of kpiIds) {
            kpiId === defaultKpiObj.value && defaultKpiObj.visible && visibleKpiIds.push(kpiId);
          }
        }
      }

      return { kpiIds: visibleKpiIds, interpretationsList };
    },
    [data, interpretationsJSON, defaultKpisVisibleData]
  );

  return {
    dashboardToDoList,
    getRecommendedActionList,
    getInterpretationsList,
    getKpiListForDialogGraph,
    recommendationsLoading,
    callGetCheckedRecommendations,
    setRecommendationCheckedFn,
    setRecommendationCheckedLoading,
  };
};
