import { FC, RefObject } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import solidGauge from "highcharts/modules/solid-gauge";
import more from "highcharts/highcharts-more";
import { useTheme } from "styled-components/macro";
import { rgba } from "polished";

import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { ILocalState } from "../../@types";
import { isEqual } from "lodash";
more(Highcharts);
solidGauge(Highcharts);

export interface IKPIMatrixCircleProps {
  actualValue: number;
  benchmarkValue: number;
  unitSignature: string;
  forwardRef?: RefObject<HTMLDivElement> | null;
}
export const KPIMatrixCircleChart: FC<IKPIMatrixCircleProps> = ({
  actualValue,
  benchmarkValue,
  unitSignature,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const theme = useTheme();
  const brandedColors = useSelector(
    ({
      themeOptions: {
        branded: { colors },
      },
    }: ILocalState) => colors,
    isEqual
  );

  const colors = {
    actual: brandedColors.secondary,
    actualLight: rgba(brandedColors.secondary, 0.25),
    benchmark: brandedColors.primary,
    benchmarkLight: rgba(brandedColors.primary, 0.25),
  };

  const options: Highcharts.Options = {
    credits: { enabled: false },
    chart: {
      backgroundColor: "transparent",
      type: "solidgauge",
      height: 200,
      events: {},
      style: { fontFamily: theme.values.fontFamily.copy.primary },
    },
    title: {
      useHTML: true,
      text: `<div style="position: relative; text-align: center; left: 50%;top:50%;transform:translate(-50%,50%)">
                <div style="color: ${colors.actual}">${actualValue}</div>
                <div style="font-size: 75%">${unitSignature}</div>
                <div style="color: ${colors.benchmark}">${benchmarkValue}</div>
              </div>`,
      y: -20,
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: theme.rem(1.33),
        fontWeight: "normal",
        fontFamily: theme.values.fontFamily.copy.secondary,
        color: colors.actual,
      },
    },
    tooltip: { enabled: false },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          outerRadius: "110%",
          innerRadius: "95%",
          backgroundColor: colors.benchmarkLight,
          borderWidth: 0,
        },
        {
          outerRadius: "94%",
          innerRadius: "79%",
          backgroundColor: colors.actualLight,
          borderWidth: 0,
        },
      ],
    },
    series: [
      {
        // name: "Benchmark",
        type: "solidgauge",
        data: [
          {
            color: colors.benchmark,
            radius: "110%",
            innerRadius: "95%",
            y: benchmarkValue,
          },
        ],
      },
      {
        // name: "Ihr Ist-Wert",
        type: "solidgauge",
        data: [
          {
            color: colors.actual,
            radius: "94%",
            innerRadius: "79%",
            y: actualValue,
          },
        ],
      },
    ],
    yAxis: { min: 0, max: 100 /*kpi.FullCircleValue*/, lineWidth: 0, tickPositions: [] },
    plotOptions: {
      solidgauge: {
        dataLabels: { enabled: false },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
      },
    },
  };

  return (
    <div ref={ref}>
      {inView && <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />}
    </div>
  );
};
