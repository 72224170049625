import { useSelector } from "react-redux";
import {
  ClientInput,
  FileUploadVariant,
  IArrangementKeys,
  IArrangementKeysV1,
  IArrangementKeysV2,
  IArrangementKeysV3,
  IArrangementKeysV4,
  ILocalState,
  IUploadSettings,
} from "../../@types/index.d";
import { useMemo } from "react";
import { isEqual } from "lodash";

export const initialMappingFromVariant = (variant: FileUploadVariant): IArrangementKeys => {
  const v1: IArrangementKeysV1 = {
    account_code: "",
    description: "",
    balance: "",
    balance_h: "",
    balance_s: "",
    starting_balance: "",
    starting_balance_h: "",
    starting_balance_s: "",
  };
  const v2: IArrangementKeysV2 = {
    account_code: "",
    balance_sh: "",
    starting_balance_sh: "",
    description: "",
    balance: "",
    starting_balance: "",
  };
  const v3: IArrangementKeysV3 = {
    account_code: "",
    balance_h: "",
    balance_s: "",
    description: "",
    starting_balance_h: "",
    starting_balance_s: "",
  };
  const v4: IArrangementKeysV4 = {
    account_code: "",
    balance: "",
    description: "",
    starting_balance: "",
  };

  switch (variant) {
    case FileUploadVariant.V1:
      return v1;
    case FileUploadVariant.V2:
      return v2;
    case FileUploadVariant.V3:
      return v3;
    case FileUploadVariant.V4:
      return v4;
    default:
      throw Error(`${variant} variant can not be other than v1,v2,v3,v4`);
  }
};
const reMapUploadPreferences = ({
  variant_type,
  sheet_name,
  format,
  quote,
  delimiter,
  ...rest
}: IUploadSettings): IUploadSettings | undefined => {
  if (variant_type && format) {
    const mapping = initialMappingFromVariant(variant_type);
    const mappedMapping: Record<string, string> = {};
    const preferencesMap = new Map(Object.entries(rest));
    Object.keys(mapping).forEach((key: string) => {
      const val = preferencesMap.get(key);
      if (val) {
        mappedMapping[key] = val || "";
      }
    });
    const newMapping = mappedMapping as unknown as IArrangementKeys;
    return {
      variant_type,
      format,
      sheet_name,
      quote,
      delimiter,
      mapping: newMapping,
    };
  }
  return undefined;
};
export const useSelectedClientData = (): ClientInput | false => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const client = useMemo(
    () =>
      currentUser.clients.find(
        ({ client_id }) => client_id === currentUser.selectedClient.client_id
      ),
    [currentUser.clients, currentUser.selectedClient.client_id]
  );
  if (!client) return false;
  if (!client.mapping) return false;

  const uploadPreferences = client.customization?.upload_settings
    ? reMapUploadPreferences(client.customization?.upload_settings)
    : undefined;

  return {
    ...client,
    mapping: client.mapping, // explicitly defined to avoid TS-linter error.(although unnecessary)
    customer_id: currentUser.appUser.customer_id,
    customization: {
      ...client.customization,
      upload_settings: uploadPreferences,
    },
    links: client.links,
  };
};
