import { RelationLink } from "./amplify";
import { ApolloQueryResult } from "@apollo/client";

export interface IKpi {
  month: string;
  actual: number;
  potential: number;
  plan: number;
  links: [RelationLink];
}
export interface IKpiPreferences {
  client_id: string;
  kpis: IKpiData[];
  links: [RelationLink];
}
export interface IKpiData {
  value: string;
  benchmark: number;
  months: IKpi[];
}
export interface IGetKpisQueryVars {
  kpiQuery: {
    client_id: string;
    plan_id?: string;
    filter?: string | string[];
    latest?: boolean;
  };
}

export interface IKpiQueryParams {
  kpiId: string;
}

export enum ERecommendedActionLevels {
  LOW = "Strategisch",
  MIDDLE = "Mittelfristig",
  URGENT = "Dringend",
}

export interface IGetRecommendationsRetVal {
  check_id: string;
  kpi_ids: string[];
  interpretations: string[];
  recommendations: string[];
}

interface IRecommendationsJSONLinks {
  href: string;
  rel: string;
}
export interface IRecommendationsJSON {
  level: ERecommendationLevels;
  links: IRecommendationsJSONLinks[];
  summary: string;
  value: string;
  checked: boolean;
  dialog_text: string;
  dialog_links: string[];
}

export interface IInterpretationsJSON {
  value: string;
  summary: string;
}

export interface ICheckedRecommendations {
  checked_at: string;
  recommendation_id: string;
}

export interface ICheckedRecommendationRetVal {
  plan_id: string;
  recommendations: ICheckedRecommendations[];
}
export interface IKpiListForGraph {
  kpiIds: string[];
  interpretationsList: IInterpretationsJSON[];
}

export interface ICheckedRecommendationsRetVal {
  getCheckedRecommendations: ICheckedRecommendationRetVal;
}

export interface ICheckedRecommendationsVar {
  client_id: string;
}

export interface IKpiVisibleDataRetVal {
  results: { value: string; visible: boolean }[];
}

export interface ISetRecommendationCheckedVars {
  data: { client_id: string; plan_id: string; recommendation_id: string };
}

export interface IGetRecommendationsCallRet {
  getRecommendations: IGetRecommendationsRetVal[];
}

export enum ERecommendationLevels {
  LVL01 = "LVL01",
  LVL02 = "LVL02",
  LVL03 = "LVL03",
}

export type TCallGetCheckedRecommendationsRetVal = Promise<
  ApolloQueryResult<ICheckedRecommendationsRetVal>
>;
export type TKpiRecommendationList = (kpiId: string) => IRecommendationsJSON[];
export type TKpiInterpretationList = (kpiId: string) => IInterpretationsJSON[];
export type TKpiGraphList = (actionId: string) => IKpiListForGraph;
