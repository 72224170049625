import { gql, useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState, IWarmUpScopes, IWarmUpTopics, IWarmUpAction } from "../../@types/index.d";

import { Dispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

const WARM_UP_QUERY = gql`
  query warmUp($topic: Topic!, $scope: EventScopes!) {
    warmUp(topic: $topic, scope: $scope)
  }
`;
export type TUseWarmUpReturnValue = (topic: IWarmUpTopics, scope: IWarmUpScopes) => void;
/**
 * Sends warmUp event to lambdas with minimum 10 minute delay
 */
export const useWarmUp = (): TUseWarmUpReturnValue => {
  const warmUp = useSelector(({ warmUp }: ILocalState) => warmUp, isEqual);
  const dispatch = useDispatch<Dispatch<IWarmUpAction>>();
  const apolloClient = useApolloClient();
  return (topic, scope): void => {
    const tenMinutesDiffAsMilliSec = Date.now() - 10 /*minute*/ * 60 /* second */ * 1000; /*ms*/

    const lastWarmUpTimeStamp = warmUp[topic][scope];

    if (lastWarmUpTimeStamp === 0 || lastWarmUpTimeStamp < tenMinutesDiffAsMilliSec) {
      apolloClient.query({ query: WARM_UP_QUERY, variables: { topic, scope } }).then(({ data }) => {
        if (data?.warmUp) {
          dispatch({ type: topic, scope, value: Date.now() });
        }
      });
    }
  };
};
