import { useTranslation } from "react-i18next";
import { parseDate } from "../common";

type TDateToTranslationKey = (key: string, date: string, format: "long" | "short") => string;

export const useTranslatedDate = (): TDateToTranslationKey => {
  const { t } = useTranslation();
  return (key, date, format): string => {
    const monthNames: string[] = t(`misc.months.${format}`, { returnObjects: true });
    // if (date.indexOf("/") < 0) return "";
    // const [M, Y] = date.split("/");
    const d = parseDate(date);
    return t(key, { month: monthNames[d.month], year: d.year });
  };
};
