import { Panel, PanelBody, PanelTitle } from "../../../containers/Panel";
import { FC } from "react";
import { StyledVideoHelp } from "./styled.setup-content";
import { useTranslation } from "react-i18next";
import { IVideoProps, Video } from "../../../components/Video";
import { getAssetPath } from "../../../_lib/common";
import config from "../../../config";
export const VideoHelp: FC = () => {
  const { t } = useTranslation(["common"], { useSuspense: true });

  const src = getAssetPath({ file: "client/videos/intro.mp4" });
  if (!src) return null;
  const videoProps: IVideoProps = {
    poster: config.webapp.poster,
    source: { src },
  };

  return (
    <StyledVideoHelp isLoading={false}>
      <Panel>
        <PanelTitle
          headline={t("setup.video.headline")}
          subline={t("setup.video.subline")}
          help="0"
        />
        <PanelBody>
          <Video {...videoProps} />
        </PanelBody>
      </Panel>
    </StyledVideoHelp>
  );
};
