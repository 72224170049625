import { FC } from "react";
import { AlertTypeEnums, IAlertProps } from "../../@types/index.d";
import { StyledAlert, StyledAlertIcon, StyledAlertText } from "./styled.alert";
import { Icon, IconNameEnums, IconSizeEnums } from "../Icon";

export const Alert: FC<IAlertProps> = ({
  children,
  type = AlertTypeEnums.BASIC,
  closable = false,
}) => {
  const IconOfType = {
    [AlertTypeEnums.SUCCESS]: IconNameEnums.CHECK,
    [AlertTypeEnums.ERROR]: IconNameEnums.EXCLAMATION,
    [AlertTypeEnums.INFO]: IconNameEnums.INFO_CIRCLE,
    [AlertTypeEnums.WARNING]: IconNameEnums.BELL,
    [AlertTypeEnums.BASIC]: IconNameEnums.BULB,
  };
  return (
    <>
      <StyledAlert type={type}>
        <StyledAlertIcon type={type}>
          <Icon name={IconOfType[type]} size={IconSizeEnums.SMALL} />
        </StyledAlertIcon>
        <StyledAlertText>{children}</StyledAlertText>
      </StyledAlert>
    </>
  );
};
