import {
  IPDConfigChecks,
  IPlanDetailsConfigRows,
  ITablesStaticTree,
} from "../_lib/hooks/dataStore/dataStore-types";

export interface IPlan extends IPlanInput {
  plan_id?: string;
  active: boolean;
  title: string;
  description: string;
  interval: string;
  status: string;
  susa_id: string;
  client_id: string;
  revenue_ratios: IRevenueRatios;
  revenue_growth: number;
  month: string;
  months: number;
  created_at: string;
  calculated: IPlanCalculated[];
  templates: IPlanTemplates;
  // to be removed when preview is rewritten
  actuals?: IPlanCalculated[];
}

export interface IPlanCalculated {
  month: number;
  date: string;
  data: IPlanCalculatedValue[];
  type?: string;
  all_fiscal_years_susas_provided_to_this_month?: boolean;
  hidden?: boolean;
  value?: number;
}

export interface IPlanCalculatedValue {
  global_code_id?: string;
  kpi_id?: string;
  balance?: number | null;
  base?: number | null;
  base_balance?: number | null;
  ratio?: number;
  starting_balance?: number | null;
  balance_cumulated?: number | null;
  starting_balance_cumulated?: number | null;
  sum_cumulated?: number;
  comment?: string | null;
  date?: string;
  value?: number;
  percentage?: number;
  loan?: number;
  inflow?: number;
  outflow?: number;
}

interface IKeyIndex {
  [key: string]: unknown;
}

export type IPlanTemplates = {
  [key in EPlanningSubPage]: IPlanSubTemplates;
};

export enum EPlanSubTemplatesKey {
  LINES = "lines",
  KPIS = "kpis",
  CORRECTIONS = "corrections",
  TAXES = "taxes",
  SINGLE_PAYMENTS = "one_time_payments",
}
export interface IPlanSubTemplates extends IKeyIndex {
  lines: IPlanLines[];
  kpis: Pick<IPlanCalculated, "date" | "data">[];
  corrections: Pick<IPlanCalculated, "date" | "data" | "hidden">[];
  taxes: IPlanTaxes[];
  values: Pick<IPlanCalculated, "date" | "data">[];
  afa: IPlanCapexAfa[];
  one_time_payments: IPlanPersonnelPayments[];
  revenue_lines: Pick<IPlanLines, "line_id" | "title">[]; // added for costs response
}

export interface IPlanLines extends IKeyIndex {
  title: string;
  line_id: string;
  date: string;
  kpi_id: string;
  type: string;
  global_code_id: string;
  investment_costs: string;
  duration_in_years: string;
  is_recurring: boolean;
  cycle: string;
  active_until: string;
  sales_revenue: string;
  residual_value: string;
  recognize_as_ao: boolean;
  revenue_relation: GlobalCodesEnums | string;
  remaining_months: string;
  monthly_afa: string;
  year: number;
  is_intercompany: boolean;
  is_changeable: boolean;
  is_revenue_related: boolean;
  nonwage_labor_costs: IPlanTaxes[];
  extra_salary: IPlanTaxes[];
  monthly_gross: IPlanCalculatedValue[];
  monthly_number_employees: IPlanCalculatedValue[];
  loan_amount: string;
  start_date: string;
  repayment_amount: string;
  valuta: string;
  duration_in_months: string;
  cycle: number;
  interest_rate: string;
  values: Pick<IPlanCalculated, "date" | "data" | "hidden" | "value">[];
  revenue_percentages: IRevPercentageValue[];
}

interface IRevPercentageValue {
  year: number;
  value: number;
}

export interface IPlanLinesYears extends IKeyIndex {
  title: string;
  line_id: string;
  year: number;
  is_intercompany: boolean;
  nonwage_labor_costs: IPlanTaxes[];
  extra_salary: IPlanTaxes[];
  monthly_gross: IPlanCalculatedValue[];
  monthly_number_employees: IPlanCalculatedValue[];
  values: Record<string, Pick<IPlanCalculated, "date" | "data" | "hidden" | "value">[]>;
}

export interface IPlanPersonnelPayments {
  year: number;
  balance: number;
  values: IPlanCalculatedValue[];
}

export interface IPlanTaxes {
  value: number;
  year: number;
}

export interface IPlanCapexAfa {
  date: string;
  inflow: number;
  outflow: number;
  type?: string;
}

export interface ICopyPlanInput {
  title: string;
  description: string;
  active: boolean;
}

export interface IPlanInput extends ICopyPlanInput {
  susa_id: string;
  client_id: string;
  revenue_ratios: IRevenueRatios;
  revenue_growth: number;
  months: number;
  interval?: string;
  cut_incomplete_year?: boolean;
}

export interface IPlanData {
  getPlans: undefined | IPlan[];
}

export interface IPlanDataVariables {
  params: GetPlansInput;
}

export interface GetPlansInput {
  client_id: string;
  active?: boolean;
  with_data?: boolean;
  with_actuals?: boolean;
}

export interface ICreatePlan {
  createPlan: IPlan;
}

export interface IGetPlan {
  getPlan: IPlan;
}

export interface ICopyPlan {
  copyPlan: IPlan;
}

export interface IEditPlan {
  editPlan: IPlan;
}

export interface IDeletePlan {
  deletePlan: boolean;
}

export interface IUpdateTemplatesRetVal {
  updateTemplates: IPlan;
}

export interface IUpdateTemplatesVariables {
  plan_id: string;
  type: string;
  data: Partial<IPlanTemplates>;
}

export interface IGetPlanVariables {
  plan_id: ID;
  with_data: boolean;
  with_actuals: boolean;
  template_filter?: string | EPlanningSubPage;
}
export interface ICreatePlanVariables {
  plan: IPlanInput;
}

export interface ICopyPlanVariables {
  plan_id: string;
  plan: ICopyPlanInput;
}

export interface IEditPlanVariables {
  plan_id: string;
  plan: ICopyPlanInput;
}

export interface IDeletePlanVariables {
  plan_id: string;
}

export enum EUpdateCacheType {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
  REMOVE = "REMOVE",
  ACTIVATE = "ACTIVATE",
}

export type ICopyFromPlan = Pick<IPlan, "active" | "title" | "description" | "plan_id"> & {
  templates?: IPlanTemplates;
};

export interface IRevenueRatios {
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}

export enum GlobalCodesEnums {
  P01 = "P01",
  P02 = "P02",
  P02R = "P02R",
  P03 = "P03",
  P03T = "P03T",
  P04 = "P04",
  P05 = "P05",
  P05A = "P05A",
  P05B = "P05B",
  P06 = "P06",
  P07 = "P07",
  P08A = "P08A",
  P08B = "P08B",
  P08C = "P08C",
  P08D = "P08D",
  P08E = "P08E",
  P08F = "P08F",
  P08G = "P08G",
  P08 = "P08",
  P08T = "P08T",
  P09 = "P09",
  P09E = "P09E",
  P10 = "P10",
  P11 = "P11",
  P12 = "P12",
  P12N = "P12N",
  P12O = "P12O",
  P13 = "P13",
  P14 = "P14",
  P14R = "P14R",
  P15 = "P15",
  P16 = "P16",
  P20N = "P20N",
  BA01 = "BA01",
  BA02 = "BA02",
  BA03 = "BA03",
  BA03A = "BA03A",
  BA04 = "BA04",
  BA05 = "BA05",
  BA06 = "BA06",
  BA06T = "BA06T",
  BA06A = "BA06A",
  BA07 = "BA07",
  BA08 = "BA08",
  BA08A = "BA08A",
  BA08B = "BA08B",
  BA09 = "BA09",
  BA10 = "BA10",
  BA11 = "BA11",
  BA11A = "BA11A",
  BA12 = "BA12",
  BA13 = "BA13",
  BA14 = "BA14",
  BA15 = "BA15",
  BA16 = "BA16",
  BA16A = "BA16A",
  BA17 = "BA17",
  BA18 = "BA18",
  BA18A = "BA18A",
  BA20T = "BA20T",
  BE01 = "BE01",
  BE02 = "BE02",
  BE03 = "BE03",
  BE04 = "BE04",
  BE05 = "BE05",
  BE05E = "BE05E",
  BP01 = "BP01",
  BP02 = "BP02",
  BP03 = "BP03",
  BP03A = "BP03A",
  BP04 = "BP04",
  BP05 = "BP05",
  BP06 = "BP06",
  BP07 = "BP07",
  BP08 = "BP08",
  BP09 = "BP09",
  BP10 = "BP10",
  BP10L = "BP10L",
  BP11 = "BP11",
  BP20T = "BP20T",
  CF01 = "CF01",
  CF02 = "CF02",
  CF03 = "CF03",
  CF04 = "CF04",
  CF05 = "CF05",
  CF06 = "CF06",
  CF07 = "CF07",
  CF08 = "CF08",
  CF09 = "CF09",
  CF10 = "CF10",
  CF11 = "CF11",
  CF12 = "CF12",
  CF13 = "CF13",
  CF14 = "CF14",
  CF15 = "CF15",
  CF16 = "CF16",
  CF17 = "CF17",
  CF18 = "CF18",
  CF19 = "CF19",
  CF19E = "CF19E",
  CF20 = "CF20",
  CF21 = "CF21",
  CF22 = "CF22",
  CF23 = "CF23",
  OT01 = "OT01",
  OT02 = "OT02",
  L01 = "L01",
  L02 = "L02",
  L03 = "L03",
  L04 = "L04",
  L05 = "L05",
  L06 = "L06",
  L07 = "L07",
  L08 = "L08",
  L09 = "L09",
  L10 = "L10",
  L11 = "L11",
  L12 = "L12",
  L13 = "L13",
  L14 = "L14",
  L15 = "L15",
  L16 = "L16",
  L17 = "L17",
  L18 = "L18",
  L19 = "L19",
  L20 = "L20",
  L21 = "L21",
  L22 = "L22",
  L23 = "L23",
  L24 = "L24",
  L25 = "L25",
  L26 = "L26",
  L27 = "L27",
  L28 = "L28",
  L29 = "L29",
  L30 = "L30",
  L31 = "L31",
  L32 = "L32",
}

// ------------------ NEW PLAN TS DEFINITIONS STARTS HERE -----------------

export interface IPlanDetailsValues {
  global_code_id: string;
  values: Record<string, number[]>;
  comments?: Record<string, string[]>;
}

export interface ICompsToRender {
  comp: FC;
  title: string;
  name: string;
}

// next interface is for graphql request input definitions
export interface ICreateRevenueInput {
  title: string;
  year: number;
  revenue: number;
  related_goods?: number;
  related_services?: number;
  growth?: number;
  is_intercompany: boolean;
  plan_id: string;
}

export interface ICreateRevenueLineVariables {
  revenue_line: ICreateRevenueInput;
}

export interface ICreateRevenueLine {
  createRevenueLine: IPlan;
}

export interface IUpdateTemplatesVariables {
  plan_id: string;
  type: EPlanningSubPage;
  data: IPlanTemplates;
}

export interface IUpdateTemplatesRetVal {
  updateTemplates: IPlan;
}

export interface ICreatePersonnelLineInput {
  plan_id: string;
  title: string;
  year: number;
  number_employees: number;
  annual_gross: number;
  nonwage_labor_costs?: number;
  extra_salary?: number;
  growth?: number;
}

export interface ICreatePersonnelLineVariables {
  personnel_line: ICreatePersonnelLineInput;
}

export interface ICreatePersonnelLine {
  createPersonnelLine: IPlan;
}

export interface ICreateCostLineInput {
  plan_id: string;
  title: string;
  year: number;
  global_code_id: GlobalCodesEnums;
  is_revenue_related?: boolean;
  revenue_relation?: GlobalCodesEnums;
  revenue_percentage?: number;
  costs?: number;
  growth?: number;
}

export interface ICreateCostLineVariables {
  cost_line: ICreateCostLineInput;
}

export interface ICreateCostLine {
  createCostLine: IPlan;
}

export interface ICreateInvestmentLineInput {
  title: string;
  date: string;
  global_code_id: string;
  type: string;
  investment_costs?: number;
  duration_in_years?: number;
  is_recurring?: boolean;
  cycle?: number;
  active_until?: string;
  sales_revenue?: number;
  residual_value?: number;
  recognize_as_ao?: boolean;
  remaining_months?: number;
  monthly_afa?: number;
}

export interface ICreateInvestmentLineVariables {
  investment_line: ICreateInvestmentLineInput;
}

export interface ICreateInvestmentLine {
  createInvestmentLine: IPlan;
}

export interface ICreateFinancingLineInput {
  plan_id: string;
  title: string;
  date: string;
  global_code_id: string;
  type: string;
  loan_amount?: number;
  start_date?: string;
  repayment_amount?: number;
  valuta?: number;
  duration_in_months?: number;
  cycle?: number;
  interest_rate?: number;
}

export interface ICreateFinancingLineVariables {
  financing_line: ICreateFinancingLineInput;
}

export interface ICreateFinancingLine {
  createFinancingLine: IPlan;
}

export interface IPlanningContext {
  selectedPlanningPage: EPlanningPage;
  dataStore: IPlanningDetailsDataStore;
  animationDirection: SlideInDirections;
  templates: IPlanTemplates;
  planYears: Record<string, IPlanCalculated[]>;
  PDTablesConfig: IPDTablesConfig;
  PDTablesData: IPDTablesYears;
  cellBeingChanged: string | undefined;
  futureMonths: boolean;
  selectedDetailsSubPage?: EPlanningSubPage;
  selectedTablesSubPage?: TablesSubPage;
  selectedDetailsYear?: string;
  selectedLineId?: string;
  plans?: IPlan[];
  selectedPlan?: IPlan;
  planningLoadingStatus: boolean;
}

export interface IPlanningDetailsDataStore {
  tablesDataTree: Record<ETablesDataTreeKeys, [IPlanPreviewTree]>;
  detailsConfig: IPDDataStoreConfig;
}

export interface IPDDataStoreConfig {
  tablesConfig: [ITablesConfigTree];
  staticConfig: [ITablesStaticTree];
  tables: [IPlanDetailsConfigTables];
  rows: [IPlanDetailsConfigRows];
  checks: [IPDConfigChecks];
}

export interface IPDTablesConfig {
  [key: string]: Record<TablesSubPage, IPDTablesConfigData[]>;
}

export type TablesSubPage =
  | Exclude<EPlanningSubPage, EPlanningSubPage.CORRECTIONS>
  | "balance"
  | "other"
  | "profit_and_loss"
  | "receivables_liabilities"
  | "inventories";

export interface IPDTablesConfigData {
  title: string;
  code: string;
  content: string[];
  hasSumCol: boolean;
  page: EPlanningSubPage;
  rows: ITableRowDataEntry[];
  additional_input: EAdditionalInput;
}

export enum EAdditionalInput {
  TAX = "tax_rate",
  SINGLE_PAYMENTS = "single_payments",
  NON_WAGE_COSTS = "nonwage_labor_costs",
  EXTRA_SALARY = "extra_salary",
  REVENUE_PERCENTAGES = "revenue_percentages",
}

export enum EPlanningSessionStorage {
  TEMPLATES = "templates",
  CELLS_TO_RECALC = "cellsToRecalc",
}

export interface IPlanLinesYears {
  line_id: string;
  title: string;
  is_intercompany: boolean;
  year: number;
  values: Record<string, Pick<IPlanCalculated, "date" | "data" | "hidden">[]>;
  global_code_id?: string;
}

export enum ETablesDataTreeKeys {
  balanceActive = "balanceActive",
  balancePassive = "balancePassive",
  profitAndLoss = "profitAndLoss",
  cashFlow = "cashFlow",
}

// governing planning location
export enum EPlanningPage {
  LIST = "LIST",
  CREATE = "CREATE",
  PREVIEW = "PREVIEW",
  DETAILS = "DETAILS",
}

// governing planning subpage location
export enum EPlanningSubPage {
  REVENUE = "revenue",
  PERSONNEL = "personnel",
  COSTS = "costs",
  CAPITAL = "working_capital",
  INVESTMENT = "capex",
  FINANCIAL = "financing",
  CORRECTIONS = "corrections",
}

// governing row types
export enum EPDTablesRows {
  MONTHS = "months",
  ACTUAL_VALUES = "actual_values",
  PLAN_VALUES = "plan_values",
  REVENUE_LINES = "revenue_lines",
  PERSONNEL_LINES = "personnel_lines",
  PERSONNEL_LINES_EMPLOYEES = "personnel_lines_employees",
  PERSONNEL_LINES_TOTAL = "personnel_lines_total",
  COSTS_LINES = "costs_lines",
  CORRECTIONS = "corrections",
  TAX_RATE = "tax_rate",
  SINGLE_PAYMENTS_PERCENT = "single_payments_percent",
  SINGLE_PAYMENTS = "single_payments",
  KPI = "KPI",
  LOANS = "loans",
  CASH_FLOW = "cash_flow",
  INTERESTS = "interests",
  INVESTMENT = "investment",
  SUM = "sum",
  TOTAL = "total",
}

export interface IEditTitleOrDeleteLineDialogState {
  lineKey: EPlanningSubPage;
  lineId: string;
  title: string;
}

export enum ELineDialogType {
  EDIT,
  DELETE,
}

export interface IAddRevenueLineDialogState {
  initial_form_state: boolean;
  title: string;
  year: string;
  revenue: string;
  related_goods?: string;
  related_services?: string;
  growth?: string;
}

export interface IAddPersonnelLineDialogState {
  initial_form_state: boolean;
  year: string;
  title: string;
  number_employees: string;
  annual_gross: string;
  nonwage_labor_costs?: string;
  extra_salary?: string;
  growth?: string;
}

export interface IAddCostLineDialogState {
  initial_form_state: boolean;
  title: string;
  year: string;
  global_code_id: GlobalCodesEnums | undefined;
  is_revenue_related: boolean;
  revenue_relation: GlobalCodesEnums | undefined;
  revenue_percentage: string;
  costs: string;
  growth: string;
}

export enum EInvestmentFinancingType {
  INFLOW = "inflow",
  OUTFLOW = "outflow",
}

export interface IAddInvestmentLineDialogState {
  initial_form_state: boolean;
  title: string;
  date: string;
  global_code_id: string;
  type: string;
  investment_costs?: string;
  duration_in_years?: string;
  is_recurring?: boolean;
  cycle?: string;
  active_until?: string;
  sales_revenue?: string;
  residual_value?: string;
  recognize_as_ao?: boolean;
  remaining_months?: string;
  monthly_afa?: string;
}

export interface IAddFinancingLineDialogState {
  initial_form_state: boolean;
  title: string;
  date: string;
  global_code_id: string;
  type: string;
  loan_amount?: string;
  interest_rate?: string;
  start_date?: string;
  repayment_amount?: string;
  valuta?: string;
  duration_in_months?: string;
  cycle?: string;
}

export interface IEditPlanningLineTitleRetVal {
  editPlanningLineTitle: IPlan;
}

export interface IEditPlanningLineTitleVariables {
  title: string;
  plan_id: string;
  line_id: string;
  rel: EPlanningSubPage;
}

export interface IDeletePlanningLineVariables {
  plan_id: string;
  line_id: string;
  rel: EPlanningSubPage;
}

export interface IDeletePlanningLineRetVal {
  deletePlanningLine: { data: IPlan; lineType: string };
}

export interface ISelectionObj {
  code: string;
  text: string;
}

export interface ISubPageProps {
  planId: string;
}

export interface ISelectionValues {
  [key: string]: string;
}

export interface IPropsForInputAndSelectRetVal {
  name: keyof IAddInvestmentLineDialogState;
  type: SelectionTypes | InputTypes;
  label: string;
  labelPosition: IInputLabelPosition;
  onChange: (e: ISelectValue<string> | ChangeEvent<HTMLInputElement>) => void;
  invalidWarning?: string;
  value: string;
  withUnit?: string;
}

export interface IPropsForOnOffSwitchRetVal {
  value: ECheckboxState;
  name: keyof IAddInvestmentLineDialogState;
  variant: ButtonVariantEnums;
  label: string;
  labelPosition: IInputLabelPosition;
  onChange: () => void;
}
