import styled, { css } from "styled-components/macro";

export const StyledContentNav = styled.section(
  ({ theme }) => css`
    grid-area: content-nav;
    color: ${theme.branded.colors.secondary};
    background-color: ${theme.values.component.content.backgroundColor};
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: ${theme.rem(4)};
    padding-left: ${theme.rem(1)};
    padding-right: ${theme.rem(1)};
    z-index: 10;
  `
);

export const StyledBarsButton = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    align-items: stretch;
    & > button {
      color: ${theme.values.colors.global.greys._085};
    }
  `
);
export const StyledContentNavSections = styled.section(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
  `
);
export const StyledContentNavTitle = styled(StyledContentNavSections)(
  ({ theme }) => css`
    font-size: ${theme.rem(1.25)};
    padding-left: ${theme.rem(1)};
    flex-grow: 1;
    font-weight: ${theme.values.fontWeight.bold};
  `
);

export const StyledContentNavClientManagement = styled(StyledContentNavSections)(
  ({ theme }) => css`
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0 ${theme.rem(1)};
  `
);
export const StyledContentNavUserManagement = styled(StyledContentNavSections)(
  ({ theme }) => css`
    justify-content: flex-end;
    padding: 0 ${theme.rem(1)};
    min-width: 200px;
    & span {
      color: ${theme.branded.colors.primary};
    }
  `
);
