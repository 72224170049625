import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ILocalState, EPublicPathName, EPrivatePathName } from "../../@types/index.d";
import { FC, Suspense } from "react";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import { lazy } from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction, UserStateActionTypes } from "../../_lib/store/reducers";
// import { ErrorPage } from "./ErrorPage";
import { isEqual } from "lodash";

const Login = lazy(() => import("./Login/Login"));
const Reset = lazy(() => import("./Reset"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const Forgot = lazy(() => import("./Forgot"));
const Register = lazy(() => import("./Register/index"));

export const Public: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  history.listen(() => {
    currentUser.errors.length > 0 &&
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          errors: [],
        },
      });
  });

  // const dialogs = useDialogs();
  // useEffect(() => {
  //   if (currentUser.forceChange) history.push(EPublicPathName.CHANGE_PASSWORD);
  // }, [currentUser.forceChange, history]);

  return (
    <Switch>
      <Route exact path={EPublicPathName.HOME}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Redirect to={EPublicPathName.LOGIN} />
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.LOGIN}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Login />
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.CHANGE_PASSWORD}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <ChangePassword />
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.RESET}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Reset />
        </Suspense>
      </Route>
      <Route exact path={EPublicPathName.FORGOT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Forgot />
        </Suspense>
      </Route>
      <Route exact path={[EPublicPathName.REGISTER, EPublicPathName.REGISTER_SUCCESS]}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Register />
        </Suspense>
      </Route>
      <Route exact path={EPrivatePathName.LOGOUT}>
        <Redirect to={EPublicPathName.LOGIN} />
      </Route>

      <Route path={Object.values(EPrivatePathName)}>
        <Redirect to={EPublicPathName.LOGIN} />
      </Route>

      <Route exact path="*">
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          {/* <ErrorPage
            title="404 - NOT FOUND!"
            message="The page you are looking for is not found!"
          /> */}
          <Redirect to={EPublicPathName.LOGIN} />
        </Suspense>
      </Route>
    </Switch>
  );
};
