import { FC } from "react";
import { StyledContent } from "./styled.content";

export const Content: FC = ({ children }) => {
  return (
    <>
      <StyledContent>{children}</StyledContent>
    </>
  );
};
