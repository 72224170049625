import { ChangeEvent, createContext, FC, useContext, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  StyledFilterableList,
  StyledSearchBar,
  StyledSearchResults,
} from "./styled.filterable-list";

import {
  StyledList,
  StyledListCategories,
  StyledListIndustries,
  StyledListSubIndustries,
} from "./styled.category-filter";

import { gql, useQuery } from "@apollo/client";
import { ISelectValue, IDialogKeyEnums } from "../../@types/index.d";
import { useDialogs } from "../../_lib/hooks";
import ErrorBoundary from "../../_lib/ErrorBoundry";
import { Input } from "../formElements";
import { LoadingIndicator, LoaderTypeEnums } from "../LoadingIndicator";
import { FilterCategories } from "../../_lib/common";

const initialSectors: IContextData = {
  selectionText: "",
  selectedValue: "",
  query: "",
  data: [],
};

const CategoriesContext = createContext<IContextData>(initialSectors);
const CategoriesUpdater = createContext((e: ISelectValue<string>) => {
  // forwards ClientSetupDispatch to children
});

export interface IRawData {
  value: string;
  tree?: IRawData[] | null;
}
export interface IFilterableData extends IRawData {
  label: string;
  selected: boolean;
  visible: boolean;
  tree?: IFilterableData[] | null;
}

export interface IContextData {
  selectionText: string;
  selectedValue: string;
  query: string;
  data: IFilterableData[];
}

const GET_CATEGORIES_TREE = gql`
  {
    getCategoriesTree {
      value
      tree {
        value
        tree {
          value
        }
      }
    }
  }
`;

/**
 * List all available categories retrieved from CategoriesContext
 */
const ListCategories: FC = () => {
  const categories = useContext(CategoriesContext);

  return (
    <>
      {categories.data.map(
        (category, index) => category.visible && <ListIndustries key={index} {...category} />
      )}
    </>
  );
};
/**
 * List all available Industries retrieved from CategoriesContext
 */
const ListIndustries: FC<IFilterableData> = ({ /*value,*/ label, selected, tree = [] }) => {
  return (
    <StyledListIndustries selected={selected} data-test-id={"ListIndustriesX"}>
      <StyledListCategories>{label}</StyledListCategories>

      <StyledList>
        {tree &&
          tree.map((item, index) => item.visible && <ListSubIndustries key={index} {...item} />)}
      </StyledList>
    </StyledListIndustries>
  );
};
/**
 * List all available SubIndustries retrieved from CategoriesContext
 */
const ListSubIndustries: FC<IFilterableData> = ({ value, label, selected, tree = [] }) => {
  const branchesUpdater = useContext(CategoriesUpdater);
  const dialogs = useDialogs();

  const handleIndustrySelect = (e: ISelectValue<string>): void => {
    branchesUpdater(e);
    dialogs.close(IDialogKeyEnums.SELECT_LIST);
  };

  return (
    <StyledListIndustries selected={selected}>
      {
        <span
          onClick={(): void => {
            handleIndustrySelect({
              name: "industries",
              value,
              text: label,
            });
          }}
        >
          {label}
        </span>
      }
      <StyledList>
        {tree?.map(
          (subItem, index) =>
            subItem.visible && (
              <StyledListSubIndustries
                selected={subItem.selected}
                data-test-id={"ListSubIndustries"}
                key={index}
                onClick={(): void => {
                  handleIndustrySelect({
                    name: "sub_industries",
                    value: subItem.value,
                    text: subItem.label,
                  });
                }}
              >
                {subItem.label}
              </StyledListSubIndustries>
            )
        )}
      </StyledList>
    </StyledListIndustries>
  );
};
export interface IBranchesFilterProps {
  category: string;
  updater: (e: ISelectValue<string>) => void;
  testID?: string;
}
export const categoryFilterTestID = "CategoryFilter";
/**
 * Creates Context from data , filters it using search query and
 * renders relevant result which can be used as dialog content.
 */
export const CategoryFilter: FC<IBranchesFilterProps> = ({ updater, category, testID = "" }) => {
  const _testID = testID + categoryFilterTestID;
  const { t } = useTranslation();
  const { data: categoriesData, loading: branchesLoading } = useQuery(GET_CATEGORIES_TREE);

  const [queryString, setQueryString] = useState("");
  const { getCategoriesTree } = categoriesData || {};
  const categories: IContextData = useMemo(() => {
    return getCategoriesTree
      ? {
          data: getCategoriesTree,
          query: queryString,
          selectedValue: category,
          selectionText: "",
        }
      : initialSectors;
  }, [getCategoriesTree, category, queryString]);
  //

  const memoizedData: IContextData = useMemo(() => {
    if (branchesLoading) return categories;

    const filteredCategories = new FilterCategories(categories, t, queryString);
    return {
      ...categories,
      data: filteredCategories.filter(),
    };
  }, [categories, branchesLoading, queryString, t]);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setQueryString(e.target.value);
  };

  return (
    <ErrorBoundary>
      <CategoriesUpdater.Provider value={updater}>
        <CategoriesContext.Provider value={memoizedData}>
          <StyledFilterableList data-test-id={_testID}>
            <StyledSearchBar>
              <Input
                name="searchText"
                value={queryString}
                onChange={handleQueryChange}
                placeholder={t("misc.search")}
                disabled={branchesLoading}
              />
            </StyledSearchBar>
            <StyledSearchResults>
              {
                <StyledList data-test-id={_testID + "-list"}>
                  {branchesLoading ? (
                    <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />
                  ) : (
                    <ListCategories />
                  )}
                </StyledList>
              }
            </StyledSearchResults>
          </StyledFilterableList>
        </CategoriesContext.Provider>
      </CategoriesUpdater.Provider>
    </ErrorBoundary>
  );
};
