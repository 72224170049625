import {
  EPDTablesRows,
  EPlanningSubPage,
  IPDDataStoreConfig,
  IPDTablesConfigData,
  IPDTablesConfig,
  IPlan,
  IPlanCalculated,
  IPlanLinesYears,
  IPlanLines,
  IPlanTemplates,
  IPlanCalculatedValue,
  GlobalCodesEnums,
} from "../../../@types/index.d";
import { ITableRowDataEntry } from "./usePDDataUpdate";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import { ITablesConfigTree } from "../dataStore/dataStore-types";

// used to match the fiscal year to month by index
export const monthsStringArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// function to get yearly data for the plan, year: [months array]
export const getPlanYears = (
  months: Pick<IPlanCalculated, "date" | "data" | "hidden">[] | IPlanCalculatedValue[]
): Record<string, IPlanCalculated[]> => {
  // initiate empty object
  const years: Record<string, IPlanCalculated[]> = {};

  // chunk the months in to array of array of 12 months
  const chunksArray = months
    .map((_, i, all) => all.slice(12 * i, 12 * i + 12))
    .filter((x) => x.length) as IPlanCalculated[][];

  // create [year]: [calculated] object
  for (const yearArray of chunksArray) {
    const yearFirst = yearArray[0].date.split("/")[1];
    const yearLast = yearArray[yearArray.length - 1].date.split("/")[1];
    if (yearFirst === yearLast) years[yearFirst] = [...yearArray];
    else years[yearFirst + " - " + yearLast] = [...yearArray];
  }

  // return the object
  return years;
};

// function to sort an array of objects by date MM/YYYY
export const compareDates = (
  a: Pick<IPlanCalculated, "date" | "data">,
  b: Pick<IPlanCalculated, "date" | "data">
): number => {
  // Split the dates into arrays of month and year
  const [aMonth, aYear] = a.date.split("/");
  const [bMonth, bYear] = b.date.split("/");

  // If the years are different, sort based on year
  if (aYear !== bYear) {
    return Number(aYear) - Number(bYear);
  }
  // If the years are the same, sort based on month
  else if (aMonth !== bMonth) {
    return Number(aMonth) - Number(bMonth);
  }
  // If the months and years are the same, the dates are equal
  else {
    return 0;
  }
};

// function to create an array of tables from their configuration data
export const getPDTablesArray = (
  selectedDetailsSubPage: EPlanningSubPage,
  templates: IPlanTemplates,
  selectedPlan: IPlan,
  detailsConfig: IPDDataStoreConfig
): IPDTablesConfig => {
  const planYearsArray = getPlanYearsArray(selectedPlan?.calculated);
  const tablesYears = {} as IPDTablesConfig;
  const tableConfig = detailsConfig.tablesConfig.find(
    (tableConf) => tableConf.value === selectedDetailsSubPage
  ) as ITablesConfigTree;
  // loop through plan years
  for (const year of planYearsArray) {
    // create object key for every year of the plan
    tablesYears[year] = {} as Record<string, IPDTablesConfigData[]>;
    // loop through every table subpage in table config
    for (const tablePage of tableConfig.tree) {
      // clone tables list from congfign
      const tables = cloneDeep(detailsConfig.tables);
      // for every tabkle sub page create a key in years object and fill it with tables coming from config
      tablesYears[year][tablePage.value] = tables
        .filter((table) => tablePage.tree.some((t) => t.value === table.value))
        .filter((table) => {
          // initiate true for show table
          let showTable = true;

          // if there is a check for the table and the item is not present switch show table to false
          if (table.check_for) {
            const itemPresent = templates[selectedDetailsSubPage][table.check_for];
            const isArray = Array.isArray(itemPresent);

            showTable = isArray && !!itemPresent.length;
          }
          return showTable;
        })
        .map((table) => {
          // add table title
          table.title = i18next.t(`planning.details.table_titles.${table.value}`);
          // rename key value to code for ease of use later
          delete Object.assign(table, { code: table.value })["value"];
          // create rows array from rows from config
          const rows = getPDTableRows(table, templates, parseInt(year.split(" - ")[0]));
          // replace rows list with rows objects
          table.rows = rows;

          // return parsed table object
          return table;
        });
    }
  }

  return tablesYears;
};

// function to create an array of rows for each table from their configuration data
const getPDTableRows = (
  table: IPDTablesConfigData,
  templates: IPlanTemplates,
  selectedYear: number
): ITableRowDataEntry[] => {
  const rows = [] as ITableRowDataEntry[];
  const colNum = table.hasSumCol ? 14 : 13;
  // console.log("table", table);

  for (const item of table.content) {
    if (item === EPDTablesRows.REVENUE_LINES) {
      // continue if there are no lines
      if (!templates.revenue || !templates.revenue.lines) continue;
      // console.log("item", item, " - -", selectedYear);

      for (const line of templates.revenue.lines) {
        if (selectedYear < line.year) continue;
        const row = {
          type: item,
          title: line.title,
          line_id: line.line_id,
          colNum: colNum,
        } as ITableRowDataEntry;

        // fill array differently for different tables (P01 - !is_intecompany, P02 - is_intercompany, others both)
        if (table.code === GlobalCodesEnums.P01 && !line.is_intercompany) {
          rows.push(row);
        }
        if (table.code === GlobalCodesEnums.P02 && line.is_intercompany) {
          rows.push(row);
        }
        if (table.code === GlobalCodesEnums.P04 || table.code === GlobalCodesEnums.P05) {
          rows.push(row);
        }
      }
    } else if (
      item === EPDTablesRows.PERSONNEL_LINES ||
      item === EPDTablesRows.PERSONNEL_LINES_EMPLOYEES ||
      item === EPDTablesRows.PERSONNEL_LINES_TOTAL
    ) {
      // continue if there are no lines
      if (!templates.personnel || !templates.personnel.lines) continue;
      for (const line of templates.personnel.lines) {
        if (selectedYear < line.year) continue;
        const row = {
          type: item,
          title: line.title,
          line_id: line.line_id,
          colNum: colNum,
        } as ITableRowDataEntry;
        rows.push(row);
      }
    } else if (item === EPDTablesRows.COSTS_LINES) {
      // continue if there are no lines
      if (!templates.costs || !templates.costs.lines) continue;
      for (const line of templates.costs.lines) {
        if (selectedYear < line.year) continue;
        if (table.code !== line.global_code_id) continue;
        const row = {
          type: item,
          title: line.title,
          line_id: line.line_id,
          colNum: colNum,
        } as ITableRowDataEntry;
        if (!line.is_revenue_related) row.editable = true;
        rows.push(row);
      }
    } else {
      const row = {
        type: item,
        title: i18next.t(`planning.details.row_titles.${item}`),
        colNum: colNum,
      } as ITableRowDataEntry;
      rows.push(row);
    }
  }
  return rows;
};

// function to get line years, calculated from templates in format [year]: [values for that year]
export const getPlanLinesYears = (lines: IPlanLines[]): IPlanLinesYears[] | undefined => {
  const linesArr = [];

  for (const line of lines) {
    const lineObj = {} as IPlanLinesYears;
    for (const key in line) {
      lineObj[key] = line[key];
    }
    lineObj.values = getPlanYears(line.values ? line.values : line.monthly_gross);
    linesArr.push(lineObj);
  }
  return linesArr;
};

// function to return a string array of plan years
export const getPlanYearsArray = (
  planMonths?: Pick<IPlanCalculated, "date" | "data">[]
): string[] => {
  const planYearsArray = [] as string[];

  if (!planMonths) planMonths = [];
  const monthChunks = planMonths
    .map((_, i, all) => all.slice(12 * i, 12 * i + 12))
    .filter((x) => x.length) as IPlanCalculated[][];

  // create [year]: [calculated] object
  for (const yearArray of monthChunks) {
    const yearFirst = yearArray[0].date.split("/")[1];
    const yearLast = yearArray[yearArray.length - 1].date.split("/")[1];
    if (yearFirst === yearLast) planYearsArray.push(yearFirst);
    else planYearsArray.push(`${yearFirst} - ${yearLast}`);
  }

  return planYearsArray;
};
