import { keyframes } from "styled-components/macro";

export const animateToBottom = keyframes`
  from {
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0%);
  }
`;
export const animateToBottomOut = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-120%);
  }
`;
export const animateToTop = keyframes`
  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(0%);
  }
`;
export const animateToTopOut = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(120%);
  }
`;
export const animateToRight = keyframes`
  from {
    transform: translate(-120%, 0);
  }
  to {
    transform: translateX(0%, 0);
  }
`;
export const animateToRightOut = keyframes`
  from {
    transform: translateX(0%, 0);
  }
  to {
    transform: translate(-120%, 0);
  }
`;
export const animateToLeft = keyframes`
  from {
    transform: translate(120%, 0);
  }
  to {
    transform: translateX(0%, 0);
  }
`;
export const animateToLeftOut = keyframes`
  from {
    transform: translateX(0%, 0);

  }
  to {
    transform: translate(-120%, 0);

  }
`;
