import { FC } from "react";
import { StyledFinancialGradeReport } from "./styled.financial-grade-report";
import { Trans, useTranslation } from "react-i18next";
import { useSelectedClientData } from "../../../../_lib/hooks";
import { LoaderTypeEnums, LoadingIndicator } from "../../../../components/LoadingIndicator";
import { formatNumber } from "../../../../_lib/common";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../../components/Icon";
import { EEvaluationTrend, IEvaluationGrade } from "../../../../@types/index.d";

const {
  Container,
  ContainerInner,
  ReportDate,
  ReportCaption,
  ReportBody,
  ReportFooter,
  ReportColumn,
  ReportGroupSummary,
  ReportGroupSummaryNote,
  ReportGroupSummaryWeight,
  ReportGroup,
  ReportGroupCaption,
  ReportGroupSection,
  ReportGroupSectionCaption,
  ReportGroupSectionCaptionDescription,
  ReportGroupSectionCaptionWeight,
  ReportGroupSectionEntry,
  ReportGroupSectionEntrySum,
  EntryDescription,
  EntryNote,
  EntryTrend,
  SubSectionDescription,
  SubSectionNote,
} = StyledFinancialGradeReport;

export interface IGradesTree {
  value: string;
  all_relevant: boolean;
  tree: IGradeSection[];
}

export interface IGradeSection {
  value: string;
  weight: number;
  tree: IGradeSubSection[];
}
export interface IGradeSubSection {
  value: string;
  weight: number;
  tree: IGradeEntry[];
}

export interface IGradeEntry {
  value: string;
  note: number;
  trend: EEvaluationTrend;
}

export interface IFinancialGradeReportProps {
  notes: IEvaluationGrade[];
  gradesTree?: IGradesTree;
  currentGrade?: number | string;
  prevGrade?: number | string;
  dateOfReport?: string;
}
export const FinancialGradeReport: FC<IFinancialGradeReportProps> = ({
  gradesTree,
  notes,
  dateOfReport,
  currentGrade,
  prevGrade,
}) => {
  const { t } = useTranslation(["datastore"]);
  const selectedClientData = useSelectedClientData();
  const company_name = selectedClientData === false ? "" : selectedClientData.name;
  const gradesLocale: Record<string, string> = t("datastore:grades", { returnObjects: true });
  const kpisLocale: Record<string, string> = t("datastore:kpis", { returnObjects: true });

  const sectionNotes = [
    notes.find((note) => note.grade_id === "GRD001")?.grade,
    notes.find((note) => note.grade_id === "GRD004")?.grade,
  ];
  return (
    <Container>
      <ContainerInner>
        <ReportDate>
          {dateOfReport || <LoadingIndicator type={LoaderTypeEnums.TEXT} length={20} />}
        </ReportDate>
        <ReportCaption>
          <Trans i18nKey="dashboard.financial_grade.title" values={{ company_name }} />
        </ReportCaption>

        <ReportBody>
          {(gradesTree?.tree || []).map((section: IGradeSection, index: number) => {
            const sectionNote = formatNumber(sectionNotes[index] || -1, {
              maximumFractionDigits: 1,
            });
            return (
              <ReportColumn key={`${section.value}-${index}`}>
                {
                  <>
                    <ReportGroup>
                      <ReportGroupCaption>{gradesLocale[section?.value]}</ReportGroupCaption>
                      {(section?.tree || []).map(
                        (subSection: IGradeSubSection, subSectionKey: number) => {
                          const subSectionNote = notes.find(
                            (note) => note.grade_id === subSection?.value
                          )?.grade;
                          return (
                            <ReportGroupSection key={`${subSection.value}-${subSectionKey}`}>
                              <ReportGroupSectionCaption>
                                <ReportGroupSectionCaptionDescription>
                                  {gradesLocale[subSection?.value]}
                                </ReportGroupSectionCaptionDescription>
                                <ReportGroupSectionCaptionWeight>
                                  {t("dashboard.financial_grade.weight", {
                                    weight: subSection?.weight,
                                  })}
                                </ReportGroupSectionCaptionWeight>
                              </ReportGroupSectionCaption>

                              {subSection?.tree &&
                                subSection?.tree.map((entry: IGradeEntry, entryKey: number) => {
                                  const { grade, trend } =
                                    notes.find((note) => note.grade_id === entry?.value) || {};

                                  return (
                                    <ReportGroupSectionEntry key={`${entry.value}-${entryKey}`}>
                                      <EntryDescription>
                                        {entry?.value === "___" ? (
                                          <LoadingIndicator
                                            type={LoaderTypeEnums.TEXT}
                                            length={30}
                                          />
                                        ) : (
                                          kpisLocale[entry?.value]
                                        )}
                                      </EntryDescription>
                                      <EntryNote>
                                        {grade ? (
                                          formatNumber(grade, { maximumFractionDigits: 1 })
                                        ) : (
                                          <LoadingIndicator
                                            type={LoaderTypeEnums.TEXT}
                                            length={4}
                                          />
                                        )}
                                      </EntryNote>
                                      <EntryTrend trend={trend}>
                                        <Icon
                                          name={IconNameEnums.ARROW_RIGHT}
                                          size={IconSizeEnums.XSMALL}
                                        />
                                      </EntryTrend>
                                    </ReportGroupSectionEntry>
                                  );
                                })}
                              <ReportGroupSectionEntrySum>
                                <SubSectionDescription>
                                  {t("dashboard.financial_grade.sub_section_grade")}
                                </SubSectionDescription>
                                <SubSectionNote>
                                  {subSectionNote ? (
                                    formatNumber(subSectionNote, { maximumFractionDigits: 1 })
                                  ) : (
                                    <LoadingIndicator type={LoaderTypeEnums.TEXT} length={4} />
                                  )}
                                </SubSectionNote>
                              </ReportGroupSectionEntrySum>
                            </ReportGroupSection>
                          );
                        }
                      )}
                    </ReportGroup>
                    <ReportGroupSummary>
                      <ReportGroupSummaryNote>
                        {sectionNotes[index] &&
                          t("dashboard.financial_grade.section_grade", {
                            note: sectionNote,
                          })}
                      </ReportGroupSummaryNote>
                      <ReportGroupSummaryWeight>
                        {t("dashboard.financial_grade.weight", {
                          weight: section?.weight,
                        })}
                      </ReportGroupSummaryWeight>
                    </ReportGroupSummary>
                  </>
                }
              </ReportColumn>
            );
          })}
        </ReportBody>

        <ReportFooter>
          {t("dashboard.financial_grade.total_grade", {
            current_grade: currentGrade,
            prev_grade: prevGrade,
          })}
        </ReportFooter>
      </ContainerInner>
    </Container>
  );
};
