import { FC } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import solidGauge from "highcharts/modules/solid-gauge";
import more from "highcharts/highcharts-more";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { ILocalState } from "../../@types";
import { useSelector } from "react-redux";

more(Highcharts);
solidGauge(Highcharts);
/*
 **************************************************
 * Custom Styling of Highcharts using css is possible.
 * If it's decided to do so. Useful example : 💣
 * https://codesandbox.io/s/highcharts-react-demo-vosjm?file=/demo.jsx
 **************************************************
 */
export interface IFinancialGradeChartProps {
  data: IFinancialGradeData[];
}

export interface IFinancialGradeData {
  month: string;
  label: string;
  grade: number;
}
export const FinancialGradeChart: FC<IFinancialGradeChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const brandedColors = useSelector(
    ({
      themeOptions: {
        branded: { colors },
      },
    }: ILocalState) => colors,
    isEqual
  );
  const colors = {
    actual: brandedColors.secondary,
    benchmark: brandedColors.primary,
  };

  const options: Highcharts.Options = {
    lang: {
      decimalPoint: t("misc.decimalPoint"),
    },
    chart: {
      zoomType: "x",
    },
    title: {
      text: t("dashboard.financial_grade.graph.chart_title"),
    },
    exporting: { enabled: false },
    xAxis: {
      type: "datetime",
      categories: data.map(({ label }) => label),
    },
    yAxis: {
      title: {
        text: t("dashboard.financial_grade.graph.yAxis_title"),
      },
      reversed: true,
    },
    legend: { enabled: false },
    plotOptions: {
      area: {
        fillColor: "transparent",
        marker: {
          radius: 4,
          color: colors.actual,
        },
        lineWidth: 3,
        lineColor: colors.actual,
        states: {
          hover: {
            lineWidth: 3,
            color: colors.actual,
          },
        },
        threshold: null,
      },
    },
    tooltip: {
      pointFormat: `${t("dashboard.financial_grade.graph.yAxis_title")} <b>{point.y:.1f}</b>`,
    },
    series: [
      {
        type: "area",
        color: colors.benchmark,
        data: data.map(({ grade }) => grade),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />;
};
