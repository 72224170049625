import { FC } from "react";
import { StyledPageHeadline } from "./styled.page-headline";

/**
 * Placeholder for any global wan messages. ** Not any implemented yet **
 */
export const PageHeadline: FC = () => {
  const hasWarnings = false;
  return hasWarnings ? <StyledPageHeadline> </StyledPageHeadline> : null;
};
