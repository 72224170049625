import styled, { css } from "styled-components/macro";
import { ISelectItemProps, IStyledListWrapperProps } from "./SelectItem";
import { IInputConstructorProps, IInputVariantEnums } from "../../../@types/index.d";
import { /* rgba */ darken } from "polished";

export const StyledSelectWrapper = styled.div(() => {
  return css`
    width: 100%;
    position: relative;
  `;
});

export const StyledSelectListItem = styled.li<Partial<ISelectItemProps>>(
  ({ theme, selected, disabled }) => {
    const selectedCss = css`
      background-color: ${theme.values.colors.global.greys.fourth};
    `;
    const disabledCss = css`
      color: ${theme.values.colors.global.greys.fifth};
    `;
    return css`
      padding: ${theme.rem(0.4)} ${theme.rem(0.8)};
      list-style: none;
      cursor: pointer;
      white-space: nowrap;
      ${selected && selectedCss}
      ${disabled && disabledCss}
      &:hover {
        background-color: ${theme.values.colors.global.greys.third};
      }
    `;
  }
);
export const StyledListWrapper = styled.ul<Pick<IInputConstructorProps, "variant">>(
  ({ theme, variant = IInputVariantEnums.SECONDARY }) => {
    const { borderColor } = theme.branded.inputs[variant];

    return css`
      position: absolute;
      left: 0;
      top: calc(100% + 2px);
      margin: 0;
      padding: 0;
      border: 1px solid ${borderColor};
      max-height: 25vh;
      min-width: ${theme.rem(20)};
      max-width: min(100vw, ${theme.rem(40)});
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: ${theme.values.colors.global.greys.first};
      z-index: 1000;
      transition: all 300ms;
      box-shadow: 2px 2px 6px 0 ${darken(0.25, borderColor)};
      user-select: none;
    `;
  }
);
export const StyledBrandedListWrapper = styled.ul<IStyledListWrapperProps>(
  ({ theme, variant = IInputVariantEnums.SECONDARY, position }) => {
    // const borderColor = rgba(theme.branded.inputs[variant].borderColor);
    return css`
      position: absolute;
      margin: 0;
      padding: 0;
      left: 0;
      top: calc(100% + 2px);
      border: 1px solid ${theme.branded.inputs[variant].borderColor};
      width: 100%;
      max-width: 100%;
      max-height: 25vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: ${theme.values.colors.global.greys.first};
      z-index: 1000;
    `;
  }
);
