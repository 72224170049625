import { FC, Suspense } from "react";
import { CommonLayout } from "../../../_lib/Layout";
import { lazy } from "@loadable/component";
import { LoaderTypeEnums, LoadingIndicator } from "../../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { useSelectedClientData } from "../../../_lib/hooks";
import { DashboardOptions } from "./DashboardOptions";
import { PageContent } from "../../../containers/PageContent";

const DashboardContent = lazy(() => import("./DashboardContent"));
const Dashboard: FC = () => {
  const { t } = useTranslation();
  const selectedClientData = useSelectedClientData();
  const subtitle =
    selectedClientData === false ? (
      <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
    ) : (
      selectedClientData.name
    );
  return (
    <CommonLayout>
      <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
        <PageContent
          title={t(`dashboard.options.headline`)}
          subtitle={subtitle}
          options={<DashboardOptions />}
        >
          <DashboardContent />
        </PageContent>
      </Suspense>
    </CommonLayout>
  );
};
export default Dashboard;
