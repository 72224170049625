import styled, { css, DefaultTheme, SimpleInterpolation } from "styled-components/macro";
import { DetailedHTMLProps, TdHTMLAttributes } from "react";

export interface ITRowProps {
  disabled?: boolean;
  selected?: boolean | undefined;
}
export interface ITCellProps
  extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {
  theme: DefaultTheme;
  disabled?: boolean;
  selected?: boolean | undefined;
}

type TUseTCellStyle = (props: ITCellProps) => SimpleInterpolation;
const useTCellStyle: TUseTCellStyle = ({ theme, selected = false, align }) => {
  const { _100 } = theme.values.colors.global.primary;

  const selectedCss = css`
    ::before {
      content: "";
      position: absolute;
      display: block;
      width: ${theme.rem(2 / 3)};
      top: 50%;
      left: 0;
      height: ${theme.rem(2 / 3)};
      background-color: ${_100};
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: 0 0;
    }
  `;
  return css`
    padding: ${theme.rem(0.5)};
    text-align: ${align};
    position: relative;
    overflow: hidden;
    ${selected && selectedCss}
  `;
};
export const Table = styled.table(({ theme }) => {
  return css`
    border-collapse: collapse;
  `;
});

export const TRow = styled.tr<ITRowProps>(({ theme, disabled = false, selected }) => {
  const { second: bg, third: selectedBg } = theme.values.colors.global.greys;
  const selectedCss = css`
    background-color: ${selectedBg};
  `;
  const disabledCss = css`
    opacity: 0.5;
  `;
  const selectable = selected !== undefined;

  return css`
    position: relative;

    overflow: hidden;
    cursor: ${selectable ? "pointer" : "unset"} ${disabled && disabledCss};
    ${selected && selectedCss} > td {
      border-bottom: 1px solid ${theme.values.component.borderColors.basic};
    }
    > td:first-child {
      border-left: 1px solid ${theme.values.component.borderColors.basic};
    }
    > td:last-child {
      border-right: 1px solid ${theme.values.component.borderColors.basic};
    }
    :hover {
      background-color: ${disabled ? "transparent" : bg};
    }
  `;
});

export const TCell = styled.td<ITCellProps>((props) => useTCellStyle(props));
export const THCell = styled.th<ITCellProps>((props) => {
  const tCellStyle = useTCellStyle(props);
  return css`
    ${tCellStyle};
    font-weight: ${props.theme.values.fontWeight.bold};
  `;
});

export const THead = styled(TRow)(({ theme }) => {
  const bg = theme.values.colors.global.secondary._100;
  const color = theme.values.colors.global.greys.first;
  return css`
    background-color: ${bg};
    color: ${color};
    :hover {
      background-color: ${bg};
    }
  `;
});
