import { ChangeEvent, Dispatch, DragEvent, useContext } from "react";
import { PNG } from "save-image-as";
import { EExportDataType } from "save-image-as/dist/saveImage";
import { FormDispatch, IFormState } from "../../components/formElements";
import { FileUploadFormat, IFileUploaderProps } from "../../@types/index.d";

type TUseFileUploaderOptions = Pick<IFileUploaderProps, "name" | "onChange" | "convertTo">;
interface TUseFileUploaderRetVal {
  handleFileDrop: (e: DragEvent<HTMLDivElement>) => void;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
  getFileContent: (inputName: string, context: IFormState) => FileData;
  resetFileContent: (inputName: string, dispatch: Dispatch<Record<string, string>>) => void;
}

type TUseFileUploader = (options: TUseFileUploaderOptions) => TUseFileUploaderRetVal;

export interface FileData {
  name: string;
  content: string;
  lastModified: string;
  type: string;
  size: number;
  format?: FileUploadFormat;
}

/**
 * Converts flat file info into Object.
 * @param inputName name of the file inpt field
 * @param context FormContext retrieved using useContextHook
 */
export const getFileContent = (inputName: string, context: IFormState): FileData => {
  return {
    name: context[`${inputName}__name`] || "",
    lastModified: context[`${inputName}__lastModified`] || "",
    content: context[`${inputName}__content`] || "",
    type: context[`${inputName}__type`] || "",
    size: parseInt(context[`${inputName}__size`]),
  };
};

export const resetFileContent = (
  inputName: string,
  dispatch: Dispatch<Record<string, string>>
): void => {
  dispatch({
    [`${inputName}__name`]: "",
    [`${inputName}__lastModified`]: "",
    [`${inputName}__content`]: "",
    [`${inputName}__type`]: "",
    [`${inputName}__size`]: "",
  });
};

export const useFileUploader: TUseFileUploader = ({ name, onChange, convertTo }) => {
  const formDispatch = useContext(FormDispatch);

  /**
   * convert given file(first file in FileList array, as currently only one file selection supported) to flat
   * file info to save it in FormContext. Use ``getFileContent`` function to get it as object in your components
   * @param files
   */
  const dispatchFlatFormData = (files?: FileList | null): void => {
    if (files && files.length > 0) {
      formDispatch({ [`${name}__lastModified`]: files[0].lastModified.toString() });
      formDispatch({ [`${name}__name`]: files[0].name });
      formDispatch({ [`${name}__type`]: files[0].type });
      formDispatch({ [`${name}__size`]: files[0].size.toString() });
    } else {
      formDispatch({ [`${name}__lastModified`]: "" });
      formDispatch({ [`${name}__name`]: "" });
      formDispatch({ [`${name}__type`]: "" });
      formDispatch({ [`${name}__size`]: "" });
      formDispatch({ [`${name}__content`]: "" });
    }
  };

  const prepareFileToUpload = (files?: FileList | null): void => {
    dispatchFlatFormData(files);

    const reader = new FileReader();
    reader.onload = (theFileData): void => {
      const fileData = theFileData.target?.result || "";

      formDispatch({ [`${name}__content`]: fileData.toString() });
    };
    if (files) {
      if (convertTo) {
        PNG(convertTo.maxWidth, convertTo.quality, EExportDataType.DATA_URL)
          .convert(files[0])
          .then((data) => {
            formDispatch({
              [`${name}__content`]: data?.toString() || "",
              [`${name}__type`]: "image/png",
            });
          });
      }
    }
    files && files.length > 0 && reader.readAsDataURL(files[0]);
  };

  const handleFileDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    prepareFileToUpload(e.dataTransfer.files);
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    prepareFileToUpload(e.target.files);
    onChange && onChange(e);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  return {
    handleFileChange,
    handleFileDrop,
    handleDragOver,
    getFileContent,
    resetFileContent,
  };
};
