import { TFunction } from "i18next";
import { IContextData, IFilterableData, IRawData } from "../../components/CategoryFilter";

export class FilterCategories {
  t: TFunction;
  qRegx: RegExp;
  branches: IContextData;

  constructor(branches: IContextData, t: TFunction, queryString: string) {
    this.branches = branches;
    this.t = t;
    this.qRegx = new RegExp(queryString, "i");
  }

  private filteredCategories(category: IRawData): IFilterableData {
    if (!category) throw new Error("Set category first");
    const industries =
      category?.tree?.map((industry: IRawData) => this.filteredIndustries(industry)) || [];
    return {
      value: category.value,
      label: this.t(`datastore:client.categories.${category.value}`),
      visible: Array.isArray(industries)
        ? industries?.filter((industry) => industry.visible).length > 0
        : false,
      selected: false,
      tree: industries,
    };
  }
  private filteredIndustries(industry: IRawData): IFilterableData {
    const subIndustries =
      industry?.tree?.map((subIndustry: IRawData) => this.filteredSubIndustries(subIndustry)) || [];
    const label = this.t(`datastore:client.industries.${industry.value}`);

    return {
      value: industry.value,
      label,
      visible:
        label.search(this.qRegx) >= 0 ||
        subIndustries.filter((subIndustry) => subIndustry.visible).length > 0,
      selected: industry.value === this.branches.selectedValue,
      tree: subIndustries,
    };
  }
  private filteredSubIndustries(subIndustry: IRawData): IFilterableData {
    const label = this.t(`datastore:client.sub_industries.${subIndustry.value}`);
    return {
      value: subIndustry.value,
      label,
      selected: subIndustry.value === this.branches.selectedValue,
      visible: label.search(this.qRegx) >= 0,
    };
  }
  public filter(): IFilterableData[] {
    return this.branches.data.map((category: IRawData) => this.filteredCategories(category));
  }
}
