import { useClientSelection } from "./useClientSelection";
import { useDispatch, useSelector } from "react-redux";
import {
  DataSyncStateActionTypes,
  EDataSyncLevel,
  EDataSyncScope,
  IDataSyncAction,
  ILocalState,
  IUserInfo,
  SelectClientState,
} from "../../@types/index.d";
import { gql, useApolloClient } from "@apollo/client";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction, UserStateActionTypes } from "../store/reducers";
import { IUploadedSuSa } from "./useUploadsList";
import { useSyncEvents } from "./syncEvents";
import { isEqual } from "lodash";

type clientSwitcher = {
  switchClient: (clientId: string | null, state?: SelectClientState) => void;
};
export const useSwitchClient = (): clientSwitcher => {
  const { clientSelection } = useClientSelection();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dataSync = useSelector(({ dataSync }: ILocalState) => dataSync, isEqual);
  const client = useApolloClient();

  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchDataSync = useDispatch<Dispatch<IDataSyncAction>>();
  const { stopSync } = useSyncEvents();

  const stopAndResetSyncStates = (level: EDataSyncLevel, scopes: EDataSyncScope[]): void => {
    scopes.forEach((scope): void => {
      if (dataSync[level][scope].timeStamp > 0) {
        dispatchDataSync({ type: DataSyncStateActionTypes.RESET, payload: { level, scope } });
        stopSync({ level, scope });
      }
    });
  };

  const switchClient = (clientId: string | null, _state?: SelectClientState): void => {
    if (clientId === null) {
      const state = _state || currentUser.selectedClient.state;
      clientSelection.removeClient();
      if (
        state === currentUser.selectedClient.state &&
        clientId === currentUser.selectedClient.client_id
      ) {
        return;
      }
      stopAndResetSyncStates(EDataSyncLevel.CLIENT, Object.values(EDataSyncScope));

      dispatch({
        type: UserStateActionTypes.SET_SELECTED_CLIENT,
        payload: {
          selectedClient: {
            client_id: clientId,
            state: state,
            kpis: [],
            evaluation: undefined,
            uploads: undefined,
          },
        } as Pick<IUserInfo, "selectedClient">,
      });
    } else {
      clientSelection.set(clientId);
      const stateNotChanged =
        clientId === clientSelection.get().client_id && _state === currentUser.selectedClient.state;
      if (stateNotChanged) {
        return;
      }

      stopAndResetSyncStates(EDataSyncLevel.CLIENT, Object.values(EDataSyncScope));
      if (_state === SelectClientState.DRAFT) {
        if (
          currentUser.selectedClient.client_id === clientId &&
          currentUser.selectedClient.state === _state
        ) {
          return;
        }

        dispatch({
          type: UserStateActionTypes.SET_SELECTED_CLIENT,
          payload: {
            selectedClient: {
              state: _state,
              client_id: clientId,
            },
          },
        });

        return;
      }
      if (currentUser.selectedClient.state !== SelectClientState.PENDING) {
        dispatch({
          type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING,
          payload: { selectedClient: { client_id: clientId } },
        });
      }
      client
        .query({
          query: gql`
            query getSuSas($client_id: ID!) {
              getActualSuSas(client_id: $client_id) {
                client_id
                susa_id
              }
              getAllSuSasOfClient(client_id: $client_id, withPlans: true) {
                date
                format
                plans_count
                uploaded_at
                susa_id
              }
            }
          `,
          variables: {
            client_id: clientId,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          const hasActuals = data?.getActualSuSas?.length > 0;

          const state = hasActuals ? SelectClientState.READY : SelectClientState.DRAFT;
          const uploads: IUploadedSuSa[] = data?.getAllSuSasOfClient;
          if (
            currentUser.selectedClient.state === SelectClientState.PENDING ||
            (state === currentUser.selectedClient.state &&
              clientId === currentUser.selectedClient.client_id)
          )
            return;
          const kpis =
            currentUser.clients.find((client) => client.client_id === clientId)?.customization
              ?.dashboard_kpis || [];
          dispatch({
            type: UserStateActionTypes.SET_SELECTED_CLIENT,
            payload: {
              selectedClient: {
                client_id: clientId,
                state,
                kpis,
                uploads: { list: uploads },
              },
            } as Pick<IUserInfo, "selectedClient">,
          });
          clientSelection.set(clientId);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };
  return { switchClient };
};
