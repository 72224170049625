import styled, { css } from "styled-components/macro";
import { useResponsive } from "../../../_lib/hooks/useResponsive";

const StyledContentOptionsContainer = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        padding: ${theme.rem(1)};
        background-color: ${theme.values.component.content.backgroundColor};
        border-top: 1px solid ${theme.values.component.borderColors.basic};
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${theme.rem(1)};
      `,
    },
    LARGE: {
      style: css`
        flex-wrap: nowrap;
        margin-bottom: 0;
      `,
    },
  });
});

const StyledContentOptionsInfo = styled.div(({ theme }) => {
  return css`
    flex-grow: 0;
    padding-right: ${theme.rem(0.5)};
  `;
});
const StyledContentOptionsTitle = styled.h3(({ theme }) => {
  return css`
    color: ${theme.branded.colors.primary};
    font-weight: ${theme.values.component.title.fontWeight};
    font-size: ${theme.rem(1.5)};
    margin: 0;
    line-height: 1.25;
  `;
});
const StyledContentOptionsSubTitle = styled.h4(({ theme }) => {
  return css`
    color: ${theme.branded.colors.secondary};
    font-weight: ${theme.values.component.subTitle.fontWeight};
    font-size: ${theme.rem(1.125)};
    margin: 0;
  `;
});
const StyledContentOptionsContent = styled.div(({ theme }) => {
  return css`
    flex-grow: 1;
    padding-left: ${theme.rem(0.5)};
  `;
});

export const StyledContentOptions = {
  Container: StyledContentOptionsContainer,
  Header: StyledContentOptionsInfo,
  Title: StyledContentOptionsTitle,
  SubTitle: StyledContentOptionsSubTitle,
  Content: StyledContentOptionsContent,
};
