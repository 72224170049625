import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

interface IImageWrapperProps {
  imageUrl: string;
}

interface ISectionProps {
  width?: number;
  height?: number;
  flex?: EFlexDirection;
  shadow?: boolean;
  margin?: number;
  aspect?: boolean;
}

interface IPriceNumberProps {
  star?: boolean;
}

export enum EFlexDirection {
  ROW = "row",
  COLUMN = "column",
}

interface IFormEntryProps {
  button?: boolean;
  sepa?: boolean;
}

interface IFormRowProps {
  columns?: number;
  height?: number;
}

const Price = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        display: flex;
        flex-direction: column;
        gap: ${theme.rem(1 / 5)};
        align-content: center;
        align-items: flex-start;
        & > span {
          padding: 0;
          margin: 0;
        }
        & > h2 {
          padding: 0;
          margin: 0;
        }
      `,
    },
    LARGE: {
      style: css`
        flex-direction: row;
      `,
    },
  });
});

const Wrapper = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        display: grid;
        width: 100%;
        height: 90vh;
        grid-template-areas: "sidebar content";
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 4fr;
        margin: ${theme.rem(0)} ${theme.rem(0)} ${theme.rem(0.5)} ${theme.rem(0)};
        box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
          ${theme.values.colors.global.greys._025};
        border-radius: ${theme.rem(2)};
        font-size: ${theme.rem(0.8)};
        & button {
          padding: ${theme.rem(0.5)} ${theme.rem(3)};

          & span {
            font-size: ${theme.rem(1)};
          }
        }
      `,
    },
    LARGE: {
      style: css`
        height: 95%;
        margin: ${theme.rem(1)} ${theme.rem(1)} ${theme.rem(1.5)} ${theme.rem(1)};
        font-size: ${theme.rem(1)};
        max-width: ${theme.rem(80)};
        min-width: ${theme.rem(50)};
      `,
    },
  });
});

const ContentWrapper = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        grid-area: content;
        border-radius: ${theme.rem(0)} ${theme.rem(2)} ${theme.rem(2)} ${theme.rem(0)};
        background-color: ${theme.values.colors.global.greys._000};
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: ${theme.rem(3)};
        padding-top: ${theme.rem(1)};
      `,
    },
    LARGE: {
      style: css`
        height: ${theme.rem(40)};
      `,
    },
    XLARGE: {
      style: css`
        height: ${theme.rem(55)};
      `,
    },
  });
});

const ContentTitleWrapper = styled.div(({ theme }) => {
  return css`
    margin: ${theme.rem(0)} auto;
    display: flex;
    gap: ${theme.rem(1)};
    justify-content: center;
    align-items: center;
    & svg {
      fill: black;
    }
  `;
});

const MainTitle = styled.h1(({ theme }) => {
  return css`
    text-align: center;
  `;
});

const ContentTitleIcon = styled.div<IImageWrapperProps>(({ theme, imageUrl }) => {
  return css`
    background: url(/assets/${imageUrl}) no-repeat center center;
    background-size: 70%;
    width: ${theme.rem(3)};
    height: ${theme.rem(3)};
  `;
});

const ContentImage = styled.div<IImageWrapperProps>(({ theme, imageUrl }) => {
  return css`
    width: 100%;
    background: url(/assets/${imageUrl}) no-repeat center center;
    background-size: cover;
    background-position: 0 0;
    aspect-ratio: 14 / 9;
  `;
});

const SectionWrapper = styled.div<ISectionProps>(
  ({ theme, width, height, flex, shadow, margin, aspect }) => {
    return css`
      ${shadow &&
      `box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
    ${theme.values.colors.global.greys._025};border-radius: ${theme.rem(2)};`}
      ${width && `width: ${width}%;`}
    ${flex &&
      `display: flex; flex-direction: ${flex}; align-items: center; justify-content: space-between;`}
    ${height && `height: ${theme.rem(height)};`}
    ${margin && `margin-bottom: ${theme.rem(margin)};`}
    ${aspect && `aspect-ratio: 16 / 9;`}
    `;
  }
);

const BtnWrapper = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        position: relative;
        & button {
          font-size: ${theme.rem(1)};
        }
      `,
    },
    LARGE: {
      style: css`
        & button {
          font-size: ${theme.rem(1.2)};
        }
      `,
    },
  });
  return css`
    position: relative;
    & button {
      font-size: ${theme.rem(1)};
    }
  `;
});

const BtnText = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        bottom: ${theme.rem(-1)};
        color: ${theme.values.colors.global.greys._040};
        font-size: ${theme.rem(0.6)};
        font-weight: normal;
        left: 30%;
        position: absolute;
        transform: translateX(-10%);
      `,
    },
    LARGE: {
      style: css`
        bottom: ${theme.rem(-1.5)};
        font-size: ${theme.rem(0.9)};
        transform: translateX(-20%);
        width: 100%;
      `,
    },
  });
});

const SubTitle = styled.h3(({ theme }) => {
  return css``;
});

const SectionText = styled.p(({ theme }) => {
  return css`
    text-align: justify;
  `;
});

const SectionTextWrapper = styled.div(({ theme }) => {
  return css`
    text-align: justify;
  `;
});

const SectionList = styled.ul(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        margin: 0;
        margin-bottom: ${theme.rem(4)};
        padding-left: ${theme.rem(1)};
      `,
    },
    XLARGE: {
      style: css`
        margin: 0;
        padding-left: ${theme.rem(1)};
        height: ${theme.rem(20)};
      `,
    },
  });
});

const SectionListItem = styled.li(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        padding: ${theme.rem(0)};
      `,
    },
    XLARGE: {
      style: css`
        padding: ${theme.rem(0.4)};
      `,
    },
  });
});

const ContentLinkWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;
});

const ContentLink = styled.p(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        cursor: pointer;
        margin: ${theme.rem(0.5)};
        &:hover {
          color: ${theme.values.colors.global.primary._085};
        }
      `,
    },
    LARGE: {
      style: css`
        cursor: pointer;
        margin: ${theme.rem(0.2)};

        &:hover {
          color: ${theme.values.colors.global.primary._085};
        }
      `,
    },
  });
});

const ContentFooter = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        width: 100%;
        height: ${theme.rem(3)};

        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: flex-start;
        margin-bottom: ${theme.rem(1)};
        & > button {
          font-size: ${theme.rem(1)};
        }
      `,
    },
    LARGE: {
      style: css`
        & button {
          font-size: ${theme.rem(1.2)};
        }
      `,
    },
  });
});

const PriceWrapper = styled.div(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: ${theme.rem(5)};
      `,
    },
    LARGE: {
      style: css`
        width: 100%;
        justify-content: center;
      `,
    },
  });
});

const PriceNumber = styled.h2<IPriceNumberProps>(({ theme, star = false }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        font-size: ${theme.rem(1)};
        ${star &&
        `
        &:after {
          content: "*";
          vertical-align: super;
          color: ${theme.values.colors.global.primary._085};
        }`}
      `,
    },
    LARGE: {
      style: css`
        font-size: ${theme.rem(1.5)};
      `,
    },
  });
});

const PriceText = styled.span(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        font-size: ${theme.rem(0.6)};
        color: ${theme.values.colors.global.greys._040};
        font-weight: normal;
        padding: ${theme.rem(0.5)};
      `,
    },
    LARGE: {
      style: css`
        font-size: ${theme.rem(0.8)};
      `,
    },
  });
});

const TrialText = styled.span(({ theme }) => {
  return useResponsive({
    MEDIUM: {
      style: css`
        position: absolute;
        bottom: ${theme.rem(2)};
        margin-left: ${theme.rem(-2)};
        font-size: ${theme.rem(0.6)};
        &:before {
          font-size: ${theme.rem(1.1)};
          content: "* ";
          color: ${theme.values.colors.global.primary._085};
        }
      `,
    },
    LARGE: {
      style: css`
        font-size: ${theme.rem(0.9)};
        bottom: ${theme.rem(3)};
      `,
    },
  });
});

const TrialTextLink = styled.span(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.primary._085};
    cursor: pointer;
  `;
});

// ------------- PAYMENT PAGE -------------

const PaymentWrapper = styled.div(() => {
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `;
});

const Row = styled.div<IFormRowProps>(({ theme, columns, height }) => {
  return css`
    display: grid;
    gap: ${theme.rem(1)};
    grid-template-columns: repeat(${columns || 1}, 1fr);
    margin-bottom: ${theme.rem(1)};
    ${height && `height: ${theme.rem(height)};`}
  `;
});

const Entry = styled.div<IFormEntryProps>(({ theme, button, sepa }) => {
  return css`
    display: flex;
    ${button && `justify-content: flex-end;margin-top:  ${theme.rem(2)};`}
    line-height: 1.5;
    ${sepa && `font-size: ${theme.rem(0.75)};`}
  `;
});

const CheckoutCard = styled.div(({ theme }) => {
  return css`
    border-radius: ${theme.rem(0)} ${theme.rem(2)} ${theme.rem(2)} ${theme.rem(0)};
    background-color: ${theme.values.colors.global.greys._000};
    display: flex;
    flex-direction: column;
    height: ${theme.rem(35)};
    width: ${theme.rem(30)};
    justify-content: space-between;
    padding: ${theme.rem(0)} ${theme.rem(2)} ${theme.rem(2)} ${theme.rem(2)};
    & button {
      margin-top: ${theme.rem(0.5)};
    }
  `;
});

const DeleteAccountBtn = styled.div(({ theme }) => {
  return css`
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    display: inline;
    margin: ${theme.rem(0.4)};
    text-align: center;
    cursor: "pointer";
    padding: ${theme.rem(0.55)} ${theme.rem(3.5)};
    border-radius: ${theme.rem(2 / 3)};
    transition: all 300ms;
    border: ${theme.branded.buttons.primary.color.base.background} 2px solid;
    &:hover {
      background: ${theme.branded.buttons.primary.color.base.background};
      color: ${theme.branded.buttons.primary.color.base.color};
    }
  `;
});

interface IQInvoicesWrapperProps {
  margin_bottom?: boolean;
}
const StyledDownloadInvoices = styled.div<IQInvoicesWrapperProps>(({ theme, margin_bottom }) => {
  return css`
    width: max(60vh, ${theme.rem(22)});
    margin-bottom: ${margin_bottom ? theme.rem(2) : 0};
  `;
});

export const StyledAppStore = {
  Wrapper,
  ContentWrapper,
  ContentTitleWrapper,
  MainTitle,
  ContentTitleIcon,
  SectionWrapper,
  SubTitle,
  SectionText,
  SectionTextWrapper,
  SectionList,
  SectionListItem,
  ContentImage,
  ContentLinkWrapper,
  ContentLink,
  ContentFooter,
  PriceWrapper,
  Price,
  PriceText,
  PriceNumber,
  TrialText,
  TrialTextLink,
  Row,
  PaymentWrapper,
  Entry,
  CheckoutCard,
  DeleteAccountBtn,
  BtnWrapper,
  BtnText,
  StyledDownloadInvoices,
};
