import { Dispatch, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EAssetType, IAsset, ILocalState, ISelectValue } from "../../../../@types/index.d";
import { cloneDeep, isEqual, omit, values } from "lodash";
import { ECustomizationListType } from "../useCustomizationHelper";
import { FormContext, FormDispatch, IFormState } from "../../../../components/formElements";
import { CustomizationActionTypes, ICustomizationAction } from "../../../../_lib/store/reducers";
import initialData from "../../../../_lib/store/initialState";

interface IUseAssetsHelperRetVal {
  assetsList: IAsset[];
  accountAsset: IAsset | undefined;
  assetFormContext: IFormState;
  resetAssetForm: () => void;
  changeSelectedAsset: (e: ISelectValue<string>) => void;
}

export const useAssetsHelper = (): IUseAssetsHelperRetVal => {
  const selectedAccount = useSelector(
    ({ customization: { selectedAccount } }: ILocalState) => selectedAccount,
    isEqual
  );
  const selectedAsset = useSelector(
    ({ customization: { selectedAsset } }: ILocalState) => selectedAsset,
    isEqual
  );
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const customizationList = useSelector(
    ({ customization: { customizationList } }: ILocalState) => customizationList,
    isEqual
  );
  const dispatchCustomization = useDispatch<Dispatch<ICustomizationAction>>();
  const assetFormContext = useContext<IFormState>(FormContext);
  const assetFormDispatch = useContext(FormDispatch);

  // get a list for assets select input
  const assetsList = useMemo((): IAsset[] => {
    if (!selectedAccount) return [];
    let assetsListCopy = cloneDeep(themeOptions.branded.assets);
    if (selectedAccount.type === ECustomizationListType.CLIENT)
      assetsListCopy = omit(assetsListCopy, [
        EAssetType.LOGIN_BACKGROUND_IMAGE,
        EAssetType.LOGIN_LOGO,
      ]);
    const assetsList = values(assetsListCopy).map((item) => {
      if (!item.href) {
        switch (item.type) {
          case EAssetType.APP_LOGO:
            return themeOptions.branded.assets.app_logo;
          case EAssetType.LOGIN_LOGO:
            return themeOptions.branded.assets.login_logo;
          case EAssetType.APP_BACKGROUND_IMAGE:
            return themeOptions.branded.assets.app_background_image;
          case EAssetType.LOGIN_BACKGROUND_IMAGE:
            return themeOptions.branded.assets.login_background_image;

          default:
            break;
        }
      }
      return item;
    });

    return assetsList;
  }, [selectedAccount, themeOptions.branded.assets]);

  // get selected asset from customizationList redux or undefined
  const accountAsset = useMemo(() => {
    if (!selectedAccount || assetFormContext[selectedAsset]) return;
    const selectedCustomization = customizationList.find(
      (c) => c.account_id === selectedAccount.accountId
    );
    // if there is no customization return default
    if (
      !selectedCustomization ||
      !selectedCustomization.assets ||
      !selectedCustomization.assets[selectedAsset]
    ) {
      return initialData.themeOptions.branded.assets[selectedAsset];
    }
    // return found account asset
    return selectedCustomization.assets[selectedAsset];
  }, [assetFormContext, customizationList, selectedAccount, selectedAsset]);

  // reset asset form
  const resetAssetForm = (): void => {
    const formCopy = cloneDeep(assetFormContext);
    formCopy[selectedAsset] = "";
    formCopy[`${selectedAsset}__name`] = "";
    formCopy[`${selectedAsset}__type`] = "";
    formCopy[`${selectedAsset}__size`] = "";
    formCopy[`${selectedAsset}__content`] = "";
    formCopy[`${selectedAsset}__lastModified`] = "";
    assetFormDispatch(formCopy);
  };

  // change selected asset in redux
  const changeSelectedAsset = (e: ISelectValue<string>): void => {
    if (e.value === selectedAsset) return;

    dispatchCustomization({
      type: CustomizationActionTypes.SET_SELECTED_ASSET,
      payload: {
        selectedAsset: e.value as EAssetType,
      },
    });
  };

  return {
    assetsList,
    accountAsset,
    assetFormContext,
    resetAssetForm,
    changeSelectedAsset,
  };
};
