import { FC, useEffect } from "react";
import { useDialogContext, useDialogs } from "../../../../_lib/hooks";
import { IDialogKeyEnums, IDialogOptions } from "../../../../@types/index.d";
import { StyledUploadValidationSummary } from "./styled.upload-validation-summary";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../../components/Icon";
import { P } from "../../../../components/Typography";
import { gql, useLazyQuery } from "@apollo/client";
import { LoaderTypeEnums, LoadingIndicator } from "../../../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariantEnums } from "../../../../components/formElements";
import { formatPercentage } from "../../../../_lib/common";

const GET_UPLOAD_VALIDATION_SUMMARY = gql`
  query getValidation($susa_id: ID!) {
    result: getUploadValidationSummary(susa_id: $susa_id) {
      susa_id
      quickCheck {
        profitAndLoss
        activePassive
        cashFlow
      }
      keyFacts {
        name
        absolute
        percent
      }
    }
  }
`;

interface IUploadValidationQueryResult {
  result: Omit<IUploadValidationContext, "date" | "loading">;
}

interface IUploadValidationQueryVars {
  susa_id: string;
}
export interface IUploadValidationContext {
  susa_id: string;
  date: string;
  loading: boolean;
  quickCheck: IQuickCheck;
  keyFacts: IKeyFacts[];
}

interface IQuickCheck {
  profitAndLoss: EQuickCheckCode;
  activePassive: EQuickCheckCode;
  cashFlow: EQuickCheckCode;
}

interface IKeyFacts {
  name: string;
  absolute: number;
  percent: number;
}
export enum EQuickCheckCode {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INITIAL = "INITIAL",
}

export interface IQuickCheckItemProps extends Pick<IUploadValidationContext, "loading"> {
  caption: string;
  state: EQuickCheckCode;
}
const QuickCheckItem: FC<IQuickCheckItemProps> = ({ caption, loading, state }) => {
  return (
    <StyledUploadValidationSummary.QuickChek.Item>
      <StyledUploadValidationSummary.QuickChek.Caption>
        {caption}
      </StyledUploadValidationSummary.QuickChek.Caption>
      <StyledUploadValidationSummary.QuickChek.Icon state={state}>
        <Icon
          name={
            state === EQuickCheckCode.SUCCESS
              ? IconNameEnums.CHECK_CIRCLE
              : IconNameEnums.TIMES_CIRCLE
          }
          size={IconSizeEnums.LARGE}
          loading={loading}
        />
      </StyledUploadValidationSummary.QuickChek.Icon>
    </StyledUploadValidationSummary.QuickChek.Item>
  );
};
const Content: FC = () => {
  const { t } = useTranslation();
  const dialogContext = useDialogContext<IUploadValidationContext | undefined>(
    IDialogKeyEnums.SIMPLE,
    undefined
  );
  const { setData } = useDialogs();
  const [getValidationResult, { data, loading }] = useLazyQuery<
    IUploadValidationQueryResult,
    IUploadValidationQueryVars
  >(GET_UPLOAD_VALIDATION_SUMMARY);
  useEffect(() => {
    if (dialogContext?.susa_id) {
      getValidationResult({
        variables: { susa_id: dialogContext.susa_id },
      });
    }
  }, [dialogContext?.susa_id, getValidationResult]);

  useEffect(() => {
    if (
      data?.result.susa_id &&
      data?.result.susa_id === dialogContext?.susa_id &&
      dialogContext?.loading === true
    ) {
      setData(IDialogKeyEnums.SIMPLE, { data: { ...data.result, loading: false } });
    }
  }, [data?.result, dialogContext?.susa_id, dialogContext?.loading, setData]);

  return (
    <>
      <P>{t("upload.summary.quick_check.title")}</P>
      <StyledUploadValidationSummary.QuickChek.Container>
        {dialogContext && (
          <>
            <QuickCheckItem
              caption={t("upload.summary.quick_check.profit_loss")}
              loading={loading}
              state={dialogContext?.quickCheck.profitAndLoss}
            />
            <QuickCheckItem
              caption={t("upload.summary.quick_check.active_passive")}
              loading={loading}
              state={dialogContext?.quickCheck.activePassive}
            />
            <QuickCheckItem
              caption={t("upload.summary.quick_check.cash_flow")}
              loading={loading}
              state={dialogContext?.quickCheck.cashFlow}
            />
          </>
        )}
      </StyledUploadValidationSummary.QuickChek.Container>
      <P>{t("upload.summary.key_facts.title")}</P>
      <StyledUploadValidationSummary.KeyFacts.Container>
        {dialogContext?.keyFacts.map(({ name, absolute, percent }, index) => {
          return (
            <StyledUploadValidationSummary.KeyFacts.Item key={index}>
              <StyledUploadValidationSummary.KeyFacts.ItemName>
                {loading ? (
                  <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={10} />
                ) : (
                  <>{t(`upload.summary.key_facts.items.${name}`)}</>
                )}
              </StyledUploadValidationSummary.KeyFacts.ItemName>
              <StyledUploadValidationSummary.KeyFacts.ItemAbs>
                {loading ? (
                  <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={3} />
                ) : (
                  absolute
                )}
              </StyledUploadValidationSummary.KeyFacts.ItemAbs>
              <StyledUploadValidationSummary.KeyFacts.ItemPercent>
                {loading ? (
                  <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={3} />
                ) : (
                  t(`misc.percent`, { value: formatPercentage(percent) })
                )}
              </StyledUploadValidationSummary.KeyFacts.ItemPercent>
            </StyledUploadValidationSummary.KeyFacts.Item>
          );
        })}
      </StyledUploadValidationSummary.KeyFacts.Container>
    </>
  );
};
const Footer: FC = () => {
  const { t } = useTranslation();
  const { close } = useDialogs();
  const { loading } = useDialogContext<Partial<IUploadValidationContext>>(IDialogKeyEnums.SIMPLE, {
    loading: false,
  });
  const handleCloseDialog = (): void => {
    close(IDialogKeyEnums.SIMPLE);
  };
  return (
    <StyledUploadValidationSummary.DialogFooter>
      <Button
        type={"button"}
        disabled={loading}
        inverted={false}
        variant={ButtonVariantEnums.PRIMARY}
        onClick={handleCloseDialog}
        inline={true}
      >
        {t("misc.close")}
      </Button>
    </StyledUploadValidationSummary.DialogFooter>
  );
};

export const uploadValidationSummaryDialog: IDialogOptions = {
  dialogKey: IDialogKeyEnums.SIMPLE,
  closable: true,
  content: <Content />,
  footer: <Footer />,
};
