import { IKpiAssetLinks, useDialogs, useKpiMatrix, useKpiRecommendations } from "..";
import {
  IDialogKeyEnums,
  ILocalState,
  IRecommendationsJSON,
  Relation,
} from "../../../@types/index.d";
import { FC, useEffect, useMemo } from "react";
import CarouselComp from "../../../containers/Carousel";
import { KpiMatrixListItem } from "../../../components/KpiMatrixList/KpiMatrixListItem";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { getAssetPath } from "../../common";
import { Video } from "../../../components/Video";
import {
  GraphAndInterpretations,
  GraphSubText,
  GraphSubTextitem,
  InterpretationItem,
  InterpretationsList,
  KpiDetailsWrapper,
  TextCaption,
} from "./styled.kpi-details";
import { v4 as uuidv4 } from "uuid";

const Content: FC<{ actionCode: string }> = ({ actionCode }) => {
  const { t } = useTranslation([
    "digital/common",
    "digital/interpretations",
    "digital/recommendations",
  ]);
  const selectedClient = useSelector(
    ({ currentUser: { selectedClient } }: ILocalState) => selectedClient,
    isEqual
  );
  const [getKpiHistory, { data, called }] = useKpiMatrix();
  const kpiList = data?.kpiMatrix?.kpis || [];

  const { getKpiListForDialogGraph } = useKpiRecommendations();
  const { kpiIds, interpretationsList } = getKpiListForDialogGraph(actionCode);

  const recommendationsJSON: Record<string, IRecommendationsJSON> = t(
    "digital/recommendations:action_details",
    {
      returnObjects: true,
    }
  );

  const actionVideo = useMemo<IKpiAssetLinks | null>(() => {
    if (!recommendationsJSON || !actionCode) return null;
    const actionLinks = recommendationsJSON[actionCode]?.links;

    if (!actionLinks) return null;

    const { href: video } = actionLinks.find(({ rel }) => rel === Relation.video) || {};
    const { href: poster } = actionLinks.find(({ rel }) => rel === Relation.poster) || {};

    const result = { video: getAssetPath({ file: video }), poster: getAssetPath({ file: poster }) };
    return result;
  }, [actionCode, recommendationsJSON]);

  useEffect(() => {
    if (!selectedClient?.client_id) return;
    if (called) return;
    getKpiHistory({
      variables: {
        kpiQuery: {
          client_id: selectedClient?.client_id,
          latest: true,
          filter: kpiIds,
        },
      },
    });
  }, [called, getKpiHistory, kpiIds, selectedClient?.client_id]);

  // to return the anchor elemets that takes place in the text
  const anchors = useMemo<JSX.Element[] | undefined>(() => {
    const links: string[] = recommendationsJSON[actionCode]?.dialog_links;
    if (links && links.length) {
      const anchorArr = [];
      for (const link of links) {
        const anchor = (
          <a key={uuidv4()} href={link} target="_blank" rel="noreferrer">
            links
          </a>
        );
        anchorArr.push(anchor);
      }

      return anchorArr;
    }
    return undefined;
  }, [actionCode, recommendationsJSON]);

  return (
    <KpiDetailsWrapper>
      {/* {loading && <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />} */}
      <TextCaption>{t("dialogs.kpi.details_dialog.caption")}</TextCaption>
      <GraphAndInterpretations>
        <InterpretationsList>
          {interpretationsList.map((item, ind) => (
            <InterpretationItem key={ind}>{item.summary}</InterpretationItem>
          ))}
        </InterpretationsList>
        <CarouselComp autoPlay={false} showArrows={true} showIndicators={true}>
          {kpiList.map((kpi, index) => (
            <KpiMatrixListItem key={`${index}/${kpi}`} kpi={kpi} forKpiDialog={true} />
          ))}
        </CarouselComp>
      </GraphAndInterpretations>
      <GraphSubText>
        <GraphSubTextitem>{t("dashboard.kpi_matrix.chart.actual_value")}</GraphSubTextitem>
        &nbsp;&nbsp;
        <GraphSubTextitem>{t("dashboard.kpi_matrix.chart.benchmark")}</GraphSubTextitem>
      </GraphSubText>
      {actionVideo?.video && (
        <Video source={{ src: actionVideo.video }} poster={actionVideo.poster} />
      )}
      <Trans i18nKey={`digital/recommendations:action_details.${actionCode}.dialog_text`}>
        {anchors && anchors.map((anchor) => anchor)}
      </Trans>
    </KpiDetailsWrapper>
  );
};

interface IUseKpiDetailsRetVal {
  openKpiDetailsDialog: (value: string) => void;
}

export type TUseKpiDetails = () => IUseKpiDetailsRetVal;

export const useKpiDetailsDialog: TUseKpiDetails = () => {
  const { t } = useTranslation(["digital/recommendations"]);
  const { open } = useDialogs();

  const recommendationsJSON: Record<string, IRecommendationsJSON> = t(
    "digital/recommendations:action_details",
    {
      returnObjects: true,
    }
  );

  const openKpiDetailsDialog = (value: string): void => {
    const title = recommendationsJSON[value].summary;

    open({
      title,
      closable: true,
      content: <Content actionCode={value} />,
      dialogKey: IDialogKeyEnums.SIMPLE,
      flexible: false,
    });
  };

  return {
    openKpiDetailsDialog,
  };
};
