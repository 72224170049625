import { FC, useCallback, useContext, useMemo } from "react";
import { Panel, PanelBody, PanelTitle } from "../../Panel";

import {
  AlertTypeEnums,
  AlignmentEnums,
  FileProps,
  FileUploadFormat,
  FileUploadVariant,
  ILocalState,
} from "../../../@types/index.d";
import { P } from "../../../components/Typography";

import {
  Button,
  ButtonVariantEnums,
  Form,
  FormRow,
  IFormState,
  Switch,
} from "../../../components/formElements";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StyledDatevPdfUpload } from "../styled.uploaders";
import {
  EUploadersActionTypes,
  IFileUploadState,
  UploadersContext,
  UploadersDispatch,
} from "../index";
import { useRelationLinks } from "../../../_lib/hooks/useRelationLinks";
import { useSuSaUpload } from "../../../_lib/hooks";
import { PlanPeriod } from "../PlanPeriod";
import { Alert } from "../../../components/Alert";
import { SinglePDFUpload } from "./SinglePDFUpload";
import { getFingerprint } from "../../../_lib/common/getFingerprint";
import { SlideIn, SlideInDirections } from "../../../_lib/Transitions";
import { useIsMultipleUploadInProgress } from "../UploadersSharedContext/sharedHooks";
import { MultiSuSasUpload } from "./MultiSuSasUpload";
import { isEqual } from "lodash";
import { EventListenerCodes } from "../../../_lib/hooks/useEventListener/types.event-listener";

export const datevPdfUploadTestID = "DatevPdfUpload";
export const DatevPdfUpload: FC = () => {
  const { t } = useTranslation(["errors"]);

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);

  const { isInitial } = useContext(UploadersContext);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const clientId = currentUser.selectedClient.client_id;
  const [upload, { loading: waitUpload, data }, errorState] = useSuSaUpload();
  const selectedFilesCountInMulti = Object.keys(uploadersContext.sharedState.files).length;
  const { getRelationLinks } = useRelationLinks();
  const multiUploadInProgress = useIsMultipleUploadInProgress();

  const uploadFileCallBack = useCallback(
    (file: FileProps, format: FileUploadFormat) => {
      if (clientId === null) return;

      const fileToBeUploaded: IFileUploadState = {
        ...uploadersContext.sharedState,
        client_id: clientId,
        file: {
          ...file,
          links: getRelationLinks(clientId),
        },
        settings: {
          ...uploadersContext.sharedState.settings,
          format,
          variant_type: FileUploadVariant.UNKNOWN,
        },
      };

      return upload(fileToBeUploaded);
    },
    [clientId, uploadersContext, getRelationLinks, upload]
  );

  const handleUpload = (uploadForm: IFormState): void => {
    if (clientId === null) {
      throw new Error("Client ID can not be null!");
    }

    if (uploadersContext.sharedState.multipleUpload) {
      if (selectedFilesCountInMulti === 0) {
        return;
      }
      Object.entries(uploadersContext.sharedState.files).forEach(([, value]) => {
        uploadFileCallBack(value, uploadForm.upload_format as FileUploadFormat);
      });
    } else {
      const fileSelected =
        uploadersContext.sharedState.file.size > 0 &&
        uploadersContext.sharedState.file.type === "application/pdf";
      const dateSelected = uploadersContext.sharedState.dateSelected === true;
      if (
        fileSelected !== uploadersContext.sharedState.fileSelected ||
        dateSelected !== uploadersContext.sharedState.dateSelected
      ) {
        uploadersDispatch({
          type: EUploadersActionTypes.SET_STATE,
          payload: {
            data: { dateSelected, fileSelected },
          },
        });
      }

      if (!dateSelected || !fileSelected) {
        return;
      }
      uploadFileCallBack(
        uploadersContext.sharedState.file,
        uploadForm.upload_format as FileUploadFormat
      );
    }
  };
  const singleUploadInProgress = useMemo(() => {
    if (!data?.result || !errorState) return false;
    if (uploadersContext.sharedState.multipleUpload) return false;
    const { covid } = getFingerprint(data?.result.covid);
    if (covid === undefined) return false;
    const currentErrorState = errorState[covid];
    return waitUpload || currentErrorState === EventListenerCodes.UNKNOWN;
  }, [data, errorState, waitUpload, uploadersContext.sharedState.multipleUpload]);

  const hasError = useMemo(() => {
    if (!data?.result || !errorState) return false;
    const currentErrorState = errorState[data?.result.covid];
    return (
      typeof currentErrorState === "string" &&
      currentErrorState !== EventListenerCodes.UNKNOWN &&
      currentErrorState !== EventListenerCodes.TRANSACTION_OK
    );
  }, [data?.result, errorState]);

  const canUpload = useMemo(() => {
    if (singleUploadInProgress) return false;
    if (multiUploadInProgress) return false;
    if (uploadersContext.sharedState.multipleUpload) {
      if (selectedFilesCountInMulti === 0) return false;
      if (currentUser.selectedClient.uploads?.loading) return false;
    }
    return true;
  }, [
    singleUploadInProgress,
    uploadersContext.sharedState.multipleUpload,
    selectedFilesCountInMulti,
    currentUser.selectedClient.uploads?.loading,
    multiUploadInProgress,
  ]);

  return (
    <>
      <StyledDatevPdfUpload
        isLoading={singleUploadInProgress || multiUploadInProgress}
        data-test-id={datevPdfUploadTestID}
      >
        <Panel>
          <PanelTitle
            headline={t("setup.upload_form_2.headline")}
            subline={t("setup.upload_form_2.datev.subline")}
            help={isInitial ? "0" : null}
          />
          <PanelBody>
            <Form onSubmitHandler={handleUpload}>
              {uploadersContext.sharedState.multipleUpload ? (
                <MultiSuSasUpload />
              ) : (
                <SinglePDFUpload disabled={singleUploadInProgress} />
              )}

              {hasError && !singleUploadInProgress && (
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Alert type={AlertTypeEnums.ERROR}>{t(`errors:${errorState}`)}</Alert>
                </FormRow>
              )}
              {isInitial && (
                <FormRow align={AlignmentEnums.STRETCH}>
                  <PlanPeriod disabled={singleUploadInProgress} />
                </FormRow>
              )}
              <FormRow align={AlignmentEnums.STRETCH}>
                <P>
                  {t("setup.upload_form_2.datev.select_pdf_creator", { step: isInitial ? 3 : 4 })}
                </P>
              </FormRow>
              <FormRow align={AlignmentEnums.STRETCH}>
                <Switch
                  variant={ButtonVariantEnums.SECONDARY}
                  name={"upload_format"}
                  firstItem={{
                    value: FileUploadFormat.PDF_DATEV_PRO,
                    text: <Trans i18nKey="setup.upload_form_2.datev.pdf_type.pro" />,
                    isSelected: true,
                  }}
                  secondItem={{
                    value: FileUploadFormat.PDF_DATEV_COMPANY,
                    text: <Trans i18nKey="setup.upload_form_2.datev.pdf_type.online" />,
                    isSelected: false,
                  }}
                />
              </FormRow>

              <FormRow align={AlignmentEnums.LEFT}>
                 
                {(waitUpload || multiUploadInProgress) && (
                  <SlideIn direction={SlideInDirections.TO_LEFT}>
                    <Alert type={AlertTypeEnums.INFO}>
                      <Trans i18nKey="setup.upload_form_2.datev.messages.wait_for_upload" />
                    </Alert>
                  </SlideIn>
                )}
              </FormRow>
              <FormRow align={AlignmentEnums.LEFT}>
                <Button
                  variant={ButtonVariantEnums.SECONDARY}
                  inverted={false}
                  type={"submit"}
                  inline={true}
                  disabled={!canUpload}
                  data-test-id="pdf-upload-submit"
                >
                  {t("setup.upload_form_2.datev.submit_upload")}
                </Button>
              </FormRow>
            </Form>
          </PanelBody>
        </Panel>
      </StyledDatevPdfUpload>
    </>
  );
};
