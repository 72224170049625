import styled, { css } from "styled-components/macro";

export const KpiDetailsWrapper = styled.div(({ theme }) => {
  return css`
    max-height: 80vh;
    overflow-x: auto;
    width: ${theme.rem(72)};
    padding: ${theme.rem(1)};
  `;
});

export const TextCaption = styled.p(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1.2)};
    font-weight: bold;
  `;
});

export const GraphAndInterpretations = styled.div(({ theme }) => {
  return css`
    display: flex;
    margin-bottom: ${theme.rem(1.33)};
    gap: ${theme.rem(2)};
  `;
});

export const InterpretationsList = styled.ul(({ theme }) => {
  return css`
    min-width: 55%;
  `;
});

export const InterpretationItem = styled.li(({ theme }) => {
  return css``;
});

export const GraphSubText = styled.p(({ theme }) => {
  return css`
    text-align: right;
    margin-right: ${theme.rem(8.66)};
    position: relative;
    font-size: ${theme.rem(1)};
    font-weight: bold;

    > span:first-of-type {
      &:before {
        content: "";
        position: absolute;
        right: ${theme.rem(12.53)};
        display: block;
        width: ${theme.rem(0.6)};
        height: ${theme.rem(0.6)};
        top: ${theme.rem(0.4)};
        border-radius: 50%;
        background: red;
      }
    }

    > span:last-of-type {
      margin-left: ${theme.rem(1.66)};
      &:before {
        content: "";
        position: absolute;
        right: ${theme.rem(5.2)};
        display: block;
        width: ${theme.rem(0.6)};
        height: ${theme.rem(0.6)};
        top: ${theme.rem(0.4)};
        border-radius: 50%;
        background: black;
      }
    }
  `;
});

export const GraphSubTextitem = styled.span(({ theme }) => {
  return css``;
});
