import styled, { css } from "styled-components/macro";
import { IActionButtonProps } from "./ActionButton";

export const StyledActionButton = styled.button<IActionButtonProps>(
  ({ theme, variant, inverted, disabled, isOpen, inline = true }) => {
    const { color, radius } = theme.branded.buttons[variant];

    const basic = css`
      border: 0;
      padding: ${theme.rem(0.5)} ${theme.rem(1)};
      background-color: ${color.base.background};
      color: ${color.base.color};
      cursor: pointer;
      font: inherit;

      &[disabled] {
        color: ${color.disabled.color};
        background-color: ${color.disabled.background};
        cursor: auto;
      }
      &:hover {
        background-color: ${color.hover.background};
        color: ${color.hover.color};
      }
    `;
    const openCss = isOpen
      ? css`
          border-radius: 0 0 0 0;
        `
      : css`
          border-radius: ${radius} 0 0 ${radius};
        `;
    return css`
      ${basic}
      ${openCss}
    `;
  }
);
