import styled, { css } from "styled-components/macro";
import { IButtonProps } from "./Button";
import { IButtonBrandedProps } from "./ButtonBranded";

/**
 * Base Styled Comp for Branded Buttons
 */
export const DefaultBrandedButton = styled.button<IButtonBrandedProps>(({ theme, inline }) => {
  const basic = css`
    background-image: none;
    border-radius: 10px;
    cursor: pointer;
    display: ${inline ? "inline-flex" : "flex"};
    width: ${inline ? "unset" : "100%"};
    align-items: center;
    font-family: inherit;
    font-size: ${theme.rem(1)};
    font-weight: ${theme.values.fontWeight.normal};
    line-height: 1.42857;
    margin-bottom: 0;
    padding: ${`${theme.rem(0.75)} ${theme.rem(1.25)}`};
    justify-content: center;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    transition: background-color 300ms;
  `;

  return css`
    ${basic}
  `;
});

/**
 * Styled Text Comp for Branded Buttons
 */
export const StyledBrandedButtonText = styled.span<Partial<IButtonProps>>(
  ({ theme, icon }) => css`
    padding-left: ${theme.rem(icon ? 1 : 0)};
    flex-grow: 1;
  `
);

/**
 *
 * Base Branded Button style extended with theme properties
 */
export const StyledButtonBranded = styled(DefaultBrandedButton)(({ inverted, theme, variant }) => {
  const {
    color: { base, hover, disabled },
    radius,
  } = theme.branded.buttons[variant];
  const basic = css`
    border-radius: ${radius};
    background-color: ${inverted ? base.inverted.background : base.background};
    color: ${inverted ? base.inverted.color : base.color};
    border: 1px solid transparent;
    &:hover {
      color: ${inverted ? hover.inverted.color : hover.color};
      background-color: ${inverted ? hover.inverted.background : hover.background};
    }
    &[disabled] {
      cursor: not-allowed;
      color: ${inverted ? disabled.inverted.color : disabled.color};
      background-color: ${inverted ? disabled.inverted.background : disabled.background};
    }
  `;

  return css`
    ${basic}
  `;
});
