import styled, { css } from "styled-components/macro";
import { IconButtonProps } from "./IconButton";
import { ButtonVariantEnums } from "./Button";

export const StyledIconButton = styled.button<Partial<IconButtonProps>>(
  ({ theme, variant = ButtonVariantEnums.SECONDARY, inverted }) => {
    const {
      color: { base, hover, disabled },
    } = theme.branded.buttons[variant];
    
    const basic = css`
      padding: ${theme.rem(0.5)};
      background: none;
      border: none;
      color: ${!inverted ? base.inverted.color : base.color};
      cursor: pointer;
      transition: color 300ms;
      flex-grow: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      &:hover {
        color: ${!inverted ? hover.inverted.color : hover.color};
        background: none;
      }
      &[disabled] {
        cursor: auto;
        color: ${!inverted ? disabled.inverted.color : disabled.color};
      }
    `;
    return css`
      ${basic}
    `;
  }
);
