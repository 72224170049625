import { FC } from "react";
import styled, { css, keyframes } from "styled-components/macro";
const dotAnimation = keyframes`
    0% {
      opacity: 1;
    }

    80% {
      opacity: 0;
    }
  `;
const StyledPageLoader = styled.section(({ theme }) => {
  return css`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  `;
});
const StyledPageLoaderDot = styled.div<IPageLoaderProps>(({ theme, dotColor = "#ffffff" }) => {
  return css`
    width: 15px;
    height: 15px;
    margin: 0 3px;
    border-radius: 50%;
    background-color: ${dotColor};
    display: inline-block;
    animation: ${dotAnimation} 1.5s infinite;
    animation-delay: 500ms;
    opacity: 0.5;
    &:first-of-type {
      opacity: 0;
      animation-delay: 0ms;
    }
    &:last-of-type {
      opacity: 0.7;
      animation-delay: 1s;
    }
  `;
});

export interface IPageLoaderProps {
  dotColor?: string;
}
export const PageLoader: FC<IPageLoaderProps> = ({ children, ...props }) => {
  return (
    <>
      <StyledPageLoader>
        <StyledPageLoaderDot {...props} />
        <StyledPageLoaderDot {...props} />
        <StyledPageLoaderDot {...props} />
      </StyledPageLoader>
    </>
  );
};
