import { FC, useContext } from "react";
import { Panel, PanelBody, PanelTitle } from "../../Panel";
import { StyledUploadManualEntryGuV } from "../styled.uploaders";
import { useTranslation } from "react-i18next";
import { Cell, Row, Table } from "../../../components/Table";

import { IManualEntryItems, ManualEntrySum, IGlobalCodes } from "./";
import { LoaderTypeEnums, LoadingIndicator } from "../../../components/LoadingIndicator";
import { UploadersContext } from "../index";

const GlobalGuVCodesLoading: FC = () => {
  const rows = new Array(33).fill("", 0, 33).map((item: string, index: number) => (
    <Row key={index}>
      <Cell>
        <LoadingIndicator type={LoaderTypeEnums.TEXT} length={12} />
      </Cell>
      <Cell>
        <LoadingIndicator type={LoaderTypeEnums.INPUT_FIELD} length={5} />
      </Cell>
    </Row>
  ));
  return <>{rows}</>;
};

export interface IGuVProps {
  data: IGlobalCodes;
  loading: boolean;
}
export const GuV: FC<IGuVProps> = ({ data, loading }) => {
  const { t } = useTranslation();
  const { isInitial } = useContext(UploadersContext);
  const guvs = data?.getGlobalCodesTree?.filter(
    (item: IManualEntryItems) => item?.value && item?.value.startsWith("P")
  );
  return (
    <StyledUploadManualEntryGuV isLoading={loading}>
      <Panel>
        <PanelTitle headline={t("setup.upload_form_2.manual.guv")} help={isInitial ? "0" : null} />
        <PanelBody>
          <Table width={"100%"}>
            <tbody>
              {loading ? (
                <GlobalGuVCodesLoading />
              ) : (
                guvs?.map((guv: IManualEntryItems, guvIndex: number) => (
                  <ManualEntrySum key={`guvIndex-${guvIndex}`} {...guv} type={"guv"} />
                ))
              )}
            </tbody>
          </Table>
        </PanelBody>
      </Panel>
    </StyledUploadManualEntryGuV>
  );
};
