import { Dispatch, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  ILocalState,
  SelectClientState,
} from "../../@types/index.d";
import { useClientLogo, useClients, useSwitchClient } from "../../_lib/hooks";
import { Trans, useTranslation } from "react-i18next";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import {
  StyledDeleteConfirmItem,
  StyledDeleteIcon,
  StyledExistingItem,
  StyledExistingItemLogo,
} from "./styled.client-list";
import { Button, ButtonVariantEnums } from "../../components/formElements/Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../components/Icon";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useEventListener } from "../../_lib/hooks/useEventListener";
import { ICurrentUserAction, UserStateActionTypes } from "../../_lib/store/reducers";
interface IExistingClientProps {
  index: number;
  client_id: string;
  customer_id: string;
  total: number;
  name: string;
  onSelect: () => void;
  testID: string;
}

export const ExistingClient: FC<IExistingClientProps> = ({
  index,
  total,
  name,
  client_id,
  onSelect,
  testID,
}) => {
  const dataSync = useSelector(({ dataSync }: ILocalState) => dataSync, isEqual);
  const appUser = useSelector(({ currentUser: { appUser } }: ILocalState) => appUser, isEqual);

  const [getLogo, { image: clientLogo, loading: isLogoLoading }] = useClientLogo();

  const [confirming, setConfirming] = useState(false);
  const { switchClient } = useSwitchClient();

  const { t } = useTranslation();
  const { remove } = useClients();

  const { startEventSync } = useEventListener();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();

  useEffect(() => {
    if (isLogoLoading) return;
    getLogo(client_id);
  }, [client_id, getLogo, isLogoLoading]);

  const handleDeleteClient = (): void => {
    const covid = uuidv4();
    // to initiate the loading spinner which will be cancelled in private index
    dispatchCurrentUser({
      type: UserStateActionTypes.EVENT_IN_PROGRESS,
      payload: { eventInProgress: true },
    });
    remove(client_id, covid)
      .then(() => {
        startEventSync({
          level: EDataSyncLevel.CUSTOMER,
          accountId: appUser.customer_id,
          transactionId: covid,
          scope: EDataSyncScope.CLIENT,
          type: EDataSyncTypes.DELETE,
        });
      })
      .catch(() => {
        dispatchCurrentUser({
          type: UserStateActionTypes.EVENT_IN_PROGRESS,
          payload: { eventInProgress: false },
        });
      });
  };
  const handleConfirmDelete = (): void => {
    setConfirming(true);
  };
  const handleCancelConfirmDelete = (): void => {
    setConfirming(false);
  };

  const waitDelete = dataSync.CUSTOMER.CLIENT.pending;
  return (
    <>
      {waitDelete && confirming && <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />}
      {confirming ? (
        <>
          <StyledDeleteConfirmItem to="#" data-test-id={testID + "-confirming"}>
            <div className="title">{t(`dialogs.client_management.delete_confirm.title`)}</div>
            <div className="text">
              <Trans
                i18nKey={"dialogs.client_management.delete_confirm.text"}
                values={{ client_name: name }}
              />
            </div>
            <div className={"buttons_row"}>
              <Button
                variant={ButtonVariantEnums.SECONDARY}
                inverted={true}
                type={"button"}
                inline={true}
                onClick={handleCancelConfirmDelete}
                disabled={waitDelete}
                data-test-id={testID + "-confirming-cancel"}
              >
                {t(`misc.cancel`)}
              </Button>
              <Button
                variant={ButtonVariantEnums.SECONDARY}
                inverted={false}
                type={"button"}
                inline={true}
                onClick={handleDeleteClient}
                disabled={waitDelete}
                data-test-id={testID + "-confirming-delete"}
              >
                {t(`misc.delete`)}
              </Button>
            </div>
          </StyledDeleteConfirmItem>
        </>
      ) : (
        <>
          <StyledExistingItem
            to="#"
            onClick={(e): void => {
              e.preventDefault();

              if (waitDelete) return;
              switchClient(client_id, SelectClientState.UNKNOWN);
              onSelect();
            }}
            data-abo={`${index + 1} /  ${total}`}
            data-test-id={testID}
          >
            {clientLogo ? (
              <StyledExistingItemLogo src={clientLogo} alt="" />
            ) : (
              <Icon name={IconNameEnums.IMAGE} size={IconSizeEnums.XLARGE} />
            )}
            {name}
          </StyledExistingItem>
          {!waitDelete && (
            <StyledDeleteIcon
              type={"button"}
              variant={ButtonVariantEnums.SECONDARY}
              icon={<Icon name={IconNameEnums.TRASH} size={IconSizeEnums.SMALL} />}
              onClick={handleConfirmDelete}
              inverted={false}
              disabled={waitDelete}
              data-test-id={testID + "-delete"}
            />
          )}
        </>
      )}
    </>
  );
};
