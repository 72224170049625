import React, { FC, useContext, useEffect } from "react";
import ReactQuill from "react-quill";

import { FormElementContainer, TFormElementContainerProps } from "../FormElementContainer";
import { StyledInnerWrapper } from "../Input/styled.input";
import { FormContext, FormDispatch } from "../Form";
import { StyledTextEditor } from "./styled.text-editor";
export interface ITextEditorProps extends TFormElementContainerProps {
  value: string;
  name: string;
}

export const TextEditor: FC<ITextEditorProps> = ({ children, value, name, ...containerProps }) => {
  const formDispatch = useContext(FormDispatch);
  const formContext = useContext(FormContext);

  useEffect(() => {
    if (formContext[name] === undefined) {
      formDispatch({ [name]: value });
    }
  }, [formContext, formDispatch, name, value]);
  const handleTextChange = (content: string): void => {
    if (formContext[name] !== content) {
      formDispatch({ [name]: content });
    }
  };
  return (
    <FormElementContainer {...containerProps}>
      <StyledInnerWrapper data-text-editor={name}>
        <StyledTextEditor>
          <ReactQuill
            theme="snow"
            value={formContext[name]}
            onChange={handleTextChange}
            bounds={`[data-text-editor="${name}"]`}
          />
        </StyledTextEditor>
      </StyledInnerWrapper>
    </FormElementContainer>
  );
};
