export enum LocalCacheEnums {
  CUSTOMER_ID = "LastLoggedInUser",
  SELECTED_CLIENT_ID = "selectedClientId",
  CLIENT_LOGOS = "clientLogos",
}

export interface ILocalCacheParams {
  key: LocalCacheEnums;
  value: string | ILogoProps;
}

export interface ILogoProps {
  hash: string;
  image: string;
}
export enum CacheGroupEnums {
  CURRENT_USER = "CURRENT_USER",
}

export interface LocalCacheRemoveItemOptions {
  logo_hash?: string;
}
/**
 * Custom Local Storage to manage grouped information.
 */
class LocalCache {
  /**
   * Returns value of a give key from it's group.
   * @param key
   */
  getItem(key: LocalCacheEnums): string | null {
    switch (key) {
      case LocalCacheEnums.CUSTOMER_ID:
      case LocalCacheEnums.SELECTED_CLIENT_ID:
      case LocalCacheEnums.CLIENT_LOGOS:
        const currentUser = JSON.parse(localStorage.getItem(CacheGroupEnums.CURRENT_USER) || "{}");
        return currentUser[key];

      default:
        return null;
    }
  }

  /**
   * Sets value to a key in group where it belongs to.
   * @param key
   * @param value
   */
  setItem(key: LocalCacheEnums, value: string | ILogoProps): void {
    const currentUser = JSON.parse(localStorage.getItem(CacheGroupEnums.CURRENT_USER) || "{}");
    switch (key) {
      case LocalCacheEnums.CUSTOMER_ID:
      case LocalCacheEnums.SELECTED_CLIENT_ID:
        localStorage.setItem(
          CacheGroupEnums.CURRENT_USER,
          JSON.stringify({ ...currentUser, [key]: value })
        );
        break;
      case LocalCacheEnums.CLIENT_LOGOS:
        if (typeof value === "string") return;
        const logos = (currentUser[LocalCacheEnums.CLIENT_LOGOS] as [ILogoProps]) || [];

        const allOtherLogos = logos.filter((logo) => logo.hash !== value.hash);
        localStorage.setItem(
          CacheGroupEnums.CURRENT_USER,
          JSON.stringify({ ...currentUser, [key]: [...allOtherLogos, value] })
        );
    }
  }
  length(): number {
    return localStorage.length;
  }

  /**
   * Delete stored group (object)
   * @param group
   */
  clear(group: CacheGroupEnums): void {
    localStorage.removeItem(group);
  }

  /**
   * Delete only given key value from it's group
   * @param key
   * @param options
   */
  removeItem(key: LocalCacheEnums, options?: LocalCacheRemoveItemOptions): void {
    const currentUser = JSON.parse(localStorage.getItem(CacheGroupEnums.CURRENT_USER) || "{}");
    switch (key) {
      case LocalCacheEnums.CUSTOMER_ID:
      case LocalCacheEnums.SELECTED_CLIENT_ID:
        delete currentUser[key];
        localStorage.setItem(CacheGroupEnums.CURRENT_USER, JSON.stringify(currentUser));
        break;
      case LocalCacheEnums.CLIENT_LOGOS:
        if (!options?.logo_hash) return;
        const logos = (currentUser[LocalCacheEnums.CLIENT_LOGOS] as [ILogoProps]) || [];

        const allOtherLogos = logos.filter((logo) => logo.hash !== options.logo_hash);
        localStorage.setItem(
          CacheGroupEnums.CURRENT_USER,
          JSON.stringify({ ...currentUser, [LocalCacheEnums.CLIENT_LOGOS]: [...allOtherLogos] })
        );
    }
  }
  key(keyIndex: number): string | null {
    return localStorage.key(keyIndex);
  }
}

export const localCache = new LocalCache();
