import styled from "styled-components/macro";

export const StyledPageContent = styled.section`
  display: grid;
  grid-area: content;
  grid-template-areas:
    "content-nav"
    "content";
  grid-template-rows: min-content 1fr;
`;
