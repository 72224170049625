/**
 * @file Form `<input />` element
 */

import { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import {
  StyledInputUnit,
  StyledInnerWrapper,
  StyledInput,
} from "./styled.input";

import { FormElementContainer } from "../FormElementContainer";
import { FormDispatch } from "../Form";

import { IInputConstructorProps, IInputLabelPosition, InputTypes } from "../../../@types/index.d";
import { formatNumber, onlyNumbersAndSingleDot, unFormatNumber } from "../../../_lib/common";

/**
 * Creates Input Element
 */
export const InputConstructor: FC<IInputConstructorProps> = ({
  onChange,
  onBlur,
  onFocus,
  value,
  name,
  type = InputTypes.TEXT,
  label,
  labelPosition = IInputLabelPosition.TOPLEFT,
  placeholder = "",
  autoComplete = "off",
  children,
  valid,
  invalidWarning,
  noBorder,
  withUnit,
  alignText,
  ...rest
}: IInputConstructorProps) => {
  const formDispatch = useContext(FormDispatch);
  const [val, setValue] = useState<string | undefined>(value);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    if (onChange) {
      onChange(e);
    }

    const tempVal = e.target.value;
    switch (type) {
      case InputTypes.TABLENUM:
        setValue(onlyNumbersAndSingleDot(tempVal));
        break;
      default:
        setValue(e.target.value);
    }
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>): void {
    if (onFocus) {
      onFocus(e);
    }

    const tempVal = e.currentTarget.value;
    switch (type) {
      case InputTypes.TABLENUM:
        const val = parseFloat(unFormatNumber(tempVal));
        setValue(isNaN(val) ? "0,00" : val.toString());
        break;
      case InputTypes.FORMATTEDNUM:
        const v = parseFloat(unFormatNumber(tempVal));
        setValue(isNaN(v) ? "" : v.toString());
        break;
      default:
    }
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
    if (onBlur) {
      onBlur(e);
    }

    const tempVal = e.currentTarget.value;
    switch (type) {
      case InputTypes.TABLENUM:
        const val = parseFloat(unFormatNumber(tempVal));
        setValue(isNaN(val) ? "0,00" : formatNumber(val));
        break;
      case InputTypes.FORMATTEDNUM:
        const v = parseFloat(unFormatNumber(tempVal));
        setValue(isNaN(v) ? "" : formatNumber(v));
        break;
      default:
    }
  }

  useEffect(() => {
    switch (type) {
      case InputTypes.NUMBER:
        const v = parseFloat(value);
        setValue(isNaN(v) ? "" : v.toString());

        break;
      case InputTypes.TABLENUM:
        const val = parseFloat(value);
        setValue(isNaN(val) ? "" : formatNumber(val));

        break;
      default:
        setValue(value);
    }
  }, [setValue, value, type]);

  useEffect(() => {
    formDispatch({ [name]: val || "" });
  }, [val, name, formDispatch]);

  return (
    <FormElementContainer
      label={label}
      valid={valid}
      invalidWarning={invalidWarning}
      labelPosition={labelPosition}
    >
      <StyledInnerWrapper noBorder={noBorder}>
        <StyledInput
          name={name}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={val}
          autoComplete={autoComplete}
          type={type}
          placeholder={placeholder}
          alignText={alignText}
          {...rest}
        />
        {children}
        {withUnit && <StyledInputUnit>{withUnit}</StyledInputUnit>}
      </StyledInnerWrapper>
    </FormElementContainer>
  );
};
