import { FC, useContext, useEffect } from "react";
import { AlertTypeEnums, IFileUploaderProps, ILocalState } from "../../../@types/index.d";
import { FileUploader, FormContext, FormDispatch } from "../../../components/formElements";

import { useSelector } from "react-redux";
import { getFileContent, resetFileContent } from "../../../_lib/hooks";
import { useSingleUploadSucceeded } from "./sharedHooks";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../index";
import { useSystemMessages } from "../../../_lib/hooks/useSystemMessages";
import { EAutoCloseTimeOut } from "../../../_lib/store/reducers";
import { isEqual } from "lodash";

export const SingleSuSaFile: FC<IFileUploaderProps> = ({ children, ...rest }) => {
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const file = getFileContent(rest.name, formContext);

  const { addSystemMessage } = useSystemMessages();

  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { client_id } = currentUser.selectedClient;
  const singleUploadSucceeded = useSingleUploadSucceeded();
  useEffect(() => {
    if (singleUploadSucceeded) {
      resetFileContent("pdfFile", formDispatch);
      addSystemMessage({
        identifier: uploadersContext.sharedState.file.name,
        type: AlertTypeEnums.SUCCESS,
        code: "Upload succeed!",
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
      });
      uploadersDispatch({ type: EUploadersActionTypes.RESET, payload: {} });
    }
  }, [
    singleUploadSucceeded,
    uploadersDispatch,
    formDispatch,
    uploadersContext.sharedState.file.name,
    addSystemMessage,
  ]);

  useEffect(() => {
    if (
      uploadersContext.sharedState.file.content !== file.content ||
      uploadersContext.sharedState.file.type !== file.type ||
      uploadersContext.sharedState.file.name !== file.name ||
      uploadersContext.sharedState.file.lastModified !== file.lastModified
    ) {
      if (client_id === null) return;
      uploadersDispatch({
        type: EUploadersActionTypes.SET_STATE,
        payload: {
          data: {
            client_id,
            fileSelected: file.content.length > 0 && file.type === rest.accept,
          },
          file,
        },
      });
    }
  }, [
    client_id,
    file,
    rest.accept,
    uploadersDispatch,
    uploadersContext.sharedState.file.content,
    uploadersContext.sharedState.file.type,
    uploadersContext.sharedState.file.name,
    uploadersContext.sharedState.file.size,
    uploadersContext.sharedState.file.lastModified,
  ]);
  if (currentUser.selectedClient.client_id === null) return null;
  return <FileUploader {...rest} />;
};
