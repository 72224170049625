import {
  FC,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  useContext,
  useEffect,
  ChangeEvent,
  useCallback,
} from "react";

import { FormElementContainer, TFormElementContainerProps } from "../FormElementContainer";
import { InputProps } from "../../../@types/index.d";
import { StyledTextArea } from "./styled.text-area";
import { FormContext, FormDispatch } from "../Form";

export type TTextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  TFormElementContainerProps &
  Pick<InputProps<HTMLTextAreaElement>, "variant">;

export interface ITextAreaProps extends TTextAreaProps {
  name: string;
  value: string;
}
export const TextArea: FC<ITextAreaProps> = ({
  children,
  name,
  value = "",
  variant,
  ref,
  ...rest
}) => {
  const formDispatch = useContext(FormDispatch);
  const formContext = useContext(FormContext);
  const sliceToMaxlength = useCallback(
    (str = ""): string => {
      if (rest.maxLength && rest.maxLength > 0 && str) return str.slice(0, rest.maxLength);
      return str || "";
    },
    [rest.maxLength]
  );
  useEffect(() => {
    if (formContext[name] === undefined) {
      formDispatch({ [name]: sliceToMaxlength(value) });
    }
  }, [value, formContext, formDispatch, name, sliceToMaxlength]);
  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    formDispatch({ [name]: sliceToMaxlength(e.target.value) });
  };
  return (
    <FormElementContainer
      label={rest.label}
      labelPosition={rest.labelPosition}
      invalidWarning={rest.invalidWarning}
      valid={rest.valid}
    >
      <StyledTextArea.Wrapper variant={variant} labelPosition={rest.labelPosition}>
        <StyledTextArea.Element onChange={handleValueChange} value={formContext[name]} {...rest} />
        {rest.maxLength && (
          <StyledTextArea.RestCounter>{`${formContext[name]?.length || 0}/${
            rest.maxLength
          }`}</StyledTextArea.RestCounter>
        )}
      </StyledTextArea.Wrapper>
    </FormElementContainer>
  );
};
