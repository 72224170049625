import { IUserInfo } from "../../@types/index.d";
import { localCache, LocalCacheEnums } from "./localCache";

export interface ISelectedClientInCache {
  client_id: string | null;
  customer_id: string | null;
}
export class SelectedClient {
  user: IUserInfo;
  constructor(currentUser: IUserInfo) {
    this.user = currentUser;
    localCache.setItem(LocalCacheEnums.CUSTOMER_ID, this.user.appUser.customer_id);
  }
  set(clientId: string): void {
    localCache.setItem(LocalCacheEnums.SELECTED_CLIENT_ID, clientId);
    localCache.setItem(LocalCacheEnums.CUSTOMER_ID, this.user.appUser.customer_id);
  }
  get(): ISelectedClientInCache {
    return {
      client_id: localCache.getItem(LocalCacheEnums.SELECTED_CLIENT_ID),
      customer_id: localCache.getItem(LocalCacheEnums.CUSTOMER_ID),
    };
  }
  removeClient(): ISelectedClientInCache {
    localCache.removeItem(LocalCacheEnums.SELECTED_CLIENT_ID);

    return {
      client_id: localCache.getItem(LocalCacheEnums.SELECTED_CLIENT_ID),
      customer_id: localCache.getItem(LocalCacheEnums.CUSTOMER_ID),
    };
  }

  validate(): boolean {
    if (localCache.getItem(LocalCacheEnums.CUSTOMER_ID) === null) {
      return false;
    }
    if (localCache.getItem(LocalCacheEnums.CUSTOMER_ID) === "") {
      return false;
    }
    if (localCache.getItem(LocalCacheEnums.CUSTOMER_ID) !== this.user.appUser.customer_id) {
      return false;
    }

    if (localCache.getItem(LocalCacheEnums.SELECTED_CLIENT_ID) === null) return false;

    return !!this.user.clients.find(
      (client) => client.client_id === localCache.getItem(LocalCacheEnums.SELECTED_CLIENT_ID)
    );
  }
  clear(): void {
    localCache.removeItem(LocalCacheEnums.SELECTED_CLIENT_ID);
    localCache.removeItem(LocalCacheEnums.CUSTOMER_ID);
  }
}
