import { gql, useApolloClient } from "@apollo/client";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { IUploadsAction, IUploadsActionTypes } from "../../store/reducers";

const GET_MAPPING_FOR_SUSA = gql`
  query getMappingForSusa($susa_id: ID!) {
    getMappingForSusa(susa_id: $susa_id) {
      susa_id
      date
      format
      client_id
      mapped_at
      data {
        global_code_id
        account_code
        account_code_complete
        description
        balance
        starting_balance
        account_code_id
      }
    }
  }
`;

export interface IUseMapping {
  getMappingForSusa: (susa_id: string) => void;
}

export const useMapping = (): IUseMapping => {
  const apolloClient = useApolloClient();
  const dispatchUploads = useDispatch<Dispatch<IUploadsAction>>();

  // used for page visit events (add the page_id to config if it is missing and call the function with the page id)
  const getMappingForSusa = (susa_id: string): void => {
    apolloClient
      .query({
        fetchPolicy: "network-only",
        query: GET_MAPPING_FOR_SUSA,
        variables: { susa_id: susa_id },
      })
      .then(({ data }) => {
        if (data && data.getMappingForSusa) return data.getMappingForSusa;
      })
      .then((mappedSusa) => {
        dispatchUploads({
          type: IUploadsActionTypes.SET_MAPPED_SUSA,
          payload: {
            mappedSusa: mappedSusa,
          },
        });
      });
  };

  return {
    getMappingForSusa,
  };
};
