import { FC } from "react";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../../components/Icon";
import { IconButton, ButtonVariantEnums } from "../../../../components/formElements/Button";

export const Notifications: FC = () => {
  return (
    <>
      <IconButton
        icon={<Icon name={IconNameEnums.EXCLAMATION} size={IconSizeEnums.MEDIUM} />}
        variant={ButtonVariantEnums.SECONDARY}
        type={"button"}
        inverted={false}
      />
      <IconButton
        icon={<Icon name={IconNameEnums.BELL} size={IconSizeEnums.MEDIUM} />}
        variant={ButtonVariantEnums.SECONDARY}
        type={"button"}
        inverted={false}
      />
    </>
  );
};
