import styled, { css } from "styled-components/macro";
import { IDropDownProps } from "./index";
import { ButtonVariantEnums } from "../Button";

export const StyledDropDown = styled.div<Pick<IDropDownProps, "inline">>(
  ({ /*theme,*/ inline = true }) => {
    return css`
      display: ${inline ? "inline-block" : "block"};
      position: relative;
    `;
  }
);
export const StyledDropDownButtons = styled.div<
  Pick<IDropDownProps, "variant" | "inverted" | "isOpen" | "inline">
>(({ theme, isOpen, variant = ButtonVariantEnums.PRIMARY, inverted = false, inline = true }) => {
  const { radius } = theme.branded.buttons[variant];
  const openCss = isOpen
    ? css`
        border-radius: ${radius} 0 0 0;
        border-bottom-width: 0;
      `
    : css`
        border-radius: ${radius} 0 0 ${radius};
        border-bottom-width: 1px;
      `;
  const basic = css`
    display: ${inline ? "inline-flex" : "flex"};
    border: 0;
    overflow: hidden;
    transition: border-radius 300ms;
    > :first-child {
      flex-grow: 1;
      ${openCss}
    }
    > :last-child {
      flex-grow: 0;
      border-radius: 0 ${radius} ${radius} 0;
      border: 1px solid;
    }
  `;

  return css`
    ${basic}
  `;
});
