import { FC } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import solidGauge from "highcharts/modules/solid-gauge";
import more from "highcharts/highcharts-more";
import { useTheme } from "styled-components/macro";
import { isEqual } from "lodash";
import { ILocalState } from "../../@types";
import { useSelector } from "react-redux";

more(Highcharts);
solidGauge(Highcharts);
/*
 **************************************************
 * Custom Styling of Highcharts using css is possible.
 * If it's decided to do so. Useful example : 💣
 * https://codesandbox.io/s/highcharts-react-demo-vosjm?file=/demo.jsx
 **************************************************
 */
export interface IKPIHistoryProps {
  data: number[];
  benchmark?: number;
  labels: {
    data: string[];
    benchmark?: string;
    series: string;
  };
}
export const KPIHistoryChart: FC<IKPIHistoryProps> = ({ data, benchmark, labels }) => {
  const theme = useTheme();
  const brandedColors = useSelector(
    ({
      themeOptions: {
        branded: { colors },
      },
    }: ILocalState) => colors,
    isEqual
  );
  const colors = {
    actual: brandedColors.secondary,
    benchmark: brandedColors.primary,
  };

  const options: Highcharts.Options = {
    chart: {
      styledMode: false, // Turn it to `true` to enable custom graph styling with css
    },
    title: {
      text: "",
    },
    subtitle: { text: "" },
    xAxis: {
      categories: labels.data,
    },
    yAxis: {
      max: Math.max(...data),
      title: { text: "Y-Axis Title" },
      plotLines: [
        {
          value: benchmark,
          color: colors.benchmark,
          dashStyle: "Solid",
          width: 2,
          label: {
            text: labels.benchmark,
          },
        },
      ],
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          color: colors.actual,
          style: {
            fontSize: theme.rem(1.25),
          },
        },
      },
    },
    // labels: {
    //   items: [
    //     {
    //       html: "",
    //       style: {
    //         left: "50px",
    //         top: "18px",
    //         color: "#00ff11",
    //       },
    //     },
    //   ],
    // },
    series: [
      {
        type: "column",
        name: labels.series,
        data,
        color: colors.actual,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />;
};
