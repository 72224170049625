import { FC, ReactChild, ReactElement, ReactFragment, ReactNode, ReactPortal } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { StyledCarousel } from "./styled-carousel";
import { Carousel } from "react-responsive-carousel";

export interface Props {
  children:
    | (ReactChild[] &
        (boolean | ReactChild | ReactElement | ReactFragment | ReactNode | ReactPortal | null))
    | undefined;
  showArrows: boolean;
  showIndicators: boolean;
  autoPlay: boolean;
}

const CarouselComp: FC<Props> = ({ children, showArrows, showIndicators, autoPlay }) => {
  return (
    <StyledCarousel.Wrapper>
      <Carousel
        autoPlay={autoPlay}
        showArrows={showArrows}
        showIndicators={showIndicators}
        interval={autoPlay ? 10000 : 0}
        showStatus={false}
        showThumbs={false}
        stopOnHover={true}
        infiniteLoop={true}
        transitionTime={500}
      >
        {children}
      </Carousel>
    </StyledCarousel.Wrapper>
  );
};

export default CarouselComp;
