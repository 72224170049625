import { createRef, FC, useContext, useEffect } from "react";
import { IFileUploaderProps } from "../../../@types/index.d";
import { InputConstructor } from "../Input/InputConstructor";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import { ButtonVariantEnums } from "../Button";
import { InputButton } from "../Input/InputButton";
import { FormContext } from "../Form";
import { StyledFileInput, StyledFileUploader } from "./styled.file-uploader";
import { useFileUploader } from "../../../_lib/hooks";

export const FileUploader: FC<IFileUploaderProps> = ({ children, accept, convertTo, ...rest }) => {
  const { handleFileDrop, handleFileChange, handleDragOver, getFileContent } = useFileUploader({
    name: rest.name,
    convertTo,
    onChange: rest.onChange,
  });
  const formContext = useContext(FormContext);
  const fileName = getFileContent(rest.name, formContext)?.name || "";
  const fileInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (fileName === "" && fileInputRef.current !== null) {
      fileInputRef.current.value = "";
    }
  });
  
  return (
    <>
      <StyledFileUploader onDragOver={handleDragOver} onDrop={rest.onDrop}>
        <InputConstructor {...rest} readOnly={true} value={fileName}>
          <StyledFileInput
            type="file"
            onChange={handleFileChange}
            onDrop={handleFileDrop}
            accept={accept}
            ref={fileInputRef}
          />
          <InputButton
            icon={<Icon name={IconNameEnums.UPLOAD} size={IconSizeEnums.SMALL} />}
            inverted={false}
            variant={ButtonVariantEnums.SECONDARY}
            type={"button"}
          />
        </InputConstructor>
      </StyledFileUploader>
    </>
  );
};
