import { FC } from "react";
import {
  StyledCardContainer,
  StyledCardBody,
  StyledCardTitle,
  StyledCardFooter,
  CenteredCardContainer,
} from "./styled.card";

import { ICardProps, ICardTypeEnums } from "../../@types/index.d";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";

export const Card: FC<ICardProps> = ({
  children,
  type = ICardTypeEnums.FULL_WIDTH,
  loading = false,
}: ICardProps) => {
  switch (type) {
    case ICardTypeEnums.CENTERED: {
      return (
        <CenteredCardContainer>
          <StyledCardContainer>
            {loading && <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />}
            {children}
          </StyledCardContainer>
        </CenteredCardContainer>
      );
    }
    default:
      return (
        <>
          <StyledCardContainer>{children}</StyledCardContainer>
        </>
      );
  }
};
/**
 * Styled container for the content of the component
 */
export const CardBody: FC = ({ children }) => {
  return (
    <>
      <StyledCardBody>{children}</StyledCardBody>
    </>
  );
};
/**
 * Styled container for the title of the component
 */
export const CardTitle: FC = ({ children }) => {
  return (
    <>
      <StyledCardTitle>{children}</StyledCardTitle>
    </>
  );
};
/**
 * Styled container for the footer of the component
 */
export const CardFooter: FC = ({ children }) => {
  return (
    <>
      <StyledCardFooter>{children}</StyledCardFooter>
    </>
  );
};
