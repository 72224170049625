import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

const StyledCardContainerDefaults = styled.section(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        padding: ${theme.rem(0.5)};
      `,
    },
    SMALL: {
      style: css`
        padding: ${theme.rem(1.33)};
      `,
    },
  });
});
export const StyledCardContainer = styled(StyledCardContainerDefaults)(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        flex-direction: column;
        box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
          ${theme.values.colors.global.greys._025};
        border-radius: ${theme.rem(1.75)};
        background-color: ${theme.values.colors.global.greys.first};
        transition: width 300ms;
        min-width: 300px;
        position: relative;
        max-width: 100%;
      `,
    },
    SMALL: {
      style: css`
        max-width: 600px;
      `,
    },
  });
});
export const StyledCardTitle = styled(StyledCardContainerDefaults)(({ theme }) => css``);
export const StyledCardBody = styled(StyledCardContainerDefaults)(
  ({ theme }) => css`
    display: block;
    justify-content: center;
  `
);
export const StyledCardFooter = styled(StyledCardContainerDefaults)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-around;
  `
);

export const CenteredCardContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
`;
