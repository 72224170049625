import { FC } from "react";
import { StyledFinancialGrade } from "./styled.financial-grade";

import { FinancialGradeReport, IFinancialGradeReportProps } from "./FinancialGradeReport";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { EPrivatePathName, IDialogKeyEnums } from "../../../../@types/index.d";
import { useDialogs, useEvaluations } from "../../../../_lib/hooks";

import {
  ChartContainer,
  FinancialGradeChart,
  IFinancialGradeChartProps,
} from "../../../../components/Charts";

export interface IFinancialGradeProps {
  report: IFinancialGradeReportProps;
  chart: IFinancialGradeChartProps;
}

export const FinancialGrade: FC = () => {
  const dialog = useDialogs();

  const { chartData, reportData, loading, ...report } = useEvaluations();
  const lastMonth = chartData[0];
  reportData(lastMonth?.month);
  const reversedChartData = chartData.reverse();
  const handleCloseDialogBeforeRedirect = (): void => {
    dialog.close(IDialogKeyEnums.SIMPLE);
  };

  const chart = { data: reversedChartData };
  return (
    <StyledFinancialGrade.Container>
      <ChartContainer>
        <FinancialGradeChart {...chart} />
      </ChartContainer>
      <FinancialGradeReport {...report} />
      <StyledFinancialGrade.Footer>
        <Trans i18nKey={"dashboard.financial_grade.download"}>
          <Link to={EPrivatePathName.REPORTS_CURRENT} onClick={handleCloseDialogBeforeRedirect} />
        </Trans>
      </StyledFinancialGrade.Footer>
    </StyledFinancialGrade.Container>
  );
};
