import styled, { css } from "styled-components/macro";
import { IButtonProps } from "./Button";

/**
 * Simple Styled Comp for Buttons
 */
export const DefaultButton = styled.button<IButtonProps>(
  ({ theme, inline }) => css`
    background-image: none;
    border-radius: 10px;
    cursor: pointer;
    display: ${inline ? "inline-flex" : "flex"};
    width: ${inline ? "unset" : "100%"};
    align-items: center;
    font-family: inherit;
    font-size: ${theme.rem(1)};
    font-weight: ${theme.values.fontWeight.normal};
    line-height: 1.42857;
    padding: ${`${theme.rem(0.35)} ${theme.rem(1.25)}`};
    justify-content: center;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    transition: background-color 300ms, width 300ms;
    max-width: 100%;
  `
);

export const StyledButtonText = styled.span<Partial<IButtonProps>>(
  ({ theme, icon }) => css`
    padding-left: ${theme.rem(icon ? 1 : 0)};
    flex-grow: 1;
  `
);
/**
 *
 * Themed Styled Comp
 */
export const StyledButton = styled(DefaultButton)(({ inverted, theme, variant }) => {
  const {
    color: { base, hover, disabled },
    radius,
  } = theme.branded.buttons[variant];
  const basic = css`
    border-radius: ${radius};
    background-color: ${inverted ? base.inverted.background : base.background};
    color: ${inverted ? base.inverted.color : base.color};
    border: 1px solid ${inverted ? base.inverted.color : "transparent"};
    &:hover {
      color: ${inverted ? hover.inverted.color : hover.color};
      background-color: ${inverted ? hover.inverted.background : hover.background};
      border: 1px solid ${inverted ? hover.inverted.color : "transparent"};
    }
    &[disabled] {
      cursor: not-allowed;
      color: ${inverted ? disabled.inverted.color : disabled.color};
      background-color: ${inverted ? disabled.inverted.background : disabled.background};
    }
  `;

  return css`
    ${basic}
  `;
});
