import { Children, FC, ReactElement, ReactNode } from "react";
import { StyledButton, StyledButtonText } from "./styled.button";
import { IIconProps } from "../../Icon";
export interface IButtonProps {
  /** React Node to be rendered as button inner text*/
  children?: ReactNode;
  /** Visual type of the button */
  variant: ButtonVariantEnums;
  /** Visual type of the button */
  inline?: boolean;
  /** Inverts Color and Background IColor */
  inverted: boolean;
  /** Function to be called on click event */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** type of button element*/
  type: "submit" | "reset" | "button";
  /** disabled state of the button*/
  disabled?: boolean;
  /** */
  icon?: ReactElement<FC<IIconProps>>;
}
export enum ButtonVariantEnums {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

/**
 * Themeable Buttons.
 *
 *
 */
export const Button: FC<IButtonProps> = (props: IButtonProps) => {
  return (
    <>
      <StyledButton {...props}>
        {props.icon}
        {Children.count(props.children) > 0 && (
          <StyledButtonText icon={props.icon}>{props.children}</StyledButtonText>
        )}
      </StyledButton>
    </>
  );
};
