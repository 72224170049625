import { useTranslation } from "react-i18next";
import { useDialogs } from "./useDialogs";
import { CustomerState, IDialogKeyEnums, ILocalState } from "../../@types/index.d";
import { ClientsList, ClientsListFooter } from "../../containers/ClientSelector";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
// import { useDialogs } from "@canei/app-components";

type TUseClientSelectorUI = () => { show: () => void; hide: () => void };
export const useClientSelectorUI: TUseClientSelectorUI = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { t } = useTranslation();
  const dialogs = useDialogs();

  return {
    show: (): void => {
      if (currentUser.state === CustomerState.READY) {
        dialogs.open({
          dialogKey: IDialogKeyEnums.CLIENT_MANAGEMENT_DIALOG,
          closable: currentUser?.selectedClient.client_id !== null,

          title: t("dialogs.client_management.title"),
          content: <ClientsList />,
          footer: <ClientsListFooter />,
        });
      }
    },
    hide: (): void => {
      dialogs.hide(IDialogKeyEnums.CLIENT_MANAGEMENT_DIALOG);
    },
  };
};
