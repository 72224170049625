import { FC, RefObject, useContext } from "react";
import { StyledUploadOptionItem, StyledUploadOptions } from "./styled.uploaders";
import { P } from "../../components/Typography";
import {
  EUploadersActionTypes,
  IUploadMethods,
  UploadersContext,
  UploadersDispatch,
  uploadersSelectDatevTestID,
  uploadersSelectManualTestID,
  uploadersSelectXlsTestID,
} from "./index";
import { IDialogKeyEnums, ILocalState } from "../../@types/index.d";
import { Trans, useTranslation } from "react-i18next";
import { useDialogs } from "../../_lib/hooks";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

export interface ISelectUploadMethod {
  /**
   * Div element to be scrolled after selection of Upload method
   */
  forwardRef?: RefObject<HTMLDivElement> | null;
}

export const SelectUploadMethod: FC<ISelectUploadMethod> = ({ forwardRef }) => {
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const dialogs = useDialogs();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const { t } = useTranslation();

  const showFirstUploadWarning = (): void => {
    const scrollToUploadForm = (): void => {
      window.setTimeout(handleScrollToRef, 1);
      sessionStorage.setItem(IDialogKeyEnums.PRACTICAL_TIPS, "shown");
    };

    if (
      currentUser.clients.length < 2 &&
      sessionStorage.getItem(IDialogKeyEnums.PRACTICAL_TIPS) !== "shown"
    ) {
      dialogs.open({
        dialogKey: IDialogKeyEnums.PRACTICAL_TIPS,
        closable: true,
        onClose: scrollToUploadForm,
        title: t("practical_tips.note_before_first_upload.title"),
        content: (
          <>
            <Trans i18nKey="practical_tips.note_before_first_upload.text" />
          </>
        ),
        flexible: false,
      });
    } else {
      scrollToUploadForm();
    }
  };
  const handleScrollToRef = (): void => {
    forwardRef?.current &&
      window.scrollTo({ left: 0, top: forwardRef.current.offsetTop, behavior: "smooth" });
  };

  const handleSelectUploadDatev = (): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_UPLOADERS_METHOD,
      payload: { uploadMethod: IUploadMethods.DATEV },
    });
    showFirstUploadWarning();
  };

  const handleSelectUploadExcel = (): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_UPLOADERS_METHOD,
      payload: { uploadMethod: IUploadMethods.EXCEL },
    });
    showFirstUploadWarning();
  };
  const handleSelectUploadManual = (): void => {
    uploadersDispatch({
      type: EUploadersActionTypes.SET_UPLOADERS_METHOD,
      payload: { uploadMethod: IUploadMethods.MANUAL },
    });
    showFirstUploadWarning();
  };
  return (
    <StyledUploadOptions>
      <StyledUploadOptionItem
        onClick={handleSelectUploadDatev}
        selected={uploadersContext.uploadMethod === IUploadMethods.DATEV}
        data-test-id={uploadersSelectDatevTestID}
      >
        <div className="title">{t("setup.upload_form_1.options.datev.headline")}</div>
        <div className="info">
          <P>{t("setup.upload_form_1.options.datev.subline")}</P>
        </div>
      </StyledUploadOptionItem>
      <div className="separator" data-or={"oder"} />
      <StyledUploadOptionItem
        onClick={handleSelectUploadExcel}
        selected={uploadersContext.uploadMethod === IUploadMethods.EXCEL}
        data-test-id={uploadersSelectXlsTestID}
      >
        <div className="title">{t("setup.upload_form_1.options.excel.headline")}</div>
        <div className="info">
          <P>{t("setup.upload_form_1.options.excel.subline")}</P>
        </div>
      </StyledUploadOptionItem>
      <div className="separator" data-or={"oder"} />
      <StyledUploadOptionItem
        onClick={handleSelectUploadManual}
        selected={uploadersContext.uploadMethod === IUploadMethods.MANUAL}
        data-test-id={uploadersSelectManualTestID}
      >
        <div className="title">{t("setup.upload_form_1.options.manual.headline")}</div>
        <div className="info">
          <P>{t("setup.upload_form_1.options.manual.subline")}</P>
        </div>
      </StyledUploadOptionItem>
    </StyledUploadOptions>
  );
};
