import { ISortType } from "../hooks";

export type ICompareTwoStrings = (first: string, second: string, sort: ISortType) => -1 | 0 | 1;

/**
 * Helper function for Array.prototype.sort. returns -1,0,1 according to defined sort type
 *
 * @param first first text to be compared to
 *
 * @param second second text to be compared to
 *
 * @param sort Type of sort
 *
 * @returns number -1 or 0 or 1
 */
export const compareTwoStrings: ICompareTwoStrings = (first, second, sort) => {
  if (first === second) return 0;
  if (sort === ISortType.ASC) {
    return first < second ? -1 : 1;
  }
  if (sort === ISortType.DESC) {
    return first < second ? 1 : -1;
  }
  return 0;
};
