import { FC } from "react";
import { StyledKpiMatrixList } from "./styled.kpi-matrix-list";
import { IKPIMatrixCircleProps, KPIMatrixCircleChart } from "../Charts";

import { EPrivatePathName, IDialogKeyEnums, IKpiData } from "../../@types/index.d";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useClients, useDialogs, useSelectedClientData } from "../../_lib/hooks";
import { EFormatCurrencyDivision, EFormatNumberStyle, formatNumber } from "../../_lib/common";
import { IKpiMatrixProps } from ".";

interface IKpiMatrixElementProps extends Partial<IKpiMatrixProps> {
  kpi: IKpiData;
  forKpiDialog?: boolean;
}
export const KpiMatrixListItem: FC<IKpiMatrixElementProps> = ({
  kpi,
  forKpiDialog,
  kpiListToDelete,
  setKpiListToDelete,
}) => {
  const history = useHistory();
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const location = useLocation();
  const { upsert } = useClients();
  const selectedClientData = useSelectedClientData();
  const isDashboardsPage = location.pathname === EPrivatePathName.DASHBOARD;

  const circle: IKPIMatrixCircleProps = {
    actualValue: kpi.months[0].actual,
    benchmarkValue: kpi.benchmark,
    unitSignature: "%",
  };

  const kpiListFromLocales: Record<string, string> = t(`datastore:kpis`, {
    returnObjects: true,
  });
  const kpiTitle =
    kpi && kpi.value && kpiListFromLocales && kpiListFromLocales[kpi.value]
      ? kpiListFromLocales[kpi.value]
      : "";

  const handleSelectKpi = (): void => {
    if (forKpiDialog) {
      dialogs.close(IDialogKeyEnums.SIMPLE);
      history.push(`${EPrivatePathName.KPI}/${kpi.value}`);
    }
    history.push(`${EPrivatePathName.KPI}/${kpi.value}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // to render potential value under the graph and to delete - in dashboard page
  const infoAndDeleteBlock = (): JSX.Element => {
    const removeKpi = (): void => {
      if (!selectedClientData) return;
      // to be able to put kpi id to the state so that it can be read via parent comp
      setKpiListToDelete &&
        setKpiListToDelete((prevState) => ({
          ...prevState,
          kpiIds: [...prevState.kpiIds, kpi.value],
        }));

      const { dashboard_kpis } = selectedClientData.customization;

      // to be sure not to render removed kpi graph, in this moment dashboard kpis may not be updated, so we also check kpiListToDelete state
      const newKpiList = dashboard_kpis?.filter(
        (item) => item !== kpi.value && !kpiListToDelete?.kpiIds.includes(item)
      );

      newKpiList &&
        upsert({
          ...selectedClientData,
          customization: {
            ...selectedClientData.customization,
            dashboard_kpis: newKpiList,
          },
        });
    };
    return (
      <>
        <span>
          {formatNumber(kpi.months[0].potential || 0, {
            maximumFractionDigits: 0,
            style: EFormatNumberStyle.decimal,
            divideTo: EFormatCurrencyDivision.THOUSAND,
          })}{" "}
          {t("misc.in_thousand")}
        </span>
        <p onClick={removeKpi}>Entfernen</p>
      </>
    );
  };

  // to remove the kpi graph from dashboard immediately if the user clicks "entfernen", meanwhule delete fn runs in the background
  if (kpiListToDelete?.kpiIds.includes(kpi.value)) return <></>;

  return (
    <StyledKpiMatrixList.Wrapper>
      <StyledKpiMatrixList.Item onClick={handleSelectKpi}>
        <StyledKpiMatrixList.ItemCaption>{kpiTitle}</StyledKpiMatrixList.ItemCaption>
        <KPIMatrixCircleChart {...circle} />
      </StyledKpiMatrixList.Item>
      {isDashboardsPage && !forKpiDialog && (
        <StyledKpiMatrixList.PotentialLink>
          {infoAndDeleteBlock()}
        </StyledKpiMatrixList.PotentialLink>
      )}
    </StyledKpiMatrixList.Wrapper>
  );
};
