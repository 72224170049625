import { FC } from "react";
import styled, { keyframes } from "styled-components/macro";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const StyledFadeIn = styled.div`
  animation: ${fadeIn} 1s forwards;
  animation-iteration-count: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
`;
export const FadeIn: FC = ({ children }) => {
  return <StyledFadeIn>{children}</StyledFadeIn>;
};
