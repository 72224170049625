import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import {
  GET_CUSTOMIZATION_METADATA,
  IGetCustomizationRetVal,
  ISetCustomizationRetVal,
  SET_CUSTOMIZATION_METADATA,
} from "./customization-queries";
import { IThemeOptionsAction, ThemeStateActionTypes } from "../../store/reducers";
import { CustomizationActionTypes, ICustomizationAction } from "../../store/reducers/customization";
import { useState } from "react";
import {
  EAssetType,
  IAsset,
  IBrandedAssets,
  IBrandedColors,
  ICustomizationListItem,
  ILocalState,
} from "../../../@types/index.d";
import {
  ECustomizationListType,
  useCustomizationHelper,
} from "../../../views/Private/Customization/useCustomizationHelper";
import { cloneDeep, isEqual, omit } from "lodash";
import { useRelationLinks } from "../useRelationLinks";
import { useAssets } from "../useAssets";
import { useCustomer } from "../useCustomer";
import initialData from "../../store/initialState";
import { useAssetsHelper } from "../../../views/Private/Customization/Assets/useAssetsHelper";
import { useClient } from "../useClient";

interface ISetCustomizationParams {
  data: ICustomizationListItem;
}

interface IUsePlanDetailsConfigRetVal {
  getCustomizations: () => void;
  setCustomization: (params: ISetCustomizationParams, active?: boolean) => Promise<void>;
  setCustomizationActive: (
    accountId: string,
    customizationList: ICustomizationListItem[]
  ) => Promise<void>;
  updateAssets: () => Promise<void>;
  customizationLoading: boolean;
}

export const useCustomization = (): IUsePlanDetailsConfigRetVal => {
  const [customizationLoading, setCustomizationLoading] = useState<boolean>(false);
  const dispatchBranded = useDispatch<Dispatch<IThemeOptionsAction>>();
  const dispatchCustomization = useDispatch<Dispatch<ICustomizationAction>>();
  const apolloClient = useApolloClient();
  const { cleanNullOrUndefinedArray, cleanNullOrUndefinedObject, changeSelectedAccount } =
    useCustomizationHelper();
  const { updateCustomer } = useCustomer();
  const { updateClient } = useClient();
  const { getAssetRelationLinks } = useRelationLinks();
  const { getAssetSrc } = useAssets();
  const { resetAssetForm } = useAssetsHelper();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const customizationList = useSelector(
    ({ customization: { customizationList } }: ILocalState) => customizationList,
    isEqual
  );
  const selectedAccount = useSelector(
    ({ customization: { selectedAccount } }: ILocalState) => selectedAccount,
    isEqual
  );

  // get customization metadata from CRM
  const getCustomizations = (): void => {
    setCustomizationLoading(true);
    apolloClient
      .query<IGetCustomizationRetVal>({
        query: GET_CUSTOMIZATION_METADATA,
        variables: {
          account_ids: [
            currentUser.appUser.customer_id,
            ...currentUser.clients.map((client) => client.client_id),
          ],
        },
        fetchPolicy: "no-cache",
      })
      .then(async ({ data }) => {
        if (data && data.result) {
          const customizationArrCopy = cloneDeep(data.result) as ICustomizationListItem[];
          for (const customization of customizationArrCopy) {
            const relationList = getAssetRelationLinks(customization.account_id);
            if (!relationList.length) continue;
            for (const link of relationList) {
              const src = await getAssetSrc(link);
              if (!customization.assets) customization.assets = {} as IBrandedAssets;
              customization.assets[link.rel.valueOf() as keyof IBrandedAssets] = {
                type: EAssetType[link.rel.valueOf().toUpperCase() as keyof typeof EAssetType],
                href: src,
              } as IAsset;
            }
          }
          // clean out null values
          const customizationArr = cleanNullOrUndefinedArray(customizationArrCopy);

          // dispatch customization array to redux
          dispatchCustomization({
            type: CustomizationActionTypes.SET_CUSTOMIZATION_LIST,
            payload: {
              customizationList: customizationArrCopy,
            },
          });
          const active = customizationArr.find((item) => item.active);

          if (active) {
            changeSelectedAccount(active.account_id);
            const colors: IBrandedColors = { ...active.colors };
            dispatchBranded({
              type: ThemeStateActionTypes.SET_BRANDED_COLORS,
              payload: {
                colors,
              },
            });
            const assets = { ...active.assets } as IBrandedAssets;
            dispatchBranded({
              type: ThemeStateActionTypes.SET_BRANDED_ASSETS,
              payload: {
                assets,
              },
            });
          }
        }
      })
      .finally(() => setCustomizationLoading(false));
  };

  // set customization metadata in CRM
  const setCustomization = async (
    params: ISetCustomizationParams,
    active?: boolean
  ): Promise<void> => {
    !active && setCustomizationLoading(true);
    apolloClient
      .mutate<ISetCustomizationRetVal>({
        mutation: SET_CUSTOMIZATION_METADATA,
        variables: {
          ...params,
        },
        fetchPolicy: "no-cache",
      })
      .then(async ({ data }) => {
        if (data && data.result) {
          const customizationCopy = cloneDeep(data.result) as ICustomizationListItem;
          const relationList = getAssetRelationLinks(customizationCopy.account_id);

          if (relationList.length)
            for (const link of relationList) {
              const src = await getAssetSrc(link);
              if (!customizationCopy.assets) customizationCopy.assets = {} as IBrandedAssets;
              customizationCopy.assets[link.rel.valueOf() as keyof IBrandedAssets] = {
                type: EAssetType[link.rel.valueOf().toUpperCase() as keyof typeof EAssetType],
                href: src,
              } as IAsset;
            }

          // clean out null values
          const customization = cleanNullOrUndefinedObject(
            customizationCopy
          ) as ICustomizationListItem;

          // update customization array to redux
          dispatchCustomization({
            type: CustomizationActionTypes.UPDATE_CUSTOMIZATION_LIST,
            payload: {
              customization: customization,
            },
          });

          if (customization.active) {
            changeSelectedAccount(customization.account_id);
            const colors: IBrandedColors = { ...customization.colors };
            dispatchBranded({
              type: ThemeStateActionTypes.SET_BRANDED_COLORS,
              payload: {
                colors,
              },
            });
            const assets = { ...customization.assets } as IBrandedAssets;
            dispatchBranded({
              type: ThemeStateActionTypes.SET_BRANDED_ASSETS,
              payload: {
                assets,
              },
            });
          }
        }
      })
      .finally(() => !active && setCustomizationLoading(false));
  };

  // function to change active metadata
  const setCustomizationActive = async (
    accountId: string,
    customizationList: ICustomizationListItem[]
  ): Promise<void> => {
    setCustomizationLoading(true);
    const customizationListCopy = customizationList.map((customization) => {
      const customizationCopy = cloneDeep(customization);
      if (customizationCopy.account_id === accountId) customizationCopy.active = true;
      else customizationCopy.active = false;
      return customizationCopy;
    });

    const promises = customizationListCopy.map(async (customization) => {
      return await setCustomization({ data: omit(customization, ["assets"]) }, true);
    });
    await Promise.all(promises);
    setCustomizationLoading(false);
  };

  // function to update assets after upload or delete
  const updateAssets = async (): Promise<void> => {
    setCustomizationLoading(true);
    let account;
    switch (selectedAccount.type) {
      case ECustomizationListType.CLIENT:
        account = await updateClient(selectedAccount.accountId);
        break;
      case ECustomizationListType.CUSTOMER:
        account = await updateCustomer();
        break;
    }

    // find account customization
    const customization = cloneDeep(
      customizationList.find((c) => c.account_id === selectedAccount.accountId)
    );
    if (!customization || !account || !account.links) return;

    // reset customization assets
    customization.assets = cloneDeep(initialData.themeOptions.branded.assets);

    // filter asset links
    const relationList = account.links.filter((link) => {
      // turn link.rel in to a key of EAssetType enum
      const key = link.rel.valueOf().toUpperCase() as keyof typeof EAssetType;
      return EAssetType[key];
    });

    // add asset for every link found
    for (const link of relationList) {
      const src = await getAssetSrc(link);
      if (!customization.assets) customization.assets = {} as IBrandedAssets;
      customization.assets[link.rel.valueOf() as keyof IBrandedAssets] = {
        type: EAssetType[link.rel.valueOf().toUpperCase() as keyof typeof EAssetType],
        href: src,
      } as IAsset;
    }

    if (customization.active) {
      const assets = { ...customization.assets } as IBrandedAssets;
      dispatchBranded({
        type: ThemeStateActionTypes.SET_BRANDED_ASSETS,
        payload: {
          assets,
        },
      });
    }

    // update customization array to redux
    dispatchCustomization({
      type: CustomizationActionTypes.UPDATE_CUSTOMIZATION_LIST,
      payload: {
        customization: cleanNullOrUndefinedObject(customization),
      },
    });

    // stop loading and reset form
    setCustomizationLoading(false);
    resetAssetForm();
  };

  return {
    getCustomizations,
    setCustomization,
    setCustomizationActive,
    updateAssets,
    customizationLoading,
  };
};
