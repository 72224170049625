import { FC, useState } from "react";
import { StyledVideo } from "./styled.video";
import { LoaderTypeEnums, LoadingIndicator } from "../LoadingIndicator";

//assets_store/media/de/client/videos/intro.mp4
export interface IVideoProps {
  poster?: string;
  source: IVideoSourceAttr | IVideoSourceAttr[];
}

export interface IVideoSourceAttr {
  src: string;
  type?: IVideoTypes;
}

export enum IVideoTypes {
  MP4 = "video/mp4",
}

export const Video: FC<IVideoProps> = ({ source, poster }) => {
  const sources: IVideoSourceAttr[] = Array.isArray(source) ? source : [source];
  const [videoReady, setVideoReady] = useState(false);
  const [videoPlayedOnce, setVideoPlayedOnce] = useState(false);
  const [startVideo, setStartVideo] = useState(false);
  const handleVideoReady = (): void => {
    if (!videoReady) setVideoReady(true);
  };
  const handleVideoPlayed = (): void => {
    if (!videoPlayedOnce) setVideoPlayedOnce(true);
  };
  const handleStartPlay = (): void => {
    setStartVideo(true);
  };
  const canPlay = poster ? videoReady && videoPlayedOnce : true;
  const loading = startVideo && !canPlay;
  return (
    <StyledVideo.Wrapper>
      {loading && <LoadingIndicator type={LoaderTypeEnums.PAGE} dotColor="currentColor" />}

      {startVideo ? (
        <StyledVideo.Media
          controls={true}
          controlsList="nodownload"
          preload="false"
          poster={poster}
          onCanPlayThrough={handleVideoReady}
          isReady={canPlay}
          onPlay={handleVideoPlayed}
          autoPlay={true}
        >
          {sources.map(({ src, type = IVideoTypes.MP4 }, index) => (
            <source src={src} type={type} key={index} />
          ))}
        </StyledVideo.Media>
      ) : (
        <StyledVideo.PosterImage src={poster} onClick={handleStartPlay} />
      )}
    </StyledVideo.Wrapper>
  );
};
