import styled, { css } from "styled-components/macro";
import { useResponsive } from "../../_lib/hooks/useResponsive";

const Container = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      `,
    },
  });
});
const Wrapper = styled.div(({ theme }) => {
  return css`
    padding: ${theme.rem(1)};
    width: ${theme.rem(20)};
    justify-self: center;
  `;
});
const Item = styled.div(({ theme }) => {
  return css`
    cursor: pointer;
  `;
});
const ItemCaption = styled.div(({ theme }) => {
  return css`
    text-align: center;
    height: ${theme.rem(2.5)};
  `;
});
const PotentialLink = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1)};
    cursor: pointer;
    p {
      font-size: ${theme.rem(0.8)};
      font-style: italic;
    }
  `;
});

export const StyledKpiMatrixList = {
  Container,
  Wrapper,
  Item,
  ItemCaption,
  PotentialLink,
};
