import { ActionCreatorWithPayload, createAction, createReducer, current } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import {
  EAdditionalInput,
  EPDTablesRows,
  EPlanningSubPage,
  IPlan,
  IPlanCalculatedValue,
  IPlanningContext,
  TablesSubPage,
} from "../../../@types/index.d";
import { getPDTablesArray, getPlanYears } from "../../hooks/planning/planningCommon";
import initialData from "../initialState";

export enum IPlanningActionTypes {
  SET_PLANS = "SET_PLANS",
  UPDATE_PLANS = "UPDATE_PLANS",
  SET_SELECTED_PLAN = "SET_SELECTED_PLAN",
  SET_TEMPLATES = "SET_TEMPLATES",
  SET_SELECTED_PLANNING_PAGE = "SET_SELECTED_PLANNING_PAGE", // CREATE - DETAILS - PREVIEW - OVERVIEW
  SET_DETAILS_SUB_PAGE = "SET_DETAILS_SUB_PAGE", // revenue - personnel - costs - working_capital - capex - financing - corrections
  SET_TABLES_SUB_PAGE = "SET_TABLES_SUB_PAGE", // selectedDetailsSubPage (without corrections) - balance - profit_and_loss - other
  SET_DETAILS_YEAR = "SET_DETAILS_YEAR",
  SET_SELECTED_LINE_ID = "SET_SELECTED_LINE_ID",
  SET_DATA_STORE = "SET_DATA_STORE", // set details data store
  SET_TABLES_DATA_SET = "SET_TABLES_DATA_SET", // set data set for table cells
  NUKE_DETAILS_DATA = "NUKE_DETAILS_DATA", // reset details data
  SET_ANIMATION_DIRECTION = "SET_ANIMATION_DIRECTION",
  EDIT_CORRECTIONS = "EDIT_CORRECTIONS",
  UPDATE_LINES_SIMPLE = "UPDATE_LINES_SIMPLE",
  EDIT_REVENUE_LINES = "EDIT_REVENUE_LINES",
  EDIT_PERSONNEL_LINES = "EDIT_PERSONNEL_LINES",
  EDIT_COSTS_LINES = "EDIT_COSTS_LINES",
  EDIT_KPIS = "EDIT_KPIS",
  EDIT_ADDITIONAL_INPUT = "EDIT_ADDITIONAL_INPUT",
  EDIT_SINGLE_PAYMENTS = "EDIT_SINGLE_PAYMENTS",
  ADD_CELL_TO_IGNORE = "ADD_CELL_TO_IGNORE",
  SET_FUTURE_MONTHS = "SET_FUTURE_MONTHS",
  SET_PLANNING_LOADING_STATUS = "SET_PLANNING_LOADING_STATUS",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  IPlanningActionTypes,
  ActionCreatorWithPayload<Partial<IPlanningPayload>, IPlanningActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(IPlanningActionTypes) as Array<keyof typeof IPlanningActionTypes>) {
  actionFunctions[IPlanningActionTypes[key]] = createAction(IPlanningActionTypes[key]);
}

export interface IPlanningPayload extends IPlanningContext {
  editTemplates: IEditTemplatesObj[];
  editAdditionalInput: IEditAddlInputObj;
  updatePlans: IPlan;
  updateLines: IUpdateLinesSimple;
}

export interface IUpdateLinesSimple {
  selectedDetailsSubPage: EPlanningSubPage;
  lineId: string;
  key: string;
  value: number | string | boolean;
}

export interface IEditAddlInputObj {
  type: EAdditionalInput;
  year: number;
  value: number;
  lineId?: string;
}

export interface IEditTemplatesObj {
  selectedPage?: EPlanningSubPage;
  rowType?: EPDTablesRows;
  lineInd?: number;
  monthInd?: number;
  yearInd?: number;
  codeObjArr: ICodeObjArr[];
}

interface ICodeObjArr {
  codeObjInd?: number;
  codeObj: IPlanCalculatedValue;
}
export interface IPlanningAction {
  type: IPlanningActionTypes;
  payload?: Partial<IPlanningPayload>;
}

const planning = createReducer(initialData.planning, (builder) => {
  builder
    .addCase(actionFunctions[IPlanningActionTypes.SET_PLANS], (state, action) => {
      if (!action.payload?.plans) {
        state.plans = initialData.planning.plans;
        return state;
      }
      state.plans = action.payload.plans;

      // to be able to stop loading spinner
      if (state.planningLoadingStatus) {
        state.planningLoadingStatus = false;
      }
    })
    .addCase(actionFunctions[IPlanningActionTypes.UPDATE_PLANS], (state, action) => {
      if (!action.payload.updatePlans) return state;

      const currentState = cloneDeep(current(state));
      if (!currentState || !currentState.plans || !state.plans) return state;
      const editedPlanIndex = currentState.plans.findIndex(
        (p) => p.plan_id === action.payload.updatePlans?.plan_id
      );

      // to set 5 keys instead of the whole plan
      const { plan_id, active, title, description, created_at } = action.payload.updatePlans;
      const editedPlan = { plan_id, active, title, description, created_at };
      // update edited plan in state.plans
      state.plans[editedPlanIndex] = editedPlan as IPlan;
      // reset templates
      state.templates = action.payload.updatePlans.templates;
      // reset planYears
      const planYears = getPlanYears(action.payload.updatePlans.calculated);

      // set selectedDetailsYear
      let selectedDetailsYear = currentState.selectedDetailsYear;
      if (!selectedDetailsYear) {
        selectedDetailsYear = Object.keys(planYears)[0];
        state.selectedDetailsYear = selectedDetailsYear;
      }

      // if there is a selectedDetailsSubPage and selectedDetailsYear reset tables config
      if (currentState.selectedDetailsSubPage) {
        const PDTablesArrayTest = getPDTablesArray(
          currentState.selectedDetailsSubPage,
          action.payload.updatePlans.templates,
          action.payload.updatePlans,
          currentState.dataStore.detailsConfig
        );

        state.selectedTablesSubPage = Object.keys(
          PDTablesArrayTest[selectedDetailsYear]
        )[0] as TablesSubPage;
        state.PDTablesConfig = PDTablesArrayTest;
      }

      // save new plan
      state.selectedPlan = action.payload.updatePlans;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_SELECTED_PLAN], (state, action) => {
      if (!action.payload?.selectedPlan) return state;

      // clone current state
      const currentState = cloneDeep(current(state));
      // reset templates
      state.templates = action.payload.selectedPlan.templates;
      const planYears = getPlanYears(action.payload.selectedPlan.calculated);
      // reset planYears
      state.planYears = planYears;

      // set selectedDetailsYear
      let selectedDetailsYear = currentState.selectedDetailsYear;
      if (!selectedDetailsYear) {
        selectedDetailsYear = Object.keys(planYears)[0];
        state.selectedDetailsYear = selectedDetailsYear;
      }

      // if there is a selectedDetailsSubPage and selectedDetailsYear reset tables config
      // if (currentState.selectedDetailsSubPage) {
      //   const PDTablesArrayTest = getPDTablesArray(
      //     currentState.selectedDetailsSubPage,
      //     action.payload.selectedPlan.templates,
      //     action.payload.selectedPlan,
      //     currentState.dataStore.detailsConfig
      //   );
      //   state.selectedTablesSubPage = Object.keys(
      //     PDTablesArrayTest[selectedDetailsYear]
      //   )[0] as TablesSubPage;
      //   state.PDTablesConfig = PDTablesArrayTest;
      // }

      // save new plan
      state.selectedPlan = action.payload.selectedPlan;
      // to be able to stop loading spinner

      if (state.planningLoadingStatus) {
        state.planningLoadingStatus = false;
      }
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_TEMPLATES], (state, action) => {
      if (!action.payload?.templates) return state;
      // clone current state
      const currentState = cloneDeep(current(state));

      // if there is a selectedDetailsSubPage and selectedDetailsYear reset tables config
      if (currentState.selectedDetailsSubPage && currentState.selectedPlan) {
        const PDTablesArrayTest = getPDTablesArray(
          currentState.selectedDetailsSubPage,
          action.payload.templates,
          currentState.selectedPlan,
          currentState.dataStore.detailsConfig
        );
        // trigger reset of tables
        state.PDTablesConfig = PDTablesArrayTest;
      }

      // set templates
      state.templates = action.payload.templates;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_SELECTED_PLANNING_PAGE], (state, action) => {
      if (!action.payload?.selectedPlanningPage) return state;
      state.selectedPlanningPage = action.payload.selectedPlanningPage;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_DETAILS_SUB_PAGE], (state, action) => {
      if (!action.payload?.selectedDetailsSubPage) return state;

      // clone current state
      let currentState = cloneDeep(current(state));
      // reset templates
      if (currentState.selectedPlan) state.templates = currentState.selectedPlan.templates;
      currentState = cloneDeep(current(state));
      // get planYears
      const planYears =
        currentState.selectedPlan && getPlanYears(currentState.selectedPlan.calculated);

      // reset selectedDetailsYear
      const selectedDetailsYear = planYears && Object.keys(planYears)[0];
      state.selectedDetailsYear = selectedDetailsYear;

      // reset tables config
      if (planYears && currentState.selectedPlan && selectedDetailsYear) {
        const PDTablesArrayTest = getPDTablesArray(
          action.payload.selectedDetailsSubPage,
          currentState.templates,
          currentState.selectedPlan,
          currentState.dataStore.detailsConfig
        );
        state.selectedTablesSubPage = Object.keys(
          PDTablesArrayTest[selectedDetailsYear]
        )[0] as TablesSubPage;
        state.PDTablesConfig = PDTablesArrayTest;
      }

      // to set selectedLineId initially
      if (
        !currentState.selectedLineId &&
        currentState.templates[action.payload.selectedDetailsSubPage]?.lines &&
        currentState.templates[action.payload.selectedDetailsSubPage]?.lines.length
      ) {
        const selectedDetailsYearAsNum =
          selectedDetailsYear && parseInt(selectedDetailsYear?.split(" / ")[0]);
        // filter lines by selected year
        const linesFilteredByYear = currentState.templates[
          action.payload.selectedDetailsSubPage
        ].lines.filter((line) => line.year === selectedDetailsYearAsNum);
        // if none, select the first one
        if (!linesFilteredByYear.length)
          state.selectedLineId =
            currentState.templates[action.payload.selectedDetailsSubPage].lines[0].line_id;
        // if there is lines in selected year select the first one
        else state.selectedLineId = linesFilteredByYear[0].line_id;
      }

      // save new selectedDetailsSubPage
      state.selectedDetailsSubPage = action.payload.selectedDetailsSubPage;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_TABLES_SUB_PAGE], (state, action) => {
      if (!action.payload?.selectedTablesSubPage) return state;
      state.selectedTablesSubPage = action.payload.selectedTablesSubPage;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_DETAILS_YEAR], (state, action) => {
      if (!action.payload?.selectedDetailsYear) return state;
      state.selectedDetailsYear = action.payload.selectedDetailsYear;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_SELECTED_LINE_ID], (state, action) => {
      // if (!action.payload?.selectedLineId) return state;
      state.selectedLineId = action.payload.selectedLineId;
    })

    .addCase(actionFunctions[IPlanningActionTypes.SET_DATA_STORE], (state, action) => {
      if (!action.payload?.dataStore) return state;
      state.dataStore = action.payload.dataStore;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_TABLES_DATA_SET], (state, action) => {
      if (!action.payload?.PDTablesData) return state;
      state.PDTablesData = action.payload.PDTablesData;
    })

    .addCase(actionFunctions[IPlanningActionTypes.NUKE_DETAILS_DATA], (state, action) => {
      state.selectedDetailsSubPage = initialData.planning.selectedDetailsSubPage;
      state.selectedDetailsYear = initialData.planning.selectedDetailsYear;
      state.selectedLineId = initialData.planning.selectedLineId;
      state.PDTablesConfig = initialData.planning.PDTablesConfig;
      state.PDTablesData = initialData.planning.PDTablesData;
      state.templates = initialData.planning.templates;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_ANIMATION_DIRECTION], (state, action) => {
      if (!action.payload?.animationDirection) return state;
      state.animationDirection = action.payload.animationDirection;
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_CORRECTIONS], (state, action) => {
      if (!action.payload.editTemplates) return state;
      action.payload.editTemplates.forEach((editCorrObj) => {
        const selectedPage = editCorrObj.selectedPage;
        const monthInd = editCorrObj.monthInd;
        if (monthInd === undefined || !selectedPage) return state;
        const codeObjArr = [...editCorrObj.codeObjArr];
        codeObjArr.forEach((item) => {
          if (item.codeObjInd === undefined) return;
          state.templates[selectedPage].corrections[monthInd].data[item.codeObjInd] = item.codeObj;
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_REVENUE_LINES], (state, action) => {
      if (!action.payload.editTemplates) return state;
      action.payload.editTemplates.forEach((editLinesObj) => {
        const selectedPage = editLinesObj.selectedPage;
        const lineInd = editLinesObj.lineInd;
        const monthInd = editLinesObj.monthInd;
        if (lineInd === undefined || monthInd === undefined || !selectedPage) return state;
        const codeObjArr = [...editLinesObj.codeObjArr];

        codeObjArr.forEach((item) => {
          if (item.codeObjInd === undefined) return;
          state.templates[selectedPage].lines[lineInd].values[monthInd].data[item.codeObjInd] =
            item.codeObj;
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_PERSONNEL_LINES], (state, action) => {
      if (!action.payload.editTemplates) return state;
      action.payload.editTemplates.forEach((editLinesObj) => {
        const lineInd = editLinesObj.lineInd;
        const monthInd = editLinesObj.monthInd;
        const rowType = editLinesObj.rowType;
        if (lineInd === undefined || monthInd === undefined || !rowType) return state;
        const codeObjArr = [...editLinesObj.codeObjArr];

        codeObjArr.forEach((item) => {
          if (item.codeObj.balance === undefined || item.codeObj.balance === null) return state;
          switch (rowType) {
            case EPDTablesRows.PERSONNEL_LINES:
              state.templates.personnel.lines[lineInd].monthly_gross[monthInd].balance =
                item.codeObj.balance;
              break;
            case EPDTablesRows.PERSONNEL_LINES_EMPLOYEES:
              state.templates.personnel.lines[lineInd].monthly_number_employees[monthInd].value =
                item.codeObj.balance;
              break;

            default:
              break;
          }
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_COSTS_LINES], (state, action) => {
      if (!action.payload.editTemplates) return state;
      action.payload.editTemplates.forEach((editLinesObj) => {
        const lineInd = editLinesObj.lineInd;
        const monthInd = editLinesObj.monthInd;
        if (lineInd === undefined || monthInd === undefined) return state;
        const codeObjArr = [...editLinesObj.codeObjArr];

        codeObjArr.forEach((item) => {
          if (item.codeObj.balance === undefined || item.codeObj.balance === null) return state;

          state.templates.costs.lines[lineInd].values[monthInd].value = item.codeObj.balance;
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.UPDATE_LINES_SIMPLE], (state, action) => {
      if (!action.payload.updateLines) return state;
      const { selectedDetailsSubPage, lineId, key, value } = action.payload.updateLines;
      const lineInd = state.templates[selectedDetailsSubPage].lines.findIndex(
        ({ line_id }) => line_id === lineId
      );
      state.templates[selectedDetailsSubPage].lines[lineInd][key] = value;
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_KPIS], (state, action) => {
      if (!action.payload.editTemplates) return state;

      action.payload.editTemplates.forEach((editKpiObj) => {
        const selectedPage = editKpiObj.selectedPage;
        const monthInd = editKpiObj.monthInd;
        if (monthInd === undefined || !selectedPage) return state;
        const codeObjArr = [...editKpiObj.codeObjArr];

        codeObjArr.forEach((item) => {
          if (item.codeObjInd === undefined) return;
          state.templates[selectedPage].kpis[monthInd].data[item.codeObjInd] = item.codeObj;
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_ADDITIONAL_INPUT], (state, action) => {
      if (!action.payload.editAdditionalInput) return state;
      let yearInd: number;
      let lineInd: number;
      switch (action.payload.editAdditionalInput.type) {
        case EAdditionalInput.TAX:
          yearInd = state.templates.corrections.taxes.findIndex(
            (taxYear) => taxYear.year === action.payload.editAdditionalInput?.year
          );
          state.templates.corrections.taxes[yearInd].value =
            action.payload.editAdditionalInput?.value;
          break;
        case EAdditionalInput.SINGLE_PAYMENTS:
          yearInd = state.templates.personnel.one_time_payments.findIndex(
            (SPYear) => SPYear.year === action.payload.editAdditionalInput?.year
          );
          state.templates.personnel.one_time_payments[yearInd].balance =
            action.payload.editAdditionalInput?.value;
          break;
        case EAdditionalInput.NON_WAGE_COSTS:
          yearInd = state.templates.personnel.one_time_payments.findIndex(
            (SPYear) => SPYear.year === action.payload.editAdditionalInput?.year
          );
          lineInd = state.templates.personnel.lines.findIndex(
            (line) => line.line_id === action.payload.editAdditionalInput?.lineId
          );
          state.templates.personnel.lines[lineInd].nonwage_labor_costs[yearInd].value =
            action.payload.editAdditionalInput?.value;
          break;
        case EAdditionalInput.EXTRA_SALARY:
          yearInd = state.templates.personnel.one_time_payments.findIndex(
            (SPYear) => SPYear.year === action.payload.editAdditionalInput?.year
          );
          lineInd = state.templates.personnel.lines.findIndex(
            (line) => line.line_id === action.payload.editAdditionalInput?.lineId
          );
          state.templates.personnel.lines[lineInd].extra_salary[yearInd].value =
            action.payload.editAdditionalInput?.value;
          break;
        case EAdditionalInput.REVENUE_PERCENTAGES:
          lineInd = state.templates.costs.lines.findIndex(
            (line) => line.line_id === action.payload.editAdditionalInput?.lineId
          );
          yearInd = state.templates.costs.lines[lineInd].revenue_percentages?.findIndex(
            (SPYear) => SPYear.year === action.payload.editAdditionalInput?.year
          );
          state.templates.costs.lines[lineInd].revenue_percentages[yearInd].value =
            action.payload.editAdditionalInput?.value;
          break;
        default:
          break;
      }
    })
    .addCase(actionFunctions[IPlanningActionTypes.EDIT_SINGLE_PAYMENTS], (state, action) => {
      if (!action.payload.editTemplates) return state;
      action.payload.editTemplates.forEach((editSinglePayObj) => {
        const selectedPage = editSinglePayObj.selectedPage;
        const yearInd = editSinglePayObj.yearInd;
        if (yearInd === undefined || !selectedPage) return state;
        const codeObjArr = [...editSinglePayObj.codeObjArr];
        codeObjArr.forEach((item) => {
          if (item.codeObjInd === undefined) return;
          state.templates.personnel.one_time_payments[yearInd].values[item.codeObjInd].percentage =
            item.codeObj.balance as number;
          state.templates[selectedPage].one_time_payments[yearInd].values[item.codeObjInd].comment =
            item.codeObj.comment;
        });
      });
    })
    .addCase(actionFunctions[IPlanningActionTypes.ADD_CELL_TO_IGNORE], (state, action) => {
      if (!action.payload?.cellBeingChanged) return state;
      state.cellBeingChanged = action.payload.cellBeingChanged;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_FUTURE_MONTHS], (state, action) => {
      if (action.payload?.futureMonths === undefined) return state;
      state.futureMonths = action.payload.futureMonths;
    })
    .addCase(actionFunctions[IPlanningActionTypes.SET_PLANNING_LOADING_STATUS], (state, action) => {
      if (action.payload?.planningLoadingStatus === undefined) return state;
      state.planningLoadingStatus = action.payload.planningLoadingStatus;
    });
});

export default planning;
