import { gql } from "@apollo/client";

export const GET_CACHED_EVENT_STATE = gql`
  query getTransaction(
    $transactionId: ID!
    $accountId: ID!
    $level: CacheLevel!
    $scope: EventScopes
    $type: EventTypes
  ) {
    event: getCachedTransaction(
      transactionId: $transactionId
      accountId: $accountId
      level: $level
      scope: $scope
      type: $type
    ) {
      status
      action {
        type
        scope
        guid
        covid
        links {
          rel
          href
        }
      }
    }
  }
`;

export const GET_CACHED_EVENTS = gql`
  query getClientsCachedEventsArray($eventsState: [EventsState!]!) {
    eventsStateArray: getClientsCachedEventsArray(eventsState: $eventsState) {
      transactionId
      accountId
      status
      timeOutTimer
    }
  }
`;
