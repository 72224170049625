import { FC } from "react";
import styled, { css } from "styled-components/macro";
import { circleAnimation, svgAnimation } from "./ProgressIndicator";

const StyledIconLoader = styled.svg(() => {
  return css`
    animation: 2s linear infinite ${svgAnimation};
    circle {
      animation: 1.4s ease-in-out infinite both ${circleAnimation};
      display: block;
      fill: transparent;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      transform-origin: 50% 50%;
    }
  `;
});
export const IconLoader: FC = () => {
  return (
    <StyledIconLoader viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" />
    </StyledIconLoader>
  );
};
