import {
  IAccountCodesTree,
  IGlobalCodesTree,
} from "../views/Private/Upload/AllUploads/useUploadsHelper";
import { RelationLink } from "./amplify";

// governing uploads location
export enum EUploadsPage {
  LIST = "LIST",
  ACCOUNT_LINES = "ACCOUNT_LINES",
  MAPPING = "MAPPING",
}

// governing uploads location
export enum EMappingsubPage {
  ACTUAL = "ist_values",
  CALCULATED = "calculated_values",
}

export interface IUploadsContext {
  selectedSusa?: string;
  mappedSusa?: IMappedSusa;
  dataStore?: IUploadsDS;
}

export interface IMappedSusa {
  susa_id: string;
  date: string;
  format: FileUploadFormat;
  client_id: string;
  mapped_at: string;
  data: IMappedSusaData[];
}

export interface IMappedSusaData {
  global_code_id: string;
  account_code: number;
  account_code_complete?: number;
  description: string;
  balance: number;
  starting_balance: number;
  account_code_id?: string;
  value?: number;
}

export interface IUploadsDS {
  accountCodes: [IAccountCodesTree];
  globalCodes: [IGlobalCodesTree];
}

export interface IEnrichedMappedSusaData {
  global_code_id: string;
  account_code: number;
  account_code_complete: number;
  description: string;
  balance: number;
  starting_balance: number;
  account_code_id: string;
  general_description: string;
  canei_description: string;
}

export interface FileProps {
  content: string;
  client_id: string;
  links: RelationLink[];
  lastModified: string;
  date: string | null;
  name: string;
  type: string;
  size: number;
}

export interface IArrangementKeysV4 {
  account_code: string;
  description: string;
  balance: string;
  starting_balance: string;
}
export interface IArrangementKeysV3 {
  account_code: string;
  description: string;
  starting_balance_s: string;
  starting_balance_h: string;
  balance_s: string;
  balance_h: string;
}
export interface IArrangementKeysV2 {
  account_code: string;
  description: string;
  starting_balance: string;
  starting_balance_sh: string;
  balance: string;
  balance_sh: string;
}
export interface IArrangementKeysV1 {
  account_code: string;
  description: string;
  starting_balance: string;
  starting_balance_s: string;
  starting_balance_h: string;
  balance: string;
  balance_s: string;
  balance_h: string;
}

type IArrangementKeys =
  | IArrangementKeysV1
  | IArrangementKeysV2
  | IArrangementKeysV3
  | IArrangementKeysV4;

export enum FileUploadFormat {
  CSV = "CSV",
  XLS = "XLS",
  TXT = "TXT",
  PDF_DATEV_PRO = "PDF_DATEV_PRO",
  PDF_DATEV_COMPANY = "PDF_DATEV_COMPANY",
  MANUAL = "MANUAL",
  JPG = "JPG",
  PNG = "PNG",
  SVG = "SVG",
  API = "API",
}

export enum FileUploadVariant {
  V1 = "v1",
  V2 = "v2",
  V3 = "v3",
  V4 = "v4",
  UNKNOWN = "UNKNOWN",
}
