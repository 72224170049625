import { FC } from "react";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";

import { IconButton, IconButtonProps } from "./IconButton";
import { ISortType } from "../../../_lib/hooks";
import styled, { css } from "styled-components/macro";

export interface ISortButtonProps extends Omit<IconButtonProps, "icon"> {
  sort: ISortType;
  text?: string;
}

const StyledSortButtonWrapper = styled.div(() => {
  return css`
    display: flex;
    align-content: space-between;
    align-items: center;
    flex-basis: 100%;
    > div {
      flex-grow: 2;
    }
  `;
});
export const SortButton: FC<ISortButtonProps> = ({ children, sort, text, ...rest }) => {
  const iconProps = {
    ASC: { name: IconNameEnums.SORT_UP, size: IconSizeEnums.SMALL },
    DESC: { name: IconNameEnums.SORT_DOWN, size: IconSizeEnums.SMALL },
    NOSORT: { name: IconNameEnums.SORT, size: IconSizeEnums.SMALL },
  };
  return (
    <StyledSortButtonWrapper>
      <div>{text}</div>
      <IconButton icon={<Icon {...iconProps[sort]} />} {...rest} />
    </StyledSortButtonWrapper>
  );
};
