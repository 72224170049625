import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import {
  ICurrentUserAction,
  IDraftClientAction,
  IEventsState,
  UserStateActionTypes,
} from "../store/reducers";
import {
  DraftClientStateActionTypes,
  EDataSyncLevel,
  EDataSyncRefreshRate,
  EDataSyncScope,
  EDataSyncTypes,
  EPrivatePathName,
  FileUploadFormat,
  FileUploadVariant,
  IArrangementKeys,
  ILocalState,
  IUploadSettings,
  RelationLink,
  SelectClientState,
} from "../../@types/index.d";
import { useHistory } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import {
  IUploadedSuSa,
  useClients,
  usePlans,
  useSelectedClientData,
  useSwitchClient,
  useSyncEvents,
} from "./";
import { useContext, useEffect, useMemo, useState } from "react";
import { getFingerprint } from "../common/getFingerprint";
import {
  EFileUploadState,
  EUploadersActionTypes,
  IFileUploadState,
  UploadersContext,
  UploadersDispatch,
} from "../../containers/Uploaders";
import { isEqual } from "lodash";
import { useEventListener } from "./useEventListener";
import { EventListenerCodes } from "./useEventListener/types.event-listener";

const MUT_UPLOAD = gql`
  mutation upload($file: FileInput!) {
    result: uploadSuSaAsFile(file: $file) {
      guid
      covid
    }
  }
`;
const MUT_UPLOAD_PREVIEW = gql`
  mutation upload($file: FileInput!) {
    preview: uploadPreview(file: $file)
  }
`;
const MUT_UPLOAD_REVIEWED = gql`
  mutation upload($file: FileInput!) {
    result: publishReviewedFile(file: $file) {
      guid
      covid
    }
  }
`;

interface IUploadSuSaData {
  result: {
    guid: string;
    covid: string;
  };
}
interface IUploadSuSaVariables {
  file: SuSaFileProps;
}

interface IFilePreviewData {
  preview: string;
}
export interface SuSaFileProps {
  client_id: string;
  links: RelationLink[];
  content: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
  date: string;
  format: FileUploadFormat;
  sheet_name: string;
  delimiter: string;
  quote: string;
  variant_type: FileUploadVariant;
  mapping?: Partial<IArrangementKeys>;
}

const getSuSaFilePropsFromState = (state: IFileUploadState): SuSaFileProps => {
  if (state.settings.format === null) {
    throw new Error("File format can not be null!");
  }
  if (state.settings?.variant_type === null) {
    // throw new Error("variant_type can not be null!");
  }
  if (state.file?.date === null) {
    throw new Error("date can not be null!");
  }
  const { type, size = 0, content = "", date, lastModified, links } = state.file;
  const {
    variant_type,
    format,
    sheet_name = "",
    delimiter = "",
    quote = "",
    mapping,
  } = state.settings;
  return {
    client_id: state.client_id,
    name: state.file.name || "",
    format,
    type: type,
    sheet_name,
    delimiter,
    quote,
    variant_type,
    size: size,
    content: content,
    date,
    lastModified,
    links,
    mapping,
  };
};

// returns the scope value which will be used in transaction call for susa uploading
const getUploadTransactionScopeVal = (
  newSusaDate: string,
  list?: IUploadedSuSa[] | undefined
): EDataSyncScope | undefined => {
  const dateListOfSuSas = list && list.map(({ date }) => date);
  const isNewSusaDateExists =
    dateListOfSuSas && dateListOfSuSas?.includes(newSusaDate) ? true : false; // to check whether the new susa date is same with one of the previously uploaded susas' date
  const isThereAttachedPlan =
    list && !!list.find(({ date }) => date === newSusaDate)?.plans_count ? true : false; // to check whether the new susa date has attached plan - if the one above variable is true

  // if it is not initial susa
  if (dateListOfSuSas && dateListOfSuSas.length >= 1) {
    // if the picked new susa date exists among the curennt susa dates and that susa has an attached plan
    if (isNewSusaDateExists && isThereAttachedPlan) {
      return EDataSyncScope.KPI_PLAN;
    }
    // if the picked new susa date exists among the curennt susa dates BUT has no attached plan
    else if ((isNewSusaDateExists && !isThereAttachedPlan) || !isNewSusaDateExists) {
      return EDataSyncScope.KPI;
    }
  }
  // if it is the initial susa
  else if (!dateListOfSuSas || dateListOfSuSas.length === 0) {
    return EDataSyncScope.KPI_PLAN;
  }
};

type TUseSuSaUpload = () => [
  (file: IFileUploadState, onComplete?: (data: FetchResult<IUploadSuSaData>) => void) => void,
  MutationResult<IUploadSuSaData>, // redirected mutation result
  IEventsState // wvwntsState
];
type TUseSuSaUploadPreview = () => [
  (file: IFileUploadState, onComplete?: (data: FetchResult<IFilePreviewData>) => void) => void,
  MutationResult, // redirected mutation result
  Record<string, EventListenerCodes> | undefined // errorState
];

const useErrorState = (errorState: IEventsState, transactionID: string | undefined): void => {
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const planningLoadingStatus = useSelector(
    ({ planning: { planningLoadingStatus } }: ILocalState) => planningLoadingStatus,
    isEqual
  );

  const history = useHistory();
  const { callGetPlans } = usePlans();
  const { stopSync } = useSyncEvents();
  const { switchClient } = useSwitchClient();
  const susaFileUploadContext = useContext(UploadersContext);
  const susaFileUploadDispatch = useContext(UploadersDispatch);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const susaStateDateFromTransactionId = useMemo<string | undefined>(() => {
    let stateDate;
    transactionID &&
      Object.entries(susaFileUploadContext.sharedState.fileUploadState).forEach(([name, state]) => {
        if (state.transactionId === transactionID) stateDate = name;
      });
    return stateDate;
  }, [susaFileUploadContext.sharedState.fileUploadState, transactionID]);

  useEffect(() => {
    if (!transactionID || !errorState) return;
    const errorStateOfTransaction = errorState[transactionID] as EventListenerCodes;

    if (!errorStateOfTransaction) return;
    switch (errorStateOfTransaction) {
      case undefined:
        // do nothing
        break;
      case EventListenerCodes.UNKNOWN:
        if (currentUser.selectedClient.state === SelectClientState.PENDING) return;
        if (currentUser.selectedClient.state === SelectClientState.READY) return;
        dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING, payload: {} });
        break;
      case EventListenerCodes.TRANSACTION_OK:
        if (!plans && !planningLoadingStatus)
          callGetPlans(currentUser?.selectedClient?.client_id as string);
        if (history.location.pathname === EPrivatePathName.SETUP) {
          switchClient(currentUser.selectedClient.client_id, SelectClientState.READY);
          history.push(EPrivatePathName.DASHBOARD);
        }
        if (
          susaStateDateFromTransactionId &&
          susaFileUploadContext.sharedState.fileUploadState[susaStateDateFromTransactionId]
            .state === EFileUploadState.UPLOADING
        ) {
          susaFileUploadDispatch({
            type: EUploadersActionTypes.SET_UPLOAD_STATE,
            payload: {
              date: susaStateDateFromTransactionId,
              uploadState: {
                transactionId: transactionID,
                state: EFileUploadState.UPLOADED,
                transactionState: errorStateOfTransaction,
              },
            },
          });
        }
        break;
      default:
        // an error code returned from event listener
        susaFileUploadDispatch({
          type: EUploadersActionTypes.SET_UPLOAD_STATE,
          payload: {
            date: susaStateDateFromTransactionId,
            uploadState: {
              transactionId: transactionID,
              state: EFileUploadState.ERRORED,
              transactionState: errorStateOfTransaction,
            },
          },
        });
        break;
    }
  }, [
    currentUser.selectedClient.state,
    currentUser.selectedClient.client_id,
    dispatchUser,
    errorState,
    history,
    switchClient,
    stopSync,
    transactionID,
    susaStateDateFromTransactionId,
    susaFileUploadDispatch,
    susaFileUploadContext.sharedState.fileUploadState,
    callGetPlans,
    planningLoadingStatus,
    plans,
  ]);
};

export const useSuSaUpload: TUseSuSaUpload = () => {
  const eventsState = useSelector(({ eventsState }: ILocalState) => eventsState, isEqual);
  const selectedClient = useSelector(
    ({ currentUser: { selectedClient } }: ILocalState) => selectedClient,
    isEqual
  );
  // state to be used in useErrorState hook
  const [transactionID, setTransactionID] = useState<string | undefined>(undefined);

  const { startSync } = useSyncEvents();
  const { startEventSync } = useEventListener();

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchDraft = useDispatch<Dispatch<IDraftClientAction>>();

  const [upload, rest] = useMutation<IUploadSuSaData, IUploadSuSaVariables>(MUT_UPLOAD);

  useErrorState(eventsState, transactionID);

  return [
    (file): void => {
      file.file.date &&
        uploadersDispatch({
          type: EUploadersActionTypes.SET_UPLOAD_STATE,
          payload: { date: file.file.date, uploadState: { state: EFileUploadState.UPLOADING } },
        });
      upload({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      })
        .then(({ data }) => {
          if (!data?.result) return;
          if (uploadersContext.isInitial) {
            startSync({
              level: EDataSyncLevel.CLIENT,
              scope: EDataSyncScope.SUSA,
              refreshRate: EDataSyncRefreshRate.FAST,
            });
            batch(() => {
              dispatchDraft({ type: DraftClientStateActionTypes.RESET_DRAFT_CLIENT, payload: {} });
              dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING, payload: {} });
            });
          }

          const transactionId = getFingerprint(data.result.covid).covid;
          if (!transactionId) return;
          setTransactionID(transactionId);

          if (!file.file.date) return;
          uploadersDispatch({
            type: EUploadersActionTypes.SET_UPLOAD_STATE,
            payload: { date: file.file.date, uploadState: { transactionId } },
          });

          // to start the loading spinner which will be cancelled in private index
          dispatchUser({
            type: UserStateActionTypes.EVENT_IN_PROGRESS,
            payload: { eventInProgress: true },
          });
          startEventSync({
            transactionId,
            accountId: file.client_id,
            level: EDataSyncLevel.CLIENT,
            type: EDataSyncTypes.UPSERT,
            scope: getUploadTransactionScopeVal(file.file.date, selectedClient?.uploads?.list),
          });
        })
        .catch(() => {
          // Upload error
        });
    },
    rest,
    eventsState,
  ];
};

export const useUploadPreview: TUseSuSaUploadPreview = () => {
  const [uploadPreview, rest] = useMutation<IFilePreviewData, IUploadSuSaVariables>(
    MUT_UPLOAD_PREVIEW
  );

  return [
    (file, onComplete): void => {
      uploadPreview({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      }).then((fetchResult) => {
        onComplete && onComplete(fetchResult);
      });
    },
    rest,
    undefined, // errorState is not used here as this is not related with cached events
  ];
};

export const useUploadReviewedFile: TUseSuSaUpload = () => {
  const eventsState = useSelector(({ eventsState }: ILocalState) => eventsState, isEqual);
  const selectedClient = useSelector(
    ({ currentUser: { selectedClient } }: ILocalState) => selectedClient,
    isEqual
  );
  // state to be used in useErrorState hook
  const [transactionID, setTransactionID] = useState<string | undefined>(undefined);

  const { upsert } = useClients();
  const { startSync } = useSyncEvents();
  const { startEventSync } = useEventListener();
  const selectedClientData = useSelectedClientData();

  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchDraft = useDispatch<Dispatch<IDraftClientAction>>();

  const [uploadPreview, rest] = useMutation<IUploadSuSaData, IUploadSuSaVariables>(
    MUT_UPLOAD_REVIEWED
  );

  useErrorState(eventsState, transactionID);

  const updateClientUploadPreference = (): Promise<FetchResult> | undefined => {
    if (selectedClientData === false) throw new Error("Client must be selected!");
    const { mapping, ...rest } = uploadersContext.sharedState.settings;
    const preferenceChanged =
      uploadersContext.sharedState.savePreferences ===
      (selectedClientData.customization.upload_settings === undefined);

    const upload_settings: IUploadSettings | undefined = uploadersContext.sharedState
      .savePreferences
      ? { ...mapping, ...rest }
      : undefined;

    if (preferenceChanged) {
      return upsert({
        ...selectedClientData,
        customization: {
          ...selectedClientData.customization,
          upload_settings,
        },
      });
    }
    return undefined;
  };

  return [
    (file): void => {
      file.file.date &&
        uploadersDispatch({
          type: EUploadersActionTypes.SET_UPLOAD_STATE,
          payload: { date: file.file.date, uploadState: { state: EFileUploadState.UPLOADING } },
        });
      uploadPreview({
        variables: {
          file: getSuSaFilePropsFromState(file),
        },
      })
        .then(({ data }) => {
          if (!data?.result) return;
          if (uploadersContext.isInitial) {
            startSync({
              level: EDataSyncLevel.CLIENT,
              scope: EDataSyncScope.SUSA,
              refreshRate: EDataSyncRefreshRate.FAST,
            });
            batch(() => {
              dispatchDraft({ type: DraftClientStateActionTypes.RESET_DRAFT_CLIENT, payload: {} });
              dispatchUser({ type: UserStateActionTypes.SET_SELECTED_CLIENT_PENDING, payload: {} });
            });
          }
          const transactionId = getFingerprint(data?.result.covid).covid;
          if (!transactionId) return;
          setTransactionID(transactionId);

          if (!file.file.date) return;

          uploadersDispatch({
            type: EUploadersActionTypes.SET_UPLOAD_STATE,
            payload: { date: file.file.date, uploadState: { transactionId } },
          });

          // to start the loading spinner which will be cancelled in private index
          dispatchUser({
            type: UserStateActionTypes.EVENT_IN_PROGRESS,
            payload: { eventInProgress: true },
          });
          startEventSync({
            transactionId,
            accountId: file.client_id,
            level: EDataSyncLevel.CLIENT,
            type: EDataSyncTypes.UPSERT,
            scope: getUploadTransactionScopeVal(file.file.date, selectedClient?.uploads?.list),
          });
        })
        .then(() => {
          return updateClientUploadPreference();
        });
    },
    rest,
    eventsState,
  ];
};
