import { FC, useContext, useEffect } from "react";
import { StyledTotalSum, StyledUploadManualEntry } from "../styled.uploaders";

import { FiscalYear } from "./FiscalYear";
import { Form, FormContext, FormDispatch, IFormState } from "../../../components/formElements/Form";
import { gql, useQuery } from "@apollo/client";
import { formatCurrency } from "../../../_lib/common";
import { Cell, Row } from "../../../components/Table";
import { Trans } from "react-i18next";
import { Input } from "../../../components/formElements/Input";
import {
  FileUploadFormat,
  FileUploadVariant,
  ILocalState,
  InputTypes,
  IWarmUpScopes,
  IWarmUpTopics,
} from "../../../@types/index.d";
import { GuV } from "./GuV";
import { Balance } from "./Balance";

import { Submit } from "./Submit";
import { useSelector } from "react-redux";
import { useRelationLinks } from "../../../_lib/hooks/useRelationLinks";

import { useSuSaUpload } from "../../../_lib/hooks";

import { useWarmUp } from "../../../_lib/hooks/useWarmUp";
import { IFileUploadState, UploadersContext } from "../index";
import { isEqual } from "lodash";

export const GET_GLOBAL_CODES_TREE = gql`
  {
    getGlobalCodesTree {
      value
      tree {
        value
        tree {
          value
          tree {
            value
          }
        }
      }
    }
  }
`;
export interface IGlobalCodes {
  getGlobalCodesTree: IManualEntryItems[];
}
export interface IManualEntryItems {
  value?: string;
  tree?: IManualEntryTree[];
  type: "guv" | "balance";
}

export interface IManualEntryTree {
  value?: string;
  tree?: IManualEntryTree[];
  type: "guv" | "balance";
}
export interface ITotalSumProps {
  fieldName: string;
}

export const sumModel = new Map();

sumModel.set("P02R", ["P01", "P02"]);
sumModel.set("P03T", ["P02R", "P03"]);
sumModel.set("P05A", ["P04", "P05"]);
sumModel.set("P05B", ["P03T", "P05A"]);
sumModel.set("P08", ["P08A", "P08B", "P08C", "P08D", "P08E", "P08F", "P08G"]);
sumModel.set("P08T", ["P06", "P07", "P05B", "P08"]);
sumModel.set("P09E", ["P08T", "P09"]);
sumModel.set("P12N", ["P10", "P11", "P12"]);
sumModel.set("P12O", ["P09E", "P12N"]);
sumModel.set("P14R", ["P13", "P14"]);
sumModel.set("P20N", ["P12O", "P14R", "P15", "P16"]);
sumModel.set("BA03A", ["BA01", "BA02", "BA03"]);
sumModel.set("BA06A", ["BA04", "BA05", "BA06"]);
sumModel.set("BA08A", ["BA07", "BA08"]);
sumModel.set("BA08B", ["BA08A", "BA06A", "BA03A"]);
sumModel.set("BA11A", ["BA09", "BA10", "BA11"]);
sumModel.set("BA16A", ["BA12", "BA13", "BA14", "BA15", "BA16"]);
sumModel.set("BA18A", ["BA17", "BA18", "BA16A", "BA11A"]);
sumModel.set("BA20T", ["BA08B", "BA18A"]);
sumModel.set("BE05E", ["BE01", "BE02", "BE03", "BE04", "BE05"]);
sumModel.set("BP03A", ["BP01", "BP02", "BP03"]);
sumModel.set("BP10L", ["BP04", "BP05", "BP06", "BP07", "BP08", "BP09", "BP10"]);
sumModel.set("BP20T", ["BE05E", "BP03A", "BP10L", "BP11"]);
sumModel.set("ST_BA03A", ["ST_BA01", "ST_BA02", "ST_BA03"]);
sumModel.set("ST_BA06A", ["ST_BA04", "ST_BA05", "ST_BA06"]);
sumModel.set("ST_BA08A", ["ST_BA07", "ST_BA08"]);
sumModel.set("ST_BA08B", ["ST_BA08A", "ST_BA06A", "ST_BA03A"]);
sumModel.set("ST_BA11A", ["ST_BA09", "ST_BA10", "ST_BA11"]);
sumModel.set("ST_BA16A", ["ST_BA12", "ST_BA13", "ST_BA14", "ST_BA15", "ST_BA16"]);
sumModel.set("ST_BA18A", ["ST_BA17", "ST_BA18", "ST_BA16A", "ST_BA11A"]);
sumModel.set("ST_BA20T", ["ST_BA08B", "ST_BA18A"]);
sumModel.set("ST_BE05E", ["ST_BE01", "ST_BE02", "ST_BE03", "ST_BE04", "ST_BE05"]);
sumModel.set("ST_BP03A", ["ST_BP01", "ST_BP02", "ST_BP03"]);
sumModel.set("ST_BP10L", [
  "ST_BP04",
  "ST_BP05",
  "ST_BP06",
  "ST_BP07",
  "ST_BP08",
  "ST_BP09",
  "ST_BP10",
]);
sumModel.set("ST_BP20T", ["ST_BE05E", "ST_BP03A", "ST_BP10L", "ST_BP11"]);
sumModel.set("BA17:BP05", []);
sumModel.set("ST_BA17:BP05", []);
sumModel.set("BE05", ["P20N"]);

export const TotalSum: FC<ITotalSumProps> = ({ fieldName }) => {
  const formContext = useContext(FormContext);
  const formDispatch = useContext(FormDispatch);

  const sum = sumModel
    .get(fieldName)
    .reduce((prev: number, cur: string) => prev + parseFloat(formContext[cur] || "0"), 0);

  useEffect(() => {
    if (fieldName && formContext[fieldName] !== sum.toString()) {
      formDispatch({ [fieldName]: sum.toString() });
    }
  });

  return <StyledTotalSum>{formatCurrency(formContext[fieldName] || 0)}</StyledTotalSum>;
};

export const ManualEntrySubItemValue: FC<IManualEntryItems> = ({
  value: fieldName,
  tree,
  type,
}) => {
  return (
    <>
      {tree &&
        tree.length > 0 &&
        tree.map((entryItem: IManualEntryItems, entryValue: number) => (
          <ManualEntrySubItemValue key={`entryValue-${entryValue}`} {...entryItem} type={type} />
        ))}
      {fieldName && (
        <Row>
          <Cell level={"subTitle"}>
            <Trans i18nKey={`datastore:manual_entries.${type}.${fieldName}`} />
          </Cell>
          {type === "balance" && (
            <Cell highlight={true} align="right">
              {fieldName === "BE05" ? (
                <> </>
              ) : (
                <Input
                  value={""}
                  name={`ST_${fieldName}`}
                  type={InputTypes.NUMBER}
                  step={"0.01"}
                  placeholder="0,00"
                />
              )}
            </Cell>
          )}
          <Cell highlight={true} align="right">
            {fieldName !== "BE05" ? (
              <Input
                value={""}
                name={fieldName}
                type={InputTypes.NUMBER}
                step={"0.01"}
                placeholder="0,00"
              />
            ) : (
              <TotalSum fieldName={fieldName} />
            )}
          </Cell>
        </Row>
      )}
    </>
  );
};

export const ManualEntrySubItem: FC<IManualEntryItems> = ({ value: fieldName, tree, type }) => {
  return (
    <>
      {tree &&
        tree.length > 0 &&
        tree.map((entryItem: IManualEntryItems, entryValue: number) =>
          entryItem.tree && entryItem.tree.length > 0 ? (
            <ManualEntrySubItem key={`entryValue-${entryValue}`} {...entryItem} type={type} />
          ) : (
            <ManualEntrySubItemValue key={`entryValue-${entryValue}`} {...entryItem} type={type} />
          )
        )}
      {fieldName && (
        <Row>
          <Cell level={"title"} highlight={true}>
            <Trans i18nKey={`datastore:manual_entries.${type}.${fieldName}`} />
          </Cell>

          <Cell highlight={true} align="right">
            {fieldName && <TotalSum fieldName={fieldName} />}
          </Cell>
          {type === "balance" && (
            <Cell highlight={true} align="right">
              {fieldName && <TotalSum fieldName={`ST_${fieldName}`} />}
            </Cell>
          )}
        </Row>
      )}
    </>
  );
};

export const ManualEntrySum: FC<IManualEntryItems> = ({ value: fieldName, tree, type }) => {
  return (
    <>
      {tree &&
        tree.length > 0 &&
        tree.map((subItem: IManualEntryItems, subItemIndex: number) => (
          <ManualEntrySubItem key={`subItemIndex-${subItemIndex}`} {...subItem} type={type} />
        ))}
      {fieldName && (
        <Row>
          <Cell level={"upper"} highlight={true}>
            <Trans i18nKey={`datastore:manual_entries.${type}.${fieldName}`} />
          </Cell>
          {type === "balance" && (
            <Cell highlight={true} align="right">
              {fieldName && <TotalSum fieldName={`ST_${fieldName}`} />}
            </Cell>
          )}
          <Cell highlight={true} align="right">
            {fieldName && <TotalSum fieldName={fieldName} />}
          </Cell>
        </Row>
      )}
    </>
  );
};

export const ManualEntry: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { getRelationLinks } = useRelationLinks();
  const { data: globalCodesData, loading: globalCodesLoading } = useQuery(GET_GLOBAL_CODES_TREE);
  const [upload, { loading: uploadLoading }] = useSuSaUpload();
  const uploadersContext = useContext(UploadersContext);
  const warmUp = useWarmUp();

  useEffect(() => {
    warmUp(IWarmUpTopics.FILE_TOPIC, IWarmUpScopes.SUSA);
  }, [warmUp]);
  const handleOnSubmit = (form: IFormState): void => {
    const contentKeys = Object.keys(form).filter((keyName: string) => /^[PB]/.test(keyName));
    if (!currentUser.selectedClient.client_id) {
      throw new Error("Client Id can not be null for Manual entry upload");
    }
    const content = contentKeys.map((key: string) => {
      const global_code_id = key;
      const balance: number = parseFloat(form[key]) || 0;
      const starting_balance: number = parseFloat(form[`ST_${key}`]) || 0;
      return {
        balance,
        starting_balance,
        global_code_id,
      };
    });

    const manualEntry: IFileUploadState = {
      ...uploadersContext.sharedState,
      client_id: currentUser.selectedClient.client_id,
      file: {
        ...uploadersContext.sharedState.file,
        content: JSON.stringify({ data: content }),
        name: "",
        links: getRelationLinks(currentUser.selectedClient.client_id),
        date: form.date,
        lastModified: "",
        size: content.length,
        type: "json",
      },
      settings: {
        ...uploadersContext.sharedState.settings,
        format: FileUploadFormat.MANUAL,
        variant_type: FileUploadVariant.UNKNOWN,
      },
    };

    upload(manualEntry);
  };

  return (
    <Form onSubmitHandler={handleOnSubmit}>
      <StyledUploadManualEntry>
        <FiscalYear />
        <GuV data={globalCodesData} loading={globalCodesLoading || uploadLoading} />
        <Balance data={globalCodesData} loading={globalCodesLoading || uploadLoading} />
        <Submit />
      </StyledUploadManualEntry>
    </Form>
  );
};
