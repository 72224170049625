export enum EventErrorsCodes {
  UNKNOWN = "UNKNOWN",
  TIME_OUT = "TIME_OUT",
  UPLOAD_TIME_OUT = "UPLOAD_TIME_OUT",
  TRANSACTION_OK = "000",
  INTERNAL_SERVER_ERROR = "001",
  FILE_EVENT_ERROR = "400",
  FILE_EVENT_FORMAT = "401",
  FILE_EVENT_ACCOUNTS = "402",
  FILE_EVENT_CONVERTIBLE = "403",
  FILE_EVENT_SHEET = "405",
  FILE_EVENT_TYPE = "411",
  FILE_EVENT_PASSWORD = "412",
}

export interface IApiError {
  type: string;
  code: string;
  msg: string;
}
