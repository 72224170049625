import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { Client, EAssetType, ILocalState, Relation, RelationLink } from "../../@types/index.d";

interface IUseRelationLinks {
  getRelationLinks: (clientId: string | null) => RelationLink[];
  getUploadRelationLinks: (accountId: string, assetType: EAssetType) => RelationLink[];
  getAssetRelationLinks: (accountId: string) => RelationLink[];
}
type TUseRelationLinks = () => IUseRelationLinks;
/**
 * Returns Relation links array for current selected client and a
 * function that calculates RelationLink array for a given client id of customer
 */
export const useRelationLinks: TUseRelationLinks = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  // function to ectract relation links relevant to the upload
  const getUploadRelationLinks = (accountId: string, assetType: EAssetType): RelationLink[] => {
    // escape if called without accountId
    if (!accountId) return [];

    // check if asset is for customer
    const isCustomer = currentUser.appUser.customer_id === accountId;

    // initialize empty links array
    let links = [] as RelationLink[] | undefined;

    // grab links for customer or client
    switch (isCustomer) {
      case true:
        links = currentUser.appUser.customer.links;
        if (!links) return [];
        // return links filtering out everything but customer and eventual asset rel
        return links
          .filter((link) => link.rel === Relation.self || link.rel === (assetType as string))
          .map((link) => {
            return {
              href: link.href,
              rel: link.rel === Relation.self ? Relation.customer : link.rel,
            };
          });
      case false:
        links = currentUser.clients.find((client: Client) => client.client_id === accountId)?.links;
        if (!links) return [];

        // return links filtering out everything but customer, client and eventual asset rel
        return (links = links
          .filter(
            (link) =>
              link.rel === Relation.self ||
              link.rel === Relation.customer ||
              link.rel === (assetType as string)
          )
          .map((link) => {
            return {
              href: link.href,
              rel: link.rel === Relation.self ? Relation.client : link.rel,
            };
          }));
      // default escape with empty array
      default:
        return [];
    }
  };

  // function to get asset links
  const getAssetRelationLinks = (accountId: string): RelationLink[] => {
    // escape if called without accountId
    if (!accountId) return [];

    // check if asset is for customer
    const isCustomer = currentUser.appUser.customer_id === accountId;

    // initialize empty links array
    let links = [] as RelationLink[];

    // grab links for customer or client
    switch (isCustomer) {
      case true:
        if (currentUser.appUser.customer.links) links = currentUser.appUser.customer.links;
        // return links filtering out everything but customer and eventual asset rel
        return links.filter((link) => {
          // turn link.rel in to a key of EAssetType enum
          const key = link.rel.valueOf().toUpperCase() as keyof typeof EAssetType;
          return EAssetType[key];
        });
      case false:
        const client = currentUser.clients.find((client: Client) => client.client_id === accountId);
        if (client) links = client.links;
        // return links filtering out everything but customer, client and eventual asset rel
        return links.filter((link) => {
          // turn link.rel in to a key of EAssetType enum
          const key = link.rel.valueOf().toUpperCase() as keyof typeof EAssetType;
          return EAssetType[key];
        });
      // default escape with empty array
      default:
        return links;
    }
  };

  const getRelationLinks = (clientId: string | null): RelationLink[] => {
    // when we delete the selectedClient, it was giving an error because of line 113. This started to happen after extending this hook. So the second condition in the below if is a temporary fix for that
    if (!clientId || currentUser.clients.length === 0) {
      return [];
    }

    const links = currentUser.clients.find(
      (client: Client) => client.client_id === clientId
    )?.links;

    if (!links) {
      throw new Error(
        "hooks:useRelationLinks - clientId not found in currentUser" +
          currentUser.clients.join(", ")
      );
    }

    return links.map((link: RelationLink) => {
      return { href: link.href, rel: link.rel === Relation.self ? Relation.client : link.rel };
    });
  };
  return {
    getUploadRelationLinks,
    getAssetRelationLinks,
    getRelationLinks,
  };
};
