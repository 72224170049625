import {
  FC,
  createContext,
  useReducer,
  ReactNode,
  SyntheticEvent,
  Dispatch,
  useEffect,
} from "react";
import { AlignmentEnums } from "../../../@types/index.d";
import { StyledForm, StyledFormRow } from "./styled.form";
import { SimpleInterpolation } from "styled-components/macro";

export const FormContext = createContext<IFormState>({});
export const FormDispatch = createContext<Dispatch<IFormState>>(() => {
  // initial dispatch function
});

export interface IFormProps {
  children: ReactNode;
  onSubmitHandler?: (form: IFormState) => void;
  subscribe?: (form: IFormState) => void;
  initialData?: IFormState;
  testID?: string;
  customStyle?: SimpleInterpolation;
}
export type IFormState = Record<string, string>;

export const Form: FC<IFormProps> = ({
  children,
  onSubmitHandler,
  subscribe,
  initialData,
  testID,
  customStyle,
}: IFormProps) => {
  const reduceForm = (state: IFormState, action: IFormState): IFormState => {
    return {
      ...state,
      ...action,
    };
  };
  const [form, formDispatcher] = useReducer(reduceForm, initialData || {});

  function handleFormSubmit(e: SyntheticEvent): void {
    e.preventDefault();
    onSubmitHandler && onSubmitHandler(form);
  }
  useEffect(() => {
    subscribe && subscribe(form);
  }, [form, subscribe]);
  return (
    <StyledForm onSubmit={handleFormSubmit} data-test-id={testID} customStyle={customStyle}>
      <FormDispatch.Provider value={formDispatcher}>
        <FormContext.Provider value={form}>{children}</FormContext.Provider>
      </FormDispatch.Provider>
    </StyledForm>
  );
};

export interface IFormRowProps {
  children: ReactNode;
  align: AlignmentEnums;
}
export const FormRow: FC<IFormRowProps> = ({ children, align }: IFormRowProps) => {
  return <StyledFormRow align={align}>{children}</StyledFormRow>;
};
