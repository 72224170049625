import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types";
import { EAppStoreMenuItem } from "../useAppStore";
import { useAppStoreHelper } from "../useAppStoreHelper";
import { AppStoreMenuItem } from "./MenuItem";
import { StyledAppStoreMenu } from "./styled.appStore-menu";
import { isEqual } from "lodash";

export const AppStoreMenu: FC = () => {
  const { handleSelected } = useAppStoreHelper();
  const content = useSelector(({ appStore: { content } }: ILocalState) => content, isEqual);
  const menuSubFeatures = useSelector(
    ({ appStore: { menuSubFeatures } }: ILocalState) => menuSubFeatures,
    isEqual
  );

  if (!content) return null;

  return (
    <>
      <StyledAppStoreMenu.MenuWrapper>
        {Object.keys(content).map((menuItem) => {
          const showSubFeatures = menuSubFeatures?.value === menuItem;
          if (content[menuItem as EAppStoreMenuItem].sub_feature) return null;
          return (
            <React.Fragment key={Math.random()}>
              <AppStoreMenuItem
                {...content[menuItem as EAppStoreMenuItem]}
                key={`menu-${menuItem}-${Math.random()}`}
                handleSelected={handleSelected}
                contentItem={menuItem}
              />
              {showSubFeatures &&
                menuSubFeatures &&
                menuSubFeatures.tree.map((subFeature) => {
                  return (
                    <AppStoreMenuItem
                      {...content[subFeature.value as EAppStoreMenuItem]}
                      key={`menu-${subFeature.value}-${Math.random()}`}
                      handleSelected={handleSelected}
                      contentItem={subFeature.value}
                      showSubFeatures={showSubFeatures}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      </StyledAppStoreMenu.MenuWrapper>
    </>
  );
};
