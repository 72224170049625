import { useApolloClient } from "@apollo/client";
import { Dispatch, useCallback } from "react";
import { GET_CACHED_EVENT_STATE } from "./eventListener-queries";
import {
  EventListenerCodes,
  IEventListenerQueryResult,
  IStartEventListenerInput,
} from "./types.event-listener";
import { IEventsAction, IEventsActionTypes } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../@types";
import { isEqual } from "lodash";

interface IUseEventListenerRetVal {
  startEventSync: (params: IStartEventListenerInput) => void;
}

export const useEventListener = (): IUseEventListenerRetVal => {
  const eventsState = useSelector(({ eventsState }: ILocalState) => eventsState, isEqual);
  const dispatchEventsState = useDispatch<Dispatch<IEventsAction>>();

  const apolloClient = useApolloClient();

  // event listener function
  const startEventSync = useCallback(
    (params: IStartEventListenerInput) => {
      // if there are no params in the array stop
      if (!params) return;

      // if there is already an event listener running stop
      if (eventsState && eventsState[params.transactionId]) return;

      // if there is no event state yet create one
      if (!eventsState) {
        dispatchEventsState({
          type: IEventsActionTypes.UPDATE_EVENTS_STATE,
          payload: {
            event: {
              transactionId: params.transactionId,
              value: EventListenerCodes.UNKNOWN,
            },
          },
        });
      }

      // if there is event state add the new event
      else {
        dispatchEventsState({
          type: IEventsActionTypes.UPDATE_EVENTS_STATE,
          payload: {
            event: {
              transactionId: params.transactionId,
              value: EventListenerCodes.UNKNOWN,
            },
          },
        });
      }

      // declare when the listener was fired up
      const startedAt = Date.now();
      let i = -1;

      // try getting the event state from cached events in backend
      const getEventState = (): void => {
        apolloClient
          .query<IEventListenerQueryResult>({
            query: GET_CACHED_EVENT_STATE,
            variables: {
              ...params,
            },
            fetchPolicy: "network-only",
          })
          .then(({ data }) => {
            // if thre is a response save it to eventsState
            if (data?.event && data?.event?.status) {
              const { status } = data?.event;
              dispatchEventsState({
                type: IEventsActionTypes.UPDATE_EVENTS_STATE,
                payload: {
                  event: {
                    transactionId: params.transactionId,
                    value: status,
                  },
                },
              });
            } else {
              // if there is no response check if it was fired up for more than 2 minutes ago and set it to timeout
              if ((Date.now() - startedAt) / 1000 > /*2 Minutes*/ 2 * 60) {
                dispatchEventsState({
                  type: IEventsActionTypes.UPDATE_EVENTS_STATE,
                  payload: {
                    event: {
                      transactionId: params.transactionId,
                      value: EventListenerCodes.TIME_OUT,
                    },
                  },
                });
              } else {
                // if no event state try again
                i = window.setTimeout(getEventState, 3000);
              }
            }
          })
          .catch((err) => {
            dispatchEventsState({
              type: IEventsActionTypes.UPDATE_EVENTS_STATE,
              payload: {
                event: {
                  transactionId: params.transactionId,
                  value: EventListenerCodes.ERROR,
                },
              },
            });
          });
      };
      getEventState();
      return (): void => {
        i !== -1 && window.clearTimeout(i);
      };
    },
    [apolloClient, eventsState, dispatchEventsState]
  );

  return {
    startEventSync,
  };
};
