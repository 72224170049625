import { FC } from "react";
import styled, { css } from "styled-components/macro";
import {
  animateToBottom,
  animateToTop,
  animateToRight,
  animateToLeft,
  animateToLeftOut,
  animateToRightOut,
  animateToTopOut,
  animateToBottomOut,
} from "./styled.slide-in";
export interface ISlideInProps {
  direction?: SlideInDirections;
  out?: boolean;
}
export enum SlideInDirections {
  TO_TOP = "TO_TOP",
  TO_BOTTOM = "TO_BOTTOM",
  TO_LEFT = "TO_LEFT",
  TO_RIGHT = "TO_RIGHT",
}

const StyledSlideIn = styled.div<ISlideInProps>(
  ({ theme, direction = SlideInDirections.TO_TOP, out = false }) => {
    const animations = {
      [SlideInDirections.TO_BOTTOM]: { in: animateToBottom, out: animateToBottomOut },
      [SlideInDirections.TO_TOP]: { in: animateToTop, out: animateToTopOut },
      [SlideInDirections.TO_RIGHT]: { in: animateToRight, out: animateToRightOut },
      [SlideInDirections.TO_LEFT]: { in: animateToLeft, out: animateToLeftOut },
    };

    const slideInAnimation = out ? animations[direction].out : animations[direction].in;

    return css`
      animation: ${slideInAnimation} 600ms forwards;
      animation-iteration-count: 1;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    `;
  }
);

export const SlideIn: FC<ISlideInProps> = ({ children, ...props }) => {
  return <StyledSlideIn {...props}>{children}</StyledSlideIn>;
};
