import styled, { css } from "styled-components/macro";
import { AlignmentEnums } from "../../../@types/index.d";
import { IFormProps, IFormRowProps } from "./index";
export const StyledForm = styled.form<Partial<IFormProps>>(({ theme, customStyle }) => {
  return css`
    display: flex;
    flex-wrap: wrap;
    ${customStyle}
  `;
});
export const StyledFormRow = styled.div<IFormRowProps>`
  display: flex;
  flex-basis: 100%;
  margin-bottom: ${({ theme }): string => theme.rem(1)};
  ${({ align }): string => (align === AlignmentEnums.LEFT ? `justify-content: flex-start;` : ``)};
  ${({ align }): string => (align === AlignmentEnums.RIGHT ? `justify-content: flex-end;` : ``)};
  ${({ align }): string => (align === AlignmentEnums.CENTER ? `justify-content: center;` : ``)};
  ${({ align }): string => (align === AlignmentEnums.STRETCH ? `justify-content: stretch;` : ``)};
  ${({ align }): string =>
    align === AlignmentEnums.SPACE_AROUND ? `justify-content: space-around;` : ``};
  ${({ align }): string =>
    align === AlignmentEnums.SPACE_BETWEEN ? `justify-content: space-between;` : ``};
`;
