import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { FC } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { IDialogKeyEnums, ILocalState } from "../../../../@types/index.d";
import { IAppStoreContext } from "../appStoreTypes";
import { StyledAppStore } from "../styled.appStore-page";
import { EAppStoreMenuItem } from "../useAppStore";
import { AppStoreFeature } from "./FeaturePage";
import { AppStoreOverview } from "./OverviewPage";
import AppStorePaymentPage from "./PaymentPage";
import { useDialogContext } from "../../../../_lib/hooks";
import { isEqual } from "lodash";

export const AppStoreContent: FC = () => {
  const content = useSelector(({ appStore: { content } }: ILocalState) => content, isEqual);
  const selectedMenuItem = useSelector(
    ({ appStore: { selectedMenuItem } }: ILocalState) => selectedMenuItem,
    isEqual
  );
  const dialogContext = useDialogContext<IAppStoreContext | undefined>(
    IDialogKeyEnums.APP_STORE,
    undefined
  );
  const createAppStorePage = (): React.ReactElement => {
    if (!content) return <></>;
    switch (selectedMenuItem) {
      case EAppStoreMenuItem.OVERVIEW:
        return (
          <>
            <AppStoreOverview />
          </>
        );
      case EAppStoreMenuItem.PAYMENT:
        return (
          <>
            <AppStorePaymentPage storeState={dialogContext?.state} />
          </>
        );
      default:
        return (
          <>
            <AppStoreFeature />
          </>
        );
    }
  };

  if (!content) return null;

  return (
    <>
      <StyledAppStore.ContentWrapper>
        <StyledAppStore.ContentTitleWrapper>
          {content[selectedMenuItem].icon && (
            <Icon
              name={content[selectedMenuItem].icon as IconNameEnums}
              size={IconSizeEnums.MEDIUM}
            />
          )}
          <StyledAppStore.MainTitle>
            <Trans components={{ sup: <sup /> }}>{content[selectedMenuItem].title}</Trans>
          </StyledAppStore.MainTitle>
        </StyledAppStore.ContentTitleWrapper>

        {/* create App store Page to be shown (OverviewPage, FeaturePage, PaymentPage in Content*/}
        {createAppStorePage()}
      </StyledAppStore.ContentWrapper>
    </>
  );
};
