import { useDispatch, useSelector } from "react-redux";
import {
  AlertTypeEnums,
  IDownloadFile,
  IDownloadsAction,
  IDownloadsActionType,
  ILocalState,
} from "../../../@types/index.d";
import { Dispatch } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSystemMessages } from "../useSystemMessages";
import { EAutoCloseTimeOut } from "../../store/reducers";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

export const useDownloadsListener = (): void => {
  const { t } = useTranslation(["errors"]);
  const downloads = useSelector(({ downloads }: ILocalState) => downloads, isEqual);
  const dispatchDownload = useDispatch<Dispatch<IDownloadsAction>>();
  const erroredDownloads = useMemo(
    () => downloads.filter(({ error }) => error !== undefined),
    [downloads]
  );
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const { addSystemMessage } = useSystemMessages();
  const downloadFileCallback = useCallback(
    (href: string, filename: string): Promise<HTMLAnchorElement> => {
      const a = document.createElement("a");
      a.href = href;

      a.download = filename || "report[unknown]";

      const clickHandler = (): void => {
        setTimeout((): void => {
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);

      return new Promise((res) => {
        a.click();
        res(a);
      });
    },
    []
  );

  const fileToDownload = useMemo<IDownloadFile | undefined>(() => {
    //
    if (isDownloadInProgress) return undefined;

    return downloads.find(
      ({ downloadUrl, isDownloading }) => isDownloading && downloadUrl !== undefined
    );
  }, [downloads, isDownloadInProgress]);
  useEffect(() => {
    if (fileToDownload === undefined) return;
    const { isDownloading, ...rest } = fileToDownload;
    setIsDownloadInProgress(true);
    dispatchDownload({
      type: IDownloadsActionType.EDIT_DOWNLOAD,
      payload: { ...rest, isDownloading: true, downloadUrl: undefined },
    });
    rest.downloadUrl &&
      downloadFileCallback(rest.downloadUrl, rest.name).then(() => {
        dispatchDownload({
          type: IDownloadsActionType.REMOVE_DOWNLOAD,
          payload: { ...rest, isDownloading: false },
        });
        setIsDownloadInProgress(false);
      });
  }, [dispatchDownload, downloadFileCallback, fileToDownload]);

  useEffect(() => {
    erroredDownloads.forEach((file) => {
      addSystemMessage({
        type: AlertTypeEnums.ERROR,
        identifier: file.identifier,
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        code: t(`${file.error}`),
      });
      dispatchDownload({
        type: IDownloadsActionType.REMOVE_DOWNLOAD,
        payload: { ...file, downloadUrl: undefined, isDownloading: false },
      });
    });
  }, [addSystemMessage, dispatchDownload, erroredDownloads, t]);
};
