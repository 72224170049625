import { FC, ReactElement, ReactNode, Suspense } from "react";
import { StyledPageContent } from "./styled.page-content";

import { Content, ContentOptions, ContentBody, ContentNav } from "./Content";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";

export interface IPageContentProps {
  options?: ReactNode;
  title: string;
  subtitle: string | ReactElement;
}
export const PageContent: FC<IPageContentProps> = ({ children, options, ...props }) => {
  return (
    <StyledPageContent>
      <ContentNav />
      <Content>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.COMPONENT} />}>
          <ContentOptions {...props}>{options || null}</ContentOptions>
          <ContentBody>{children}</ContentBody>
        </Suspense>
      </Content>
    </StyledPageContent>
  );
};
