import styled, { css } from "styled-components/macro";

export const StyledFileUploader = styled.div(() => {
  return css`
    display: inline-flex;
    flex-basis: 100%;
    position: relative;
  `;
});
export const StyledFileInput = styled.input(
  () => css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  `
);
