import { FC, useEffect, useMemo } from "react";
import { StyledCommonLayout } from "./styled.common";
import { PageHeadline } from "../../containers/PageHeadline";
import { SideNav } from "../../containers/SideNav";
import { PageFooter } from "../../containers/PageFooter";

import {
  EPlanningPage,
  EPrivatePathName,
  ILocalState,
  IThemeOptions,
  LayoutEnums,
  SelectClientState,
} from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { IThemeOptionsAction, ThemeStateActionTypes } from "../store/reducers";
import { Dispatch } from "@reduxjs/toolkit";
import { useNoPlanFoundWarning } from "../hooks";
import { useHistory } from "react-router-dom";
import { SystemMessages } from "../../containers/SystemMessages";
import { LoaderTypeEnums, LoadingIndicator } from "../../components/LoadingIndicator";
import { isEqual } from "lodash";

export const CommonLayout: FC = ({ children }) => {
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const planningLoadingStatus = useSelector(
    ({ planning: { planningLoadingStatus } }: ILocalState) => planningLoadingStatus,
    isEqual
  );
  const selectedPlanningPage = useSelector(
    ({ planning: { selectedPlanningPage } }: ILocalState) => selectedPlanningPage,
    isEqual
  );
  const uploads = useMemo(
    () => currentUser.selectedClient.uploads?.list || [],
    [currentUser.selectedClient.uploads?.list]
  );

  const { open, close } = useNoPlanFoundWarning();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();

  // effect to open-close no plan warning dialog
  useEffect(() => {
    // conditionSet1, conditionSet2 and conditionSet3 variables are to define the conditions to open no plan warning dialog
    const conditionSet1 =
      currentUser.selectedClient.state === SelectClientState.READY &&
      !planningLoadingStatus &&
      plans?.length === 0 &&
      history.location.pathname !== EPrivatePathName.PLAN_CREATE;

    const conditionSet2 =
      history.location.pathname === EPrivatePathName.PLAN_CREATE &&
      selectedPlanningPage !== EPlanningPage.CREATE;

    // user should be able to click these pages in sidenav
    const conditionSet3 =
      history.location.pathname !== EPrivatePathName.COMPANY &&
      history.location.pathname !== EPrivatePathName.COMPANY_DATA &&
      history.location.pathname !== EPrivatePathName.COMPANY_USERS &&
      history.location.pathname !== EPrivatePathName.CUSTOMIZATION &&
      history.location.pathname !== EPrivatePathName.ACCOUNT;

    if ((conditionSet1 || conditionSet2) && conditionSet3) {
      open();
    } else {
      if (uploads && uploads.length > 0) {
        if (uploads.find(({ plans_count }) => plans_count > 0) !== undefined) {
          close();
        }
      } else {
        close();
      }
    }
  }, [
    currentUser.selectedClient.state,
    open,
    close,
    plans,
    history.location.pathname,
    planningLoadingStatus,
    selectedPlanningPage,
    uploads,
  ]);

  useEffect(() => {
    if (themeOptions.layout !== LayoutEnums.COMMON) {
      dispatch({
        type: ThemeStateActionTypes.SET_LAYOUT,
        payload: { layout: LayoutEnums.COMMON } as Partial<IThemeOptions>,
      });
    }
  });

  return (
    <StyledCommonLayout>
      <PageHeadline />
      <SideNav />
      {children}
      <PageFooter />
      <SystemMessages />
      {currentUser.selectedClient.state === SelectClientState.PENDING && (
        <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
      )}
    </StyledCommonLayout>
  );
};
