import { useDispatch, useSelector } from "react-redux";
import {
  ISystemMessage,
  ISystemMessageAction,
  ISystemMessageActionTypes,
  TSystemMessagePatch,
} from "../store/reducers";
import { Dispatch } from "@reduxjs/toolkit";
import { ILocalState } from "../../@types";
import { isEqual } from "lodash";

export interface IUseSystemMessagesReturnValue {
  addSystemMessage: (payload: ISystemMessage) => void;
  editSystemMessage: (identifier: string, data: TSystemMessagePatch) => void;
  removeSystemMessage: (identifier: string) => void;
}

/**
 * Simplified hook for manipulate System messages
 */
export const useSystemMessages = (): IUseSystemMessagesReturnValue => {
  const dispatch = useDispatch<Dispatch<ISystemMessageAction>>();
  const systemMessages = useSelector(({ systemMessages }: ILocalState) => systemMessages, isEqual);
  return {
    addSystemMessage: ({ identifier, ...data }: ISystemMessage): void => {
      if (
        systemMessages.find(
          ({ identifier: existingIdentifier }) => existingIdentifier === identifier
        )
      )
        return;
      dispatch({
        type: ISystemMessageActionTypes.ADD_SYSTEM_MESSAGE,
        payload: {
          identifier,
          data,
        },
      });
    },
    editSystemMessage: (identifier: string, patch: TSystemMessagePatch): void => {
      dispatch({
        type: ISystemMessageActionTypes.EDIT_SYSTEM_MESSAGE,
        payload: {
          identifier,
          patch,
        },
      });
    },
    removeSystemMessage: (identifier: string): void => {
      dispatch({
        type: ISystemMessageActionTypes.REMOVE_SYSTEM_MESSAGE,
        payload: {
          identifier,
        },
      });
    },
  };
};
