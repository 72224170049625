import { FC, useMemo } from "react";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../../components/Icon";
import { ButtonVariantEnums, IconButton } from "../../../../components/formElements/Button";

import {
  StyledBarsButton,
  StyledContentNav,
  StyledContentNavClientManagement,
  StyledContentNavSections,
  StyledContentNavTitle,
  StyledContentNavUserManagement,
} from "./styled.content-nav";

import { useTranslation } from "react-i18next";
import { ClientManagement } from "./ClientManagement";
import { UserOptions } from "./UserOptions";
import { Notifications } from "./Notifications";
import { ILocalState, IThemeOptions, LayoutEnums } from "../../../../@types/index.d";
import { SetupLogo } from "./SetupLogo";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import {
  IThemeOptionsAction,
  ThemeStateActionTypes,
} from "../../../../_lib/store/reducers/themeOptions";
import { isEqual } from "lodash";

export const ContentNav: FC = () => {
  const { t } = useTranslation();
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();
  const isLayoutCommon = themeOptions.layout === LayoutEnums.COMMON;
  const isLayoutSetup = themeOptions.layout === LayoutEnums.SETUP;
  const clientName = useMemo(() => {
    const selectedClient = currentUser.clients.find(
      (client) => client.client_id === currentUser.selectedClient.client_id
    );

    if (!selectedClient) return t("app.name");
    return selectedClient.name;
  }, [currentUser.clients, currentUser.selectedClient.client_id, t]);

  const handleToggleSideNav = (): void => {
    dispatch({
      type: ThemeStateActionTypes.SET_THEME,
      payload: {
        ...themeOptions,
        sideNavExpanded: !themeOptions.sideNavExpanded,
      } as Partial<IThemeOptions>,
    });
  };
  return (
    <>
      <StyledContentNav>
        {isLayoutCommon && (
          <>
            <StyledBarsButton>
              <IconButton
                variant={ButtonVariantEnums.SECONDARY}
                type={"button"}
                onClick={handleToggleSideNav}
                icon={<Icon name={IconNameEnums.BARS} size={IconSizeEnums.MEDIUM} />}
                inverted={false}
              />
            </StyledBarsButton>
            <StyledContentNavTitle>
              <span>{clientName}</span>
            </StyledContentNavTitle>
          </>
        )}
        {isLayoutSetup && (
          <StyledContentNavSections>
            <SetupLogo />
          </StyledContentNavSections>
        )}
        <StyledContentNavClientManagement>
          <ClientManagement />
        </StyledContentNavClientManagement>
        <StyledContentNavSections>
          <Notifications />
        </StyledContentNavSections>
        <StyledContentNavUserManagement>
          <UserOptions />
        </StyledContentNavUserManagement>
      </StyledContentNav>
    </>
  );
};
