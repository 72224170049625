import { useTranslation } from "react-i18next";
type IUseFormatDate = () => (value: string | number | Date) => string;
export const useFormatDate: IUseFormatDate = () => {
  const { t } = useTranslation();
  const monthNames: string[] = t(`misc.months.long`, { returnObjects: true });

  return (value: string | number | Date): string => {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${`0${day}`.slice(-2)} ${monthNames[month]} ${year}`;
  };
};
