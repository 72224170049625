import { getI18n } from "react-i18next";
import awsConfig from "../../awsConfig";
export interface IGetAssetPathOptions {
  file: string | undefined;
}
export type TGetAssetPath = (options: IGetAssetPathOptions) => string | undefined;

export const getAssetPath: TGetAssetPath = ({ file }) => {
  const { language } = getI18n();
  if (file === undefined) return undefined;
  return `https://${awsConfig.assets.host}/${language}/${file}`;
};
