import { default as appStore } from "./appStore";
import { default as currentUser } from "./currentUser";
import { default as customization } from "./customization";
import { default as dataSync } from "./dataSync";
import { default as downloads } from "./downloads";
import { default as draftClient } from "./draftClient";
import { default as eventsState } from "./eventsState";
import { default as planning } from "./planning";
import { default as reporting } from "./reports";
import { default as systemMessages } from "./systemMessages";
import { default as themeOptions } from "./themeOptions";
import { default as uploads } from "./uploads";
import { default as warmUp } from "./warmUp";

import { combineReducers } from "@reduxjs/toolkit";

export * from "./appStore";
export * from "./currentUser";
export * from "./customization";
export * from "./dataSync";
export * from "./downloads";
export * from "./draftClient";
export * from "./eventsState";
export * from "./planning";
export * from "./reports";
export * from "./systemMessages";
export * from "./themeOptions";
export * from "./uploads";
export * from "./warmUp";

export default combineReducers({
  appStore,
  currentUser,
  customization,
  dataSync,
  downloads,
  draftClient,
  eventsState,
  planning,
  reporting,
  systemMessages,
  themeOptions,
  uploads,
  warmUp,
});
