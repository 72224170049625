import { FC, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { LoaderTypeEnums, LoadingIndicator } from "../../../../components/LoadingIndicator";
import { formatNumber } from "../../../../_lib/common";
import { useSelector } from "react-redux";
import {
  EDataSyncLevel,
  EDataSyncRefreshRate,
  EDataSyncScope,
  ILocalState,
} from "../../../../@types/index.d";
import { useSyncEvents } from "../../../../_lib/hooks";
import { isEqual } from "lodash";

export const CurrentGrade: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { currentGrade, prevGrade, pending } = currentUser.selectedClient.evaluation || {};
  const { startSync, stopSync } = useSyncEvents();
  const { client_id } = currentUser.selectedClient;
  const [inSync, setInSync] = useState(false);

  useEffect(() => {
    if (!client_id || inSync) return;
    const refreshRate =
      currentGrade === undefined ? EDataSyncRefreshRate.FAST : EDataSyncRefreshRate.SLOW;
    const retryCount = refreshRate === EDataSyncRefreshRate.FAST ? 10 : undefined;

    startSync(
      {
        level: EDataSyncLevel.CLIENT,
        scope: EDataSyncScope.EVAL,
        refreshRate,
      },
      retryCount
    );
    setInSync(true);
    return (): void => {
      if (!inSync) return;
      stopSync({ level: EDataSyncLevel.CLIENT, scope: EDataSyncScope.EVAL });
      setInSync(false);
    };
  }, [client_id, currentGrade, startSync, stopSync, inSync]);

  if (!currentGrade && pending) {
    return (
      <LoadingIndicator
        type={LoaderTypeEnums.TEXT}
        placeholder={
          <Trans
            i18nKey={"dashboard.options.report_note"}
            values={{
              current_note: "..",
              previous_note: ".",
            }}
          />
        }
      />
    );
  }
  if (currentGrade) {
    return (
      <Trans
        i18nKey={"dashboard.options.report_note"}
        values={{
          current_note: formatNumber(currentGrade || -1, {
            maximumFractionDigits: 1,
          }),
          previous_note: formatNumber(prevGrade || -1, {
            maximumFractionDigits: 1,
          }),
        }}
      />
    );
  }

  return (
    <>
      <>&nbsp;</>
      <br />
      <span> </span>
      <br />
      <>&nbsp;</>
    </>
  );
};
