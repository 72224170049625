import { FC, useContext, MouseEvent } from "react";
import { SelectDispatch } from "./Select";
import { StyledSelectListItem } from "./styled.select";
import { IInputConstructorProps, IInputLabelPosition } from "../../../@types";
export interface IStyledListWrapperProps extends Pick<IInputConstructorProps, "variant"> {
  position: DOMRect;
  labelPosition?: IInputLabelPosition;
}

export interface ISelectItemProps {
  /** will be assigned to Select element value*/
  value: string;
  /** visible Text in Select in UI */
  text: string;
  /** A list item which has matching value with selectionValue property of Select element
   *  will be set  automatically by Select Comp. No need to set this.
   * */
  selected?: boolean;
  /**
   * A list item which can not be selected
   */
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLLIElement>) => void;
  testID?: string;
}
export const SelectItemTestID = "SelectItem";

/**
 * List item in a Select component wit the type of "list"
 *
 */
export const SelectItem: FC<ISelectItemProps> = ({
  value = "",
  text = "",
  selected = false,
  disabled = false,
  onClick,
  testID = "",
}: ISelectItemProps) => {
  const selectionDispatch = useContext(SelectDispatch);
  const handleSelection = (e: MouseEvent<HTMLLIElement>): void => {
    if (disabled) return;
    selectionDispatch({ value, text, open: false });
    onClick && onClick(e);
  };

  return (
    <StyledSelectListItem
      onClick={handleSelection}
      selected={selected}
      disabled={disabled}
      data-test-id={testID + SelectItemTestID}
    >
      {text}
    </StyledSelectListItem>
  );
};
