import styled, { css } from "styled-components/macro";

const Container = styled.div(({ theme }) => {
  return css`
    position: fixed;
    z-index: 1;
    right: ${theme.rem(1)};
    bottom: ${theme.rem(1)};
    width: min(${theme.rem(20)}, 90vw);
    overflow-x: hidden;
  `;
});

export const StyledSystemMessages = { Container };
