import { gql } from "@apollo/client/core";

export const GET_PLAN_DETAILS_CONFIG = gql`
  {
    result: getPlanDetailsConfig {
      tablesDataTree {
        balanceActive {
          value
          tree {
            value
            tree {
              value
              tree {
                value
              }
            }
          }
        }
        balancePassive {
          value
          tree {
            value
            tree {
              value
              tree {
                value
              }
            }
          }
        }
        profitAndLoss {
          value
          tree {
            value
            tree {
              value
              tree {
                value
              }
            }
          }
        }
        cashFlow {
          value
          tree {
            value
            tree {
              value
              tree {
                value
              }
            }
          }
        }
      }
      detailsConfig {
        tablesConfig {
          value
          navigation
          tree {
            value
            tree {
              value
            }
          }
        }
        staticConfig {
          value
          check
          check_for
          tree {
            value
            render
          }
        }
        tables {
          value
          summary
          content
          hasSumCol
          code
          additional_input
          check_for
        }
        rows {
          type
          value
          data_source
          sub_data_source
          code
          value_key
        }
        checks {
          value
          true {
            check {
              value
            }
            render {
              value
            }
          }
          false {
            check {
              value
            }
            render {
              value
            }
          }
        }
      }
    }
  }
`;
