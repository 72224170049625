import { useTranslation } from "react-i18next";
import { FC, useContext, useEffect, useState } from "react";

import {
  FileUploadVariant,
  IArrangementKeys,
  ISelectValue,
  SelectionTypes,
} from "../../../../@types/index.d";
import { ISelectItemProps, Select, SelectItem } from "../../../../components/formElements/Select";
import { IVariants } from "./PreviewVariations";
import { initialMappingFromVariant } from "../../../../_lib/hooks";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../../index";

interface IArrangeValuesProps {
  fieldName: string;
}
export const ArrangeValues: FC<IArrangeValuesProps> = ({ fieldName }) => {
  const { t } = useTranslation();
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const variants: IVariants[] = t("setup.upload_form_2.excel.variants", { returnObjects: true });
  const { mapping, variant_type } = uploadersContext.sharedState.settings;
  const variant = variants.find((v) => v.type === variant_type)?.fields as IArrangementKeys;
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (!initialRender) return;
    setInitialRender(false);
  }, [initialRender]);

  useEffect(() => {
    if (mapping || variant_type === FileUploadVariant.UNKNOWN) return;
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        settings: {
          mapping: initialMappingFromVariant(variant_type),
        },
      },
    });
  }, [uploadersDispatch, mapping, variant_type]);

  if (variant_type === FileUploadVariant.UNKNOWN) return null;
  if (!mapping) return null;

  const handleValueArrangement = ({ value, name }: ISelectValue<string>): void => {
    if (!mapping) return;
    const key: keyof IArrangementKeys = value as unknown as keyof IArrangementKeys;
    const changedMapping: Partial<typeof mapping> = {};
    if (value === "__deselect__") {
      Object.entries(mapping).forEach(([mKey, mVal]) => {
        const m: keyof IArrangementKeys = mKey as unknown as keyof IArrangementKeys;
        if (mVal === name) {
          changedMapping[m] = "";
        }
      });
    }
    if (value !== "__deselect__" && mapping[key] !== name) {
      Object.keys(mapping).forEach((mKey) => {
        const m: keyof IArrangementKeys = mKey as unknown as keyof IArrangementKeys;
        if (m !== key && mapping[m] === name) {
          changedMapping[m] = "";
        }
      });
      changedMapping[key] = name;
    }

    if (Object.keys(changedMapping).length === 0) return;
    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        settings: {
          mapping: { ...mapping, ...changedMapping },
        },
      },
    });
  };
  /**
   * Calculates Variation SelectItem state props...
   */
  const selectionState = (k: keyof IArrangementKeys): Partial<ISelectItemProps> => {
    // const { settings } = uploadersContext;

    if (!mapping[k]) {
      return { disabled: false, selected: false };
    } else
      return {
        disabled: !!mapping[k] && mapping[k] !== "",
        selected: mapping[k] === fieldName,
      };
  };
  const getSelectedItemValue = (): { text: string; value: string } => {
    const val = Object.entries(mapping).find(([, value]) => value === fieldName);
    const hasInitialValue = val && val[0];
    let text = t("misc.select");
    let value = "__deselect__";

    if (hasInitialValue && val) {
      value = val[0];
      const k = val[0] as keyof IArrangementKeys;
      text = variant[k];
    }

    return { text, value };
  };
  return (
    <>
      {mapping && (
        <Select
          key={variant_type}
          type={SelectionTypes.LIST}
          name={fieldName}
          onChange={handleValueArrangement}
          {...getSelectedItemValue()}
        >
          <>
            <SelectItem key={"__deselect__"} value={"__deselect__"} text={t("misc.select")} />
            {Object.keys(mapping).map((arrangementKey, index) => {
              const k = arrangementKey as keyof IArrangementKeys;

              return (
                <SelectItem
                  key={index}
                  value={arrangementKey}
                  text={variant[k] || ""}
                  {...selectionState(k)}
                />
              );
            })}
          </>
        </Select>
      )}
    </>
  );
};
