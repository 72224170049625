import styled, { css } from "styled-components/macro";
import { useResponsive } from "../../../../_lib/hooks/useResponsive";

const Container = styled.section(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        width: max(${theme.rem(30)}, 90vw);
        height: 75vh;
        overflow-y: scroll;
        margin-bottom: ${theme.rem(1)};
      `,
    },
    MEDIUM: {
      style: css`
        width: min(${theme.rem(90)}, 90vw);
      `,
    },
  });
});
const Footer = styled.section(({ theme }) => {
  return css`
    text-align: center;
    margin-bottom: ${theme.rem(1)};
  `;
});
export const StyledFinancialGrade = {
  Container,
  Footer,
};
