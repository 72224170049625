import { FC, useContext, useEffect } from "react";
import { AlertTypeEnums, AlignmentEnums } from "../../../@types/index.d";
import { P } from "../../../components/Typography";
import { FormDispatch, FormRow } from "../../../components/formElements";
import { useTranslation } from "react-i18next";
import { MultiSuSasYear, MultiSuSaFiles } from "../UploadersSharedContext";

import { EAutoCloseTimeOut } from "../../../_lib/store/reducers";
import { useSystemMessages } from "../../../_lib/hooks/useSystemMessages";
import { resetFileContent } from "../../../_lib/hooks";
import { useMultipleUploadSucceeded } from "../UploadersSharedContext/sharedHooks";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../index";

export const MultiSuSasUpload: FC = () => {
  const formDispatch = useContext(FormDispatch);
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const { addSystemMessage } = useSystemMessages();
  const fileListInMultiUploadContext = Object.keys(uploadersContext.sharedState.files);

  const { t } = useTranslation();
  const multipleUploadSucceeded = useMultipleUploadSucceeded();
  useEffect(() => {
    if (multipleUploadSucceeded) {
      fileListInMultiUploadContext.forEach((file) => {
        resetFileContent(file, formDispatch);
      });
      uploadersDispatch({ type: EUploadersActionTypes.RESET, payload: {} });
      addSystemMessage({
        identifier: "multiple-susa-upload", // All files in multi-upload can be identified with a single identifier,
        // as this is a single operation in view of SystemMessages.
        // there fore this string can be anything , which should
        // not be possible the same identifier
        type: AlertTypeEnums.SUCCESS,
        code: "setup.upload_form_2.datev.messages.upload_success",
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
      });
    }
  }, [
    addSystemMessage,
    multipleUploadSucceeded,
    uploadersDispatch,
    formDispatch,
    fileListInMultiUploadContext,
  ]);

  return (
    <>
      <FormRow align={AlignmentEnums.STRETCH}>
        <P>{t("setup.upload_form_2.datev.select_file_multi", { step: 2 })}</P>
      </FormRow>
      <FormRow align={AlignmentEnums.STRETCH}>
        <MultiSuSasYear />
      </FormRow>
      <FormRow align={AlignmentEnums.STRETCH}>
        <P>{t("setup.upload_form_2.datev.upload_multi_info", { step: 3 })}</P>
      </FormRow>
      <FormRow align={AlignmentEnums.SPACE_BETWEEN}>
        <MultiSuSaFiles />
      </FormRow>
    </>
  );
};
