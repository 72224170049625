import { css, SimpleInterpolation } from "styled-components/macro";
import { useDevice } from "./useDevice";

enum DeviceSizes {
  MINI = "MINI",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
}

interface IUseResponsiveOptionProps {
  style?: SimpleInterpolation;
  landscape?: SimpleInterpolation;
  portrait?: SimpleInterpolation;
  retina?: SimpleInterpolation;
}
export type IUseResponsiveOptions = Partial<Record<DeviceSizes, IUseResponsiveOptionProps>>;
type TUseResponsive = (options: IUseResponsiveOptions) => SimpleInterpolation;

export const useResponsive: TUseResponsive = ({ MINI, SMALL, MEDIUM, LARGE, XLARGE }) => {
  const { isMini, isSmall, isMedium, isLarge, isXLarge, isPortrait, isLandscape, isRetina } =
    useDevice();
  const getResponsiveStyle = (options?: IUseResponsiveOptionProps): SimpleInterpolation => {
    if (!options) return;
    const { style, landscape, portrait, retina } = options;
    const basicStyle = style;
    const retinaStyle = isRetina ? retina : null;
    const orientation = isLandscape ? landscape : isPortrait ? portrait : null;
    return css`
      ${basicStyle}
      ${retinaStyle}
      ${orientation}
    `;
  };

  return css`
    ${isMini && getResponsiveStyle(MINI)}
    ${isSmall && getResponsiveStyle(SMALL)}
    ${isMedium && getResponsiveStyle(MEDIUM)}
    ${isLarge && getResponsiveStyle(LARGE)}
    ${isXLarge && getResponsiveStyle(XLARGE)}
  `;
};
