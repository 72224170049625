export enum EPrivatePathName {
  DASHBOARD = "/dashboard",
  ACCOUNT = "/account",
  CUSTOMIZATION = "/branding",
  REPORTS = "/reports",
  REPORTS_CURRENT = "/reports/current-client",
  REPORTS_CROSS_CLIENT = "/reports/cross-client",
  COMPANY = "/company",
  COMPANY_DATA = "/company/data",
  COMPANY_USERS = "/company/users",
  KPI = "/kpi",
  PLAN = "/planning",
  PLAN_CREATE = "/planning/create",
  PLAN_PREVIEW = "/planning/preview",
  UPLOAD = "/upload",
  SETUP = "/setup",
  LOGOUT = "/logout",
}
export enum EPublicPathName {
  HOME = "/",
  RESET = "/reset",
  LOGIN = "/login",
  FORGOT = "/forgot",
  CHANGE_PASSWORD = "/change-password",
  REGISTER = "/register",
  REGISTER_SUCCESS = "/register-success",
}
