import { useDialogs } from "../../../../_lib/hooks";
import { IDialogKeyEnums } from "../../../../@types/index.d";
import {
  EQuickCheckCode,
  IUploadValidationContext,
  uploadValidationSummaryDialog,
} from "../uploadValidationSummaryDialog";
import { useTranslation } from "react-i18next";
import { parseDate } from "../../../../_lib/common";

type TUseUploadValidationSummary = () => IUseUploadValidationSummaryRetVal;
interface IUseUploadValidationSummaryRetVal {
  validate: (susa_id: string, date: string) => void;
}
const initialValidationState: IUploadValidationContext = {
  susa_id: "",
  date: "",
  loading: true,
  quickCheck: {
    profitAndLoss: EQuickCheckCode.INITIAL,
    activePassive: EQuickCheckCode.INITIAL,
    cashFlow: EQuickCheckCode.INITIAL,
  },
  keyFacts: new Array(8).fill({ name: "", absolute: 0, percent: 0 }),
};
export const useUploadValidationSummary: TUseUploadValidationSummary = () => {
  const { setData, open } = useDialogs();
  const { t } = useTranslation();
  const months = t("misc.months.long", { returnObjects: true });
  return {
    validate: (susa_id: string, date: string): void => {
      const { month, year } = parseDate(date);
      const title = t("upload.summary.title", { month: months[month], year });
      open({ ...uploadValidationSummaryDialog, title });
      setData(IDialogKeyEnums.SIMPLE, { data: { ...initialValidationState, susa_id, date } });
    },
  };
};
