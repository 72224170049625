import { getI18n } from "react-i18next";
import { useEffect, useState } from "react";
import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { Relation, RelationLink } from "../../../@types/index.d";
import { getAssetPath } from "../../common";

const KPI_ASSETS = gql`
  query getKpiAssets($kpiId: ID!) {
    getKpiInfo(kpiId: $kpiId) {
      value
      links {
        href
        rel
      }
    }
  }
`;
export interface KpiAssetsInfo {
  value: string;
  links?: RelationLink[];
}

export interface IKpiAssetLinks {
  video?: string;
  poster?: string;
  formula?: string;
}
export interface IUseKpiAssetsReturnValue extends Omit<QueryResult, "data"> {
  assets?: IKpiAssetLinks;
}
type TUseKpiAssets = (kpiId: string, options: QueryHookOptions) => IUseKpiAssetsReturnValue;

export const useKpiAssets: TUseKpiAssets = (kpiId, options) => {
  const { language } = getI18n();
  const { data, ...rest } = useQuery(KPI_ASSETS, {
    variables: {
      kpiId,
    },
    ...options,
  });
  const [assets, setAssets] = useState<IKpiAssetLinks | undefined>(undefined);
  useEffect(() => {
    if (data && data?.getKpiInfo) {
      const { links } = data?.getKpiInfo[0] as KpiAssetsInfo;

      if (links) {
        const { href: video } = links.find(({ rel }) => rel === Relation.video) || {};
        const { href: poster } = links.find(({ rel }) => rel === Relation.poster) || {};
        const { href: formula } = links.find(({ rel }) => rel === Relation.formula) || {};
        setAssets({
          video: getAssetPath({ file: video }),
          poster: getAssetPath({ file: poster }),
          formula: getAssetPath({ file: formula }),
        });
      }
    }
  }, [data, language]);

  // [assets,getAssets]
  return {
    assets,
    ...rest,
  };
};
