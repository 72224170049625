import styled, { css } from "styled-components/macro";
import { IMenuItemProps } from "../../../@types/index.d";
import { rgba } from "polished";
import { Children } from "react";
import { useResponsive } from "../../../_lib/hooks/useResponsive";

export interface IStyledSubMenuProps {
  expanded: boolean;
}
export const StyledMenu = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 100%;
  `
);
export const StyledSubMenu = styled.ul<IStyledSubMenuProps>(({ theme, children, expanded }) => {
  const itemsCount = Children.count(children);
  const expandedMenuCss = css`
    max-height: ${theme.rem(3 * (itemsCount + 1))};
  `;
  const expandedSideNavCss = css`
    padding: 0 ${theme.rem(1)};
    position: relative;
    left: unset;
    top: unset;
    transform: translateY(0);
  `;
  const collapsedNavCss = css`
    padding: 0 0 0 ${theme.rem(0.5)};
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  `;
  return useResponsive({
    MINI: {
      style: css`
        background-color: ${theme.values.colors.global.secondary._100};
        list-style: none;
        max-height: 0;
        transition: max-height 300ms;
        transition-delay: 100ms;
        overflow-y: hidden;
        z-index: 3;
        ${!theme.sideNavExpanded ? expandedSideNavCss : collapsedNavCss}
        ${expanded && expandedMenuCss}
      `,
    },
    LARGE: {
      style: css`
        ${theme.sideNavExpanded ? expandedSideNavCss : collapsedNavCss}
      `,
    },
  });
});

export const StyledMenuItem = styled.li<Partial<IMenuItemProps>>(
  ({ theme, level, selected, expanded }) => {
    const topLevelSelectedCss = css`
      border-left: ${theme.rem(1 / 3)} solid ${theme.branded.colors.secondary};
    `;
    const subLevelSelectedCss = css`
      &:after {
        background-color: ${theme.branded.colors.secondary};
      }
    `;
    const subLevelCss = css`
      position: relative;
      margin-left: ${theme.rem(1.5)};
      font-size: ${theme.rem(0.9)};
      align-items: flex-start;
      &:after {
        content: "";
        position: absolute;
        width: ${theme.rem(0.5)};
        height: ${theme.rem(0.5)};
        left: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        top: 50%;
        background-color: ${theme.values.colors.global.greys.first};
        transition: all 300ms;
      }
      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: ${theme.values.colors.global.greys.first};
        left: -1px;
        top: 0;
      }
      ${selected && subLevelSelectedCss}
    `;

    const sideNavCollapsedCss = css`
      flex-direction: column;
      align-items: center;

      text-align: center;
    `;
    return useResponsive({
      LARGE: {
        style: css`
          margin: 0 ${theme.rem(theme.sideNavExpanded ? -1 : -0.5)};
          > a {
            flex-direction: row;
            justify-content: left;
            ${!theme.sideNavExpanded && sideNavCollapsedCss}
            ${level === 1 && subLevelCss}
          }
        `,
      },
      MINI: {
        style: css`
          list-style: none;
          padding: 0;
          position: relative;
          transition: background-color 300ms;
          margin: 0 ${theme.rem(-0.5)};

          > a {
            color: ${theme.branded.colors.nav_text_color};
            padding: ${theme.rem(1)} ${theme.rem(1)};
            font-weight: ${theme.values.fontWeight.bold};
            display: flex;
            justify-content: left;
            text-align: left;
            ${theme.sideNavExpanded && sideNavCollapsedCss}
            &:hover {
              background-color: ${rgba(theme.values.colors.global.greys.first, 0.25)};
            }
            &:hover,
            &:active,
            &:visited {
              text-decoration: none;
            }
          }

          ${level === 0 && selected && topLevelSelectedCss}
        `,
      },
    });
  }
);
export const StyledMenuIcon = styled.span``;
export const StyledMenuLabel = styled.span<Partial<IMenuItemProps>>(({ theme }) => {
  const expandedNavCss = css`
    padding-left: ${theme.rem(0.75)};
    font-size: ${theme.rem(1)};
  `;
  const collapsedNavCss = css`
    padding: ${theme.rem(0.5)} 0;
    font-size: ${theme.rem(2 / 3)};
  `;
  return useResponsive({
    MINI: {
      style: css`
        display: inline-block;
        flex-grow: 1;
        text-transform: capitalize;
        ${theme.sideNavExpanded
          ? collapsedNavCss
          : css`
              display: none;
            `}
      `,
    },
    LARGE: {
      style: css`
        display: inline-block;
        padding: 0;
        flex-grow: 1;
        text-transform: capitalize;
        text-align: left;
        ${theme.sideNavExpanded ? expandedNavCss : collapsedNavCss}
      `,
    },
  });
});

export const StyledSubMenuLabel = styled(StyledMenuLabel)(({ theme }) => {
  const expandedNavCss = css`
    font-size: ${theme.rem(1)};
  `;
  const collapsedNavCss = css`
    font-size: ${theme.rem(0.75)};
  `;
  return useResponsive({
    MINI: {
      style: css`
        ${!theme.sideNavExpanded ? expandedNavCss : collapsedNavCss}
      `,
    },
    LARGE: {
      style: css`
        ${theme.sideNavExpanded ? expandedNavCss : collapsedNavCss}
      `,
    },
  });
});

export const StyledRuler = styled.hr`
  border: none;
  height: 1px;
  background-color: white;
`;
