import { FC } from "react";

import {
  StyledFormElementContainer,
  // StyledBrandedFormElementContainer,
} from "./styled.form-element-container";
import { IInputConstructorProps } from "../../../@types/index.d";

export type TFormElementContainerProps = Pick<
  IInputConstructorProps,
  "label" | "valid" | "invalidWarning" | "labelPosition"
>;
export const FormElementContainer: FC<TFormElementContainerProps> = ({
  children,
  label,
  valid,
  invalidWarning,
  labelPosition,
}) => { 
  return (
    <StyledFormElementContainer.FullWidthContainer>
      <StyledFormElementContainer.ItemWrapper labelPosition={labelPosition}>
        {label && (
          <StyledFormElementContainer.Label labelPosition={labelPosition}>{label}</StyledFormElementContainer.Label>
        )}
        {children}
      </StyledFormElementContainer.ItemWrapper>
      {!valid && <StyledFormElementContainer.Warning>{invalidWarning}</StyledFormElementContainer.Warning>}
    </StyledFormElementContainer.FullWidthContainer>
  );
};
