import styled, { css, DefaultTheme, Keyframes, keyframes } from "styled-components/macro";
import { useResponsive } from "../../_lib/hooks/useResponsive";
import { rgba } from "polished";
import { EPanelTitleAlignment, IPanelProps, IPanelTitleProps } from "./index";
import { HTMLAttributes } from "react";
const initialize = (theme: DefaultTheme): Keyframes => keyframes`
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(${theme.rem(3)});
      opacity: 1;
    }
    100% {
      transform: translateY(0);

    }
  `;

export const StyledPanel = styled.section<Partial<IPanelProps>>(({ theme, shadow }) => {
  return useResponsive({
    MINI: {
      style: css`
        ${!shadow ? "" : `box-shadow: 0 0 5px ${theme.values.colors.global.greys.sixth};`}
        background-color: ${rgba(
          theme.values.component.content.backgroundColor,
          +theme.branded.colors.app_opacity / 100
        )};
        display: flex;
        flex-direction: column;
        animation: ${initialize(theme)} 100ms;
        flex: 1 0 0;
      `,
    },
  });
});
const __contentDefaults = styled.div<HTMLAttributes<HTMLDivElement>>(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        padding: ${theme.rem(0.25)};
      `,
    },
    SMALL: {
      style: css`
        padding: ${theme.rem(0.5)};
      `,
    },
  });
});
export const StyledPanelTitle = styled(__contentDefaults)(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        padding-left: 0;
        padding-right: ${theme.rem(0.5)};
        background-color: ${theme.values.component.header.backgroundColor};
        display: flex;
        z-index: 2;
      `,
    },
  });
});
export const StyledPanelTitleHeadline = styled.div(({ theme }) => {
  return css`
    border-left: ${theme.rem(0.33)} ${theme.branded.colors.secondary} solid;
    padding: 0 ${theme.rem(0.5)};
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;

    h2,
    h3 {
      line-height: 1.125;
      flex-basis: 100%;
    }
    h2 {
      margin: 0;
      font-weight: ${theme.values.fontWeight.bolder};
      color: ${theme.branded.colors.primary};
      font-size: ${theme.rem(1.125)};
      &:only-child {
        align-self: center;
      }
    }
    h3 {
      margin: 0;
      font-weight: ${theme.values.fontWeight.bold};
      color: ${theme.branded.colors.secondary};
      font-size: ${theme.rem(1)};
    }
  `;
});

export const StyledPanelTitleOptionsContainer = styled.div<Partial<IPanelTitleProps>>(
  ({ theme, align, optionsWidth }) => {
    // TODO: more precisely centering elements in PanelTitleOptions for smaller devices may be required
    const alignLeft = css`
      justify-content: flex-start;
    `;
    const alignRight = css`
      justify-content: flex-end;
    `;
    const alignCenter = css`
      justify-content: flex-start;
    `;

    return css`
      display: flex;
      align-items: center;
      ${alignLeft};
      flex-grow: 1;
      ${align === EPanelTitleAlignment.CENTER && alignCenter}
      ${align === EPanelTitleAlignment.RIGHT && alignRight}

      & > div {
        display: inline-block;
        ${optionsWidth &&
        css`
          width: ${optionsWidth};
        `}
        ${align === EPanelTitleAlignment.CENTER &&
        css`
          transform: translateX(-50%);
        `}
      }
    `;
  }
);
export const StyledPanelBody = styled(__contentDefaults)(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        flex-grow: 1;
        padding: ${theme.rem(0.5)};
      `,
    },
    SMALL: {
      style: css`
        padding: ${theme.rem(1)};
      `,
    },
    MEDIUM: {
      style: css`
        padding: ${theme.rem(1)};
      `,
    },
    LARGE: {
      style: css`
        padding: ${theme.rem(1.5)};
      `,
    },
  });
});
export const StyledPanelFooter = styled(__contentDefaults)(({ theme }) => {
  return css``;
});
const helpAnimation = keyframes`
    0% {
      transform: scale(.5);
      opacity: 0;
    }
    20% {
      transform: scale(.75);
      opacity: 1;
    }
    40% {
      transform: scale(.60);
      opacity: 0;
    }
    60% {
      transform: scale(.75);
      opacity: 1;
    }
    100%{
      transform: scale(.5);
      opacity: 0;
    }
  `;
export const StyledHelp = styled.span(({ theme }) => {
  const firstStop = theme.values.colors.global.validation.first;
  const secondStop = rgba(theme.values.colors.global.secondary.first, 0.75);
  return css`
    display: inline-block;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background-color: inherit;
    width: ${theme.rem(2)};
    height: ${theme.rem(2)};
    &:after {
      content: "";
      position: absolute;
      width: ${theme.rem(2)};
      height: ${theme.rem(2)};
      opacity: 0;
      left: 0;
      top: 2px;
      transform-origin: 50% 50%;
      background-image: radial-gradient(
        circle at 50%,
        transparent 40%,
        #ffffff50 40%,
        ${firstStop} 50%,
        ${secondStop} 75%
      );
      animation: ${helpAnimation} 1.5s 3;
      border-radius: 50%;
    }
  `;
});
export const StyledExpandable = styled.div(() => {
  return css`
    display: block;
  `;
});
