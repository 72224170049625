import styled, { css, keyframes } from "styled-components/macro";
import { CellProps } from "./Cell";
import { TableProps } from "./Table";

const appearIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const StyledTable = styled.table<TableProps>(({ theme, width }) => {
  return css`
    width: ${width ? width : "unset"};
    border-spacing: 0;
    font-size: ${theme.rem(1)};
    margin-bottom: ${theme.rem(1)};
  `;
});
export const StyledTableRow = styled.tr(({ theme }) => {
  return css``;
});

export const StyledTableCell = styled.td<CellProps>(
  ({
    theme,
    align,
    highlight,
    level,
    width = "auto",
    animate = true,
    borderRight = false,
    editable,
    noPadding,
  }) => {
    let bgColor = theme.values.colors.global.greys.first;
    if (highlight) bgColor = theme.values.colors.global.greys.second;
    if (editable) bgColor = "rgba(253, 106, 2, 0.05)" as string;

    const titleCss = css`
      font-weight: ${theme.values.fontWeight.bold};
    `;
    const upperCss = css`
      text-transform: uppercase;
    `;
    const subTitleCss = css`
      padding-left: ${theme.rem(1.5)};
      position: relative;
      z-index: 1;
    `;
    const animationCss = css`
      opacity: 0;
      animation: ${appearIn} 300ms forwards;
      animation-delay: 300ms;
    `;
    return css`
      background-color: ${bgColor};
      width: ${width};
      text-align: ${align || "left"};
      padding: ${noPadding ? "5px 0 0 0" : theme.rem(0.5)};
      border-bottom: 1px solid ${theme.values.colors.global.greys.third};
      ${borderRight && `border-right: 1px solid ${theme.values.colors.global.greys.third};`}
      ${animate && animationCss}
      ${(level === "title" || level === "upper") && titleCss}
      ${level === "upper" && upperCss}
      ${level === "subTitle" && subTitleCss}

      &:hover {
        z-index: 2;
      }
    `;
  }
);
