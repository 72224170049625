import { FC } from "react";
import { InputProps, InputTypes } from "../../../@types/index.d";
import { InputConstructor } from "./InputConstructor";

export const Input: FC<InputProps<HTMLInputElement>> = ({
  type = InputTypes.TEXT,
  ...rest
}) => {
  return (
    <>
      <InputConstructor type={type} {...rest} />
    </>
  );
};
