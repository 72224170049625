import styled, { css } from "styled-components/macro";
import {
  IInputConstructorProps,
  IInputLabelPosition,
  IInputVariantEnums,
  InputTypes,
} from "../../../@types/index.d";

interface NoBorderForInput {
  noBorder?: boolean; // added for the inputs like the ones in plan details personalplanung static parameter table
}
type StyledBrandedInputInnerWrapperProps = Pick<IInputConstructorProps, "variant"> &
  NoBorderForInput;
type StyledInnerWrapperProps = Pick<IInputConstructorProps, "variant" | "labelPosition"> &
  NoBorderForInput;

export const StyledInnerWrapper = styled.div<StyledInnerWrapperProps>(
  ({ theme, variant = IInputVariantEnums.SECONDARY, labelPosition, noBorder = false }) => {
    const { borderColor, radius } = theme.branded.inputs[variant];
    const borderNone = css`
      border: none;
    `;
    return css`
      display: flex;
      flex-basis: ${labelPosition === IInputLabelPosition.TOP ? "100%" : `${(100 / 3) * 2}%`};
      flex-grow: 1;
      align-items: center;
      align-self: end;
      border: 1px solid ${borderColor};
      ${noBorder && borderNone}
      border-radius: ${radius};
      padding: 1px;
      position: relative;
    `;
  }
);
/**
 * Inputs styled component
 */
export const StyledInput = styled.input<Partial<IInputConstructorProps>>(
  ({
    theme,
    variant = IInputVariantEnums.SECONDARY,
    type,
    disabled,
    center,
    table,
    level,
    alignText,
  }) => {
    const radius = theme.branded.inputs[variant].radius;
    const textCenter = css`
      text-align: center;
    `;
    const alignTextCss = css`
      text-align: ${alignText || "left"};
    `;
    const titleCss = css`
      font-weight: ${theme.values.fontWeight.bold};
    `;
    const tableCSS = css`
      background-color: transparent;
    `;
    return css`
      font-family: inherit;
      display: block;
      font-size: ${type === InputTypes.TABLENUM || type === InputTypes.NUMBER
        ? theme.rem(0.85)
        : theme.rem(1)};
      width: 100%;
      padding: 0 ${theme.rem(0.4)} 0 ${center ? theme.rem(0.4) : theme.rem(0.8)};
      border: none;
      font-weight: ${theme.values.fontWeight.normal};
      border-radius: ${radius};
      line-height: 2;
      z-index: 1;
      text-align: ${type === InputTypes.NUMBER || type === InputTypes.TABLENUM ? "right" : "left"};
      ${disabled && `background-color: ${theme.values.colors.global.greys.second}`};
      ${center && textCenter}
      ${alignText && alignTextCss}
      ${table && tableCSS}
      ${(level === "title" || level === "upper") && titleCss}
      &:focus {
        zoom: 1.01;
      }
    `;
  }
);

export const BrandedInnerWrapper = styled.div<Pick<IInputConstructorProps, "variant">>(() => {
  return css`
    display: flex;
    flex-basis: ${(100 / 3) * 2}%;
    flex-grow: 1;
    align-items: center;
    align-self: end;
    padding: 1px;
    position: relative;
  `;
});
export const StyledBrandedInputInnerWrapper = styled.div<StyledBrandedInputInnerWrapperProps>(
  () => {
    return css`
      display: flex;
      flex-basis: ${(100 / 3) * 2}%;
      flex-grow: 1;
      align-items: center;
      align-self: end;
      padding: 1px;
      position: relative;
    `;
  }
);

export const StyledInputUnit = styled.div(({ theme }) => {
  return css`
    padding-right: 5px;
  `;
});

export const StyledInputBranded = styled.input<Partial<IInputConstructorProps>>(
  ({ theme, alignText }) => css`
    position: relative;
    line-height: 2.5;
    width: 100%;
    text-align: ${alignText || "left"};
    padding: ${theme.rem(0.5)} ${theme.rem(0.85)};
    font-size: ${theme.rem(1)};
    border: 1px solid ${theme.values.colors.global.greys.third};
    transition: all 300ms;
    transition-delay: 100ms;
    &:focus {
      outline: none;
      border-color: ${theme.values.colors.global.primary.first};
    }
    &[disabled] {
      opacity: 0.5;
    }
  `
);
