import { useEffect } from "react";
import { gql, ObservableQuery, useLazyQuery } from "@apollo/client";

import { IEvaluations, ILocalState, SelectClientState } from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { ICurrentUserAction, UserStateActionTypes } from "../store/reducers";
import { isEqual } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";

export type refetch = typeof ObservableQuery;
export type TGetCurrentGradeTuple = () => void;
export type TUseCurrentGrade = () => { updateGrades: TGetCurrentGradeTuple };
export type IUseCurrentGradeReturnValue = (client_id: string | undefined) => void;

interface IGetEvaluationsQuery {
  getEvaluationByClientId: IEvaluations[];
}

const GET_EVALUATIONS = gql`
  query getEvaluations($params: Evaluation!) {
    getEvaluationByClientId(params: $params) {
      grades {
        grade
      }
    }
  }
`;

export const useCurrentGrade: TUseCurrentGrade = () => {
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const [getGrades, { data: gradesData }] = useLazyQuery<IGetEvaluationsQuery>(GET_EVALUATIONS, {
    fetchPolicy: "network-only",
  });
  const clientId = currentUser.selectedClient.client_id;

  useEffect(() => {
    const evaluations = gradesData?.getEvaluationByClientId;
    if (!evaluations) return;
    const currentGrade = evaluations[0]?.grades[0]?.grade;
    const prevGrade = evaluations[1]?.grades[0]?.grade;
    dispatchUser({
      type: UserStateActionTypes.UPDATE_EVALUATION_DATA,
      payload: {
        selectedClient: {
          evaluation: {
            currentGrade,
            prevGrade,
            pending: false,
          },
        },
      },
    });
  }, [dispatchUser, gradesData?.getEvaluationByClientId]);

  // TODO: handle error fallback
  //  it seems that, evaluation of first susa takes longer than calculated susa,
  //  therefore it returns not found for client_id after initial upload

  const updateGrades = (): void => {
    if (currentUser.selectedClient.state !== SelectClientState.READY) return;
    dispatchUser({
      type: UserStateActionTypes.UPDATE_EVALUATION_DATA,
      payload: {
        selectedClient: {
          evaluation: {
            pending: true,
          },
        },
      },
    });
    getGrades({
      variables: {
        params: {
          client_id: clientId,
          grade_id: "GRD000",
        },
      },
    });
  };
  return { updateGrades };
};
