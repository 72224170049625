import { Children, FC, useEffect, useState } from "react";
import { ILocalState, IMenuItemProps, SelectClientState } from "../../../@types/index.d";
import { useTheme } from "styled-components/macro";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../components/Icon";
import { StyledMenuIcon, StyledMenuItem, StyledMenuLabel, StyledSubMenuLabel } from "./styled.menu";
import { Link } from "react-router-dom";
import { LoaderTypeEnums, LoadingIndicator } from "../../../components/LoadingIndicator";
import { useSelector } from "react-redux";
import { useDevice } from "../../../_lib/hooks";
import { isEqual } from "lodash";

export const MenuItem: FC<IMenuItemProps> = ({
  children,
  icon,
  label,
  href,
  onClick,
  level = 0,
  expanded,
  selected,
}) => {
  const theme = useTheme();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const [iconSize, setIconSize] = useState(IconSizeEnums.SMALL);
  const to = { pathname: href || "#", state: { selection: href } };
  const { isLarge } = useDevice();

  useEffect(() => {
    if (isLarge) {
      setIconSize(theme.sideNavExpanded ? IconSizeEnums.SMALL : IconSizeEnums.MEDIUM);
    } else {
      theme.sideNavExpanded && setIconSize(IconSizeEnums.MEDIUM);
    }
  }, [isLarge, theme.sideNavExpanded]);

  const loading = currentUser.selectedClient.state === SelectClientState.UNKNOWN;

  // DALIBOR ===> i left the below commented for you, if u need to update onClick prop, pls go index file of Menu folder and put the onClick in menu obj, u can copy from menu.PLAN and pls delete this commented code
  // overwrite onClick function if path is planning
  // onClick =
  //   href === EPrivatePathName.PLAN
  //     ? (): void => {
  //         callGetPlans(currentUser?.selectedClient?.client_id || "");
  //         switchPlanningPage(EPlanningPage.LIST);
  //       }
  //     : href === EPrivatePathName.UPLOAD
  //     ? (): void => {
  //         switchUploadsPage(EUploadsPage.LIST);
  //       }
  //     : onClick;

  const linkProps = { onClick, to };

  return (
    <>
      <StyledMenuItem level={level} selected={selected}>
        <Link {...linkProps}>
          {level === 0 && icon !== null && (
            <StyledMenuIcon>
              <Icon name={loading ? IconNameEnums.BLANK : icon} size={iconSize} />
            </StyledMenuIcon>
          )}
          {level === 0 ? (
            <StyledMenuLabel level={level}>
              {loading ? <LoadingIndicator type={LoaderTypeEnums.TEXT} length={20} /> : label}
            </StyledMenuLabel>
          ) : (
            <StyledSubMenuLabel level={level}>
              {loading ? <LoadingIndicator type={LoaderTypeEnums.TEXT} length={20} /> : label}
            </StyledSubMenuLabel>
          )}
          {Children.count(children) > 0 && (
            <Icon
              name={
                loading
                  ? IconNameEnums.BLANK
                  : expanded
                  ? IconNameEnums.CHEVRON_UP
                  : IconNameEnums.CHEVRON_DOWN
              }
              size={IconSizeEnums.XSMALL}
            />
          )}
        </Link>
        {children}
      </StyledMenuItem>
    </>
  );
};
