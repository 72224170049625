import { Button, ButtonVariantEnums } from "../../../components/formElements/Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../../components/Icon";
import { Dispatch, FC, useContext, useEffect } from "react";
import { StyledClientNav } from "./styled.setup-content";
import {
  ClientInput,
  DraftClientSetup,
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  ILocalState,
  SelectClientState,
} from "../../../@types/index.d";
import { useTranslation } from "react-i18next";
import {
  useClients,
  useSelectedClientData,
  useSwitchClient,
  useValidateDraftClient,
} from "../../../_lib/hooks";
import { useDispatch, useSelector } from "react-redux";
import { ClientSetupContext, ClientSetupDispatch } from "./";
import { isEqual } from "lodash";
import { useEventListener } from "../../../_lib/hooks/useEventListener";
import { v4 as uuidv4 } from "uuid";
import { ICurrentUserAction, UserStateActionTypes } from "../../../_lib/store/reducers";

export const clientSetupTestID = "ClientSetupNav";
export const ClientSetupNav: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { t } = useTranslation();
  const validateDraftClient = useValidateDraftClient();
  const clientSetupContext = useContext(ClientSetupContext);
  const clientSetupDispatch = useContext(ClientSetupDispatch);
  const selectedClientData = useSelectedClientData();
  const { switchClient } = useSwitchClient();
  const { create, upsert } = useClients();
  const { startEventSync } = useEventListener();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();

  useEffect(() => {
    if (
      clientSetupContext.state === DraftClientSetup.DATA_PROGRESS &&
      currentUser.clients.find(
        ({ client_id }) => client_id !== null && client_id === currentUser.selectedClient.client_id
      )
    ) {
      clientSetupDispatch({ state: DraftClientSetup.UPLOAD });
    }
  }, [
    currentUser.clients,
    clientSetupContext.state,
    clientSetupDispatch,
    currentUser.selectedClient.state,
    currentUser.selectedClient.client_id,
  ]);

  const createNewClient = (): undefined | Promise<string> => {
    if (clientSetupContext?.mapping === undefined) return;
    if (validateDraftClient({}).length > 0) return;
    const covid = uuidv4();

    const newClientData: ClientInput = {
      client_id: "",
      customer_id: currentUser.appUser.customer_id,
      name: clientSetupContext.name,
      fiscal_year: clientSetupContext.fiscal_year,
      category: clientSetupContext.category,
      mapping: clientSetupContext.mapping,
      address: {
        line_1: "",
        line_2: "",
        postal: "",
        city: "",
        telephone: "",
        country: currentUser.appUser.customer.address.country,
      },
      customization: {
        ...clientSetupContext.customization,
      },

      links: [],
    };

    // to initiate the loading spinner which will be cancelled in private index
    dispatchCurrentUser({
      type: UserStateActionTypes.EVENT_IN_PROGRESS,
      payload: { eventInProgress: true },
    });
    return create(newClientData, covid)
      .then(({ data }) => data?.createNewClient?.client_id)
      .then((client_id) => {
        startEventSync({
          level: EDataSyncLevel.CLIENT,
          accountId: client_id,
          transactionId: covid,
          scope: EDataSyncScope.CLIENT,
          type: EDataSyncTypes.UPSERT,
        });
        switchClient(client_id, SelectClientState.DRAFT);
        return client_id;
      });
  };
  const upsertSelectedClient = (): undefined | Promise<string> => {
    if (selectedClientData === false) return;
    if (clientSetupContext?.mapping === undefined) return;

    const existingClientData: ClientInput = {
      ...selectedClientData,
      customer_id: currentUser.appUser.customer_id,
      name: clientSetupContext.name,
      fiscal_year: clientSetupContext.fiscal_year,
      category: clientSetupContext.category,
      mapping: clientSetupContext.mapping,
      address: {
        ...selectedClientData.address,
        //        country: currentUser.appUser.customer.address.country,
      },
      client_id: selectedClientData.client_id,
      customization: clientSetupContext.customization,
    };

    return upsert(existingClientData).then(() => {
      // clientSetupDispatch({ state: DraftClientSetup.UPLOAD });
      return selectedClientData.client_id;
    });
  };
  const handleCreateOrUpsertClient = (): void => {
    if (validateDraftClient({}).length === 0 && clientSetupContext.mapping) {
      clientSetupDispatch({ state: DraftClientSetup.DATA_PROGRESS });

      const createOrUpdate =
        selectedClientData === false ? createNewClient() : upsertSelectedClient();

      createOrUpdate &&
        createOrUpdate.then((client_id) => {
          if (!client_id) throw new Error();
          clientSetupDispatch({ state: DraftClientSetup.UPLOAD });
        });
    }
  };
  const handleCancel = (): void => {
    switchClient(null, SelectClientState.UNKNOWN);
    // history.push(EPrivatePathName.DASHBOARD);
  };
  const handleBack = (): void => {
    clientSetupDispatch({ state: DraftClientSetup.DATA });
  };

  return (
    <StyledClientNav>
      <Button
        variant={ButtonVariantEnums.SECONDARY}
        inverted={false}
        type={"button"}
        inline={true}
        disabled={clientSetupContext.state === DraftClientSetup.DATA}
        icon={<Icon name={IconNameEnums.CHEVRON_LEFT} size={IconSizeEnums.SMALL} />}
        onClick={handleBack}
        data-test-id={`${clientSetupTestID}-back-button`}
      >
        {t("misc.back")}
      </Button>
      <Button
        variant={ButtonVariantEnums.PRIMARY}
        inverted={false}
        type={"button"}
        inline={true}
        onClick={handleCancel}
        disabled={currentUser.clients.length === 0}
        data-test-id={`${clientSetupTestID}-cancel-button`}
      >
        {t("misc.cancel")}
      </Button>
      <Button
        variant={ButtonVariantEnums.SECONDARY}
        inverted={false}
        type={"button"}
        inline={true}
        disabled={
          clientSetupContext.state === DraftClientSetup.UPLOAD ||
          clientSetupContext.state === DraftClientSetup.DATA_PROGRESS ||
          clientSetupContext.state === DraftClientSetup.UPLOAD_PROGRESS
        }
        icon={<Icon name={IconNameEnums.CHEVRON_RIGHT} size={IconSizeEnums.SMALL} />}
        onClick={handleCreateOrUpsertClient}
        data-test-id={`${clientSetupTestID}-next-button`}
      >
        {t("misc.next")}
      </Button>
    </StyledClientNav>
  );
};
