import { FC, useContext } from "react";
import { IDialogOptions } from "../../@types/index.d";
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogFooter,
  StyledDialogTitle,
  StyledDialogTitleClose,
} from "./styled.dialog";
import { ButtonVariantEnums, IconButton } from "../formElements/Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../Icon";
import { DialogDispatch } from "./index";

export const BasicDialog: FC<IDialogOptions> = ({
  title,
  closable,
  dialogKey,
  onClose,
  flexible,
  content,
  footer,
}) => {
  const dialogs = useContext(DialogDispatch);

  return (
    <StyledDialog>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      {closable && (
        <StyledDialogTitleClose>
          <IconButton
            icon={<Icon name={IconNameEnums.TIMES} size={IconSizeEnums.SMALL} />}
            variant={ButtonVariantEnums.PRIMARY}
            type={"button"}
            inverted={false}
            onClick={(): void => {
              dialogs({ type: "close", dialogKey });
              onClose && onClose();
            }}
          />{" "}
        </StyledDialogTitleClose>
      )}
      <StyledDialogContent flexible={flexible}>{content}</StyledDialogContent>
      {footer && <StyledDialogFooter>{footer}</StyledDialogFooter>}
    </StyledDialog>
  );
};
