import { gql } from "graphql.macro";

export interface IAssetUploadReVal {
  result: { covid: string; guid: string };
}

export const UPLOAD_ASSET = gql`
  mutation uploadAsset($file: AssetFileInput!, $assetType: AssetType!) {
    result: uploadAsset(file: $file, assetType: $assetType) {
      covid
      guid
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation deleteAsset(
    $accountId: ID!
    $links: [RelationLinkInput]!
    $assetType: AssetType!
    $covid: ID
  ) {
    result: deleteAsset(
      accountId: $accountId
      links: $links
      assetType: $assetType
      covid: $covid
    ) {
      guid
      covid
    }
  }
`;

export interface IGetAssetSrcReVal {
  result: string;
}

export const GET_ASSET_SRC = gql`
  query getAssetSrc($assetHref: String!) {
    result: getAssetSrc(assetHref: $assetHref)
  }
`;
