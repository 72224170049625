import { FC, useContext, useEffect } from "react";
import { FileUploadForm } from "./FileUploadForm";
import { FilePreview } from "./FilePreview";
import { Panel, PanelBody, PanelTitle } from "../../Panel";
import { useTranslation } from "react-i18next";
import { UploadersContext } from "../index";
import { IWarmUpScopes, IWarmUpTopics } from "../../../@types/index.d";
import ErrorBoundary from "../../../_lib/ErrorBoundry";
import { useWarmUp } from "../../../_lib/hooks";

export interface PreviewData {
  [key: string]: string | number;
}

export const FileUpload: FC = () => {
  const { t } = useTranslation();
  const { isInitial } = useContext(UploadersContext);
  const warmUp = useWarmUp();

  useEffect(() => {
    warmUp(IWarmUpTopics.FILE_TOPIC, IWarmUpScopes.SUSA);
  }, [warmUp]);
  return (
    <>
      <Panel>
        <PanelTitle
          headline={t("setup.upload_form_2.headline")}
          subline={t("setup.upload_form_2.excel.subline")}
          help={isInitial ? "0" : null}
        />
        <PanelBody>
          <ErrorBoundary>
            <FileUploadForm />
            <FilePreview />
          </ErrorBoundary>
        </PanelBody>
      </Panel>
    </>
  );
};
