import { EDataSyncLevel, EDataSyncScope, EDataSyncTypes, RelationLink } from "../../../@types";

export enum EventListenerCodes {
  UNKNOWN = "UNKNOWN",
  ERROR = "ERROR",
  TIME_OUT = "TIME_OUT",
  TRANSACTION_OK = "000",
  INTERNAL_SERVER_ERROR = "001",
  FILE_EVENT_ERROR = "400",
  FILE_EVENT_FORMAT = "401",
  FILE_EVENT_ACCOUNTS = "402",
  FILE_EVENT_CONVERTIBLE = "403",
  FILE_EVENT_SHEET = "405",
  FILE_EVENT_TYPE = "411",
  FILE_EVENT_PASSWORD = "412",
}

export interface IEventListenerQueryResult {
  event?: ICachedEventData;
}

export interface ICachedEventData {
  action: ICachedEventAction;
  status: EventListenerCodes;
}

export interface ICachedEventAction {
  covid: string;
  guid: string;
  links: RelationLink;
  type: string; //TODO: define type enums as in app_backend (POST/PUT/UPSERT)
  scope: EDataSyncScope;
}

export interface IStartEventListenerInput {
  level: EDataSyncLevel;
  accountId: string;
  transactionId: string;
  scope?: EDataSyncScope;
  type?: EDataSyncTypes;
}

export interface ITransaction {
  transactionId: string;
  accountId: string;
  name?: string;
  email?: string;
}
