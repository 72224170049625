import { FC, useContext, createRef, useLayoutEffect, useEffect } from "react";
import { Panel, PanelBody, PanelTitle } from "../../Panel";
import { StyledUploadManualEntryFiscalYear } from "../styled.uploaders";
import { useTranslation } from "react-i18next";
import {
  FormContext,
  FormDispatch,
  FormRow,
  getMontPickerData,
} from "../../../components/formElements";
import { AlignmentEnums } from "../../../@types/index.d";

import { UploadersContext } from "../index";
import { PlanPeriod } from "../PlanPeriod";
import { SingleSuSaDate } from "../UploadersSharedContext";

export const FiscalYear: FC = () => {
  const formDispatch = useContext(FormDispatch);
  const formContext = useContext(FormContext);
  const { t } = useTranslation();
  const refDateInput = createRef<HTMLDivElement>();
  const { isInitial } = useContext(UploadersContext);
  const { valid: montPickerValid, value: monthPickerValue } = getMontPickerData(
    "manual-fiscal-year",
    formContext
  );

  useEffect(() => {
    if (!montPickerValid) return;
    if (formContext.date !== monthPickerValue) {
      formDispatch({ date: monthPickerValue });
      formDispatch({ dateRequired: "false" });
    }
  }, [montPickerValid, monthPickerValue, formContext.date, formDispatch]);

  useLayoutEffect(() => {
    if (formContext?.dateRequired === "true") {
      refDateInput.current &&
        window.scrollTo({ top: refDateInput.current.offsetTop, behavior: "smooth" });
    }
  });

  return (
    <StyledUploadManualEntryFiscalYear isLoading={false} ref={refDateInput}>
      <Panel>
        <PanelTitle
          headline={t("setup.upload_form_2.headline")}
          subline={t("setup.upload_form_2.manual.subline")}
          help={isInitial ? "0" : null}
        />
        <PanelBody>
          <FormRow align={AlignmentEnums.CENTER}>
            <SingleSuSaDate
              name="manual-fiscal-year"
              data={[]}
              disabled={false}
              label={t("setup.upload_form_2.datev.label.deadline")}
              valid={formContext.dateRequired !== "true"}
              invalidWarning={t("misc.required_field")}
            />
          </FormRow>
          {isInitial && (
            <FormRow align={AlignmentEnums.CENTER}>
              <PlanPeriod disabled={false} />
            </FormRow>
          )}
        </PanelBody>
      </Panel>
    </StyledUploadManualEntryFiscalYear>
  );
};
