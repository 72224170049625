import { AlertTypeEnums } from "../../../@types/index.d";

export interface ISystemMessage {
  /**
   * Unique identifier, which enables to add/edit/remove later
   */
  identifier: string;
  /**
   * error code which can be retrieved from localized errors.json
   */
  code: string;
  /**
   * Type of the shown message
   * default = AlertTypeEnums.INFO
   */
  type?: AlertTypeEnums;
  /**
   * Cn user  close this message or not
   * default = false if autoCloseTimeout is not EAutoCloseTimeOut.NO_AUTO_CLOSE, true otherwise
   */
  closable?: boolean;

  /**
   * Should the massage disappear after a given time in seconds?
   * default = EAutoCloseTimeOut.NO_AUTO_CLOSE , which means it's not closed automatically
   */
  autoCloseTimeout?: EAutoCloseTimeOut;
}

export interface ISystemMessageAction {
  type: ISystemMessageActionTypes;
  payload: ISystemMessageActionPayload;
}

/**
 * Add/edit/delete required information
 */
export interface ISystemMessageActionPayload extends Pick<ISystemMessage, "identifier"> {
  /**
   * use it to create a new message
   */
  data?: TSystemMessageData;
  /**
   * use it to edit existing message
   */
  patch?: TSystemMessagePatch;
}

export enum EAutoCloseTimeOut {
  NO_AUTO_CLOSE = 0,
  QUICK = 3000,
  MEDIUM = 6000,
  LATE = 10000,
}

export type TSystemMessageData = Omit<ISystemMessage, "identifier">;
export type TSystemMessagePatch = Partial<TSystemMessageData>;

export enum ISystemMessageActionTypes {
  ADD_SYSTEM_MESSAGE = "ADD_SYSTEM_MESSAGE",
  EDIT_SYSTEM_MESSAGE = "EDIT_SYSTEM_MESSAGE",
  REMOVE_SYSTEM_MESSAGE = "REMOVE_SYSTEM_MESSAGE",
}
const systemMessages = (
  state: ISystemMessage[] = [],
  action: ISystemMessageAction
): ISystemMessage[] => {
  if (!action.payload) return state;
  const { identifier, data, patch } = action.payload;
  switch (action.type) {
    case ISystemMessageActionTypes.ADD_SYSTEM_MESSAGE:
      if (!data) return state;
      if (state.find(({ identifier: existingIdentifier }) => existingIdentifier === identifier)) {
        return state;
      }
      return [
        ...state,
        {
          identifier,
          closable: data.autoCloseTimeout === EAutoCloseTimeOut.NO_AUTO_CLOSE,
          autoCloseTimeout: EAutoCloseTimeOut.NO_AUTO_CLOSE,
          type: AlertTypeEnums.INFO,
          ...data,
        },
      ];
    case ISystemMessageActionTypes.EDIT_SYSTEM_MESSAGE:
      return state.map((msg) => (msg.identifier === identifier ? { ...msg, ...patch } : msg));
    case ISystemMessageActionTypes.REMOVE_SYSTEM_MESSAGE:
      return state.filter(
        ({ identifier: existingIdentifier }) => existingIdentifier !== identifier
      );
    default:
      return state;
  }
};
export default systemMessages;
