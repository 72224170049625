import { EFormatCurrencyDivision, getLocaleFromLanguage } from ".";
export interface IFormatNumberOptions {
  maximumFractionDigits?: number;
  style?: EFormatNumberStyle;
  divideTo?: EFormatCurrencyDivision;
  absolute?: boolean;
}
export type TFormatNumber = (value: string | number, options?: IFormatNumberOptions) => string;

export enum EFormatNumberStyle {
  decimal = "decimal",
  percent = "percent",
  unit = "unit",
  currency = "currency",
}
export const formatNumber: TFormatNumber = (
  value,
  {
    maximumFractionDigits = 2,
    style = EFormatNumberStyle.decimal,
    divideTo = EFormatCurrencyDivision.NONE,
    absolute = false,
  } = {}
): string => {
  const strValue = value.toString();
  const result = parseFloat(strValue) / divideTo;
  const locale = getLocaleFromLanguage();
  const isAbsoluteValue = absolute ? Math.abs(result || 0) : result;
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
    style,
  }).format(isAbsoluteValue);
};

export const unFormatNumber = (value: string): string => {
  const n = value.replace(/(,|\.)([0-9]{3})/g, "$2").replace(/(,|\.)/, ".");
  return n;
};

export const onlyNumbersAndSingleDot = (value: string): string => {
  const n = value
    .replace(",", ".")
    .replace(/(?!-)[^0-9.]/g, "")
    .replace(/\./, "x")
    .replace(/\./g, "")
    .replace(/x/, ".");
  return n;
};
