import {
  DataSyncStateActionTypes,
  EDataSyncLevel,
  EDataSyncRefreshRate,
  EDataSyncScope,
  IDataChangedState,
  IDataSyncAction,
  ILocalState,
  SelectClientState,
} from "../../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { ICurrentUserAction, UserStateActionTypes } from "../../store/reducers";
import { useClients, useCurrentGrade, useUpdateSelectedClient } from "../";
import { isEqual } from "lodash";

interface IGetCacheStateResult {
  CLIENT: number;
  USER: number;
  LOGO: number;
  FILE: number;
  SUSA: number;
  KPI: number;
  EVAL: number;
  ERROR: number;
  PLAN: number;
}

const GET_CACHE_STATE = gql`
  query getCache($id: ID!, $level: CacheLevel!) {
    getCachedState(accountId: $id, level: $level) {
      CLIENT
      USER
      LOGO
      FILE
      SUSA
      KPI
      EVAL
      ERROR
      PLAN
    }
  }
`;
type TUseDataSyncByLevel = (level: EDataSyncLevel) => void;
export const useDataSyncByLevel: TUseDataSyncByLevel = (level: EDataSyncLevel): void => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dataSync = useSelector(({ dataSync }: ILocalState) => dataSync, isEqual);
  const dispatchDataSync = useDispatch<Dispatch<IDataSyncAction>>();
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const updateSelectedClient = useUpdateSelectedClient();
  const { updateGrades } = useCurrentGrade();
  // const { getUploads } = useUpdateUploadsList();
  const { update: updateClientsList } = useClients();
  const [getAccountCache, accountState] = useLazyQuery(GET_CACHE_STATE, {
    fetchPolicy: "network-only",
  });
  const accountId =
    level === EDataSyncLevel.CLIENT
      ? currentUser.selectedClient.client_id
      : currentUser.appUser.customer_id;

  const levelScopesStates = Object.values(dataSync[level]);
  const levelsRefreshRate = useMemo<EDataSyncRefreshRate>(() => {
    const getFastestRate = (rate: EDataSyncRefreshRate): boolean =>
      levelScopesStates.filter((scope) => scope.refreshRate === rate).length > 0;
    const maxRetryUntil = levelScopesStates.reduce(
      (prev: number, { retryUntil }: IDataChangedState) =>
        retryUntil || 0 > prev ? retryUntil || 0 : prev,
      0
    );
    const isFast = getFastestRate(EDataSyncRefreshRate.FAST);
    const isMedium = getFastestRate(EDataSyncRefreshRate.MEDIUM);
    const isSlow = getFastestRate(EDataSyncRefreshRate.SLOW);
    // const isIdle = getFastestRate(EDataSyncRefreshRate.IDLE);

    const speed = isFast
      ? EDataSyncRefreshRate.FAST
      : isMedium
      ? EDataSyncRefreshRate.MEDIUM
      : isSlow
      ? EDataSyncRefreshRate.SLOW
      : EDataSyncRefreshRate.IDLE;
    if (speed === EDataSyncRefreshRate.SLOW) {
      return EDataSyncRefreshRate.SLOW;
    } else if (maxRetryUntil < Date.now() || document.visibilityState === "hidden") {
      return EDataSyncRefreshRate.IDLE;
    } else {
      return speed;
    }
  }, [levelScopesStates]);

  const accountStateData = (accountState?.data?.getCachedState || {}) as IGetCacheStateResult;

  const setPollIntervalCallback = useCallback(() => {
    if (accountId === null) return;
    const { called, startPolling, stopPolling } = accountState;
    const variables = {
      id: accountId,
      level,
    };

    const queryShouldStop = levelsRefreshRate === EDataSyncRefreshRate.IDLE && called;

    if (queryShouldStop && stopPolling !== undefined) {
      stopPolling();
      return;
    }
    if (levelsRefreshRate === EDataSyncRefreshRate.IDLE) return;

    called && startPolling ? startPolling(levelsRefreshRate) : getAccountCache({ variables });
  }, [accountId, levelsRefreshRate, accountState, getAccountCache, level]);

  const updateTimeStampInLocalState = useCallback(
    (scope: EDataSyncScope, timeStamp: number) => {
      dispatchDataSync({
        type: DataSyncStateActionTypes.SET_TIMESTAMP,
        payload: {
          level,
          scope,
          state: {
            timeStamp,
          },
        },
      });
    },
    [dispatchDataSync, level]
  );
  const stopRetryOnSuccess = useCallback(
    (level: EDataSyncLevel, scope: EDataSyncScope) => {
      if (dataSync[level][scope].refreshRate !== EDataSyncRefreshRate.SLOW) {
        dispatchDataSync({
          type: DataSyncStateActionTypes.SET_REFRESH_RATE,
          payload: {
            refreshRate: EDataSyncRefreshRate.IDLE,
            retryUntil: 0,
            level,
            scope,
          },
        });
      }
    },
    [dataSync, dispatchDataSync]
  );

  const clientStateChangedCallback = useCallback(() => {
    if (accountStateData.CLIENT > dataSync.CLIENT.CLIENT.timeStamp) {
      accountId && updateSelectedClient(accountId, accountStateData.CLIENT);
      updateTimeStampInLocalState(EDataSyncScope.CLIENT, accountStateData.CLIENT);
      stopRetryOnSuccess(EDataSyncLevel.CLIENT, EDataSyncScope.CLIENT);
    }
  }, [
    accountStateData.CLIENT,
    dataSync.CLIENT.CLIENT.timeStamp,
    updateTimeStampInLocalState,
    accountId,
    updateSelectedClient,
    stopRetryOnSuccess,
  ]);

  const clientSuSaStateChangedCallback = useCallback(() => {
    if (accountStateData.SUSA > dataSync.CLIENT.SUSA.timeStamp) {
      currentUser.selectedClient.state === SelectClientState.PENDING &&
        dispatchUser({
          type: UserStateActionTypes.SET_SELECTED_CLIENT_READY,
          payload: {},
        });
      updateTimeStampInLocalState(EDataSyncScope.SUSA, accountStateData.SUSA);
      stopRetryOnSuccess(EDataSyncLevel.CLIENT, EDataSyncScope.SUSA);
    }
  }, [
    accountStateData.SUSA,
    dataSync.CLIENT.SUSA.timeStamp,
    dispatchUser,
    updateTimeStampInLocalState,
    stopRetryOnSuccess,
    currentUser.selectedClient.state,
  ]);

  const clientEvalStateChangedCallback = useCallback(() => {
    const newTimeStamp = accountStateData.EVAL;
    const localTimeStamp = dataSync.CLIENT.EVAL.timeStamp;
    if (newTimeStamp > localTimeStamp) {
      updateGrades();
      updateTimeStampInLocalState(EDataSyncScope.EVAL, accountStateData.EVAL);
      stopRetryOnSuccess(EDataSyncLevel.CLIENT, EDataSyncScope.EVAL);
    }
  }, [
    accountStateData.EVAL,
    dataSync.CLIENT.EVAL.timeStamp,
    updateTimeStampInLocalState,
    updateGrades,
    stopRetryOnSuccess,
  ]);

  const updateClientsListCallback = useCallback(() => {
    if (accountStateData.CLIENT > dataSync.CUSTOMER.CLIENT.timeStamp) {
      updateTimeStampInLocalState(EDataSyncScope.CLIENT, accountStateData.CLIENT);

      if (dataSync.CUSTOMER.CLIENT.refreshRate !== EDataSyncRefreshRate.IDLE) {
        updateClientsList({ fetchPolicy: "network-only" });
      }
      stopRetryOnSuccess(EDataSyncLevel.CUSTOMER, EDataSyncScope.CLIENT);
    }
  }, [
    accountStateData.CLIENT,
    dataSync.CUSTOMER.CLIENT.timeStamp,
    updateTimeStampInLocalState,
    updateClientsList,
    dataSync.CUSTOMER.CLIENT.refreshRate,
    stopRetryOnSuccess,
  ]);

  useEffect(() => {
    if (levelsRefreshRate !== EDataSyncRefreshRate.IDLE || accountState.loading)
      setPollIntervalCallback();
  }, [setPollIntervalCallback, levelsRefreshRate, accountState.loading]);

  useEffect(() => {
    if (currentUser.selectedClient.client_id === null) return;
    if (level === EDataSyncLevel.CLIENT) {
      clientStateChangedCallback();
      clientSuSaStateChangedCallback();
      clientEvalStateChangedCallback();
    }
    if (level === EDataSyncLevel.CUSTOMER) {
      updateClientsListCallback();
    }
  }, [
    clientStateChangedCallback,
    clientSuSaStateChangedCallback,
    clientEvalStateChangedCallback,
    level,
    updateClientsListCallback,
    currentUser.selectedClient.client_id,
  ]);
};
