import { FC } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import solidGauge from "highcharts/modules/solid-gauge";
import more from "highcharts/highcharts-more";

import styled, { css } from "styled-components/macro";
import { isEqual } from "lodash";
import { ILocalState } from "../../@types";
import { useSelector } from "react-redux";

more(Highcharts);
solidGauge(Highcharts);
interface IStyledProfitLostChartContainer {
  actualColor: string;
  actualLabel: string;
  planColor: string;
  planLabel: string;
}
const StyledProfitLostChartContainer = styled.div<IStyledProfitLostChartContainer>(
  ({ theme, actualColor, planColor, actualLabel, planLabel }) => {
    return css`
      position: relative;
      &:before,
      &:after {
        content: "${actualLabel}";
        font-size: ${theme.rem(1.125)};
        font-weight: ${theme.values.fontWeight.bold};
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 200%);
        z-index: 1;
        color: ${actualColor};
      }
      &:after {
        content: "${planLabel}";
        position: absolute;
        left: calc(50% + ${theme.rem(1.125)});
        transform: translate(100%, 200%);
        color: ${planColor};
      }
    `;
  }
);
export interface IProfitAndLossChartProps {
  data?: IProfitAndLostData[];
  optimisticData: IProfitAndLostData[];
  labels: {
    y: string;
    actual: string;
    plan: string;
  };
}

export interface IProfitAndLostData {
  label: string;
  actual?: number | null;
  plan?: number | null;
}
export const ProfitAndLossChart: FC<IProfitAndLossChartProps> = ({
  data,
  optimisticData,
  labels,
}) => {
  const brandedColors = useSelector(
    ({
      themeOptions: {
        branded: { colors },
      },
    }: ILocalState) => colors,
    isEqual
  );
  const colors = {
    actual: brandedColors.secondary,
    plan: brandedColors.primary,
  };

  const _data = data || optimisticData;
  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: _data.map(({ label }) => label),

      plotBands: [
        {
          from: 11.5,
          to: 23.5,
          color: "rgba(100, 100, 100, 0.1)",
        },
      ],
    },
    yAxis: {
      title: {
        text: labels.y,
      },
    },

    series: [
      {
        name: labels.actual,
        type: "column",
        color: colors.actual,
        data: _data.map(({ actual }) => actual || null),
      },
      {
        name: labels.plan,
        type: "spline",
        color: colors.plan,
        marker: {
          lineWidth: 1,
          lineColor: colors.plan,
          fillColor: "white",
        },
        data: _data.map(({ plan }) => plan || null),
        zoneAxis: "x",
        zones: [
          {
            value: 11,
          },
          {
            dashStyle: "Dot",
            color: colors.plan,
          },
        ],
      },
    ],
  };
  return (
    <StyledProfitLostChartContainer
      actualColor={colors.actual}
      planColor={colors.plan}
      planLabel={labels.plan}
      actualLabel={labels.actual}
    >
      <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />
    </StyledProfitLostChartContainer>
  );
};
