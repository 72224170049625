import { FC, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { P } from "../../../../components/Typography";
import {
  StyledPreviewVariant,
  StyledPreviewVariantInfo,
  StyledPreviewVariantSample,
  StyledPreviewVariations,
} from "../styled.file-upload";
import { Cell, Row, Table } from "../../../../components/Table";
import { FormRow } from "../../../../components/formElements/Form";
import { AlignmentEnums, FileUploadVariant, IArrangementKeys } from "../../../../@types/index.d";
import { Button, ButtonVariantEnums } from "../../../../components/formElements/Button";

import { initialMappingFromVariant, useSelectedClientData } from "../../../../_lib/hooks";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction, UserStateActionTypes } from "../../../../_lib/store/reducers";
import { EUploadersActionTypes, UploadersContext, UploadersDispatch } from "../../index";

export interface IVariants {
  text: string;
  sample: [string[]];
  type: FileUploadVariant;
  fields: IArrangementKeys;
}
export interface IPreviewVariant {
  selected: boolean;
}

export const PreviewVariations: FC = () => {
  const { t } = useTranslation();
  const variants: IVariants[] = t("setup.upload_form_2.excel.variants", { returnObjects: true });
  const uploadersContext = useContext(UploadersContext);
  const uploadersDispatch = useContext(UploadersDispatch);
  const selectedClientData = useSelectedClientData();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const savedSettings = useMemo(() => {
    return selectedClientData === false
      ? undefined
      : selectedClientData?.customization?.upload_settings;
  }, [selectedClientData]);
  /**
   * Each variant has different fields, which used in <Select/>
   * @param variant_type
   * @returns Related field names according to variant
   */
  const getFieldsOfVariant = (variant_type: FileUploadVariant): IArrangementKeys => {
    if (savedSettings && savedSettings.mapping && savedSettings.variant_type === variant_type) {
      return savedSettings.mapping;
    }
    return initialMappingFromVariant(variant_type);
  };
  useEffect(() => {
    if (savedSettings?.variant_type && uploadersContext.sharedState.savePreferences) {
      if (savedSettings?.variant_type !== uploadersContext.sharedState.settings.variant_type) {
        uploadersDispatch({
          type: EUploadersActionTypes.SET_STATE,
          payload: {
            settings: {
              variant_type: savedSettings.variant_type,
            },
          },
        });
      }
    }
  });
  const handleSwitchVariant = (variant_type: FileUploadVariant): void => {
    if (variant_type === uploadersContext.sharedState.settings.variant_type) return;

    uploadersDispatch({
      type: EUploadersActionTypes.SET_STATE,
      payload: {
        data: {
          savePreferences: false,
        },
        settings: {
          variant_type,
          mapping: {
            ...getFieldsOfVariant(variant_type),
          },
        },
      },
    });
    dispatchCurrentUser({
      type: UserStateActionTypes.UPDATE_UPLOAD_SETTINGS,
      payload: {
        customization: {
          upload_settings: undefined,
        },
      },
    });
  };

  return (
    <>
      <P>{t("setup.upload_form_2.excel.select_variation", { step: 4 })}</P>
      <StyledPreviewVariations>
        {variants.map((variant, iVariant) => (
          <StyledPreviewVariant
            key={iVariant}
            selected={uploadersContext.sharedState.settings.variant_type === variant.type}
          >
            <StyledPreviewVariantSample>
              <Table>
                <tbody>
                  {variant.sample.map((row, iRow) => (
                    <Row key={iRow}>
                      {row.map((cell, iCell) => (
                        <Cell
                          key={iCell}
                          highlight={iCell % 2 === 1}
                          level={iRow === 0 ? "title" : "normal"}
                          align={"center"}
                        >
                          {cell}
                        </Cell>
                      ))}
                    </Row>
                  ))}
                </tbody>
              </Table>
            </StyledPreviewVariantSample>

            <StyledPreviewVariantInfo>
              <FormRow align={AlignmentEnums.CENTER}>
                <Button
                  type={"button"}
                  variant={ButtonVariantEnums.SECONDARY}
                  inverted={false}
                  inline={true}
                  onClick={(): void => {
                    handleSwitchVariant(variant.type);
                  }}
                >
                  {t("misc.select")}
                </Button>
              </FormRow>
            </StyledPreviewVariantInfo>
            <StyledPreviewVariantInfo>
              <span>{variant.text}</span>
            </StyledPreviewVariantInfo>
          </StyledPreviewVariant>
        ))}
      </StyledPreviewVariations>
    </>
  );
};
