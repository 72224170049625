import styled, { css } from "styled-components/macro";
import { StyledResponsiveImage } from "../../StyledComponents";

export interface IStyledVideoProps {
  isReady: boolean;
}
export const Wrapper = styled.div(({ theme }) => {
  return css`
    position: relative;
    z-index: 0;
    color: ${theme.values.colors.global.primary.third};
    display: flex;
    justify-content: center;
    align-items: baseline;
  `;
});
export const PosterImage = styled(StyledResponsiveImage)(({ theme }) => {
  return css`
    cursor: pointer;
  `;
});
export const Media = styled.video<IStyledVideoProps>(({ theme, isReady, poster }) => {
  const loadingCss = css`
    opacity: 0;

    z-index: 0;
  `;
  return css`
    transition: opacity 300ms;
    transition-delay: 1s;
    opacity: 1;
    ${!isReady && loadingCss}
  `;
});

export const StyledVideo = {
  Wrapper,
  PosterImage,
  Media,
};
