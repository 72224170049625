import styled, { css } from "styled-components/macro";

export const StyledCommonLayout = styled.main(() => {
  return css`
    display: grid;
    width: 100vw;
    min-height: 100vh;
    grid-template-areas:
      "headline headline"
      "side-nav content"
      "side-nav footer";
    grid-template-rows: min-content 1fr;
    grid-template-columns: min-content auto;
  `;
});
