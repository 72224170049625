import styled, { css } from "styled-components/macro";
import { useResponsive } from "../_lib/hooks/useResponsive";

export const StyledSection = styled.section(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: grid;
        grid-row-gap: ${theme.rem(0.25)};
        grid-column-gap: ${theme.rem(0.25)};
      `,
    },
    MEDIUM: {
      style: css`
        grid-row-gap: ${theme.rem(0.5)};
        grid-column-gap: ${theme.rem(0.5)};
      `,
    },
    LARGE: {
      style: css`
        grid-row-gap: ${theme.rem(1)};
        grid-column-gap: ${theme.rem(1)};
      `,
    },
  });
});
