import styled, { css, DefaultTheme, keyframes } from "styled-components/macro";
import { AlertTypeEnums, IAlertProps } from "../../@types/index.d";
import { rgba } from "polished";

const fadeIn = keyframes`
from {
opacity: 0;
}
to {
opacity: 1;
}
`;

const useStyledAlertTheme = (theme: DefaultTheme, type?: AlertTypeEnums): [string, string] => {
  switch (type) {
    case AlertTypeEnums.SUCCESS:
      return [
        rgba(theme.values.colors.global.validation.success, 0.1),
        theme.values.colors.global.validation.success,
      ];
    case AlertTypeEnums.ERROR:
      return [
        rgba(theme.values.colors.global.validation.error, 0.1),
        theme.values.colors.global.validation.error,
      ];
    case AlertTypeEnums.WARNING:
      return [
        rgba(theme.values.colors.global.validation.fourth, 0.75),
        theme.values.colors.global.greys.sixth,
      ];
    case AlertTypeEnums.INFO:
      return [
        rgba(theme.values.colors.global.validation.info, 0.1),
        theme.values.colors.global.validation.info,
      ];
    case AlertTypeEnums.BASIC:
    default:
      return [theme.values.colors.global.greys._005, theme.values.colors.global.greys._055];
  }
};
export const StyledAlert = styled.div<IAlertProps>(({ theme, type }) => {
  const [border, color] = useStyledAlertTheme(theme, type);
  return css`
    width: 100%;
    border-radius: ${theme.branded.inputs.default.radius};
    font-weight: ${theme.values.fontWeight.bold};
    animation: ${fadeIn} 2s;

    font-style: italic;
    align-self: baseline;
    display: flex;
    align-items: center;
    border: ${border} 1px solid;
    color: ${color};
    background-color: ${theme.values.colors.global.greys.first};
  `;
});

export const StyledAlertIcon = styled.div<IAlertProps>(({ theme, type }) => {
  const [background] = useStyledAlertTheme(theme, type);
  return css`
    padding: ${theme.rem(0.5)};
    background-color: ${background};
    display: flex;
    align-self: stretch;
    align-items: center;
    color: white;
  `;
});
export const StyledAlertText = styled.div<IAlertProps>(({ theme, type }) => {
  return css`
    padding: ${theme.rem(0.5)};
  `;
});
