import { gql } from "@apollo/client/core";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

const IS_EMAIL_VALID = gql`
  query validate($email: String!) {
    isEmailValid(email: $email)
  }
`;
interface ValidateEMailVars {
  email: string;
}

interface ValidateEmailResult {
  isEmailValid: boolean;
}

type TUseValidateEmail = () => TUseValidateEmailRetVal;

type TUseValidateEmailRetVal = (email: string) => Promise<ApolloQueryResult<ValidateEmailResult>>;

export const useValidateEmail: TUseValidateEmail = () => {
  const apolloClient = useApolloClient();

  return (email: string): Promise<ApolloQueryResult<ValidateEmailResult>> => {
    return apolloClient.query<ValidateEmailResult, ValidateEMailVars>({
      query: IS_EMAIL_VALID,
      variables: { email: encodeURIComponent(email) },
      fetchPolicy: "no-cache",
    });
  };
};
