import styled, { css } from "styled-components/macro";

export interface IMonthPickerItem {
  selected: boolean;
  disabled: boolean;
}

export const StyledMonthPicker = styled.div(({ theme }) => {
  return css`
    display: inline-flex;
    flex-basis: 100%;
    position: relative;
  `;
});

export const StyledMSelectorWrapper = styled.div(({ theme }) => {
  const borderColor = theme.values.colors.global.greys.third;
  return css`
    position: absolute;
    top: 100%;
    right: 0;
    background-color: ${theme.values.colors.global.greys.first};
    display: grid;
    grid-template-columns: 1fr auto;
    padding: ${theme.rem(0.5)};
    border: 1px solid ${borderColor};
    font-size: ${theme.rem(0.75)};
    z-index: 3;
  `;
});

export const StyledPickItem = styled.div<IMonthPickerItem>(({ theme, selected, disabled }) => {
  const selectedCss = css`
    color: ${theme.branded.buttons.secondary.color.base.color};
    background-color: ${theme.branded.buttons.secondary.color.base.background};
  `;
  const disabledCss = css`
    opacity: 0.5;
    cursor: not-allowed;
  `;
  // const hover = css`
  //   color: ${theme.values.buttons.primary.color.base.color};
  //   background-color: ${theme.values.buttons.primary.color.base.background};
  // `;

  return css`
    padding: ${theme.rem(0.5)};
    border-radius: ${theme.branded.buttons.primary.radius};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${theme.values.fontWeight.bold};
    user-select: none;

    ${selected && selectedCss}
    ${disabled && disabledCss}
  `;
});

export const StyledYearsList = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `;
});

export const StyledYear = styled(StyledPickItem)(({ theme }) => {
  return css``;
});

export const StyledMonthsList = styled.div(({ theme }) => {
  return css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${theme.rem(0.25)};
    grid-row-gap: ${theme.rem(0.25)};
  `;
});

export const StyledMonth = styled(StyledPickItem)(({ theme }) => {
  return css``;
});
