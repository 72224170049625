import styled, { css } from "styled-components/macro";
import { IFilterableData } from "./index";

export const StyledList = styled.ul(({ theme }) => {
  return css`
    list-style: none;
    margin: 0;
    padding: ${theme.rem(0.4)} ${theme.rem(0.2)};
  `;
});

export const StyledListCategories = styled.span(({ theme }) => {
  return css`
    padding: ${theme.rem(0.4)} ${theme.rem(0.8)};
    cursor: auto;
    color: ${theme.values.colors.global.primary.third};
  `;
});
export const StyledListIndustries = styled.li<Partial<IFilterableData>>(({ theme, selected }) => {
  return css`
    padding: ${theme.rem(0.4)} ${theme.rem(0.8)} ${theme.rem(0.4)} ${theme.rem(1.6)};

    font-weight: ${selected ? theme.values.fontWeight.bold : theme.values.fontWeight.normal};
    list-style: ${selected ? "disc" : "none"};

    &:hover {
      cursor: pointer;
    }
  `;
});

export const StyledListSubIndustries = styled(StyledListIndustries)(({ theme, selected }) => {
  return css`
    font-weight: ${selected ? theme.values.fontWeight.bold : theme.values.fontWeight.normal};
    list-style: ${selected ? "disc" : "none"};
  `;
});
