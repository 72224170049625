import { gql } from "@apollo/client";

export const PLAN_TEMPLATES = gql`
  fragment TemplatesFragment on Plan {
    templates {
      revenue {
        lines {
          title
          year
          is_intercompany
          line_id
          values {
            date
            data {
              global_code_id
              balance
            }
            hidden
          }
        }
        corrections {
          date
          data {
            global_code_id
            balance
            comment
          }
        }
      }
      personnel {
        lines {
          title
          year
          line_id
          nonwage_labor_costs {
            year
            value
          }
          extra_salary {
            year
            value
          }
          monthly_gross {
            date
            balance
          }
          monthly_number_employees {
            date
            value
          }
        }
        one_time_payments {
          year
          balance
          values {
            date
            percentage
            comment
          }
        }
        corrections {
          date
          data {
            global_code_id
            balance
            comment
          }
        }
      }
      working_capital {
        kpis {
          date
          data {
            kpi_id
            value
          }
        }
        corrections {
          date
          data {
            global_code_id
            balance
          }
        }
      }
      costs {
        lines {
          line_id
          year
          title
          global_code_id
          is_changeable
          is_revenue_related
          revenue_relation
          revenue_percentages {
            year
            value
          }
          values {
            date
            value
            type
          }
        }
        revenue_lines {
          line_id
          title
        }
      }
      capex {
        lines {
          line_id
          title
          date
          global_code_id
          type
          investment_costs
          duration_in_years
          is_recurring
          cycle
          active_until
          sales_revenue
          residual_value
          recognize_as_ao
          remaining_months
          monthly_afa
        }
        afa {
          date
          inflow
          outflow
          type
        }
        corrections {
          date
          data {
            global_code_id
            balance
          }
        }
      }
      financing {
        lines {
          line_id
          title
          date
          global_code_id
          type
          loan_amount
          start_date
          repayment_amount
          valuta
          duration_in_months
          cycle
          interest_rate
        }
        values {
          date
          data {
            global_code_id
            loan
            inflow
            outflow
          }
        }
        corrections {
          date
          data {
            global_code_id
            balance
            comment
          }
        }
      }
      corrections {
        corrections {
          date
          data {
            global_code_id
            balance
          }
        }
        taxes {
          year
          value
        }
      }
    }
  }
`;

export const GET_PLANS_OF_CLIENT = gql`
  mutation getPlans($params: GetPlansInput!) {
    getPlans(params: $params) {
      plan_id
      title
      description
      active
      created_at
      month
      months
    }
  }
`;
export const GET_PLAN = gql`
  ${PLAN_TEMPLATES}
  mutation getPlan(
    $plan_id: ID!
    $with_data: Boolean
    $with_actuals: Boolean
    $template_filter: String
  ) {
    getPlan(
      plan_id: $plan_id
      with_data: $with_data
      with_actuals: $with_actuals
      template_filter: $template_filter
    ) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;
export const CREATE_PLAN = gql`
  mutation createPlan($plan: PlanInput!) {
    createPlan(plan: $plan) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const COPY_PLAN = gql`
  mutation copyPlan($plan: CopyPlanInput!, $plan_id: ID!) {
    copyPlan(plan: $plan, plan_id: $plan_id) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const EDIT_PLAN = gql`
  mutation editPlan($plan: CopyPlanInput!, $plan_id: ID!) {
    editPlan(plan: $plan, plan_id: $plan_id) {
      plan_id
      active
      title
      description
      created_at
    }
  }
`;
export const DELETE_PLAN = gql`
  mutation deletePlan($plan_id: ID!) {
    deletePlan(plan_id: $plan_id)
  }
`;

//-------------------- UPDATE TEMPLATES --------------------

export const UPDATE_TEMPLATES = gql`
  ${PLAN_TEMPLATES}
  mutation updateTemplates($plan_id: ID!, $type: String, $data: TemplatesInput) {
    updateTemplates(plan_id: $plan_id, type: $type, data: $data) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- REVENUE LINIE --------------------

export const CREATE_REVENUE_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation createRevenueLine($revenue_line: RevenueLineInput!) {
    createRevenueLine(revenue_line: $revenue_line) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- PERSONNEL - EMPLOYEE ROLE --------------------

export const CREATE_PERSONNEL_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation createPersonnelLine($personnel_line: PersonnelLineInput!) {
    createPersonnelLine(personnel_line: $personnel_line) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- COST LINE --------------------

export const CREATE_COST_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation createCostLine($cost_line: CostLineInput!) {
    createCostLine(cost_line: $cost_line) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- INVESTMENT LINE --------------------

export const CREATE_INVESTMENT_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation createInvestmentLine($investment_line: InvestmentLineInput!) {
    createInvestmentLine(investment_line: $investment_line) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- Financing LINE --------------------

export const CREATE_FINANCING_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation createFinancingLine($financing_line: FinancingLineInput!) {
    createFinancingLine(financing_line: $financing_line) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

//-------------------- COMMON REQUEST FOR EDIT LINE TITLES AND DELETE LINES --------------------
export const EDIT_PLANNING_LINE_TITLE = gql`
  ${PLAN_TEMPLATES}
  mutation editPlanningLineTitle($title: String!, $plan_id: ID!, $line_id: ID!, $rel: String!) {
    editPlanningLineTitle(title: $title, plan_id: $plan_id, line_id: $line_id, rel: $rel) {
      plan_id
      title
      description
      susa_id
      client_id
      interval
      revenue_ratios {
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
      }
      revenue_growth
      month
      months
      active
      created_at
      status
      calculated {
        date
        type
        data {
          global_code_id
          ratio
          balance
          balance_cumulated
        }
      }
      ...TemplatesFragment
    }
  }
`;

export const DELETE_PLANNING_LINE = gql`
  ${PLAN_TEMPLATES}
  mutation deletePlanningLine($plan_id: ID!, $line_id: ID!, $rel: String!) {
    deletePlanningLine(plan_id: $plan_id, line_id: $line_id, rel: $rel) {
      data {
        plan_id
        title
        description
        susa_id
        client_id
        interval
        revenue_ratios {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
        revenue_growth
        month
        months
        active
        created_at
        status
        calculated {
          date
          type
          data {
            global_code_id
            ratio
            balance
            balance_cumulated
          }
        }
        ...TemplatesFragment
      }
      lineType
    }
  }
`;
