import styled, { css } from "styled-components/macro";

export const StyledUserBadge = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
export const StyledUserBadgeIcon = styled.div(
  ({ theme }) => css`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${theme.values.colors.global.greys.first};
    background-color: ${theme.values.colors.global.greys.sixth};
    span {
      text-align: center;
    }
  `
);
export const StyledUseBadgeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  line-height: 1.5;
`;
