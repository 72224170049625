import {
  createContext,
  FC,
  useReducer,
  Dispatch,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  useState,
} from "react";

import { ButtonVariantEnums, IconButton } from "../Button";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import { useTranslation } from "react-i18next";
import {
  StyledMonthPicker,
  StyledMSelectorWrapper,
  StyledYearsList,
  StyledYear,
  StyledMonthsList,
  StyledMonth,
} from "./styled.month-picker";
import { InputConstructor } from "../Input/InputConstructor";
import { IInputConstructorProps } from "../../../@types/index.d";
import { InputButton } from "../Input/InputButton";
import { FormContext, FormDispatch, IFormState } from "../Form";
import { parseDate } from "../../../_lib/common";

export interface IMonthPickerState {
  open: boolean;
  data: string[];
  month?: number;
  year?: number;
  yearsPage: number;
  valid: boolean;
  __lastValidDate?: { month: number; year: number };
}

type TMonthPickerReducer = (
  state: IMonthPickerState,
  action: Partial<IMonthPickerState>
) => IMonthPickerState;

export interface IMonthPickerSelectionValue {
  value: string;
  month: number;
  monthName?: string;
  monthNameShort?: string;
  Month?: string;
  year: number;
  Year?: string;
  valid: boolean;
}
export interface IMonthPickerBetweenDate {
  from?: string;
  to?: string;
}

export interface IMonthPickerProps
  extends Omit<IInputConstructorProps, "value">,
    IMonthPickerBetweenDate {
  data: string[];
  disabled: boolean;
  from?: string;
  to?: string;
  initialValue?: string;
}

export const MonthPickerContext = createContext<IMonthPickerState>({
  open: false,
  data: [],
  yearsPage: 0,
  valid: false,
});
export const MonthPickerDispatch = createContext<Dispatch<Partial<IMonthPickerState>>>(() => {
  // initial dispatch fn
});
export const getMontPickerData = (
  selectorName: string,
  data: IFormState
): IMonthPickerSelectionValue => {
  return {
    valid: Boolean(data[`${selectorName}_MonthPicker-valid`]),
    value: data[`${selectorName}_MonthPicker-value`],
    month: parseInt(data[`${selectorName}_MonthPicker-month`]),
    year: parseInt(data[`${selectorName}_MonthPicker-year`]),
    Month: data[`${selectorName}_MonthPicker-Month`],
    Year: data[`${selectorName}_MonthPicker-Year`],
    monthName: data[`${selectorName}_MonthPicker-monthName`],
    monthNameShort: data[`${selectorName}_MonthPicker-monthNameShort`],
  };
};
const Years: FC<IMonthPickerBetweenDate> = ({ from, to }) => {
  const monthPickerContext = useContext(MonthPickerContext);
  const monthPickerDispatch = useContext(MonthPickerDispatch);
  const fromYear = from ? parseInt(from.split("/")[1]) : undefined;
  const toYear = to ? parseInt(to.split("/")[1]) : undefined;
  const isYearDisabled = (year: number): boolean => {
    const noData = monthPickerContext.data.length === 0;
    if (noData && fromYear === undefined && toYear === undefined) return false;
    const inData =
      noData ||
      monthPickerContext.data.find((date) => date.split("/")[1] === year.toString()) !== undefined;
    const isBefore = fromYear ? fromYear > year : false;
    const isAfter = toYear ? toYear < year : false;
    return !inData || isBefore || isAfter;
  };
  const handleYearSelection = (year: number): void => {
    if (monthPickerContext.year === year) return;
    !isYearDisabled(year) && monthPickerDispatch({ year, month: undefined });
  };
  const handlePrev = (): void => {
    monthPickerDispatch({ yearsPage: monthPickerContext.yearsPage - 1 });
  };
  const handleNext = (): void => {
    monthPickerDispatch({ yearsPage: monthPickerContext.yearsPage + 1 });
  };
  const paginatedDate = new Date().getFullYear() + monthPickerContext.yearsPage * 3;
  const [prev, mid, next] = [paginatedDate - 1, paginatedDate, paginatedDate + 1];

  return (
    <StyledYearsList>
      <IconButton
        icon={<Icon name={IconNameEnums.CHEVRON_UP} size={IconSizeEnums.SMALL} />}
        inverted={false}
        variant={ButtonVariantEnums.SECONDARY}
        type={"button"}
        // commented next line to be able to have 2 month pickers in the same dialog (planning add-investment or add-financing dialogs)
        // disabled={isYearDisabled(monthPickerContext?.year || 0 - 2)}
        onClick={handlePrev}
      />
      <StyledYear
        selected={prev === monthPickerContext.year}
        disabled={isYearDisabled(prev)}
        onClick={(): void => {
          handleYearSelection(prev);
        }}
        data-test-id="month-picker-year-prev"
      >
        {prev}
      </StyledYear>
      <StyledYear
        selected={mid === monthPickerContext.year}
        disabled={isYearDisabled(mid)}
        onClick={(): void => {
          handleYearSelection(mid);
        }}
        data-test-id="month-picker-year-mid"
      >
        {mid}
      </StyledYear>
      <StyledYear
        selected={next === monthPickerContext.year}
        disabled={isYearDisabled(next)}
        onClick={(): void => {
          handleYearSelection(next);
        }}
        data-test-id="month-picker-year-next"
      >
        {next}
      </StyledYear>
      <IconButton
        icon={<Icon name={IconNameEnums.CHEVRON_DOWN} size={IconSizeEnums.SMALL} />}
        inverted={false}
        variant={ButtonVariantEnums.SECONDARY}
        type={"button"}
        // commented next line to be able to have 2 month pickers in the same dialog (planning add-investment or add-financing dialogs)
        // disabled={isYearDisabled(monthPickerContext.year || 2)}
        onClick={handleNext}
      />
    </StyledYearsList>
  );
};
const Months: FC<IMonthPickerBetweenDate> = ({ from, to }) => {
  const { t } = useTranslation();
  const monthPickerContext = useContext(MonthPickerContext);
  const monthPickerDispatch = useContext(MonthPickerDispatch);
  const monthsShort: string[] = t("misc.months.short", { returnObjects: true });
  const selectedMonthIndex = monthPickerContext?.month;

  const fromYear = from ? parseInt(from.split("/")[1]) : undefined;
  const fromMonth = from ? parseInt(from.split("/")[0]) - 1 : undefined;
  const toYear = to ? parseInt(to.split("/")[1]) : undefined;
  const toMonth = to ? parseInt(to.split("/")[0]) - 1 : undefined;

  const isMonthDisabled = (month: number): boolean => {
    const noData = monthPickerContext.data.length === 0;
    if (monthPickerContext.year === undefined) return true;
    if (noData && fromMonth === undefined && toMonth === undefined) return false;
    const { year } = monthPickerContext;
    const inData =
      noData ||
      monthPickerContext.data.find((date) => {
        const [monthData, yearData] = date.split("/").map((val) => parseInt(val));
        return monthData === month + 1 && yearData === monthPickerContext.year;
      }) !== undefined;
    const isBefore =
      fromMonth && fromYear && year
        ? fromYear > year || (year === fromYear && fromMonth > month)
        : false;
    const isAfter =
      toMonth && toYear && year ? toYear < year || (year === toYear && toMonth < month) : false;
    return !inData || isBefore || isAfter;
  };
  const handleMonthSelection = (month: number): void => {
    const openState = typeof monthPickerContext.year !== "number";
    !isMonthDisabled(month) && monthPickerDispatch({ month, open: openState });
  };

  return (
    <StyledMonthsList>
      {monthsShort.map((month: string, index: number) => (
        <StyledMonth
          selected={selectedMonthIndex === index}
          disabled={isMonthDisabled(index)}
          key={index}
          onClick={(): void => handleMonthSelection(index)}
          data-test-id={`month-picker-month-${index}`}
        >
          {month}
        </StyledMonth>
      ))}
    </StyledMonthsList>
  );
};
export const MonthPicker: FC<IMonthPickerProps> = ({
  data,
  from,
  to,
  disabled,
  initialValue,
  name,
  label,
  labelPosition,
  placeholder,
  invalidWarning,
  valid,
}) => {
  const { t } = useTranslation();
  const selectListRef = useRef<HTMLDivElement>(null);
  const monthsLong: string[] = t("misc.months.long", { returnObjects: true });
  const monthsShort: string[] = t("misc.months.short", { returnObjects: true });
  const formDispatch = useContext(FormDispatch);
  const formContext = useContext(FormContext);
  const [inValue, setInValue] = useState<string | undefined>(undefined);
  const monthPickerReducer: TMonthPickerReducer = (state, action) => {
    const jointState = { ...state, ...action };
    const valid = typeof jointState.month === "number" && typeof jointState.year === "number";
    const lastValidDate =
      valid && jointState.month && jointState.year
        ? { month: jointState.month, year: jointState.year }
        : state.__lastValidDate;
    if (action.open === false && !valid) {
      return { ...jointState, valid, ...state.__lastValidDate };
    }
    return { ...jointState, valid, __lastValidDate: lastValidDate };
  };

  const [monthPickerData, dispatchMonthPicker] = useReducer(monthPickerReducer, {
    open: false,
    data,
    yearsPage: 0,
    valid: false,
  });

  const memoSelectionReturnValue = useMemo<IMonthPickerSelectionValue>(() => {
    const { valid, month, year } = monthPickerData;
    const _month = month === undefined ? -1 : month + 1;
    const _year = year ? year : -1;
    const _Month = _month > 0 ? `0${_month}`.slice(-2) : "";
    const _Year = _year ? `${_year}` : "";

    return {
      month: _month, // Month as integer 1-12 or -1
      monthName: _month === -1 ? "" : monthsLong[_month - 1], // returns translated month name
      monthNameShort: _month === -1 ? "" : monthsShort[_month - 1], // returns translated month name
      Month: _Month, // two-digit month as string (ex:01,02,...,10,11,12 ) or ""
      year: _year, // four-digit year or -1 (ex: 2020 or -1)
      Year: _Year, // four-digit Year as string or ""
      valid,
      value: _month > 0 && _year > 0 ? `${_Month}/${_Year}` : "", // "MM/YYYY" or ""
    };
  }, [monthPickerData, monthsLong, monthsShort]);

  useEffect(() => {
    const selection = memoSelectionReturnValue;
    const obj: IFormState = {};
    Object.entries(selection).forEach(([key, value]) => {
      const itemKey = `${name}_MonthPicker-${key}`;
      obj[itemKey] = value ? value.toString() : "";
    });

    const isChanged =
      Object.entries(obj).filter(([key, value]) => formContext[key] !== value).length > 0;

    if (isChanged) formDispatch(obj);
  }, [memoSelectionReturnValue, formDispatch, formContext, name]);

  const toggleListHandler = (): void => {
    dispatchMonthPicker({ open: !monthPickerData.open });
  };
  useLayoutEffect(() => {
    const closeOnOuterClick = (e: MouseEvent): void => {
      if (selectListRef === null) return;
      if (selectListRef.current === null) return;
      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(
        selectListRef.current === e.target || selectListRef.current.contains(e.target as Node)
      );

      isOuter && monthPickerData.open && dispatchMonthPicker({ open: false });
    };

    if (monthPickerData.open) {
      window.document.querySelector("body")?.addEventListener("mouseup", closeOnOuterClick);
      return (): void => {
        window.document.querySelector("body")?.removeEventListener("mouseup", closeOnOuterClick);
      };
    }
  }, [monthPickerData]);
  useEffect(() => {
    if (initialValue && !inValue) {
      setInValue(initialValue);
      const { month, year } = parseDate(initialValue);
      dispatchMonthPicker({ year, month, data });
    }
  }, [data, monthPickerData.year, initialValue, monthPickerData.month, inValue]);
  const monthPickerValue = `${
    typeof monthPickerData?.month === "number" ? monthsLong[monthPickerData.month] : "__"
  }, ${monthPickerData?.year || "____"}`;

  return (
    <MonthPickerDispatch.Provider value={dispatchMonthPicker}>
      <MonthPickerContext.Provider value={monthPickerData}>
        <StyledMonthPicker ref={selectListRef}>
          <InputConstructor
            name={name}
            value={monthPickerValue}
            label={label}
            labelPosition={labelPosition}
            placeholder={placeholder}
            readOnly={true}
            onClick={toggleListHandler}
            invalidWarning={invalidWarning}
            valid={valid}
            disabled={disabled}
          >
            <InputButton
              icon={<Icon name={IconNameEnums.CALENDAR} size={IconSizeEnums.SMALL} />}
              inverted={false}
              variant={ButtonVariantEnums.SECONDARY}
              type={"button"}
              onClick={toggleListHandler}
              disabled={disabled}
            />

            {monthPickerData.open && !disabled && (
              <StyledMSelectorWrapper>
                <Years from={from} to={to} />
                <Months from={from} to={to} />
              </StyledMSelectorWrapper>
            )}
          </InputConstructor>
        </StyledMonthPicker>
      </MonthPickerContext.Provider>
    </MonthPickerDispatch.Provider>
  );
};
