import styled, { css, keyframes } from "styled-components/macro";
import { FC } from "react";
import { rgba } from "polished";

const slidingLine = keyframes`
  0% {
    left: -20%;
    top:-25%;
    width: 50%;
  }
  100% {
    top:-25%;
    left: 200%;
    width: 100%;

  }

`;
const StyledTextPlaceholder = styled.div<Partial<IInputLoaderProps>>(({ theme, length = 1 }) => {
  const randomWidth = Math.floor(Math.random() * length) + 1;
  const randomDelay = Math.floor(Math.random() * 10) * 100;

  return css`
    display: inline-block;
    width: ${theme.rem(randomWidth)};
    height: ${theme.rem(0.9)};
    background-color: ${theme.values.colors.global.greys.fourth};
    position: relative;
    overflow: hidden;
    float: right;
    &:after {
      content: "";
      height: 150%;
      width: 10px;
      position: absolute;
      background-image: linear-gradient(
        90deg,
        ${rgba(theme.values.colors.global.greys.sixth, 0)},
        ${rgba(theme.values.colors.global.greys.sixth, 0.25)},
        ${rgba(theme.values.colors.global.greys.sixth, 0)}
      );
      animation: ${slidingLine} 2s infinite;
      animation-delay: ${randomDelay}ms;
    }
  `;
});

export interface IInputLoaderProps {
  length?: number;
}
export const IInputLoader: FC<IInputLoaderProps> = ({ children, ...props }) => {
  return <StyledTextPlaceholder {...props} />;
};
