import { getI18n } from "react-i18next";

export const getCurrencyFromLanguage = (): string => {
  const { language } = getI18n();
  switch (language) {
    case "de":
      return "EUR";
    case "en":
      return "USD";
    default:
      return "EUR";
  }
};
