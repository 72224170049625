import { FC, useEffect, useState } from "react";
import { IconNameEnums } from "../../../components/Icon";

import {
  EPlanningPage,
  EPrivatePathName,
  EUploadsPage,
  ILocalState,
  TMenuProps,
} from "../../../@types/index.d";
import { StyledMenu } from "./styled.menu";

import { useTranslation } from "react-i18next";
import { MenuItem } from "./MenuItem";
import { MenuItemWithSubMenu } from "./MenuItemWithSubMenu";
import { Ruler } from "./Ruler";
import { useHistory } from "react-router-dom";
import {
  useNoPlanFoundWarning,
  usePlanningHelper,
  usePlans,
  useSelectedClientData,
} from "../../../_lib/hooks";
import { LoaderTypeEnums, LoadingIndicator } from "../../../components/LoadingIndicator";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useUploadsHelper } from "../../../views/Private/Upload/AllUploads/useUploadsHelper";

export const Menu: FC = () => {
  const { t } = useTranslation();

  const selectedClientData = useSelectedClientData();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const planningLoadingStatus = useSelector(
    ({ planning: { planningLoadingStatus } }: ILocalState) => planningLoadingStatus,
    isEqual
  );

  const history = useHistory();
  const { callGetPlans } = usePlans();
  const { switchUploadsPage } = useUploadsHelper();
  const { open } = useNoPlanFoundWarning();
  const { switchPlanningPage } = usePlanningHelper();

  const [currentLocation, setCurrentLocation] = useState(history.location.pathname);

  const clientName =
    selectedClientData === false ? (
      <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
    ) : (
      selectedClientData.name
    );

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== currentLocation) setCurrentLocation(location.pathname);
    });
  }, [history, currentLocation]);

  // if there is no plan, dont allow user to click dashboard, kpi, reporting and upload pages
  const disableClickIfNoPlan = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): boolean | void => {
    if (!planningLoadingStatus && plans?.length === 0) {
      e.preventDefault();
      // after disabling clicking some sidenav items (like dashboard or kpi page), render no plan warning dialog
      open();
    }
  };

  const menu: TMenuProps = {
    DASHBOARD: {
      ...t(`main_menu.DASHBOARD`, { returnObjects: true }),
      href: EPrivatePathName.DASHBOARD,
      icon: IconNameEnums.DASHBOARD,
      level: 0,
      selected: currentLocation === EPrivatePathName.DASHBOARD,
      onClick: disableClickIfNoPlan,
    },
    KPI: {
      ...t(`main_menu.KPI`, { returnObjects: true }),
      href: EPrivatePathName.KPI,
      icon: IconNameEnums.KPI,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.KPI),
      onClick: disableClickIfNoPlan,
    },
    PLAN: {
      ...t(`main_menu.PLAN`, { returnObjects: true }),
      href: EPrivatePathName.PLAN,
      icon: IconNameEnums.PLAN,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.PLAN),
      onClick: (): void => {
        callGetPlans(currentUser?.selectedClient?.client_id || "");
        switchPlanningPage(EPlanningPage.LIST);
      },
    },
    REPORTS: {
      ...t(`main_menu.REPORTS`, { returnObjects: true }),
      href: EPrivatePathName.REPORTS,
      icon: IconNameEnums.REPORTS,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.REPORTS),
      showSubMenu: currentLocation.startsWith(EPrivatePathName.REPORTS),
    },
    REPORTS_CROSS_CLIENT: {
      ...t(`main_menu.REPORTS_CROSS_CLIENT`, { returnObjects: true }),
      href: EPrivatePathName.REPORTS_CROSS_CLIENT,
      icon: null,
      level: 1,
      selected: currentLocation === EPrivatePathName.REPORTS_CROSS_CLIENT,
      onClick: disableClickIfNoPlan,
    },
    REPORTS_CURRENT: {
      href: EPrivatePathName.REPORTS_CURRENT,
      icon: null,
      label: clientName,
      level: 1,
      selected: currentLocation === EPrivatePathName.REPORTS_CURRENT,
      onClick: disableClickIfNoPlan,
    },
    COMPANY: {
      ...t(`main_menu.COMPANY`, { returnObjects: true }),
      href: EPrivatePathName.COMPANY,
      icon: IconNameEnums.COMPANY,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.COMPANY),
      showSubMenu: currentLocation.startsWith(EPrivatePathName.COMPANY),
    },
    COMPANY_DATA: {
      ...t(`main_menu.COMPANY_DATA`, { returnObjects: true }),
      href: EPrivatePathName.COMPANY_DATA,
      icon: IconNameEnums.COMPANY_DATA,
      level: 1,
      selected: currentLocation === EPrivatePathName.COMPANY_DATA,
    },
    COMPANY_USERS: {
      ...t(`main_menu.COMPANY_USERS`, { returnObjects: true }),
      href: EPrivatePathName.COMPANY_USERS,
      icon: IconNameEnums.COMPANY_USERS,
      level: 1,
      selected: currentLocation === EPrivatePathName.COMPANY_USERS,
    },
    UPLOAD: {
      ...t(`main_menu.UPLOAD`, { returnObjects: true }),
      href: EPrivatePathName.UPLOAD,
      icon: IconNameEnums.UPLOAD,
      level: 0,
      selected: currentLocation === EPrivatePathName.UPLOAD,
      onClick: (e): void => {
        if (!planningLoadingStatus && plans?.length === 0) {
          disableClickIfNoPlan(e);
          return;
        }
        switchUploadsPage(EUploadsPage.LIST);
      },
    },
    BRANDING: {
      ...t(`main_menu.CUSTOMIZATION`, { returnObjects: true }),
      href: EPrivatePathName.CUSTOMIZATION,
      icon: IconNameEnums.COMPANY_DATA,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.CUSTOMIZATION),
    },
    ACCOUNT: {
      ...t(`main_menu.ACCOUNT`, { returnObjects: true }),
      href: EPrivatePathName.ACCOUNT,
      icon: IconNameEnums.PROFILE_IMAGE,
      level: 0,
      selected: currentLocation.startsWith(EPrivatePathName.ACCOUNT),
    },
    LOGOUT: {
      ...t(`main_menu.LOGOUT`, { returnObjects: true }),
      href: EPrivatePathName.LOGOUT,
      icon: IconNameEnums.SIGN_OUT,
      level: 0,
      selected: currentLocation === EPrivatePathName.LOGOUT,
    },
  };

  return (
    <StyledMenu>
      <MenuItem {...menu.DASHBOARD} />
      <MenuItem {...menu.KPI} />
      <MenuItem {...menu.PLAN} />
      <MenuItemWithSubMenu {...menu.REPORTS}>
        <MenuItem {...menu.REPORTS_CROSS_CLIENT} />
        <MenuItem {...menu.REPORTS_CURRENT} />
      </MenuItemWithSubMenu>
      <MenuItemWithSubMenu {...menu.COMPANY}>
        <MenuItem {...menu.COMPANY_DATA} />
        <MenuItem {...menu.COMPANY_USERS} />
      </MenuItemWithSubMenu>
      <MenuItem {...menu.UPLOAD} />
      <Ruler showRuler={true} />
      <MenuItem {...menu.BRANDING} />
      <MenuItem {...menu.ACCOUNT} />
      <MenuItem {...menu.LOGOUT} />
    </StyledMenu>
  );
};
