import { gql } from "@apollo/client";

export const UPDATE_BILLING_ADDRESS = gql`
  mutation updateBillingAddress($params: BillingAddressInput, $customer_id: ID!) {
    updateBillingAddress(params: $params, customer_id: $customer_id) {
      line_1
      # line_2
      city
      postal
      # country
      email
      telephone
      name
    }
  }
`;

export const GET_COUPONS_LIST = gql`
  query getCouponList($product_id: ID!) {
    getCouponList(product_id: $product_id) {
      campaigns {
        coupon_id
        product_id
        end_time
        start_time
        trial_days
        price
        pricing_id
        features {
          feature_id
          pricing_id
          price
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query getSubs($customer_id: ID!) {
    result: getSubscriptions(customer_id: $customer_id) {
      subscription_id
      customer_id
      product_id
      pricing_id
      feature_id
      status
      start_date
      trial_end
      trial_start
    }
  }
`;

export const GET_CUSTOMER_STATUS = gql`
  query getCustomerStatus($customer_id: ID!) {
    customerData: getCustomerStatus(customer_id: $customer_id) {
      customer_id
      product_id
      pricing_id
      status
      start_date
      trial_end
      canceled_at
      features {
        feature_id
        pricing_id
        status
        start_date
        trial_end
        canceled_at
      }
      billing_address {
        line_1
        # line_2
        city
        postal
        # country
        email
        telephone
        name
      }
      payment_method {
        type
        last_four
        brand
        bank_code
        exp_month
        exp_year
      }
    }
  }
`;

export const GET_ALL_PRICINGS = gql`
  query getAllPricings($product_id: String!) {
    pricings: getAllPricings(product_id: $product_id) {
      pricing_id
      trial_days
      price
      default
      disabled
      feature_id
      product_id
      subscription
      page
      page_type
    }
  }
`;

export const GET_FEATURES_DATA = gql`
  query getFeaturesData {
    getFeaturesData {
      bundleTree {
        value
        tree {
          value
        }
      }
      productFeatureTree {
        value
        tree {
          value
          disabled
        }
      }
    }
  }
`;

export const GET_PROMOTIONS_DATA = gql`
  query getPromotionsData($customer_id: ID!) {
    promoFeatures: getPromotionsData(customer_id: $customer_id) {
      feature_id
      trial_end
    }
  }
`;

export const GET_PAGE_URL = gql`
  mutation getFeaturePageLink($data: FeaturePageData!) {
    page_return: getFeaturePageLink(data: $data) {
      page_id
      page_url
      page_headers {
        Authorization
      }
    }
  }
`;
