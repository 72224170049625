import { Children, FC, ReactElement, useLayoutEffect, useRef, useState } from "react";
import { IOptionProps } from "./Option";
import { ButtonVariantEnums, IconButton } from "../Button";
import { StyledDropDown, StyledDropDownButtons } from "./styled.drop-down";
import { ActionButton } from "./ActionButton";
import { Icon, IconNameEnums, IconSizeEnums } from "../../Icon";
import { StyledOptionsList, StyledOptionsListItem } from "./styled.option";

export interface IDropDownProps {
  /** React elements of type Option Comp*/
  children: (ReactElement<FC<IOptionProps>> | ReactElement<FC<IOptionProps>>[])[];
  /** innerText of the Comp */
  text: string;
  /** Visual type of the button */
  variant?: ButtonVariantEnums;
  /** Inverts Color and Background IColor */
  inverted: boolean;
  /** Optional Click event handler. Fired even the value of select does not change*/
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  /** Event handler, to be called if select value changes*/
  onChange: () => void;
  /** Should be the list content to be visible*/
  isOpen?: boolean;
  /** Disabled state of the component*/
  disabled: boolean;
  /** Should be full width or not*/
  inline?: boolean;
  /** testId root for ui test*/
  testID?: string;
}

export const dropDownTestID = "DropDown";
export const dropDownActionButtonTestID = `${dropDownTestID}-action-button`;
export const dropDownArrowButtonTestID = `${dropDownTestID}-arrow-button`;
export const dropDownListTestID = `${dropDownTestID}-list`;

/**
 * An  Button element with ``Options`` list.
 */
export const DropDown: FC<IDropDownProps> = ({
  variant = ButtonVariantEnums.SECONDARY,
  disabled,
  inverted,
  onClick,
  text,
  onChange, //TODO: add functionality to this event using ContextAPI
  isOpen = false,
  children,
  inline = false,
  testID = "",
}: IDropDownProps) => {
  const [listVisible, setListVisible] = useState(isOpen);
  const selectListRef = useRef<HTMLDivElement>(null);
  const toggleListVisibility = (): void => {
    setListVisible(!listVisible);
  };
  const handleSelect = (): void => {
    setListVisible(false);
  };

  useLayoutEffect(() => {
    if (listVisible === undefined) setListVisible(isOpen);
    function closeOnOuterClick(e: globalThis.MouseEvent): void {
      if (selectListRef === null) return;
      if (selectListRef.current === null) return;
      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(
        selectListRef.current === e.target || selectListRef.current.contains(e.target as Node)
      );

      isOuter && listVisible && setListVisible(false);
    }

    if (listVisible) {
      window.document.querySelector("body")?.addEventListener("mouseup", closeOnOuterClick);
      return (): void => {
        window.document.querySelector("body")?.removeEventListener("mouseup", closeOnOuterClick);
      };
    }
  }, [listVisible, isOpen]);
  return (
    <StyledDropDown ref={selectListRef} inline={inline} data-test-id={`${testID}${dropDownTestID}`}>
      <StyledDropDownButtons
        isOpen={listVisible}
        variant={variant}
        inverted={inverted}
        inline={inline}
      >
        <ActionButton
          variant={variant}
          type="button"
          inverted={inverted}
          disabled={disabled}
          onClick={onClick}
          isOpen={isOpen}
          data-test-id={`${testID}${dropDownActionButtonTestID}`}
        >
          {text}
        </ActionButton>

        <IconButton
          icon={
            <Icon
              size={IconSizeEnums.SMALL}
              name={listVisible ? IconNameEnums.CHEVRON_UP : IconNameEnums.CHEVRON_DOWN}
            />
          }
          inverted={inverted}
          onClick={toggleListVisibility}
          type={"button"}
          variant={variant}
          disabled={disabled}
          data-test-id={`${testID}${dropDownArrowButtonTestID}`}
        />
      </StyledDropDownButtons>
      {listVisible && (
        <StyledOptionsList
          variant={variant}
          inverted={inverted}
          data-test-id={`${testID}${dropDownListTestID}`}
        >
          {Children.map(children, (child) => (
            <StyledOptionsListItem variant={variant} inverted={inverted} onClick={handleSelect}>
              {child}
            </StyledOptionsListItem>
          ))}
        </StyledOptionsList>
      )}
    </StyledDropDown>
  );
};
