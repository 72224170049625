import { AuthError, DraftClient, IUserInfo } from "./amplify";
import { IBrandedValues, LayoutEnums } from "./styled";
import { FileUploadFormat, IUploadsContext } from "./uploads";
import { IEventsState, IReportsContext, ISystemMessage } from "../_lib/store/reducers";
import { IPlanningContext } from "./plans";
import {
  ICustomerData,
  IFeaturesDataRetVal,
  ITreeData,
} from "../_lib/hooks/appStore/appStoreTypes";
import { ICoupon } from "../views/Public/Register";
import { ICustomizationRedux } from "./customization";

export interface ILocalState {
  //__typename: TypeNameEnums.INITIAL_DATA;
  appStore: IAppStoreInfo;
  currentUser: IUserInfo;
  customization: ICustomizationRedux;
  dataSync: IDataSync;
  draftClient: DraftClient;
  downloads: TDownloadsState;
  errorState: {
    //__typename: TypeNameEnums.AUTH_ERROR;
    errors: AuthError[];
  };
  eventsState: IEventsState;
  planning: IPlanningContext;
  reporting: IReportsContext;
  systemMessages: ISystemMessage[];
  themeOptions: IThemeOptions;
  uploads: IUploadsContext;
  warmUp: IWarmUpState;
}

export interface IAppStoreInfo {
  subscriptions?: ISubscription[];
  paymentType?: string;
  features?: IFeatures[];
  pricings?: IPricing[];
  customerData?: ICustomerData;
  couponList?: ICoupon[];
  featuresData?: IFeaturesDataRetVal;
  promotionsData?: IGetPromotionsData[];
  billingAddress?: IBillingDetailedAddress;
  content?: Record<EAppStoreMenuItem, IFeatureContentItem>;
  selectedMenuItem: EAppStoreMenuItem;
  paymentWall?: boolean;
  menuSubFeatures: ITreeData | undefined;
  comparisonData?: IRenderRules[];
}

export interface ISubscription {
  subscription_id: string;
  customer_id: string;
  product_id: EProduct;
  pricing_id: EPricing;
  feature_id: string;
  links: RelationLink[];
  status: ESubscriptionStatus;
  start_date: number;
  trial_end: number;
  trial_start: number;
}

export interface IPricing {
  pricing_id: string;
  trial_days: number;
  price: number;
  default: boolean;
  disabled?: boolean;
  feature_id?: string;
  product_id?: string;
  subscription: boolean;
  page?: boolean;
  page_type?: string;
}
export interface IFeatures {
  feature_id: string;
  pricing_id: string;
  status?: string;
  tree?: IFeatureInfo[];
  start_date?: number;
  trial_end?: number;
  canceled_at?: number;
}

export interface IFeatureInfo {
  value: string;
  key: string;
  type: string;
}

export type IDataSync = Record<EDataSyncLevel, Record<EDataSyncScope, IDataChangedState>>;

export interface IDataSyncOptions {
  level: EDataSyncLevel;
  scope: EDataSyncScope;
  refreshRate?: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncLevel {
  // ACCOUNT = "ACCOUNT",
  CUSTOMER = "CUSTOMER",
  CLIENT = "CLIENT",
  // USER = "USER",
}
export enum EDataSyncScope {
  CUSTOMER = "CUSTOMER",
  SUBSCRIPTION = "SUBSCRIPTION",
  WALLET = "WALLET",
  CLIENT = "CLIENT",
  LOGO = "LOGO",
  FILE = "FILE",
  SUSA = "SUSA",
  KPI = "KPI",
  KPI_PLAN = "KPI_PLAN",
  EVAL = "EVAL",
  ERROR = "ERROR",
  PLAN = "PLAN",
  ALERT = "ALERT",
  PAGE = "PAGE",
}

export enum EDataSyncTypes {
  ADD = "ADD",
  UPSERT = "UPSERT",
  UPLOAD = "UPLOAD",
  DELETE = "DELETE",
  WARMUP = "WARMUP",
  DOWNLOAD = "DOWNLOAD",
  LOGIN = "LOGIN",
  VISIT = "VISIT",
  LOGOUT = "LOGOUT",
  REMOVE = "REMOVE",
  FLUSH = "FLUSH",
  SYNC = "SYNC",
  BUY = "BUY",
  CANCEL = "CANCEL",
}

export interface IThemeOptions {
  //__typename: TypeNameEnums.THEME_OPTIONS;
  layout: LayoutEnums;
  branded: IBrandedValues;
  sideNavExpanded: boolean;
}

export interface IDataChangedState {
  timeStamp: number;
  pending: boolean;
  timeout: boolean;
  refreshRate: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncRefreshRate {
  IDLE = 0,
  FAST = 3000,
  MEDIUM = 10000,
  SLOW = 30000,
}
export interface IDataSyncAction {
  type: DataSyncStateActionTypes;
  payload: IDataSyncActionPayload;
}
export interface IDataSyncActionPayload extends IDataSyncOptions {
  state?: Partial<Pick<IDataChangedState, "timeStamp" | "timeout">>;
}
export enum DataSyncStateActionTypes {
  SET_TIMESTAMP = "SET_TIMESTAMP",
  SET_PENDING = "SET_PENDING",
  SET_READY = "SET_READY",
  SET_TIMEOUT = "SET_TIMEOUT",
  SET_REFRESH_RATE = "SET_REFRESH_RATE",
  RESET = "RESET",
  RESET_ALL = "RESET_ALL",
}
export interface IWarmUpAction {
  type: IWarmUpTopics;
  scope: IWarmUpScopes;
  value: number;
}
export enum IWarmUpTopics {
  SUSA_TOPIC = "SUSA_TOPIC",
  FILE_TOPIC = "FILE_TOPIC",
}
export enum IWarmUpScopes {
  CUSTOMER = "CUSTOMER",
  CLIENT = "CLIENT",
  USER = "USER",
  LOGO = "LOGO",
  FILE = "FILE",
  SUSA = "SUSA",
  KPI = "KPI",
  EVAL = "EVAL",
  ERROR = "ERROR",
}

export type IWarmUpState = Record<IWarmUpTopics, IWarmUpScopeState>;
export type IWarmUpScopeState = Record<IWarmUpScopes, number>;
export interface IDownloadsAction {
  type: IDownloadsActionType;
  payload: IDownloadFile;
}

export enum IDownloadsActionType {
  ADD_DOWNLOAD = "ADD_DOWNLOAD",
  REMOVE_DOWNLOAD = "REMOVE_DOWNLOAD",
  EDIT_DOWNLOAD = "EDIT_DOWNLOAD",
  ADD_ERROR = "ADD_ERROR",
}
export interface IDownloadFile {
  identifier: string;
  name: string;
  url: string;
  format: FileUploadFormat;
  error?: string;
  isDownloading: boolean;
  downloadUrl?: string;
}
export type TDownloadsState = IDownloadFile[];
