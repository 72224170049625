import { gql } from "@apollo/client/core";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

const IS_EMAIL_AND_COUPON_VALID = gql`
  query validate($email: String!, $coupon: String!, $product: String!) {
    isEmailValid(email: $email)
    isCouponValid(product: $product, coupon: $coupon)
  }
`;
interface ValidateCouponAndEMailVars {
  email: string;
  coupon: string;
  product: string;
}

interface ValidateEmailAndCouponResult {
  isEmailValid: boolean;
  isCouponValid: boolean;
}

type TUseValidateEmailAndCoupon = () => TUseValidateEmailAndCouponRetVal;

type TUseValidateEmailAndCouponRetVal = (
  email: string,
  coupon: string,
  product: string
) => Promise<ApolloQueryResult<ValidateEmailAndCouponResult>>;

export const useValidateEmailAndCoupon: TUseValidateEmailAndCoupon = () => {
  const apolloClient = useApolloClient();

  return (
    email: string,
    coupon: string,
    product: string
  ): Promise<ApolloQueryResult<ValidateEmailAndCouponResult>> => {
    return apolloClient.query<ValidateEmailAndCouponResult, ValidateCouponAndEMailVars>({
      query: IS_EMAIL_AND_COUPON_VALID,
      variables: { email, coupon, product },
      fetchPolicy: "network-only",
    });
  };
};
