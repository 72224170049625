import { useMutation } from "@apollo/client";
import {
  AlertTypeEnums,
  ICopyPlan,
  ICopyPlanVariables,
  ICreatePlan,
  ICreatePlanVariables,
  IDeletePlan,
  IDeletePlanVariables,
  IEditPlan,
  IEditPlanVariables,
  ILocalState,
  IPlanData,
  IPlanDataVariables,
  IPlanInput,
  IPlan,
  ICopyFromPlan,
  IGetPlan,
  IGetPlanVariables,
  EPlanningPage,
  EPlanningSubPage,
  IUpdateTemplatesRetVal,
  IUpdateTemplatesVariables,
  IPlanTemplates,
} from "../../../@types/index.d";
import { useCallback } from "react";
import {
  COPY_PLAN,
  CREATE_PLAN,
  DELETE_PLAN,
  EDIT_PLAN,
  GET_PLAN,
  GET_PLANS_OF_CLIENT,
  UPDATE_TEMPLATES,
} from "./plan-queries";
import { useDispatch, useSelector } from "react-redux";
import { useSystemMessages } from "../useSystemMessages";
import { EAutoCloseTimeOut, IPlanningAction, IPlanningActionTypes } from "../../store/reducers";
import { isEqual } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
import { usePlanningHelper } from "./usePlanningHelper";

interface IUsePlansReturnValue {
  callGetPlans: (clientId: string) => void;
  plans: IPlan[] | undefined;
  plansLoading: boolean;
  getPlan: (plan_id: string, subPage?: EPlanningSubPage | string) => Promise<IPlan | undefined>;
  create: (plan: IPlanInput) => Promise<IPlan | undefined>;
  createPlanLoading: boolean;
  duplicate: (plan: ICopyFromPlan) => Promise<boolean | void>;
  edit: (plan: ICopyFromPlan) => Promise<boolean | void>;
  editPlanLoading: boolean;
  remove: (plan_id: string) => Promise<boolean | void>;
  removePlanLoading: boolean;
  activate: (plan_id: string) => void;
  updateTemplates: (
    plan_id: string,
    type: string,
    data: Partial<IPlanTemplates>
  ) => Promise<IPlan | undefined>;
}

type TUsePlans = () => IUsePlansReturnValue;
/**
 * CRUD Hook for selected Client
 */
export const usePlans: TUsePlans = () => {
  const client_id = useSelector(
    ({
      currentUser: {
        selectedClient: { client_id },
      },
    }: ILocalState) => client_id,
    isEqual
  );

  const { addSystemMessage } = useSystemMessages();
  const { switchPlanningPage } = usePlanningHelper();
  const plans = useSelector(({ planning: { plans } }: ILocalState) => plans, isEqual);
  const dispatchPlanning = useDispatch<Dispatch<IPlanningAction>>();

  const errorMessageCallback = useCallback(
    (err) => {
      addSystemMessage({
        type: AlertTypeEnums.ERROR,
        autoCloseTimeout: EAutoCloseTimeOut.MEDIUM,
        identifier: err.code,
        code: err.message,
        closable: false,
      });
    },
    [addSystemMessage]
  );
  const [getPlansOfClientFn, { data, loading: plansLoading }] = useMutation<
    IPlanData,
    IPlanDataVariables
  >(GET_PLANS_OF_CLIENT, {
    onCompleted: async (data) => {
      if (data && data.getPlans) {
        // set the plans in the store
        if (!isEqual(data.getPlans, plans)) {
          dispatchPlanning({
            type: IPlanningActionTypes.SET_PLANS,
            payload: {
              plans: [...data.getPlans],
            },
          });
        }

        dispatchPlanning({
          type: IPlanningActionTypes.SET_PLANNING_LOADING_STATUS,
          payload: {
            planningLoadingStatus: false,
          },
        });
      }
    },
    fetchPolicy: "no-cache",
  });

  // to update the planning lines
  const [updateTemplatesFn] = useMutation<IUpdateTemplatesRetVal, IUpdateTemplatesVariables>(
    UPDATE_TEMPLATES,
    {
      onError: errorMessageCallback,
      fetchPolicy: "no-cache",
    }
  );

  const updateTemplates = (
    plan_id: string,
    type: string,
    data: Partial<IPlanTemplates>
  ): Promise<IPlan | undefined> => {
    // set loading spinner to be returned to false in set_selected_plan action
    dispatchPlanning({
      type: IPlanningActionTypes.SET_PLANNING_LOADING_STATUS,
      payload: {
        planningLoadingStatus: true,
      },
    });
    return updateTemplatesFn({
      variables: {
        plan_id,
        type,
        data,
      },
    })
      .then(({ data }) => data?.updateTemplates)
      .then((plan) => {
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLAN,
          payload: {
            selectedPlan: plan,
          },
        });
        return plan;
      });
  };

  // to call getPlansOfClient Call
  const callGetPlans = (clientId: string): void => {
    // set loading spinner to be returned to false in set_selected_plan action
    dispatchPlanning({
      type: IPlanningActionTypes.SET_PLANNING_LOADING_STATUS,
      payload: {
        planningLoadingStatus: true,
      },
    });
    getPlansOfClientFn({
      variables: {
        params: { client_id: clientId, with_data: false, with_actuals: false },
      },
    });
  };
  const [getPlanbyId, { loading: getPlanLoading }] = useMutation<IGetPlan, IGetPlanVariables>(
    GET_PLAN,
    {
      onError: errorMessageCallback,
      fetchPolicy: "no-cache",
    }
  );

  const [createPlan, { loading: createPlanLoading }] = useMutation<
    ICreatePlan,
    ICreatePlanVariables
  >(CREATE_PLAN, {
    onError: errorMessageCallback,

    fetchPolicy: "no-cache",
  });
  const [copyPlan] = useMutation<ICopyPlan, ICopyPlanVariables>(COPY_PLAN, {
    onError: errorMessageCallback,
    fetchPolicy: "no-cache",
  });
  const [editPlan, { loading: editPlanLoading }] = useMutation<IEditPlan, IEditPlanVariables>(
    EDIT_PLAN,
    {
      onError: errorMessageCallback,
      fetchPolicy: "no-cache",
    }
  );
  const [removePlan, { loading: removePlanLoading }] = useMutation<
    IDeletePlan,
    IDeletePlanVariables
  >(DELETE_PLAN, {
    onError: errorMessageCallback,
    fetchPolicy: "no-cache",
  });

  const getPlan = (
    plan_id: string,
    subPage?: EPlanningSubPage | string
  ): Promise<IPlan | undefined> => {
    // set loading spinner to be returned to false in set_selected_plan action
    dispatchPlanning({
      type: IPlanningActionTypes.SET_PLANNING_LOADING_STATUS,
      payload: {
        planningLoadingStatus: true,
      },
    });
    return getPlanbyId({
      variables: {
        plan_id,
        with_data: true,
        with_actuals: true,
        template_filter: subPage,
      },
    })
      .then(({ data }) => data?.getPlan)
      .then((plan) => {
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLAN,
          payload: {
            selectedPlan: plan,
          },
        });
        return plan;
      });
  };

  const create = (plan: IPlanInput): Promise<IPlan | undefined> => {
    return createPlan({ variables: { plan } }).then(({ data }) => {
      callGetPlans(plan.client_id);
      switchPlanningPage(EPlanningPage.LIST);
      return data?.createPlan;
    });
  };

  const duplicate = ({ plan_id, ...plan }: ICopyFromPlan): Promise<boolean | void> => {
    if (!plan_id) throw new Error("plan_id must be defined");

    return copyPlan({
      variables: { plan, plan_id },
    }).then();
  };
  const edit = ({ plan_id, ...plan }: ICopyFromPlan): Promise<boolean | void> => {
    if (!plan_id) return Promise.reject("plan_id must be defined");

    return editPlan({
      variables: { plan, plan_id },
    }).then(() => callGetPlans(client_id as string));
  };
  const remove = (plan_id: string): Promise<boolean | void> => {
    return removePlan({ variables: { plan_id } }).then(() => callGetPlans(client_id as string));
  };
  const activate = (plan_id: string): void => {
    if (!plan_id) throw new Error("plan_id must be defined");
    if (plans === undefined) throw new Error("plans can not be undefined");
    const selectedPlan = plans.find((item) => item.plan_id === plan_id);
    if (selectedPlan === undefined) throw new Error("selectedPlan can not be undefined");
    const { title, description } = selectedPlan;

    edit({ plan_id, active: true, title, description: description || "" });
  };

  return {
    callGetPlans,
    plans: data?.getPlans,
    // plansLoading: data === undefined || plansLoading || getPlanLoading,
    plansLoading: plansLoading || getPlanLoading,
    getPlan,
    create,
    createPlanLoading,
    duplicate,
    edit,
    editPlanLoading,
    remove,
    removePlanLoading,
    activate,
    updateTemplates,
  };
};
