import styled, { css } from "styled-components/macro";
import { UploadMethodSelection } from "../../views/Private/Setup/styled.setup-content";
import { StyledSubSection, SubSectionProps } from "../../StyledComponents";

interface IUploadFileIconProps {
  selected: boolean;
}
export const StyledUploadsContainer = styled.section(({ theme }) => {
  return css`
    display: block;
  `;
});

export const StyledUploadSteps = styled.section(({ theme }) => {
  return css`
    display: block;
    margin-bottom: ${theme.rem(1)};
  `;
});

export const StyledUploadOptions = styled.div(({ theme }) => {
  return css`
    display: grid;
    grid-template-columns: auto min-content auto min-content auto;
    grid-column-gap: ${theme.rem(1)};
    justify-content: center;
    margin: 0 ${theme.rem(2)} ${theme.rem(2)};
    > div.separator {
      position: relative;
      display: flex;
      align-items: center;
      color: ${theme.values.colors.global.greys.fourth};
      font-size: ${theme.rem(0.75)};

      &:before {
        content: "";
        border-left: 1px solid;
        display: block;
        height: 100%;
        left: 50%;
        position: relative;
        z-index: 0;
        border-image: linear-gradient(
            to top,
            ${theme.values.colors.global.greys.fourth} calc(50% - 10px),
            ${theme.values.colors.global.greys.first} calc(50% - 10px),
            ${theme.values.colors.global.greys.first} calc(50% + 10px),
            ${theme.values.colors.global.greys.fourth} calc(50% + 10px)
          )
          1 100%;
      }

      &:after {
        content: attr(data-or);
        padding: ${theme.rem(0.25)} 0;
        background-color: transparent;
        display: inline-block;
        z-index: 1;
      }
    }
  `;
});

export const StyledUploadOptionItem = styled.section<UploadMethodSelection>(
  ({ theme, selected }) => {
    const colors = {
      active: {
        bg: theme.branded.colors.secondary,
        text: theme.values.colors.global.greys.first,
      },
      inactive: {
        bg: theme.values.colors.global.greys.fourth,
        text: "inherit",
      },
    };
    return css`
      border: 2px solid ${selected ? colors.active.bg : colors.inactive.bg};
      border-radius: 2px;
      cursor: ${selected ? "default" : "pointer"};

      > * {
        transition: all 300ms;
      }
      > div.title,
      > div.info {
        padding: ${theme.rem(1)};
        display: block;
        text-align: center;
      }
      > div.title {
        background-color: ${selected ? colors.active.bg : colors.inactive.bg};
        color: ${selected ? colors.active.text : colors.inactive.text};
      }
      > div.info {
        color: ${theme.branded.colors.primary};
      }
      &:hover {
        border-color: ${colors.active.bg};
        > div.title {
          background-color: ${colors.active.bg};
          color: ${colors.active.text};
        }
      }
    `;
  }
);

export const StyledUploadDatev = styled.section(({ theme }) => {
  return css`
    display: grid;
    grid-template-areas: "datev datev-ineterface";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.rem(1)};
  `;
});
export const StyledDatevPdfUpload = styled(StyledSubSection)<SubSectionProps>(({ theme }) => {
  return css`
    grid-area: datev;
  `;
});
export const StyledDatevInterface = styled(StyledSubSection)<SubSectionProps>(
  ({ theme, isLoading }) => {
    return css`
      grid-area: datev-ineterface;
    `;
  }
);

export const StyledUploadExcel = styled.section(({ theme }) => {
  return css`
    display: grid;
    grid-template-areas: "file-required-fields file-icons";
    grid-template-columns: 1fr 1fr;
  `;
});

export const StyledFileRequiredFields = styled.div(({ theme }) => {
  return css`
    grid-area: file-required-fields;
  `;
});
export const StyledUploadFileIcons = styled.div(({ theme }) => {
  return css`
    grid-area: file-icons;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  `;
});
export const StyledUploadFileIcon = styled.div<IUploadFileIconProps>(({ theme, selected }) => {
  const selectedProps = selected
    ? css`
        color: ${theme.values.colors.global.primary.third};
      `
    : css`
        color: ${theme.values.colors.global.greys.third};
      `;
  return css`
    ${selectedProps}
  `;
});

export const StyledUploadManualEntry = styled.section(({ theme }) => {
  return css`
    display: grid;
    grid-column-gap: ${theme.rem(1)};
    grid-row-gap: ${theme.rem(1)};
    grid-template-areas:
      "fiscal-year fiscal-year"
      "guv balance"
      "submit submit";
    grid-template-columns: 1fr 2fr;
  `;
});
export const StyledUploadManualEntryFiscalYear = styled(StyledSubSection)<SubSectionProps>(
  ({ theme, isLoading }) => {
    return css`
      grid-area: fiscal-year;
    `;
  }
);
export const StyledUploadManualEntryGuV = styled(StyledSubSection)<SubSectionProps>(
  ({ theme, isLoading }) => {
    return css`
      grid-area: guv;
    `;
  }
);
export const StyledUploadManualEntryBalance = styled(StyledSubSection)<SubSectionProps>(
  ({ theme, isLoading }) => {
    return css`
      grid-area: balance;
    `;
  }
);

export const StyledUploadManualEntrySubmit = styled(StyledSubSection)<SubSectionProps>(
  ({ theme, isLoading }) => {
    return css`
      grid-area: submit;
    `;
  }
);

export const StyledTotalSum = styled.div(({ theme }) => {
  return css`
    font-weight: ${theme.values.fontWeight.bold};
    text-align: right;
  `;
});
