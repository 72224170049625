import styled, { css, SimpleInterpolation } from "styled-components/macro";
import { Link } from "react-router-dom";
import { IconButton } from "../../components/formElements/Button";
import { useResponsive } from "../../_lib/hooks/useResponsive";
import { StyledResponsiveImage } from "../../StyledComponents";

export const StyledClientList = styled.ul(({ theme }) => {
  /** set Column count in the list */
  const columnCount = (count: number): SimpleInterpolation =>
    css`
      grid-template-columns: repeat(${count}, minmax(auto, ${100 / count}%));
    `;
  return useResponsive({
    MINI: {
      style: css`
        list-style: none;
        display: grid;
        padding: 0;
        max-width: 100vw;

        grid-column-gap: ${theme.rem(1)};
        grid-row-gap: ${theme.rem(1)};
      `,
    },
    SMALL: {
      landscape: css`
        max-width: 90vw;
        ${columnCount(3)};
      `,
      portrait: css`
        max-width: 90vw;
        ${columnCount(2)};
      `,
    },
    MEDIUM: {
      landscape: css`
        max-width: 75vw;
        ${columnCount(4)};
      `,
      portrait: css`
        max-width: 90vw;
        ${columnCount(3)};
      `,
    },
    LARGE: {
      landscape: css`
        max-width: 75vw;
        ${columnCount(4)};
      `,
    },
  });
});

export const StyledClientListItem = styled.li(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 250px;
        position: relative;
        &:last-of-type {
          grid-area: span 2;
        }
      `,
    },
  });
});
export const StyledDeleteIcon = styled(IconButton)(({ theme }) => {
  return css`
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: ${theme.values.colors.global.greys.first};
    &:hover {
      background-color: ${theme.values.colors.global.greys.first};
    }
  `;
});
const StyledClientItem = styled(Link)(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.primary.third};
    border: 2px solid;
    border-radius: ${theme.rem(0.25)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: color 300ms;
    padding: ${theme.rem(1)};
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  `;
});
export const StyledExistingItem = styled(StyledClientItem)(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.primary.third};
    font-weight: ${theme.values.fontWeight.bold};
    &:hover {
      color: ${theme.values.colors.global.primary._100};
    }
    &:before {
      content: attr(data-abo);
      background-color: ${theme.values.colors.global.greys.first};
      position: absolute;
      padding: ${theme.rem(0.25)};
      left: 50%;
      top: ${theme.rem(1)};
      transform: translate(-50%, -50%);
    }

    > span[data-expires] {
      color: ${theme.values.colors.global.primary.third};
      font-size: ${theme.rem(0.75)};
    }
  `;
});
export const StyledExistingItemLogo = styled(StyledResponsiveImage)(({ theme }) => {
  return css`
    width: auto;
    max-width: 100%;
    max-height: ${theme.rem(10)};
  `;
});
export const StyledDeleteConfirmItem = styled(StyledClientItem)(({ theme }) => {
  return css`
    border-color: ${theme.values.colors.global.validation.first};
    color: ${theme.values.colors.global.primary.third};

    > .title {
      font-weight: ${theme.values.fontWeight.bold};
    }
    > .text {
      /* No styling currently required */
    }

    > .buttons_row {
      display: flex;
      justify-content: space-between;
    }
  `;
});
export const StyledFreeContingentItem = styled(StyledClientItem)(({ theme }) => {
  return css`
    &:hover {
      color: ${theme.values.colors.global.primary._100};
    }
  `;
});
export const StyledGetMoreItem = styled(StyledExistingItem)(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.primary.third};

    &:hover {
      color: ${theme.values.colors.global.primary._100};
    }
  `;
});
