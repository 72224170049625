import { ISortType } from "../_lib/hooks";
import { RelationLink } from ".";

export interface ICreateReportContentOptions {
  action: "CREATE" | "EDIT";
  reportGroup: EReportGroup;
  accountId: string;
  type: EReportType;
  inputs: Partial<IReportInputValues>;
  saveMeta?: TCreateReportTypes;
  report_id?: string;
}

export enum EReportGroup {
  CROSS_CLIENT = "RPT001",
  CURRENT_CLIENT = "RPT002",
}
export enum EReportType {
  UNKNOWN = "UNKNOWN",
  FORECAST = "RP001", // ERGEBNIS-FORECAST,
  KPIS = "RP002", // KENNZAHLENBERICHT,
  MULTI_YEAR_PLANNING = "RP003", // // MEHRJAHRESPLANUNG,
  MONTHLY = "RP004", //"overall": "Monthly Overall",
  EVALUATION = "RP005", // MONATSZEUGNIS,
  PLANNING = "RP006", // PLANUNGSDETAILS,
  POTENTIAL = "RP007", // POTENZIALBERICHT,
  POTENTIAL_OVERVIEW = "RP008", // POTENZIALÜBERSICHT,
  LIABILITY_RISK = "RP009", // HAFTUNGSRISIKO,
  BANKERS_PERSPECTIVE = "RP010", // BANKER'S PERSPECTIVE
}

export enum EReportFormFields {
  TITLE = "title",
  INTERNAL_NOTE = "internal_note",
  SUMMARY = "summary",
  IS_VALUE = "is_value",
  PLAN_FROM = "plan_from",
  PLAN_TO = "plan_to",
  PLAN_ID = "plan_id",
  CREATED_AT = "created_at",
  STATUS = "status",
}
export interface IReportInputValues {
  [EReportFormFields.TITLE]: IReportInputPropDef<string>;
  [EReportFormFields.INTERNAL_NOTE]: IReportInputPropDef<string>;
  [EReportFormFields.SUMMARY]: IReportInputPropDef<string>;
  [EReportFormFields.IS_VALUE]: IReportInputPropDef<string>;
  [EReportFormFields.PLAN_FROM]: IReportInputPropDef<number>;
  [EReportFormFields.PLAN_TO]: IReportInputPropDef<number>;
  [EReportFormFields.PLAN_ID]: IReportInputPropDef<string | null>;
  [EReportFormFields.CREATED_AT]: IReportInputPropDef<number>;
}

export interface IReportInputPropDef<T> {
  value: T;
  required: boolean;
  valid: boolean;
  disabled: boolean;
}
export type TReportForecastInput = Pick<
  IReportInputValues,
  | EReportFormFields.TITLE
  | EReportFormFields.INTERNAL_NOTE
  | EReportFormFields.PLAN_ID
  | EReportFormFields.IS_VALUE
>;
export type TReportKpisInput = Pick<
  IReportInputValues,
  | EReportFormFields.TITLE
  | EReportFormFields.INTERNAL_NOTE
  | EReportFormFields.SUMMARY
  | EReportFormFields.IS_VALUE
>;
export type TReportMultiYearInput = Pick<
  IReportInputValues,
  | EReportFormFields.TITLE
  | EReportFormFields.INTERNAL_NOTE
  | EReportFormFields.PLAN_ID
  | EReportFormFields.SUMMARY
  | EReportFormFields.PLAN_FROM
  | EReportFormFields.PLAN_TO
>;
export type TReportPlanInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE | EReportFormFields.PLAN_ID
>;
export type TReportMonthlyEvalInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE | EReportFormFields.IS_VALUE
>;
export type TReportMonthlyInput = Pick<
  IReportInputValues,
  | EReportFormFields.TITLE
  | EReportFormFields.INTERNAL_NOTE
  | EReportFormFields.SUMMARY
  | EReportFormFields.PLAN_ID
  | EReportFormFields.IS_VALUE
>;
export type TReportPotentialOverviewInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE | EReportFormFields.IS_VALUE
>;
export type TReportPotentialInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE | EReportFormFields.IS_VALUE
>;
export type TReportLiabilityInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE
>;
export type TReportBankersPerspectiveInput = Pick<
  IReportInputValues,
  EReportFormFields.TITLE | EReportFormFields.INTERNAL_NOTE | EReportFormFields.IS_VALUE
>;

export interface IReportEntryItem {
  type: EReportType;
  report_id: string;
  links: RelationLink[];
  [EReportFormFields.TITLE]: string;
  [EReportFormFields.INTERNAL_NOTE]: string;
  [EReportFormFields.SUMMARY]?: string;
  [EReportFormFields.IS_VALUE]?: string;
  [EReportFormFields.PLAN_ID]?: string;
  [EReportFormFields.PLAN_FROM]?: string;
  [EReportFormFields.PLAN_TO]?: string;
  [EReportFormFields.CREATED_AT]: number;
  [EReportFormFields.STATUS]?: EReportStatusValues;
  __in_progress?: boolean;
  // created_at: number;
  // internal_note: string;
  // summary?: string;
  // is_value?: string;
  // plan_id?: string;
}

export enum EReportStatusValues {
  VALID = "VALID",
  INVALID_SUSA = "INVALID_SUSA",
  INVALID_PLAN = "INVALID_PLAN",
}

export interface ISortReportColumns {
  TITLE: ISortType;
  TYPE: ISortType;
  CREATED_AT: ISortType;
  IS_VALUE: ISortType;
  PLAN_VALUE: ISortType;
}

export enum EReportDownloadFormat {
  PDF = "PDF",
  EXCEL = "EXCEL",
  WORD = "WORD",
}
export type ISortReportByAction = keyof ISortReportColumns;

export interface IReportsTable {
  initialSort: ISortReportColumns;
  accountId: string;
  group: EReportGroup;
}

export interface IReportDownloading {
  report_id: string;
}

export interface IReportsContentProps {
  group: EReportGroup;
}

export interface IReportOptions {
  reportGroup: EReportGroup;
  accountId: string | null;
}

export interface IGetReportTypeQuery {
  getReportsTree: IReportGroupsTree[];
}

export interface IReportGroupsTree {
  value: EReportGroup;
  tree: IReportTypesTree[];
}
export interface IReportTypesTree {
  value: EReportType;
}

export type TValidateReportForm = (
  context: ICreateReportContentOptions
) => IValidateReportFormRetVal;
export interface IValidateReportFormRetVal {
  canSubmit: boolean;
  newContext: ICreateReportContentOptions;
}

export interface IUseReportFormContextReturnValue {
  t: TFunction;
  context: ICreateReportContentOptions | undefined;
  currentUser: IUserInfo;
  plans: IPlan[] | undefined;
  reduxReports: IReportEntryItem[];
  selectedReportRedux: Partial<IReportEntryItem> | undefined;
  yearsRange: string[];
  selectedPlanId: string | undefined;
}

export enum EReportLang {
  DE = "de",
  EN = "en",
}

export interface IReportComparedTo {
  prev_year: boolean;
  plan: boolean;
}

export interface IDownloadReportBasicInput {
  report_id: string;
  formats: FileUploadFormat[];
  links: RelationLink[];
}
export interface IDownloadMonthlyReportInput extends IDownloadReportBasicInput {
  lang: EReportLang;
  compared_to: IReportComparedTo;
}
export interface IDownloadKpiReportInput extends IDownloadReportBasicInput {
  plan_id: string;
}

export interface IDownloadReportInput {
  [EReportType.MONTHLY]: IDownloadMonthlyReportInput;
  [EReportType.FORECAST]: IDownloadReportBasicInput;
  [EReportType.PLANNING]: IDownloadReportBasicInput;
  [EReportType.KPIS]: IDownloadReportBasicInput;
  [EReportType.EVALUATION]: IDownloadReportBasicInput;
  [EReportType.POTENTIAL]: IDownloadReportBasicInput;
  [EReportType.POTENTIAL_OVERVIEW]: IDownloadReportBasicInput;
  [EReportType.MULTI_YEAR_PLANNING]: IDownloadReportBasicInput;
  [EReportType.LIABILITY_RISK]: IDownloadReportBasicInput;
}
export interface IDownloadReportContext {
  report: IReportEntryItem;
  format?: FileUploadFormat;
  lang?: EReportLang;
  compared_to?: IReportComparedTo;
  name: string;
  reportCanBeDownloaded: boolean;
  canBeComparedTo: IReportComparedTo;
}

/* USEREPORTS HOOK DEFINITIONS START */

export interface ICreateDigitalReportsVars {
  account_id: string;
  type: EReportType;
  title: string;
  internal_note: string;
  plan_id?: string;
  is_value: string;
  summary: string;
  plan_from: number;
  plan_to: number;
}
export interface ICreateDigitalReportsRetVal {
  result: {
    report_id: string;
    plan_id: string;
    created_at: number;
    type: EReportType;
    title: string;
    internal_note: string;
    summary?: string;
    is_value?: string;
    plan_from?: string;
    plan_to?: string;
    __in_progress?: boolean;
    links: RelationLink[];
  };
}

export interface IUpdateDigitalReportsVars extends Omit<ICreateDigitalReportsVars, "account_id"> {
  report_id: string;
}

export interface IUpdateDigitalReportsRetVal extends ICreateDigitalReportsRetVal {
  modified_at: number;
}

export interface IReportMetaInput {
  account_id: string;
  client_id: string;
  type: EReportType;
  internal_note: string;
  title: string;
}

export interface IMultiYearReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.MULTI_YEAR_PLANNING;
  summary: string;
  plan_id: string;
  plan_from: number;
  plan_to: number;
}

export interface IEvaluationReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.EVALUATION;
  is_value: string;
}

export interface IMonthlyReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.MONTHLY;
  is_value: string;
  summary: string;
  plan_id: string | undefined;
  client_id: string;
  length: string;
}

export interface IPotentialOverviewReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.POTENTIAL_OVERVIEW;
  is_value: string;
  plan_id: string;
}

export interface ILiabilityRiskReportMetaInput
  extends Omit<IReportMetaInput, "client_id" | "account_id"> {
  type: EReportType.LIABILITY_RISK;
  account_id: string;
}

export interface IBankersPerspectiveReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.BANKERS_PERSPECTIVE;
  internal_note: string;
  summary: string;
  plan_id: string;
  is_value: string;
}

export interface IForecastReportMetaInput extends Omit<IReportMetaInput, "account_id"> {
  type: EReportType.FORECAST;
  internal_note: string;
  plan_id: string;
  is_value: string;
}

export type TCreateReportTypes =
  | IMultiYearReportMetaInput
  | IEvaluationReportMetaInput
  | IMonthlyReportMetaInput
  | IPotentialOverviewReportMetaInput
  | ILiabilityRiskReportMetaInput
  | IBankersPerspectiveReportMetaInput
  | IForecastReportMetaInput;
// | IKpiReportMetaInput
// | IPlanningReportMetaInput
// | IPotentialReportMetaInput;

export interface IDeleteReportMutation {
  deleteReport: boolean;
}
export interface IDeleteReportMutationVars {
  report_id: string;
}
/* USEREPORTS HOOK DEFINITIONS END */
