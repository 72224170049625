import { Dispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  EPDTablesRows,
  EPlanningPage,
  EPlanningSubPage,
  EPrivatePathName,
  ILocalState,
  IPDTablesConfigData,
  IPlanCalculated,
  InputTypes,
} from "../../../@types/index.d";
import { IPlanningAction, IPlanningActionTypes } from "../../store/reducers";
import { useSessionStorage } from "../useSessionStorage";
import { monthsStringArray } from "./planningCommon";
import { ITableRowDataEntry } from "./usePDDataParsing";
import { CellInputProps } from "../../../components/Table_extended";
import { getArraySum } from "../../common/getArraySum";

interface IUsePlanningHelperRetVal {
  isValueValid: (
    val: number,
    tableCode: string,
    rowType: EPDTablesRows,
    cellInd: number
  ) => boolean;
  getCellProps: (
    table: IPDTablesConfigData,
    row: ITableRowDataEntry,
    cellInd: number
  ) => Pick<CellInputProps, "level" | "alignText" | "highlight" | "editable" | "width" | "type">;
  switchPlanningPage: (page: EPlanningPage, subPage?: EPlanningSubPage | undefined) => void;
  FYMonths: string[];
  isPlan: (planYear: IPlanCalculated) => boolean;
  istOrPlanString: (isPlan: boolean) => string;
  selectedYearIndex: number;
  isIntercompany: boolean | undefined;
  planDuration: (calculated: IPlanCalculated[]) => string[];
}

export const usePlanningHelper = (): IUsePlanningHelperRetVal => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser);
  const selectedDetailsYear = useSelector(
    ({ planning: { selectedDetailsYear } }: ILocalState) => selectedDetailsYear,
    isEqual
  );
  const selectedPlan = useSelector(
    ({ planning: { selectedPlan } }: ILocalState) => selectedPlan,
    isEqual
  );
  const selectedLineId = useSelector(
    ({ planning: { selectedLineId } }: ILocalState) => selectedLineId,
    isEqual
  );
  const PDTablesData = useSelector(
    ({ planning: { PDTablesData } }: ILocalState) => PDTablesData,
    isEqual
  );

  const planYears = useSelector(({ planning: { planYears } }: ILocalState) => planYears, isEqual);

  const dispatchPlanning = useDispatch<Dispatch<IPlanningAction>>();
  const history = useHistory();
  const { clearSessionStorage } = useSessionStorage();
  const { t } = useTranslation();

  const isValueValid = (
    val: number,
    tableCode?: string,
    rowType?: EPDTablesRows,
    cellInd?: number
  ): boolean => {
    switch (rowType) {
      case EPDTablesRows.SINGLE_PAYMENTS_PERCENT:
        // get the value array for the selected year, insert the new value
        const valArr = PDTablesData[selectedDetailsYear][tableCode][rowType].map(
          (item: { value: number }, i: number) => (i === cellInd ? val : item.value)
        );

        // sum must be 100 or less
        return getArraySum(valArr) <= 100;
      default:
        return true;
    }
  };

  // function to return css props for each cell
  const getCellProps = (
    table: IPDTablesConfigData,
    row: ITableRowDataEntry,
    cellInd: number
  ): Pick<CellInputProps, "level" | "alignText" | "highlight" | "editable" | "width" | "type"> => {
    // initialize default cell CSS object
    const cellCSSObj: Pick<
      CellInputProps,
      "level" | "alignText" | "highlight" | "editable" | "width" | "type"
    > = {
      level: "normal",
      alignText: "right",
      highlight: false,
      editable: false,
      type: InputTypes.TABLENUM,
    };

    // sum and total rows are bold
    if (row.type === EPDTablesRows.SUM || row.type === EPDTablesRows.TOTAL)
      cellCSSObj.level = "title";
    // month row is text centered and type text
    if (row.type === EPDTablesRows.MONTHS) {
      cellCSSObj.type = InputTypes.TEXT;
      cellCSSObj.alignText = "center";
    }

    // employees number row is type numberd
    if (
      table.code === "employee_number" &&
      (row.type === EPDTablesRows.PERSONNEL_LINES_EMPLOYEES || row.type === EPDTablesRows.SUM)
    ) {
      cellCSSObj.type = InputTypes.NUMBER;
    }

    // sum column is grey (-2 to normalize the array to 12)
    if (table.hasSumCol && cellInd === row.colNum - 2) {
      cellCSSObj.highlight = true;
    } else {
      // determines if the month is an actual month
      const isActual = planYears[selectedDetailsYear as string][cellInd].type === "actual";

      // actual months are grey
      if (isActual) cellCSSObj.highlight = true;
      if (!isActual) {
        // corrections are editable in plan months
        if (
          row.type === EPDTablesRows.CORRECTIONS ||
          row.type === `${EPDTablesRows.CORRECTIONS}/investment`
        )
          cellCSSObj.editable = true;
        // single payments percent are editable in plan months
        else if (row.type === EPDTablesRows.SINGLE_PAYMENTS_PERCENT) cellCSSObj.editable = true;
        // kpis are editable in plan months
        else if (row.type.includes(EPDTablesRows.KPI)) cellCSSObj.editable = true;
        // personell lines percent are editable in plan months
        else if (
          row.type === EPDTablesRows.PERSONNEL_LINES ||
          row.type === EPDTablesRows.PERSONNEL_LINES_EMPLOYEES
        )
          cellCSSObj.editable = true;
        // lines are editable in plan months if it is the selected line
        else if (row.type === EPDTablesRows.REVENUE_LINES && row.line_id === selectedLineId)
          cellCSSObj.editable = true;
        // lines are editable if they are not revenue_related
        else if (row.type === EPDTablesRows.COSTS_LINES && row.editable) cellCSSObj.editable = true;
      }
    }

    // width of a cell in %, row.colNum + 1 to account for the first cell that is 2 cells wide
    cellCSSObj.width = 100 / (row.colNum + 1);

    // return cell CSS object
    return cellCSSObj;
  };

  // function to change selected planning page
  const switchPlanningPage = (page: EPlanningPage, subPage?: EPlanningSubPage): void => {
    // do nothing if page param is missing
    if (!page) return;

    // dispatch the page, subpage to redux and push to history
    switch (page) {
      case EPlanningPage.PREVIEW:
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLANNING_PAGE,
          payload: { selectedPlanningPage: page },
        });
        dispatchPlanning({
          type: IPlanningActionTypes.SET_DETAILS_SUB_PAGE,
        });
        clearSessionStorage();
        history.push(`${EPrivatePathName.PLAN_PREVIEW}/${selectedPlan?.plan_id}`);
        break;
      case EPlanningPage.LIST:
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLANNING_PAGE,
          payload: { selectedPlanningPage: page },
        });
        dispatchPlanning({
          type: IPlanningActionTypes.NUKE_DETAILS_DATA,
        });
        clearSessionStorage();
        history.push(EPrivatePathName.PLAN);
        break;
      case EPlanningPage.CREATE:
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLANNING_PAGE,
          payload: { selectedPlanningPage: page },
        });
        dispatchPlanning({
          type: IPlanningActionTypes.NUKE_DETAILS_DATA,
        });
        clearSessionStorage();
        history.push(EPrivatePathName.PLAN_CREATE);
        break;
      case EPlanningPage.DETAILS:
        dispatchPlanning({
          type: IPlanningActionTypes.SET_DETAILS_SUB_PAGE,
          payload: { selectedDetailsSubPage: subPage },
        });
        dispatchPlanning({
          type: IPlanningActionTypes.SET_SELECTED_PLANNING_PAGE,
          payload: { selectedPlanningPage: page },
        });
        clearSessionStorage();
        history.push(`${EPrivatePathName.PLAN}/${selectedPlan?.plan_id}/${subPage}`);

        break;
    }
  };

  const selectedYearIndex = useMemo((): number => {
    if (Object.keys(planYears).length === 0) return 0;
    if (!selectedDetailsYear) return 0;
    return Object.keys(planYears).indexOf(selectedDetailsYear);
  }, [planYears, selectedDetailsYear]);

  // memo to get correct months array according to the fiscal year
  const FYMonths = useMemo((): string[] => {
    // pick up the months array from locales
    const months = t(`misc.months.short`, { returnObjects: true }) as string[];

    // determine the index position of fiscal year month in the array
    const fiscalYearMonth =
      monthsStringArray.indexOf(
        currentUser.clients.find(
          (client) => client.client_id === currentUser.selectedClient.client_id
        )?.fiscal_year as string
      ) + 1;
    return months.slice(fiscalYearMonth - 1).concat(months.slice(0, fiscalYearMonth - 1));
  }, [currentUser.clients, currentUser.selectedClient.client_id, t]);

  // function to determine if the month value comes from actuals or plan
  const isPlan = (planYear: IPlanCalculated): boolean => {
    return planYear.type && planYear.type === "actual" ? false : true;
  };

  // function to determine the time period of plan duration (not actual months)
  const planDuration = (calculated: IPlanCalculated[]): string[] => {
    const planTimePeriod: string[] = [];
    // filtering only not actual ones
    for (const data of calculated) {
      if (!data.type) planTimePeriod.push(data.date);
    }
    return planTimePeriod;
  };

  const istOrPlanString = (isPlan: boolean): string => {
    return isPlan ? t("misc.planned_value").toUpperCase() : t("misc.is_value").toUpperCase();
  };

  const isIntercompany = useMemo((): boolean | undefined => {
    if (!selectedPlan || !selectedLineId) return false;
    return selectedPlan?.templates?.revenue?.lines.find((item) => item.line_id === selectedLineId)
      ?.is_intercompany;
  }, [selectedPlan, selectedLineId]);

  return {
    isValueValid,
    getCellProps,
    switchPlanningPage,
    FYMonths,
    isPlan,
    istOrPlanString,
    selectedYearIndex,
    isIntercompany,
    planDuration,
  };
};
