import { gql, QueryTuple, useLazyQuery } from "@apollo/client";
import { IGetKpisQueryVars, IKpiPreferences } from "../../../@types/index.d";

const KPIS_LIST = gql`
  query getKpis($kpiQuery: KpiListInput) {
    kpiMatrix: getAllKPIsOfClient(options: $kpiQuery) {
      client_id
      kpis {
        value
        benchmark
        months {
          actual
          month
          plan
          potential
        }
      }
    }
  }
`;
interface IUseKpiMatrixData {
  kpiMatrix: IKpiPreferences | null;
}
type TUseKpiMatrix = () => QueryTuple<IUseKpiMatrixData, IGetKpisQueryVars>;
export const useKpiMatrix: TUseKpiMatrix = () => {
  return useLazyQuery<IUseKpiMatrixData, IGetKpisQueryVars>(KPIS_LIST);
};
