import styled, { css } from "styled-components/macro";
import { IHeadLine } from "./index";

export const StyledH1 = styled.h1<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.headline};
    font-size: ${theme.rem(4.4)};
    line-height: 1.25;
    font-weight: ${theme.values.fontWeight.bold};
    margin: 0;
  `
);

export const StyledH2 = styled.h2<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.headline};
    font-size: ${theme.rem(2.5)};
    line-height: 1.25;
    font-weight: ${theme.values.fontWeight.bold};
    margin: 0;
  `
);

export const StyledH3 = styled.h3<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.copy.secondary};
    font-size: ${theme.rem(1.5)};
    line-height: 1.5;
    margin: 0;
  `
);

export const StyledH4 = styled.h4<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.headline};
    font-size: ${theme.rem(1.25)};
    line-height: 1.25;
    margin: 0;
  `
);

export const StyledH5 = styled.h5<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.copy.primary};
    font-size: ${theme.rem(1.33)};
    line-height: 1.5;
    margin: 0;
  `
);

export const StyledH6 = styled.h6<IHeadLine>(
  ({ theme }) => css`
    font-family: ${theme.values.fontFamily.copy.secondary};
    font-size: ${theme.rem(1.125)};
    line-height: 1.5;
    margin: 0;
  `
);
