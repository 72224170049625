import { FC } from "react";
import { StyledContentOptions } from "./styled.content-options";
import { IPageContentProps } from "../index";

export type IContentOptions = Pick<IPageContentProps, "title" | "subtitle">;
export const ContentOptions: FC<IContentOptions> = ({ children, title, subtitle }) => {
  return (
    <StyledContentOptions.Container>
      <StyledContentOptions.Header>
        <StyledContentOptions.Title> {title}</StyledContentOptions.Title>
        <StyledContentOptions.SubTitle>{subtitle}</StyledContentOptions.SubTitle>
      </StyledContentOptions.Header>
      <StyledContentOptions.Content>{children}</StyledContentOptions.Content>
    </StyledContentOptions.Container>
  );
};
