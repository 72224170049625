import { gql } from "@apollo/client/core";
import { IGetCustomizationResponse } from "../../../@types";

export interface IGetCustomizationRetVal {
  result: IGetCustomizationResponse[];
}

export const GET_CUSTOMIZATION_METADATA = gql`
  query getCustomizationMetaData($account_ids: [ID!]) {
    result: getCustomizationMetaData(account_ids: $account_ids) {
      account_id
      active
      colors {
        app_opacity
        primary
        secondary
        app_background_color
        nav_background_color
        nav_text_color
        login_background_color
      }
    }
  }
`;

export interface ISetCustomizationRetVal {
  result: IGetCustomizationResponse;
}

export const SET_CUSTOMIZATION_METADATA = gql`
  mutation setCustomizationMetaData($data: CustomizationMetaDataInput) {
    result: setCustomizationMetaData(data: $data) {
      account_id
      active
      colors {
        app_opacity
        primary
        secondary
        app_background_color
        nav_background_color
        nav_text_color
        login_background_color
      }
    }
  }
`;
