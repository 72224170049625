import { gql, useMutation } from "@apollo/client";
import {
  Icon,
  IconNameEnums,
  IconSizeEnums,
  Input,
  EFormatNumberStyle,
  EFormatCurrencyDivision,
} from "@canei/app-components";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  EPrivatePathName,
  IDialogKeyEnums,
  ILocalState,
} from "../../../@types/index.d";
import { useDialogContext, useDialogs, useTransactionEventListener } from "../../../_lib/hooks";
import { useAppStoreInfo } from "../../../_lib/hooks/appStore/useAppStoreInfo";
import { EFlexDirection, StyledAppStore } from "./styled.appStore-page";
import { formatNumber } from "../../../_lib/common/formatNumber";
import {
  EAppStoreConfirmType,
  IFeatureContentItem,
  IAppStoreConfirmProps,
  IUpdateFeaturesVariables,
} from "./appStoreTypes.d";
import { useAppActivity } from "../../../_lib/hooks/appActivity";
import { useAppStoreHelper } from "./useAppStoreHelper";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { EventListenerCodes } from "../../../_lib/hooks/useEventListener/types.event-listener";
import { ButtonBranded, ButtonVariantEnums } from "../../../components/formElements";

export const UPDATE_FEATURES = gql`
  mutation updateFeatureSubscription(
    $type: ActionType!
    $customer_id: ID!
    $product_id: String
    $feature_id: String
    $pricing_id: String!
    $coupon_id: String
  ) {
    updateFeatureSubscription(
      type: $type
      customer_id: $customer_id
      product_id: $product_id
      feature_id: $feature_id
      pricing_id: $pricing_id
      coupon_id: $coupon_id
    )
  }
`;

export const Content: FC = () => {
  const { t } = useTranslation(["quick/common"]);
  const dialogs = useDialogs();
  const dialogContext = useDialogContext<IAppStoreConfirmProps | undefined>(
    IDialogKeyEnums.CONFIRM_PAYMENT,
    undefined
  );
  const { getPricingId, isMonthlySub } = useAppStoreHelper();
  const { currentUser, appStore } = useSelector((state: ILocalState) => state);
  const [transactionEvent, errorState, transaction] = useTransactionEventListener();
  const [transactionLoading, setTransactionLoading] = useState(false);
  const { getCustomerStatus } = useAppStoreInfo();
  const { userPurchaseOrCancel } = useAppActivity();
  const selectedMenuItem = useSelector(
    ({ appStore: { selectedMenuItem } }: ILocalState) => selectedMenuItem,
    isEqual
  );
  const pricings = useSelector(({ appStore: { pricings } }: ILocalState) => pricings, isEqual);
  const [updateFeatures] = useMutation(UPDATE_FEATURES, {
    onCompleted: ({ updateFeatureSubscription }) => {
      const thirdParty = pricings?.some(
        (pricing) => pricing.feature_id === selectedMenuItem && pricing.page
      );

      // if the user is purchasing a third party feature, we wait for page event to update the user
      if (thirdParty && dialogContext?.type === EAppStoreConfirmType.ADD) {
        transactionEvent({
          transactionId: updateFeatureSubscription,
          accountId: currentUser.appUser.customer_id,
          level: EDataSyncLevel.CLIENT,
          scope: EDataSyncScope.PAGE,
          type: EDataSyncTypes.UPSERT,
        });
      }
      // else we wait for wallet event to update the user
      else {
        transactionEvent({
          transactionId: updateFeatureSubscription,
          accountId: currentUser.appUser.customer_id,
          level: EDataSyncLevel.CLIENT,
          scope: EDataSyncScope.WALLET,
        });
      }
      setTransactionLoading(true);
    },
    fetchPolicy: "network-only",
  });
  const history = useHistory();

  // close dialog after the transaction is received
  useEffect(() => {
    if (!dialogContext) return;
    if (transaction?.transactionId && errorState && transactionLoading) {
      const process = errorState[transaction.transactionId];
      if (
        process === EventListenerCodes.TRANSACTION_OK ||
        process === EventListenerCodes.TIME_OUT
      ) {
        getCustomerStatus(currentUser.appUser.customer_id);
        setTransactionLoading(false);
      }
    }
  }, [
    currentUser.appUser.customer_id,
    dialogContext,
    errorState,
    getCustomerStatus,
    transaction?.transactionId,
    transactionLoading,
  ]);

  // save coupon from input field and check if it is valid
  const [coupon, setCoupon] = useState("");

  const isCouponValid = useMemo((): boolean => {
    if (!appStore.couponList) return false;

    // if selected has feature_id search for coupons and match feature_id
    if (dialogContext?.feature.feature_id)
      return appStore.couponList
        .filter((campaign) =>
          campaign.features.some(
            (feature) => feature.feature_id === dialogContext?.feature.feature_id
          )
        )
        .some((campaign) => campaign.coupon_id.toLowerCase() === coupon.toLowerCase());

    // if selected has product_id search only in coupons
    return appStore.couponList.some(
      (campaign) => campaign.coupon_id.toLowerCase() === coupon.toLowerCase()
    );
  }, [appStore.couponList, coupon, dialogContext?.feature.feature_id]);

  const handleCouponChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setCoupon(value);
  };

  // make an object with pricing_id, price and coupon_id
  const featurePricing = useMemo(() => {
    const pricing_id = getPricingId(dialogContext?.feature as IFeatureContentItem);
    const pricing = appStore.pricings?.find((pricing) => pricing.pricing_id === pricing_id);
    return {
      pricing_id: pricing_id,
      price: pricing && pricing.price ? pricing.price.toFixed(2) : "0,00",
      coupon_id: coupon,
      is_valid: isCouponValid,
    };
  }, [dialogContext?.feature, appStore.pricings, coupon, isCouponValid, getPricingId]);

  // handle function for add or remove a feature
  const handleUpdateFeatures = (): void => {
    const variables = {
      type:
        dialogContext?.type === EAppStoreConfirmType.ADD
          ? EAppStoreConfirmType.ADD
          : EAppStoreConfirmType.CANCEL,
      customer_id: currentUser.appUser.customer_id,
      pricing_id: featurePricing.pricing_id,
    } as IUpdateFeaturesVariables;

    // if updating product send product_id, else send feature_id
    if (dialogContext?.feature.product_id) {
      variables.product_id = dialogContext?.feature.product_id;
    } else {
      variables.feature_id = dialogContext?.feature.feature_id;
    }

    // if coupon is valid, add coupon_id to variables
    if (isCouponValid && coupon.length > 0) {
      variables.coupon_id = coupon;
    }

    // patch admin
    updateFeatures({
      variables: variables,
    });

    // send AppStore event
    userPurchaseOrCancel(
      dialogContext?.type === EAppStoreConfirmType.ADD ? true : false,
      dialogContext?.feature.feature_id as string
    );

    // id canceling product change auth status to false
    if (dialogContext?.feature.product_id && dialogContext?.type === EAppStoreConfirmType.CANCEL) {
      history.push(EPrivatePathName.LOGOUT);
      // dispatch({
      //   type: UserStateActionTypes.CURRENT_USER,
      //   payload: {
      //     authenticated: false,
      //   },
      // });
    }
  };

  return (
    <>
      <StyledAppStore.CheckoutCard>
        <StyledAppStore.ContentTitleWrapper>
          {dialogContext?.feature.title_icon && (
            <Icon
              name={dialogContext?.feature.title_icon as IconNameEnums}
              size={IconSizeEnums.MEDIUM}
            />
          )}
          <StyledAppStore.MainTitle>
            <Trans components={{ sup: <sup /> }}>{dialogContext?.feature.title} </Trans>
          </StyledAppStore.MainTitle>
        </StyledAppStore.ContentTitleWrapper>
        {dialogContext?.type === EAppStoreConfirmType.ADD ? (
          <StyledAppStore.SectionTextWrapper>
            <Trans
              i18nKey="quick/app_store:confirm_dialog.add_feature"
              values={{
                title:
                  dialogContext?.feature.title === "CIRCYOULAR<sup>®</sup>"
                    ? "CIRYOULAR"
                    : dialogContext?.feature.title,
              }}
              // components={{ sup: <sup /> }}
            >
              <a
                rel="noreferrer"
                href="https://canei.ag/allgemeine-geschaeftsbedingungen/"
                target="_blank"
              >
                AGB
              </a>

              <a
                rel="noreferrer"
                href="https://canei.ag/allgemeine-geschaeftsbedingungen/"
                target="_blank"
              >
                AGB
              </a>
              <a rel="noreferrer" href="https://canei.ag/impressum/" target="_blank">
                impressum
              </a>
            </Trans>
          </StyledAppStore.SectionTextWrapper>
        ) : dialogContext?.feature.product_id ? (
          <StyledAppStore.SectionTextWrapper>
            <Trans i18nKey="quick/app_store:confirm_dialog.remove_quick_01">
              <a
                rel="noreferrer"
                href={`mailto:sales@canei.ag?subject=${t(
                  `quick/app_store:confirm_dialog.mail_subject`
                )}`}
                target="_blank"
              >
                mailto
              </a>
            </Trans>
            <Trans i18nKey="quick/app_store:confirm_dialog.remove_quick_02" />
          </StyledAppStore.SectionTextWrapper>
        ) : (
          <StyledAppStore.SectionTextWrapper>
            <Trans i18nKey="quick/app_store:confirm_dialog.remove_feature_01" />
            <Trans i18nKey="quick/app_store:confirm_dialog.remove_feature_02">
              <a
                rel="noreferrer"
                href={`mailto:sales@canei.ag?subject=${t(
                  `quick/app_store:confirm_dialog.mail_subject`
                )}`}
                target="_blank"
              >
                mailto
              </a>
            </Trans>
          </StyledAppStore.SectionTextWrapper>
        )}
        <StyledAppStore.SectionWrapper flex={EFlexDirection.COLUMN} height={6}>
          <StyledAppStore.PriceWrapper>
            <StyledAppStore.Price>
              <StyledAppStore.PriceNumber>
                {t(`quick/app_store:price.price_text`)}
                {featurePricing.price &&
                  formatNumber(featurePricing.price, {
                    maximumFractionDigits: 2,
                    style: EFormatNumberStyle.decimal,
                    divideTo: EFormatCurrencyDivision.NONE,
                  })}
                {t(`quick/app_store:price.currency`)}
              </StyledAppStore.PriceNumber>
              <StyledAppStore.PriceText>
                {isMonthlySub() && (
                  <>
                    <Trans i18nKey="quick/app_store:price.per_month" />
                    <br />
                  </>
                )}
                {t(`quick/app_store:price.tax_info`)}
              </StyledAppStore.PriceText>
            </StyledAppStore.Price>
          </StyledAppStore.PriceWrapper>
          {dialogContext?.type === EAppStoreConfirmType.ADD && (
            <StyledAppStore.Entry>
              <Input
                name={"coupon"}
                value={""}
                placeholder={t("quick/common:wizards.registration.customer_data.labels.coupon")}
                branded={true}
                onChange={handleCouponChange}
                disabled={false}
                valid={coupon.length === 0 || isCouponValid}
                invalidWarning={t("error.invalid_coupon")}
              />
            </StyledAppStore.Entry>
          )}
        </StyledAppStore.SectionWrapper>
        <StyledAppStore.SectionWrapper flex={EFlexDirection.COLUMN}>
          <ButtonBranded
            inverted={false}
            type={"button"}
            inline={true}
            variant={ButtonVariantEnums.PRIMARY}
            onClick={(): void => handleUpdateFeatures()}
          >
            {dialogContext?.type === EAppStoreConfirmType.ADD
              ? t(`quick/app_store:buttons.add_long`)
              : t(`quick/app_store:buttons.remove_long`)}
          </ButtonBranded>
          <ButtonBranded
            inverted={true}
            type={"button"}
            inline={true}
            variant={ButtonVariantEnums.SECONDARY}
            onClick={(): void => dialogs.close(IDialogKeyEnums.CONFIRM_PAYMENT)}
          >
            {t(`quick/app_store:buttons.cancel`)}
          </ButtonBranded>
        </StyledAppStore.SectionWrapper>
      </StyledAppStore.CheckoutCard>
    </>
  );
};

interface IUseAddOrRemoveFeatureDialogRetVal {
  open: (type: IAppStoreConfirmProps) => void;
}

export const useConfirmDialog = (): IUseAddOrRemoveFeatureDialogRetVal => {
  const dialogs = useDialogs();
  return {
    open: (type): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.CONFIRM_PAYMENT,
        closable: true,
        content: <Content />,
        flexible: false,
        data: { ...type },
      });
    },
  };
};
