import { FC, useEffect, useState } from "react";
import { StyledSystemMessages } from "./styled.system-messages";
import { useSelector } from "react-redux";
import { ILocalState } from "../../@types/index.d";
import { Alert } from "../../components/Alert";
import { Trans } from "react-i18next";
import { EAutoCloseTimeOut } from "../../_lib/store/reducers";
import { useSystemMessages } from "../../_lib/hooks/useSystemMessages";
import { SlideIn, SlideInDirections } from "../../_lib/Transitions";
import { isEqual } from "lodash";

export const SystemMessages: FC = () => {
  const systemMessages = useSelector(({ systemMessages }: ILocalState) => systemMessages, isEqual);
  const { removeSystemMessage } = useSystemMessages();
  const [shouldBeRemoved, setShouldBeRemoved] = useState<string[]>([]);
  useEffect(() => {
    let mounted = true;
    systemMessages.forEach((msg) => {
      if (
        msg.autoCloseTimeout !== undefined &&
        msg.autoCloseTimeout !== EAutoCloseTimeOut.NO_AUTO_CLOSE
      ) {
        window.setTimeout(() => {
          if (!mounted) return;
          if (!shouldBeRemoved.includes(msg.identifier)) {
            setShouldBeRemoved((s) => [...s, msg.identifier]);
          }
        }, msg.autoCloseTimeout);
      }
    });
    return (): void => {
      mounted = false;
    };
  }, [shouldBeRemoved, systemMessages]);

  useEffect(() => {
    let mounted = true;
    shouldBeRemoved.forEach((item) => {
      mounted &&
        window.setTimeout(() => {
          removeSystemMessage(item);
          setShouldBeRemoved((s) => s.filter((remove) => remove !== item));
        }, 500);
    });
    return (): void => {
      mounted = false;
    };
  }, [removeSystemMessage, shouldBeRemoved]);

  return (
    <StyledSystemMessages.Container>
      {systemMessages.map(({ type, code, identifier }, index) => {
        return (
          <SlideIn
            out={shouldBeRemoved.includes(identifier)}
            direction={SlideInDirections.TO_TOP}
            key={index}
          >
            <Alert type={type}>{<Trans i18nKey={code} />}</Alert>
          </SlideIn>
        );
      })}
    </StyledSystemMessages.Container>
  );
};
