import { getCurrencyFromLanguage, getLocaleFromLanguage } from ".";
export type TFormatCurrency = (value: string | number, options?: IFormatCurrencyOptions) => string;
export interface IFormatCurrencyOptions {
  currency?: string;
  divideTo?: EFormatCurrencyDivision;
}
export enum EFormatCurrencyDivision {
  NONE = 1,
  THOUSAND = 1000,
  MILLION = 1000000,
}

export const formatCurrency: TFormatCurrency = (
  value,
  { currency = getCurrencyFromLanguage(), divideTo = EFormatCurrencyDivision.NONE } = {}
): string => {
  const strValue = value.toString();
  const result = parseFloat(strValue) / divideTo;
  const locale = getLocaleFromLanguage();
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    style: "currency",
    currency,
  }).format(Math.abs(result || 0));
};
