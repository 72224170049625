import { useDispatch, useSelector } from "react-redux";
import {
  ICustomizationListItem,
  IGetCustomizationResponse,
  ILocalState,
} from "../../../@types/index.d";
import { Dispatch } from "@reduxjs/toolkit";
import { CustomizationActionTypes, ICustomizationAction } from "../../../_lib/store/reducers";
import { isEqual, omit } from "lodash";

interface IAccountSelectList {
  accountId: string;
  type: ECustomizationListType;
  name: string;
}

export enum ECustomizationListType {
  CUSTOMER = "customer",
  CLIENT = "client",
}

interface IUseCustomizationHelperRetVal {
  getAccountSelectList: () => IAccountSelectList[];
  getAccountCustomization: () => ICustomizationListItem | undefined;
  changeSelectedAccount: (accountId: string) => void;
  cleanNullOrUndefinedArray: (
    customizationArray: ICustomizationListItem[]
  ) => ICustomizationListItem[];
  cleanNullOrUndefinedObject: (
    customizationObj: IGetCustomizationResponse
  ) => IGetCustomizationResponse;
}

export const useCustomizationHelper = (): IUseCustomizationHelperRetVal => {
  const dispatchCustomization = useDispatch<Dispatch<ICustomizationAction>>();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const customizationList = useSelector(
    ({ customization: { customizationList } }: ILocalState) => customizationList,
    isEqual
  );
  const selectedAccount = useSelector(
    ({ customization: { selectedAccount } }: ILocalState) => selectedAccount,
    isEqual
  );

  // get a list for account select input
  const getAccountSelectList = (): IAccountSelectList[] => {
    const selectItemArray: IAccountSelectList[] = [];
    if (currentUser.appUser.customer) {
      const selectObj = {
        accountId: currentUser.appUser.customer.customer_id,
        type: ECustomizationListType.CUSTOMER,
        name: currentUser.appUser.customer.name,
      };
      selectItemArray.push(selectObj);
    }
    if (currentUser.clients.length) {
      for (const client of currentUser.clients) {
        const selectObj = {
          accountId: client.client_id,
          type: ECustomizationListType.CLIENT,
          name: client.name,
        };
        selectItemArray.push(selectObj);
      }
    }
    return selectItemArray;
  };

  // get customization account type
  const getAccountType = (accountId: string): "client" | "customer" => {
    if (currentUser.appUser.customer_id === accountId) return "customer";
    else return "client";
  };

  // get branded set from customization
  const getAccountCustomization = (): ICustomizationListItem | undefined => {
    const customization = customizationList.find(
      (item) => item.account_id === selectedAccount.accountId
    );
    if (!customization) return;
    return omit(customization, ["assets"]);
  };

  // change selected account in redux
  const changeSelectedAccount = (accountId: string): void => {
    const selectedAccountObj = {
      accountId,
      type: getAccountType(accountId),
    };
    dispatchCustomization({
      type: CustomizationActionTypes.SET_SELECTED_ACCOUNT,
      payload: {
        selectedAccount: selectedAccountObj,
      },
    });
  };

  // function to clean customization metadata array from null values
  const cleanNullOrUndefinedArray = (
    customizationArray: ICustomizationListItem[]
  ): ICustomizationListItem[] => {
    return customizationArray.map((item) =>
      cleanNullOrUndefinedObject(item)
    ) as ICustomizationListItem[];
  };

  // function to clean customization metadata object from null values
  const cleanNullOrUndefinedObject = (
    customizationObj: ICustomizationListItem
  ): ICustomizationListItem => {
    return JSON.parse(
      JSON.stringify(customizationObj, (_key, value) => {
        return value === null ? undefined : value;
      })
    );
  };

  return {
    getAccountSelectList,
    getAccountCustomization,
    changeSelectedAccount,
    cleanNullOrUndefinedArray,
    cleanNullOrUndefinedObject,
  };
};
