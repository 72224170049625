import { DraftClient, DraftClientMissingFields } from "../../@types/index.d";

import { useContext } from "react";
import { ClientSetupContext, ClientSetupDispatch } from "../../views/Private/Setup";

export interface ValidateDraftClientOptions {
  data?: DraftClient;
}
type IUseValidateDraftClient = (options: ValidateDraftClientOptions) => string[];
export const useValidateDraftClient = (): IUseValidateDraftClient => {
  const clientSetupContext = useContext(ClientSetupContext);
  const clientSetupDispatch = useContext(ClientSetupDispatch);

  return ({ data }: ValidateDraftClientOptions): string[] => {
    const missingFields: DraftClientMissingFields[] = [];
    const _draftClient = data || clientSetupContext;
    // Mandatory fields should not be empty
    _draftClient.name.length === 0 && missingFields.push(DraftClientMissingFields.NAME);
    _draftClient.category.length === 0 && missingFields.push(DraftClientMissingFields.CATEGORY);
    _draftClient.fiscal_year.length === 0 &&
      missingFields.push(DraftClientMissingFields.FISCAL_YEAR);
    !_draftClient?.mapping && missingFields.push(DraftClientMissingFields.MAPPING);
    // Update local state with possibly caught errors
    if (!data) {
      clientSetupDispatch({ errors: missingFields });
    }
    return missingFields;
  };
};
