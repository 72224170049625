import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import {
  Client,
  ClientCustomizations,
  Customer,
  CustomerState,
  ISelectedClient,
  IUserInfo,
  SelectClientState,
} from "../../../@types/index.d";
import initialData from "../initialState";

export enum UserStateActionTypes {
  CLEAR_STORE = "CLEAR_STORE",
  CURRENT_USER = "CURRENT_USER",
  CURRENT_USER_STATE = "CURRENT_USER_STATE",
  UPDATE_CLIENTS_LIST = "UPDATE_CLIENTS_LIST",
  SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT",
  SET_SELECTED_CLIENT_UNKNOWN = "SET_SELECTED_CLIENT_UNKNOWN",
  SET_SELECTED_CLIENT_INITIAL = "SET_SELECTED_CLIENT_INITIAL",
  SET_SELECTED_CLIENT_PENDING = "SET_SELECTED_CLIENT_PENDING",
  SET_SELECTED_CLIENT_DRAFT = "SET_SELECTED_CLIENT_DRAFT",
  SET_SELECTED_CLIENT_READY = "SET_SELECTED_CLIENT_READY",
  SET_DRAFT_CLIENT = "SET_DRAFT_CLIENT",
  RESET_DRAFT_CLIENT = "RESET_DRAFT_CLIENT",
  UPDATE_SELECTED_CLIENT_DATA = "UPDATE_SELECTED_CLIENT_DATA",
  UPDATE_KPI_LIST = "UPDATE_KPI_LIST",
  UPDATE_UPLOAD_SETTINGS = "UPDATE_UPLOAD_SETTINGS",
  UPDATE_UPLOADS_LIST = "UPDATE_UPLOADS_LIST",
  UPDATE_EVALUATION_DATA = "UPDATE_EVALUATION_DATA",
  SET_SELECTED_CLIENT_SELECTABLE = "SET_SELECTED_CLIENT_SELECTABLE",
  EVENT_IN_PROGRESS = "EVENT_IN_PROGRESS",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  UPDATE_CLIENT = "UPDATE_CLIENT",
}

// initialize empty functions object ([IPlanningActionTypes]: function())
const actionFunctions = {} as Record<
  UserStateActionTypes,
  ActionCreatorWithPayload<Partial<ICurrentUserActionPayload>, UserStateActionTypes>
>;

// add a function for each action type
for (const key of Object.keys(UserStateActionTypes) as Array<keyof typeof UserStateActionTypes>) {
  actionFunctions[UserStateActionTypes[key]] = createAction(UserStateActionTypes[key]);
}

export interface ICurrentUserActionPayload extends Omit<IUserInfo, "selectedClient"> {
  selectedClient: Partial<ISelectedClient>;
  customization: Partial<ClientCustomizations>;
  customer: Customer;
  client: Client;
}

export interface ICurrentUserAction {
  type: UserStateActionTypes;
  payload: Partial<ICurrentUserActionPayload>;
}

const getSelectedClientData = (state: IUserInfo): Client | undefined => {
  const { clients, selectedClient } = state;
  return clients.find((client) => selectedClient.client_id === client.client_id);
};
const setSelectedClientData = (state: IUserInfo, payload: Client): Client[] => {
  const { clients, selectedClient } = state;
  return clients.map((client) => {
    if (selectedClient.client_id === client.client_id) {
      return {
        ...client,
        ...payload,
      };
    }
    return client;
  });
};

const currentUser = createReducer(initialData.currentUser, (builder) => {
  builder
    .addCase(actionFunctions[UserStateActionTypes.CURRENT_USER], (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        ...action.payload,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.CURRENT_USER_STATE], (state, action) => {
      return { ...state, state: action.payload?.state || CustomerState.UNKNOWN };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_CLIENTS_LIST], (state, action) => {
      return action.payload?.clients
        ? {
            ...state,
            state: CustomerState.READY,
            clients: action.payload?.clients || [],
            appUser: {
              ...state.appUser,
              clients: action.payload?.clients,
            },
          }
        : state;
    })
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_UNKNOWN], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...initialData.currentUser.selectedClient,
          state: SelectClientState.UNKNOWN,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_DRAFT], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.DRAFT,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_PENDING], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
          state: SelectClientState.PENDING,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_INITIAL], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.INITIAL,
        },
      };
    })
    .addCase(
      actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_SELECTABLE],
      (state, action) => {
        return {
          ...state,
          selectedClient: {
            ...state.selectedClient,
            state: SelectClientState.SELECTABLE,
          },
        };
      }
    )
    .addCase(actionFunctions[UserStateActionTypes.SET_SELECTED_CLIENT_READY], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.READY,
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_SELECTED_CLIENT_DATA], (state, action) => {
      const newClient = (action.payload?.clients || [])[0];
      if (newClient === undefined) {
        return state;
      }

      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          kpis: newClient.customization.dashboard_kpis || [],
        },
        clients: state.clients.map((client) =>
          client.client_id !== newClient.client_id ? client : newClient
        ),
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_KPI_LIST], (state, action) => {
      const client = getSelectedClientData(state);
      if (client === undefined) return state;
      return {
        ...state,
        clients: setSelectedClientData(state, {
          ...client,
          customization: {
            ...client.customization,
            dashboard_kpis: action.payload.customization?.dashboard_kpis || [],
          },
        }),
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_UPLOAD_SETTINGS], (state, action) => {
      const client = getSelectedClientData(state);
      if (client === undefined) return state;
      return {
        ...state,
        clients: setSelectedClientData(state, {
          ...client,
          customization: {
            ...client.customization,
            upload_settings: action.payload.customization?.upload_settings,
          },
        }),
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_EVALUATION_DATA], (state, action) => {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          evaluation: {
            ...state.selectedClient.evaluation,
            ...action.payload.selectedClient?.evaluation,
          },
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_UPLOADS_LIST], (state, action) => {
      const clientState =
        action.payload.selectedClient?.uploads?.list?.length === 0
          ? SelectClientState.DRAFT
          : state.selectedClient.state;
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: clientState,
          uploads: {
            ...action.payload.selectedClient?.uploads,
          },
        },
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.EVENT_IN_PROGRESS], (state, action) => {
      if (!action.payload) return state;
      const val = action.payload.eventInProgress ? true : false;
      return {
        ...state,
        eventInProgress: val,
      };
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_CUSTOMER], (state, action) => {
      if (!action.payload.customer) return state;
      state.appUser.customer = action.payload.customer;
    })
    .addCase(actionFunctions[UserStateActionTypes.UPDATE_CLIENT], (state, action) => {
      if (!action.payload.client) return state;
      const updateClient = action.payload.client;
      const clientInd = state.clients.findIndex(
        (client) => client.client_id === updateClient.client_id
      );
      state.clients[clientInd] = updateClient;
    })
    .addCase(actionFunctions[UserStateActionTypes.CLEAR_STORE], (state, action) => {
      return initialData.currentUser;
    });
});

export default currentUser;
