import { ISortType } from "../hooks";
export type ICompareTwoDates = (
  first: string | number,
  second: string | number,
  sort: ISortType
) => -1 | 0 | 1;

/**
 * Helper function for Array.prototype.sort for sorting dates returns -1,0,1 according to defined sort type
 *
 * @param first date ISO string or timestamp to be compared to
 *
 * @param second second date ISO string or timestamp to be compared to
 *
 * @param sort Type of sort
 *
 * @returns number -1 or 0 or 1
 */

export const compareTwoDates: ICompareTwoDates = (first, second, sort) => {
  const [A, B] = [new Date(first).getTime(), new Date(second).getTime()];
  if (A === B) return 0;
  if (sort === ISortType.ASC) {
    return A < B ? -1 : 1;
  }
  if (sort === ISortType.DESC) {
    return A < B ? 1 : -1;
  }
  return 0;
};
