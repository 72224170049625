import { gql } from "@apollo/client/core";
import { useApolloClient } from "@apollo/client";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  EMappingsubPage,
  EPrivatePathName,
  EUploadsPage,
  IEnrichedMappedSusaData,
  ILocalState,
  IMappedSusaData,
} from "../../../../@types/index.d";
import { useMapping } from "../../../../_lib/hooks/actuals/useMapping";
import { IUploadsAction, IUploadsActionTypes } from "../../../../_lib/store/reducers";
import { cloneDeep, isEqual } from "lodash";
import { ISortMappingFields } from "./sortReducer";
import { ISortType } from "../../../../_lib/hooks";
import _ from "lodash";

const GET_UPLOADS_DS = gql`
  {
    result: getUploadsDS {
      accountCodes {
        account_code_id
        summary
      }
      globalCodes {
        value
        summary
      }
    }
  }
`;

export interface IUseUploadsDS {
  result: {
    accountCodes: [IAccountCodesTree];
    globalCodes: [IGlobalCodesTree];
  };
}

export interface IAccountCodesTree {
  account_code_id: string;
  summary: string;
}

export interface IGlobalCodesTree {
  value: string;
  summary: string;
}

interface IUseUploadsHelperRetVal {
  switchUploadsPage: (page: EUploadsPage, susa_id?: string) => void;
  enrichMappingWithDescriptions: (
    susaData: IMappedSusaData[],
    subPage?: EMappingsubPage
  ) => IEnrichedMappedSusaData[];
  sortSusaData: (
    susaData: IEnrichedMappedSusaData[],
    sortInfo: ISortMappingFields
  ) => IEnrichedMappedSusaData[];
  fillUploadsDataStore: () => void;
}

export const useUploadsHelper = (): IUseUploadsHelperRetVal => {
  const { getMappingForSusa } = useMapping();
  const dispatchUploads = useDispatch<Dispatch<IUploadsAction>>();
  const apolloClient = useApolloClient();
  const dataStore = useSelector(({ uploads: { dataStore } }: ILocalState) => dataStore, isEqual);
  const history = useHistory();

  // function to switch uploads page by sending susa_id for mapping or blank for list
  const switchUploadsPage = (page: EUploadsPage, susa_id?: string): void => {
    switch (page) {
      case EUploadsPage.LIST:
        // nuke uploads context
        dispatchUploads({
          type: IUploadsActionTypes.NUKE_UPLOADS_CONTEXT,
        });
        // change url
        history.push(`${EPrivatePathName.UPLOAD}`);
        break;
      case EUploadsPage.MAPPING:
        susa_id && getMappingForSusa(susa_id);
        // dispatch the susa id to redux
        susa_id &&
          dispatchUploads({
            type: IUploadsActionTypes.SET_SELECTED_SUSA,
            payload: {
              selectedSusa: susa_id,
            },
          });
        // change url
        history.push(`${EPrivatePathName.UPLOAD}/${susa_id}`);
        break;
      default:
        break;
    }
    if (susa_id) {
      getMappingForSusa(susa_id);
      // dispatch the susa id to redux
      dispatchUploads({
        type: IUploadsActionTypes.SET_SELECTED_SUSA,
        payload: {
          selectedSusa: susa_id,
        },
      });
      // change url
      history.push(`${EPrivatePathName.UPLOAD}/${susa_id}`);
    } else {
      // nuke uploads context
      dispatchUploads({
        type: IUploadsActionTypes.NUKE_UPLOADS_CONTEXT,
      });
      // change url
      history.push(`${EPrivatePathName.UPLOAD}`);
    }
  };

  // function to add descriptions to mapped susa object
  const enrichMappingWithDescriptions = (
    susaData: IMappedSusaData[],
    subPage?: EMappingsubPage
  ): IEnrichedMappedSusaData[] => {
    // clone the data
    let enrichedSusaData = cloneDeep(susaData) as unknown as IEnrichedMappedSusaData[];

    // remove duplicates of global_code_id and get sums for each if needed
    if (subPage === EMappingsubPage.CALCULATED)
      enrichedSusaData = _(enrichedSusaData)
        // reduce to unique onjects by global_code_id
        .groupBy("global_code_id")
        // get sums for balance and starting_balance
        .map((objs, key) => ({
          global_code_id: key,
          balance: _.sumBy(objs, "balance"),
          starting_balance: _.sumBy(objs, "starting_balance"),
        }))
        .value() as IEnrichedMappedSusaData[];

    // add descriptions
    enrichedSusaData.forEach((code) => {
      // get global code summary
      if (dataStore && dataStore.globalCodes)
        code.canei_description =
          dataStore.globalCodes.find((gc) => gc.value === code.global_code_id)?.summary ||
          "no global code summary";
      // get actual code summary if needed
      if (dataStore && dataStore.accountCodes && subPage === EMappingsubPage.ACTUAL)
        code.general_description =
          dataStore.accountCodes.find((ac) => ac.account_code_id === code.account_code_id)
            ?.summary || "no account code summary";
    });

    return enrichedSusaData;
  };

  // function to sort the data according to sort selected
  const sortSusaData = (
    susaData: IEnrichedMappedSusaData[],
    sortInfo: ISortMappingFields
  ): IEnrichedMappedSusaData[] => {
    // define sort field and sort type
    let sort = { field: "account_code", type: ISortType.ASC };
    if (sortInfo.ACCOUNT_CODE !== ISortType.NOSORT)
      sort = { field: "account_code", type: sortInfo.ACCOUNT_CODE };
    else if (sortInfo.CLIENT_ACCOUNT_CODE !== ISortType.NOSORT)
      sort = { field: "account_code_complete", type: sortInfo.CLIENT_ACCOUNT_CODE };
    else if (sortInfo.CLIENT_DESCRIPTION !== ISortType.NOSORT)
      sort = { field: "description", type: sortInfo.CLIENT_DESCRIPTION };
    else if (sortInfo.GENERAL_DESCRIPTION !== ISortType.NOSORT)
      sort = { field: "general_description", type: sortInfo.GENERAL_DESCRIPTION };
    else if (sortInfo.CANEI_DESCRIPTION !== ISortType.NOSORT)
      sort = { field: "canei_description", type: sortInfo.CANEI_DESCRIPTION };
    else if (sortInfo.BALANCE !== ISortType.NOSORT)
      sort = { field: "balance", type: sortInfo.BALANCE };
    else if (sortInfo.STARTING_BALANCE !== ISortType.NOSORT)
      sort = { field: "starting_balance", type: sortInfo.STARTING_BALANCE };

    // sort the data
    return susaData.sort((a, b) => {
      switch (sort.type) {
        case ISortType.ASC:
          return a[sort.field as keyof typeof a] >= b[sort.field as keyof typeof b] ? 1 : -1;
        case ISortType.DESC:
          return b[sort.field as keyof typeof b] >= a[sort.field as keyof typeof a] ? 1 : -1;
        default:
          return a["account_code"] - b["account_code"];
      }
    });
  };

  const fillUploadsDataStore = (): void => {
    apolloClient
      .query<IUseUploadsDS>({
        query: GET_UPLOADS_DS,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        if (data && data.result)
          dispatchUploads({
            type: IUploadsActionTypes.SET_DATA_STORE,
            payload: {
              dataStore: { ...data.result },
            },
          });
      });
  };

  return {
    switchUploadsPage,
    enrichMappingWithDescriptions,
    sortSusaData,
    fillUploadsDataStore,
  };
};

// ------------------- OLD FUNCTIONS ------------------- //
