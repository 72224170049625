import styled, { css, keyframes } from "styled-components/macro";
import { EFileUploadState } from "../index";

interface IItemWrapper {
  dragging: boolean;
  selected?: boolean;
  state: EFileUploadState | undefined;
}

const Wrapper = styled.div(() => {
  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-basis: 100%;
  `;
});
const InnerWrapper = styled.div(({ theme }) => {
  return css`
    width: 100%;
    min-width: ${theme.rem(10)};
    max-width: ${theme.rem(12)};
  `;
});
const ItemWrapper = styled.div<IItemWrapper>(({ theme, dragging, selected = false, state }) => {
  const { greys, primary, validation } = theme.values.colors.global;
  const successColor = validation.second;
  const loading = state === EFileUploadState.UPLOADING;
  const loaded = state === EFileUploadState.UPLOADED;
  const loadingAnimation = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .25;
    }
    100% {
      opacity: 1;
    }
  `;
  const draggingCss = css`
    box-shadow: 0 0 4px 2px ${primary.first};
  `;
  const loadingCss = css`
    animation: ${loadingAnimation} infinite 1s;
  `;
  const loadedCss = css`
    color: ${successColor};
  `;

  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding: ${theme.rem(1)};
    margin: ${theme.rem(0.5)};
    color: ${selected ? primary.third : greys.third};
    box-shadow: 0 0 4px 2px ${loaded ? successColor : selected ? primary.third : greys.third};
    transition: all 300ms;
    ${loading && loadingCss};
    ${dragging && draggingCss};
    ${loaded && loadedCss};
  `;
});
const FileInput = styled.input(() => {
  return css`
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  `;
});

const FileName = styled.div(({ theme }) => {
  return css`
    display: block;
    max-width: 100%;
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 ${theme.rem(0.5)};
    font-size: ${theme.rem(0.75)};
    font-weight: ${theme.values.fontWeight.bold};
    margin-bottom: ${theme.rem(0.5)};
    text-align: center;
  `;
});

const MonthLabel = styled.div(({ theme }) => {
  const { primary } = theme.values.colors.global;
  return css`
    line-height: 1.5;
    color: ${primary.third};
    text-align: center;
    flex-basis: 100%;
  `;
});
const DeleteWrapper = styled.div(({ theme }) => {
  return css`
    position: absolute;
    right: 0;
    top: 0;
  `;
});

export const StyledMultiUpload = {
  Wrapper,
  InnerWrapper,
  ItemWrapper,
  DeleteWrapper,
  FileInput,
  MonthLabel,
  FileName,
};
