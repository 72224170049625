import { FC, ReactElement } from "react";
export type TDialogData<T> = Record<string, T> | undefined;
export interface IDialogOptions {
  dialogKey: IDialogKeyEnums;
  title?: string;
  closable?: boolean;
  /** Dialog content can be scrollable. If you need to take control of
   * height and scroll behaviour of the content you provide, set this to :`false`*/
  flexible?: boolean;
  onClose?: () => void;
  onSave?: () => void;
  content?: ReactElement | FC;
  footer?: ReactElement | FC;
  data?: TDialogData;
  hidden?: boolean;
}

export enum IDialogKeyEnums {
  SIMPLE = "SIMPLE",
  CLIENT_MANAGEMENT_DIALOG = "CLIENT_MANAGEMENT_DIALOG",
  SELECT_LIST = "SELECT_LIST",
  UPLOAD_SUSA = "UPLOAD_SUSA",
  PRACTICAL_TIPS = "PRACTICAL_TIPS",
  WARNING = "WARNING",
  NO_PLAN_WARNING = "NO_PLAN_WARNING",
  SELECT_KPIS = "SELECT_KPIS",
  EDIT_REVENUE_RATIOS = "EDIT_REVENUE_RATIOS",
  EDIT_PLAN = "EDIT_PLAN",
  CREATE_REPORT = "CREATE_REPORT",
  DELETE_REPORT = "DELETE_REPORT",
  DOWNLOAD_REPORT = "DOWNLOAD_REPORT",
  PLAN_IN_EXCEL = "PLAN_IN_EXCEL",
  APP_STORE = "APP_STORE",
  INVOICE_LIST = "INVOICE_LIST",
  CONFIRM_PAYMENT = "CONFIRM_PAYMENT",
}
