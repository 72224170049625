import { FC, useContext, useEffect } from "react";
import { ISwitchProps, ISwitchSizeEnums, Switch } from "./index";
import { FormDispatch } from "../Form";
import { FormElementContainer, TFormElementContainerProps } from "../FormElementContainer";
import { ECheckboxState } from "../CheckBox";

export interface IOnOffSwitch extends Omit<ISwitchProps, "firstItem" | "secondItem" | "size"> {
  value: ECheckboxState;
  disabled?: boolean;
}
export interface IOnOffSwitch extends TFormElementContainerProps {
  l?: undefined;
}
export const OnOffSwitch: FC<IOnOffSwitch> = ({
  name,
  variant,
  value,
  disabled,
  onChange,
  ...wrapperProps
}) => {
  const formDispatch = useContext(FormDispatch);

  useEffect(() => {
    formDispatch({ [name]: value });
  }, [formDispatch, value, name]);
  const hasLabel = wrapperProps.label !== undefined || wrapperProps.labelPosition !== undefined;
  const pureSwitch = (
    <Switch
      name={name}
      variant={variant}
      size={ISwitchSizeEnums.ON_OFF}
      disabled={disabled}
      firstItem={{
        value: ECheckboxState.OFF,
        isSelected: value === ECheckboxState.OFF,
        text: <></>,
      }}
      secondItem={{
        value: ECheckboxState.ON,
        isSelected: value === ECheckboxState.ON,
        text: <></>,
      }}
      onChange={onChange}
    />
  );

  return hasLabel ? (
    <FormElementContainer {...wrapperProps}>{pureSwitch}</FormElementContainer>
  ) : (
    <>{pureSwitch}</>
  );
};
