// import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

import rootReducer from "./reducers";
import thunk from "redux-thunk";
// import { applyMiddleware, configureStore, createStore } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

// const composeEnhancers = composeWithDevTools({
// options like actionSanitizer, stateSanitizer
// });
// export default configureStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export default configureStore({ middleware: [thunk], reducer: rootReducer });
