import { FC, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { IExpandableMenuItemProps } from "../../../@types/index.d";
import { MenuItem } from "./MenuItem";
import { StyledSubMenu } from "./styled.menu";
import { useDevice } from "../../../_lib/hooks";
import { useTheme } from "styled-components/macro";

export const MenuItemWithSubMenu: FC<IExpandableMenuItemProps> = ({
  children,
  showSubMenu = false,
  ...rest
}) => {
  const [subMenuVisible, setSubMenuVisible] = useState(showSubMenu);
  const handleExpand = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    setSubMenuVisible(!subMenuVisible);
  };
  const subMeuRef = useRef<HTMLUListElement>(null);
  const { isLarge } = useDevice();
  const { sideNavExpanded } = useTheme();
  useLayoutEffect(() => {
    if (isLarge && sideNavExpanded) return;
    const closeOnOuterClick: EventListener = (e: Event): void => {
      if (subMeuRef === null) return;

      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(subMeuRef.current === e.target);

      isOuter && subMenuVisible && setSubMenuVisible(false);
    };

    if (subMenuVisible) {
      window.document
        .querySelector("body")
        ?.addEventListener("mouseup", closeOnOuterClick, { capture: true });
      return (): void => {
        window.document
          .querySelector("body")
          ?.removeEventListener("mouseup", closeOnOuterClick, { capture: true });
      };
    }
  }, [isLarge, sideNavExpanded, subMenuVisible]);
  return (
    <>
      <MenuItem onClick={handleExpand} {...rest} expanded={subMenuVisible}>
        <StyledSubMenu ref={subMeuRef} expanded={subMenuVisible}>
          {children}
        </StyledSubMenu>
      </MenuItem>
    </>
  );
};
