import styled, { css } from "styled-components/macro";
import { IDropDownProps, IOptionProps } from "./index";
import { ButtonVariantEnums } from "../Button";

export const StyledOptionsList = styled.ul<Pick<IDropDownProps, "variant" | "inverted">>(
  ({ theme, variant = ButtonVariantEnums.PRIMARY, inverted }) => {
    const { color } = theme.branded.buttons[variant];
    const basic = css`
      list-style: none;
      margin: 0;
      border: 1px solid ${inverted ? color.base.color : color.base.inverted.color};
      padding: 0;
      position: absolute;
      left: 0;
      top: 100%;
      min-width: 100%;
      z-index: 1;
      max-height: ${theme.rem(20)};
      overflow-y: scroll;
    `;

    return css`
      ${basic}
    `;
  }
);
// TODO: zIndex Should be dynamically calculated to make sure it's always highest

export const StyledOptionsListItem = styled.li<Pick<IDropDownProps, "variant" | "inverted">>(
  ({ theme, variant = ButtonVariantEnums.PRIMARY, inverted }) => {
    const {
      color: { base, hover },
    } = theme.branded.buttons[variant];
    const basic = css`
      cursor: pointer;
      padding: 0;
      color: ${!inverted ? base.inverted.color : base.color};
      background-color: ${!inverted ? base.inverted.background : base.background};

      &:hover {
        color: ${!inverted ? hover.inverted.color : hover.color};
        background-color: ${!inverted ? hover.inverted.background : hover.background};
      }
    `;
    return css`
      ${basic}
    `;
  }
);

export const StyledOptions = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: ${theme.rem(0.5)} ${theme.rem(0.5)};
  `
);

export const StyledOptionText = styled.div<Partial<IOptionProps>>(
  ({ theme, selected }) => css`
    flex-grow: 1;
    padding-right: ${theme.rem(0.5)};
    font-size: ${theme.rem(1)};
    min-width: ${theme.rem(12)};
    font-weight: ${selected ? theme.values.fontWeight.bolder : theme.values.fontWeight.normal};
  `
);
