import { FC, ReactNode } from "react";

import { StyledActionButton } from "./styled.action-button";
import { ButtonVariantEnums } from "../Button";

export interface IActionButtonProps {
  /** React Node to be rendered as button inner text*/
  children?: ReactNode;
  /** Visual type of the button */
  variant: ButtonVariantEnums;
  /** Inverts Color and Background IColor */
  inverted: boolean;
  /** Function to be called on click event */
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  /** type of button element*/
  type: "submit" | "reset" | "button";
  /** disabled state of the button*/
  disabled?: boolean;
  isOpen?: boolean;
  inline?: boolean;
}

export const ActionButton: FC<IActionButtonProps> = ({ children, ...rest }) => {
  return <StyledActionButton {...rest}>{children}</StyledActionButton>;
};
