import { gql, useApolloClient } from "@apollo/client";
import { Customer, ILocalState } from "../../@types";
import { useDispatch, useSelector } from "react-redux";
import { ICurrentUserAction, UserStateActionTypes } from "../store/reducers";
import { isEqual } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";

export interface IGetcustomerReVal {
  result: Customer;
}

const GET_CUSTOMER = gql`
  query getCustomer($id: ID!) {
    result: getCustomer(id: $id) {
      api_access
      address {
        country
      }
      customer_id
      links {
        href
        rel
      }
      name
      ou
      status
      type
    }
  }
`;

interface IUseCustomerRetVal {
  updateCustomer: () => Promise<Customer | undefined>;
}

export const useCustomer = (): IUseCustomerRetVal => {
  const apolloClient = useApolloClient();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const customer_id = useSelector(
    ({
      currentUser: {
        appUser: { customer_id },
      },
    }: ILocalState) => customer_id,
    isEqual
  );

  const updateCustomer = async (): Promise<Customer | undefined> => {
    return apolloClient
      .query<IGetcustomerReVal>({
        query: GET_CUSTOMER,
        variables: {
          id: customer_id,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.result) {
          dispatchCurrentUser({
            type: UserStateActionTypes.UPDATE_CUSTOMER,
            payload: {
              customer: data.result,
            },
          });
          return data.result;
        }
      });
  };

  return {
    updateCustomer,
  };
};
