import { Dispatch, FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../../@types/index.d";
import { EAppStoreMenuItem } from "../useAppStore";
import { useAppStoreHelper } from "../useAppStoreHelper";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { useConfirmDialog } from "../useConfirmDialog";
import { useMutation } from "@apollo/client";
import { useDevice } from "@canei/app-components";
import { EAppStoreConfirmType, EButtonFunction, EButtonText } from "../appStoreTypes.d";
import { GET_PAGE_URL } from "../../../../_lib/hooks/appStore/appStore-queries";
import { EAppStoreActionType, IAppStoreAction } from "../../../../_lib/store/reducers/appStore";
import { EFlexDirection, StyledAppStore } from "../styled.appStore-page";
import CarouselComp from "../../../../containers/Carousel";
import { isEqual } from "lodash";
import { ButtonBranded, ButtonVariantEnums } from "../../../../components/formElements";

export const AppStoreFeature: FC = () => {
  const { t } = useTranslation(["digital/app_store"]);
  const content = useSelector(({ appStore: { content } }: ILocalState) => content, isEqual);
  const selectedMenuItem = useSelector(
    ({ appStore: { selectedMenuItem } }: ILocalState) => selectedMenuItem,
    isEqual
  );
  const pricings = useSelector(({ appStore: { pricings } }: ILocalState) => pricings, isEqual);
  const { handleLocation, getPrice, button, isMonthlySub, expValid } = useAppStoreHelper();
  const { currentUser } = useSelector((state: ILocalState) => state, isEqual);
  const dispatchAppStore = useDispatch<Dispatch<IAppStoreAction>>();
  const { open: handleAddOrRemoveFeatureDialog } = useConfirmDialog();
  const [pageUrlLoading, setPageUrlLoading] = useState(false);
  // send a mutation to get page_url for single use features
  const { isLarge } = useDevice();
  const [getPageUrl] = useMutation(GET_PAGE_URL, {
    onCompleted: (data) => {
      if (data && data.page_return && data.page_return.page_url) {
        const a = document.createElement("a");
        a.href = data.page_return.page_url;
        a.target = "_blank";
        a.click();
        setPageUrlLoading(false);
        // console.log("DATA version one", data);

        // fetch(data.page_return.page_url, {
        //   method: "GET",
        //   redirect: "follow",
        //   headers: data.page_return.page_headers ? data.page_return.page_headers : {},
        // }).then((response) => {
        //   response.json().then((data) => {
        //     // console.log("DATA", data);

        //     setTimeout(function () {
        //       // window.location.href = data.page_return.page_url;
        //       window.location.href = "https://canei.demo.envoria.app/";
        //     }, 333);
        //   });

        //   setPageUrlLoading(false);
        // });
      }
    },
    fetchPolicy: "network-only",
  });

  // const [getPageUrlTwo] = useMutation(GET_PAGE_URL, {
  //   onCompleted: (data) => {
  //     if (data && data.page_return) {
  //       // console.log("DATA version two", data);

  //       fetch(data.page_return.page_url, {
  //         method: "GET",
  //         redirect: "follow",
  //       }).then((response) => {
  //         response.json().then((data) => {
  //           // console.log("DATA", data);
  //         });

  //         setPageUrlLoading(false);
  //       });
  //     }
  //   },
  //   fetchPolicy: "network-only",
  // });

  // const [getPageUrlThree] = useMutation(GET_PAGE_URL, {
  //   onCompleted: (data) => {
  //     if (data && data.page_return) {
  //       setPageUrlLoading(false);
  //       const xhttp = new XMLHttpRequest();
  //       xhttp.open("GET", data.page_return.page_url, true);
  //       data.page_return.page_headers &&
  //         xhttp.setRequestHeader("Authorization", data.page_return.page_headers.Authorization);
  //       xhttp.onreadystatechange = function (): void {
  //         const self = this as unknown as XMLHttpRequest;
  //         // console.log("SELF", self.status, self);
  //         if (self.readyState === 4 && self.status === 200) {
  //           setTimeout(function () {
  //             // window.location.href = data.page_return.page_url;
  //             window.location.href = "https://canei.demo.envoria.app/";
  //           }, 333);
  //         }
  //       };
  //       xhttp.send();
  //     }
  //   },
  //   fetchPolicy: "network-only",
  // });

  // cancel subscription to feature or product
  const cancelFeature = (item: string): void => {
    if (!content) return;
    handleAddOrRemoveFeatureDialog({
      type: EAppStoreConfirmType.CANCEL,
      feature: content[selectedMenuItem],
    });
  };

  // add subscription to feature or product
  const addFeature = (item: string): void => {
    if (!content) return;
    if (!expValid) {
      sessionStorage.setItem("location", selectedMenuItem);
      dispatchAppStore({
        type: EAppStoreActionType.SET_SELECTED_MENU_ITEM,
        payload: {
          selectedMenuItem: EAppStoreMenuItem.PAYMENT,
        },
      });
      return;
    }
    handleAddOrRemoveFeatureDialog({
      type: EAppStoreConfirmType.ADD,
      feature: content[selectedMenuItem],
    });
  };

  // use one time feature product
  const makeUseFeature = (item: string): void => {
    setPageUrlLoading(true);
    const page_type = pricings?.find((pricing) => pricing.feature_id === item)?.page_type;
    const data = {
      customer_id: currentUser.appUser.customer_id,
      page_type,
    };
    getPageUrl({ variables: { data } });
  };

  // const makeUseFeatureTwo = (item: string): void => {
  //   setPageUrlLoading(true);
  //   const page_type = pricings?.find((pricing) => pricing.feature_id === item)?.page_type;
  //   const data = {
  //     customer_id: currentUser.appUser.customer_id,
  //     page_type,
  //   };
  //   getPageUrlTwo({ variables: { data } });
  // };

  // const makeUseFeatureThree = (item: string): void => {
  //   setPageUrlLoading(true);
  //   const page_type = pricings?.find((pricing) => pricing.feature_id === item)?.page_type;
  //   const data = {
  //     customer_id: currentUser.appUser.customer_id,
  //     page_type,
  //   };
  //   getPageUrlThree({ variables: { data } });
  // };

  const handleOnClick = (onClick: EButtonFunction | undefined): void => {
    if (!content) return;
    if (onClick) {
      if (onClick === EButtonFunction.ADD_FEATURE) addFeature(content[selectedMenuItem]);
      if (onClick === EButtonFunction.CANCEL_FEATURE) cancelFeature(content[selectedMenuItem]);
      if (onClick === EButtonFunction.USE_FEATURE) makeUseFeature(selectedMenuItem);
    }
    return;
  };

  // get the primary button settings for product or feature
  const getButton = (): JSX.Element => {
    if (!content) return <></>;
    if (!button) return <></>;

    return (
      <ButtonBranded
        inverted={button.inverted}
        type={"button"}
        inline={true}
        variant={button.variant}
        disabled={button.disabled ? true : false}
        onClick={(): void => handleOnClick(button.onClick)}
      >
        {pageUrlLoading ? (
          <Icon name={IconNameEnums.APP_DOWNLOAD} size={IconSizeEnums.SMALL} loading={true} />
        ) : (
          t(`quick/app_store:buttons.${button.text}`)
        )}
      </ButtonBranded>
    );
  };

  // in case of 3rd party feature get secondary use button
  // const getSecondaryButton = (): JSX.Element => {
  //   if (!content) return <></>;
  //   if (!button) return <></>;
  //   if (!button.thirdParty) return <></>;

  //   return (
  //     <Button
  //       inverted={true}
  //       type={"button"}
  //       inline={true}
  //       variant={ButtonVariantEnums.SECONDARY}
  //       disabled={button.disabled ? true : false}
  //       onClick={(): void => handleOnClick(EButtonFunction.USE_FEATURE)}
  //     >
  //       {pageUrlLoading ? (
  //         <Icon name={IconNameEnums.APP_DOWNLOAD} size={IconSizeEnums.SMALL} loading={true} />
  //       ) : (
  //         t(`quick/app_store:buttons.${EButtonText.USE}`)
  //       )}
  //     </Button>
  //   );
  // };

  // in case of 3rd party feature get secondary use button
  const getUseText = (): JSX.Element => {
    if (!content) return <></>;
    if (!button) return <></>;
    if (!button.thirdParty) return <></>;

    return (
      <>
        <Trans i18nKey="quick/app_store:price.use_text_two">
          <StyledAppStore.TrialTextLink
            onClick={(): void => handleOnClick(EButtonFunction.USE_FEATURE)}
          />
        </Trans>
        {/* <StyledAppStore.TrialTextLink onClick={(): void => makeUseFeatureTwo(selectedMenuItem)}>
          &nbsp; Fetch version 2 &nbsp;
        </StyledAppStore.TrialTextLink>
        <StyledAppStore.TrialTextLink onClick={(): void => makeUseFeatureThree(selectedMenuItem)}>
          &nbsp; xhttp version 1 &nbsp;
        </StyledAppStore.TrialTextLink> */}
      </>
      // <Button
      //   inverted={true}
      //   type={"button"}
      //   inline={true}
      //   variant={ButtonVariantEnums.SECONDARY}
      //   disabled={button.disabled ? true : false}
      //   onClick={(): void => handleOnClick(EButtonFunction.USE_FEATURE)}
      // >
      //   {pageUrlLoading ? (
      //     <Icon name={IconNameEnums.APP_DOWNLOAD} size={IconSizeEnums.SMALL} loading={true} />
      //   ) : (
      //     t(`quick/app_store:buttons.${EButtonText.USE}`)
      //   )}
      // </Button>
    );
  };

  if (!content) return null;

  return (
    <>
      <StyledAppStore.SectionWrapper height={11}>
        <StyledAppStore.SubTitle>
          <Trans components={{ sup: <sup /> }}>{content[selectedMenuItem].section_one_title}</Trans>
        </StyledAppStore.SubTitle>
        <StyledAppStore.SectionText>
          <Trans components={{ sup: <sup /> }}>{content[selectedMenuItem].section_one_text}</Trans>
        </StyledAppStore.SectionText>
      </StyledAppStore.SectionWrapper>
      <StyledAppStore.SubTitle>{getUseText()} </StyledAppStore.SubTitle>

      <StyledAppStore.SectionWrapper flex={isLarge ? EFlexDirection.ROW : EFlexDirection.COLUMN}>
        <StyledAppStore.SectionWrapper width={isLarge ? 40 : 100}>
          <StyledAppStore.SubTitle>
            <Trans components={{ sup: <sup /> }}>
              {content[selectedMenuItem].extra_content.title}
            </Trans>
          </StyledAppStore.SubTitle>
          <StyledAppStore.SectionList>
            {content[selectedMenuItem].extra_content.list.map((item: string) => (
              <StyledAppStore.SectionListItem key={`list-${item}-${Math.random()}`}>
                <Trans components={{ sup: <sup /> }}>{item}</Trans>
              </StyledAppStore.SectionListItem>
            ))}
          </StyledAppStore.SectionList>
        </StyledAppStore.SectionWrapper>
        <StyledAppStore.SectionWrapper width={isLarge ? 55 : 90} shadow={true} margin={4}>
          <CarouselComp autoPlay={false} showArrows={true} showIndicators={true}>
            {content[selectedMenuItem].extra_content.images.map((image: string) => (
              <StyledAppStore.ContentImage
                imageUrl={image}
                key={`image-${selectedMenuItem}-${Math.random()}`}
              />
            ))}
          </CarouselComp>
        </StyledAppStore.SectionWrapper>
      </StyledAppStore.SectionWrapper>
      <StyledAppStore.ContentFooter>
        <StyledAppStore.SectionWrapper flex={EFlexDirection.ROW} width={45}>
          <StyledAppStore.PriceWrapper>
            {!content[selectedMenuItem].sub_feature && (
              <StyledAppStore.Price>
                <StyledAppStore.PriceNumber star={true}>
                  {getPrice(selectedMenuItem)} {t(`quick/app_store:price.currency`)}
                </StyledAppStore.PriceNumber>
                <StyledAppStore.PriceText>
                  {isMonthlySub() && (
                    <>
                      <Trans i18nKey="quick/app_store:price.per_month" />
                      <br />
                    </>
                  )}
                  <Trans i18nKey="quick/app_store:price.tax_info" />
                </StyledAppStore.PriceText>
              </StyledAppStore.Price>
            )}
          </StyledAppStore.PriceWrapper>
          {/* get button from button object */}
          <StyledAppStore.BtnWrapper>
            {!content[selectedMenuItem].sub_feature && (
              <>
                {getButton()}
                {/* to render Monatlich Kündbar for Buchen and Aktivieren buttons */}
                {((button?.text === EButtonText.ADD &&
                  pricings?.find((item) => item.feature_id === selectedMenuItem)?.subscription) ||
                  button?.text === EButtonText.ACTIVATE) && (
                  <StyledAppStore.BtnText>
                    ({t("quick/app_store:buttons.mothly_cancellable")})
                  </StyledAppStore.BtnText>
                )}
              </>
            )}
          </StyledAppStore.BtnWrapper>
          {/* {getSecondaryButton()} */}
        </StyledAppStore.SectionWrapper>
        <ButtonBranded
          inverted={false}
          type={"button"}
          inline={true}
          variant={ButtonVariantEnums.SECONDARY}
          onClick={(): void => handleLocation(EAppStoreMenuItem.OVERVIEW)}
        >
          {t(`quick/app_store:buttons.back_to_overview`)}
        </ButtonBranded>
      </StyledAppStore.ContentFooter>

      {/* display text in content under finePrint key in content */}
      {/* {getFinePrint()} */}
    </>
  );
};
