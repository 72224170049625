import { ButtonVariantEnums } from "../Button";
import styled, { css } from "styled-components/macro";
import { ISwitchItem, ISwitchProps, ISwitchSizeEnums } from "./index";

interface IStyledSwitchItem extends ISwitchItem {
  variant: ButtonVariantEnums | ButtonVariantEnums;
}
export const StyledSwitch = styled.div<Partial<ISwitchProps>>(
  ({ theme, variant = ButtonVariantEnums.SECONDARY, size }) => {
    const normal = css`
      display: flex;
      flex-basis: 100%;
    `;
    const small = css`
      display: flex;
      width: ${theme.rem(3)};
      height: ${theme.rem(1.5)};
    `;
    return css`
      ${size === ISwitchSizeEnums.NORMAL && normal}
      ${size === ISwitchSizeEnums.ON_OFF && small}
      border-radius: ${theme.branded.buttons[variant].radius};
      border: 1px solid ${theme.branded.buttons.primary.color.base.color};
      border: 1px solid ${theme.branded.inputs.default.borderColor};
      overflow: hidden;
    `;
  }
);
export const StyledSwitchItem = styled.div<IStyledSwitchItem>(
  ({ theme, isSelected, variant, size, disabled = false }) => {
    const { color, background } = theme.branded.buttons[variant].color.base;
    const { color: hoverText, background: hoverBackground } = theme.branded.buttons[
      variant
    ].color.hover;
    const {
      color: smallOffText,
      background: smallOffBackground,
    } = theme.branded.buttons.secondary.color.base;

    const selected = css`
      color: ${color};
      background-color: ${background};
      cursor: default;
      &:hover {
        color: ${hoverText};
        background-color: ${hoverBackground};
      }
    `;
    const disabledCss = css`
      cursor: not-allowed;
      opacity: 0.75;
    `;

    const selectedSmall = css`
      ${selected}
      &:last-of-type {
        &:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 2px;
          height: 50%;
          left: 50%;
          background-color: #ffffff;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:first-of-type,
      &:first-of-type:hover {
        ${isSelected && {
          backgroundColor: smallOffBackground,
          color: smallOffText,
        }};
        &:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 30%;
          height: 30%;
          border-radius: 50%;
          left: 50%;
          border: #ffffff 2px solid;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    `;
    const normal = css`
      display: flex;
      padding: ${theme.rem(0.75)};
      ${disabled && disabledCss}
      &:hover {
        background-color: ${theme.branded.buttons.primary.color.base.color};
      }
      ${isSelected && selected}
    `;
    const small = css`
      position: relative;
      font-size: ${theme.rem(0.75)};
      color: #ffffff00;
      ${disabled && disabledCss}
      ${isSelected && selectedSmall}
    `;

    return css`
      ${size === ISwitchSizeEnums.NORMAL && normal}
      ${size === ISwitchSizeEnums.ON_OFF && small}

      justify-content: center;
      align-items: center;
      flex-basis: 50%;
      text-align: center;
      cursor: pointer;
      transition: background-color 300ms;
      ${disabled && disabledCss}
    `;
  }
);
