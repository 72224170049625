import { gql, useApolloClient } from "@apollo/client";
import { Client } from "../../@types";
import { useDispatch } from "react-redux";
import { ICurrentUserAction, UserStateActionTypes } from "../store/reducers";
import { Dispatch } from "@reduxjs/toolkit";
import { clientDataQuery } from "./useClients";

export interface IGetClientReVal {
  result: Client;
}

const GET_CLIENT = gql`
  query getClient($id: ID!) {
    result: getClient(id: $id) {
      ${clientDataQuery}
    }
  }
`;

interface IUseClientRetVal {
  updateClient: (clientId: string) => Promise<Client | undefined>;
}

export const useClient = (): IUseClientRetVal => {
  const apolloClient = useApolloClient();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const updateClient = async (clientId: string): Promise<Client | undefined> => {
    return apolloClient
      .query<IGetClientReVal>({
        query: GET_CLIENT,
        variables: {
          id: clientId,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.result) {
          dispatchCurrentUser({
            type: UserStateActionTypes.UPDATE_CLIENT,
            payload: {
              client: data.result,
            },
          });
          return data.result;
        }
      });
  };

  return {
    updateClient,
  };
};
