import { brandedValues } from "../Theme/defaultThemeValues";
import {
  Country,
  CustomerState,
  CustomerType,
  DraftClientSetup,
  EAssetType,
  EDataSyncLevel,
  EDataSyncRefreshRate,
  EDataSyncScope,
  EPlanningPage,
  ERevenueCurves,
  IAppStoreInfo,
  ICustomizationRedux,
  IDataChangedState,
  ILocalState,
  IPDTablesConfig,
  IPlanCalculated,
  IPlanningDetailsDataStore,
  IPlanTemplates,
  IReportEntryItem,
  LayoutEnums,
  SelectClientState,
} from "../../@types/index.d";
import { SlideInDirections } from "../Transitions";
import { IPDTablesYears } from "../hooks/planning/usePDDataUpdate";
import { EAppStoreMenuItem } from "../../views/Private/AppStore";
import { IEventsState } from "./reducers";

const initialEventSync: IDataChangedState = {
  timeStamp: -1,
  pending: false,
  timeout: false,
  refreshRate: EDataSyncRefreshRate.IDLE,
};
const data: ILocalState = {
  //__typename: TypeNameEnums.INITIAL_DATA,
  currentUser: {
    //__typename: TypeNameEnums.CURRENT_USER,
    authenticating: false,
    username: "",
    email: "",
    temporaryPassword: "",
    authenticated: false,
    forceChange: false,
    errors: [],
    attributes: {
      //__typename: TypeNameEnums.USER_ATTRIBUTES,
      customer_id: "",
      user_id: "",
      name: "",
      sub: "",
    },
    clients: [],
    state: CustomerState.UNKNOWN,
    appUser: {
      //__typename: TypeNameEnums.APP_USER,
      user_id: "",
      customer_id: "",
      alias: "",
      email: "",
      root: false,
      links: [],
      clients: [],
      customer: {
        //__typename: TypeNameEnums.CUSTOMER,
        name: "",
        customer_id: "",
        ou: "",
        links: [],
        address: {
          line_1: null,
          line_2: null,
          postal: null,
          telephone: null,
          city: null,
          country: Country.DEU,
        },
        api_access: false,
        // status: AccountStatus.draft,
        type: CustomerType.direct,
      },
    },
    selectedClient: {
      client_id: null,
      state: SelectClientState.UNKNOWN,
      kpis: [],
    },
    eventInProgress: false,
  },
  appStore: {
    subscriptions: undefined,
    paymentType: undefined,
    features: undefined,
    pricings: undefined,
    customerData: undefined,
    couponList: undefined,
    featuresData: undefined,
    promotionsData: undefined,
    billingAddress: undefined,
    content: undefined,
    selectedMenuItem: "overview" as EAppStoreMenuItem,
    paymentWall: undefined,
    menuSubFeatures: undefined,
  } as IAppStoreInfo,
  planning: {
    plans: undefined,
    selectedPlan: undefined,
    selectedPlanningPage: EPlanningPage.LIST,
    selectedDetailsSubPage: undefined,
    selectedTablesSubPage: undefined,
    selectedDetailsYear: undefined,
    selectedLineId: undefined,
    dataStore: {} as IPlanningDetailsDataStore,
    animationDirection: SlideInDirections.TO_LEFT,
    templates: {} as IPlanTemplates,
    planYears: {} as Record<string, IPlanCalculated[]>,
    PDTablesConfig: {} as IPDTablesConfig,
    PDTablesData: {} as IPDTablesYears,
    cellBeingChanged: undefined,
    futureMonths: false,
    planningLoadingStatus: false,
  },
  reporting: {
    reports: [],
    reportingLoadingStatus: false,
    selectedReport: {} as IReportEntryItem,
    selectedReportId: undefined,
  },
  draftClient: {
    //__typename: TypeNameEnums.DRAFT_CLIENT,
    customer_id: "",
    client_id: null,
    name: "",
    fiscal_year: "",
    category: "",
    category_type: "",
    // mapping: "",
    errors: [],
    state: DraftClientSetup.DATA,
    address: {
      line_1: "",
      line_2: "",
      city: "",
      postal: "",
      telephone: "",
      country: Country.DEU,
    },
    customization: {
      report_color: "#003f71",
      dashboard_kpis: [],
      // logo: "",
      plan_settings: {
        revenue_curve: ERevenueCurves.OF007,
        revenue_growth: 0,
        revenue_ratios: {
          jan: 8.33,
          feb: 8.33,
          mar: 8.34,
          apr: 8.33,
          may: 8.33,
          jun: 8.34,
          jul: 8.33,
          aug: 8.33,
          sep: 8.34,
          oct: 8.33,
          nov: 8.33,
          dec: 8.34,
        },
      },
    },
  },
  errorState: {
    //__typename: TypeNameEnums.AUTH_ERROR,
    errors: [],
  },
  themeOptions: {
    //__typename: TypeNameEnums.THEME_OPTIONS,
    layout: LayoutEnums.SIMPLE,
    branded: {
      //__typename: TypeNameEnums.THEME_OPTIONS_BRANDED,
      colors: {
        ...brandedValues.colors,
      },
      logo: {
        ...brandedValues.logo,
      },
      newLogo: {
        ...brandedValues.newLogo,
      },
      assets: {
        ...brandedValues.assets,
      },
      buttons: {
        ...brandedValues.buttons,
      },
      inputs: {
        ...brandedValues.inputs,
      },
    },
    sideNavExpanded: true,
  },
  dataSync: {
    [EDataSyncLevel.CLIENT]: {
      [EDataSyncScope.CUSTOMER]: initialEventSync,
      [EDataSyncScope.CLIENT]: initialEventSync,
      [EDataSyncScope.SUSA]: initialEventSync,
      [EDataSyncScope.ERROR]: initialEventSync,
      [EDataSyncScope.FILE]: initialEventSync,
      [EDataSyncScope.KPI]: initialEventSync,
      [EDataSyncScope.KPI_PLAN]: initialEventSync,
      [EDataSyncScope.LOGO]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.PLAN]: initialEventSync,
      [EDataSyncScope.SUBSCRIPTION]: initialEventSync,
      [EDataSyncScope.WALLET]: initialEventSync,
      [EDataSyncScope.ALERT]: initialEventSync,
      [EDataSyncScope.PAGE]: initialEventSync,
    },
    [EDataSyncLevel.CUSTOMER]: {
      [EDataSyncScope.CUSTOMER]: initialEventSync,
      [EDataSyncScope.CLIENT]: initialEventSync,
      [EDataSyncScope.SUSA]: initialEventSync,
      [EDataSyncScope.ERROR]: initialEventSync,
      [EDataSyncScope.FILE]: initialEventSync,
      [EDataSyncScope.KPI]: initialEventSync,
      [EDataSyncScope.KPI_PLAN]: initialEventSync,
      [EDataSyncScope.LOGO]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.PLAN]: initialEventSync,
      [EDataSyncScope.SUBSCRIPTION]: initialEventSync,
      [EDataSyncScope.WALLET]: initialEventSync,
      [EDataSyncScope.ALERT]: initialEventSync,
      [EDataSyncScope.PAGE]: initialEventSync,
    },
  },
  warmUp: {
    SUSA_TOPIC: {
      CLIENT: -1,
      CUSTOMER: -1,
      ERROR: -1,
      LOGO: -1,
      FILE: -1,
      SUSA: -1,
      USER: -1,
      KPI: -1,
      EVAL: -1,
    },
    FILE_TOPIC: {
      CLIENT: -1,
      CUSTOMER: -1,
      ERROR: -1,
      LOGO: -1,
      FILE: -1,
      SUSA: -1,
      USER: -1,
      KPI: -1,
      EVAL: -1,
    },
  },
  downloads: [],
  uploads: {
    selectedSusa: undefined,
    mappedSusa: undefined,
    dataStore: undefined,
  },
  systemMessages: [],
  eventsState: {} as IEventsState,
  customization: {
    selectedAsset: EAssetType.APP_LOGO,
  } as ICustomizationRedux,
};
const initialData = {
  ...data,
};
export default initialData;
