import styled, { css } from "styled-components/macro";

export const StyledFilterableList = styled.div(({ theme }) => {
  return css`
    width: ${theme.rem(40)};
    max-width: 100%;
    max-height: 100%;

    position: relative;
  `;
});
export const StyledSearchBar = styled.div(({ theme }) => {
  return css`
    position: fixed;
    display: block;
    width: inherit;
    z-index: 1;
  `;
});
export const StyledSearchResults = styled.div(({ theme }) => {
  return css`
    position: relative;
    display: block;
    width: 100%;
    height: 25vh;
    min-height: ${theme.rem(10)};
    overflow-y: scroll;
    top: ${theme.rem(3)};
    padding-bottom: ${theme.rem(1)};
    margin-bottom: ${theme.rem(3)};
    resize: vertical;
  `;
});
