import { createContext, Dispatch, FC, useContext, useEffect, useReducer } from "react";
import { StyledDialogOverlay, StyledDialogWrapper } from "./styled.dialog";

import { IDialogKeyEnums, IDialogOptions } from "../../@types/index.d";

import { RichDialog } from "./RichDialog";
import { BasicDialog } from "./BasicDialog";

export const DialogContext = createContext<IDialogOptions[]>([]);
export const DialogDispatch = createContext<Dispatch<IDialogDispatch>>(
  (action: IDialogDispatch) => []
);

export const DialogComponent: FC = () => {
  const currentDialogs = useContext(DialogContext);
  const dialogs = useContext(DialogDispatch);
  const richDialogs = [
    IDialogKeyEnums.PRACTICAL_TIPS,
    IDialogKeyEnums.WARNING,
    IDialogKeyEnums.NO_PLAN_WARNING,
    IDialogKeyEnums.DELETE_REPORT,
  ];
  const handleCloseAll = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    dialogs({ type: "closeAll" });
  };
  const allDialogsHidden =
    currentDialogs.length === currentDialogs.filter(({ hidden }) => hidden).length;
  return (
    <>
      {currentDialogs.length === 0 ? (
        <></>
      ) : (
        <>
          <StyledDialogWrapper hidden={allDialogsHidden}>
            <StyledDialogOverlay onClick={handleCloseAll} />
            {currentDialogs.map((dialog: IDialogOptions, key: number) => (
              <div key={key} style={{ display: dialog.hidden ? "none" : "block" }}>
                {richDialogs.includes(dialog.dialogKey) ? (
                  <RichDialog {...dialog} />
                ) : (
                  <BasicDialog {...dialog} />
                )}
              </div>
            ))}
          </StyledDialogWrapper>
        </>
      )}
    </>
  );
};

export interface IDialogDispatch {
  type: "open" | "close" | "hide" | "closeAll" | "save" | "saveAndClose" | "setData";
  dialog?: IDialogOptions;
  dialogKey?: IDialogKeyEnums;
  data?: Partial<Pick<IDialogOptions, "data">>;
  hidden?: boolean;
}

const fnReducer = (state: IDialogOptions[], action: IDialogDispatch): IDialogOptions[] => {
  const selectedDialog = (key: string): IDialogOptions | undefined =>
    state.find((dialog) => dialog.dialogKey === key);
  const removeDialog = (key: string): IDialogOptions[] => {
    const dialogToBeClosed = state.find((dialog) => dialog.dialogKey === key);
    if (dialogToBeClosed && dialogToBeClosed.onClose) {
      dialogToBeClosed.onClose();
    }
    return state.filter((dialog) => dialog.dialogKey !== key);
  };
  switch (action.type) {
    case "open":
      if (action.dialog) {
        const dialogExists = state.find((item) => item.dialogKey === action.dialog?.dialogKey);
        if (dialogExists) {
          return state.map((item) =>
            item.dialogKey === action.dialog?.dialogKey ? { ...item, hidden: false } : item
          );
        } else {
          return [...state, action.dialog];
        }
      }
      return state;

    case "close":
      return action.dialogKey ? removeDialog(action.dialogKey) : state;
    case "hide":
      return state.map((item) =>
        item.dialogKey === action.dialogKey ? { ...item, hidden: action.hidden } : item
      );
    case "closeAll":
      return state.filter((dialog) => {
        if (dialog.closable) {
          removeDialog(dialog.dialogKey);
        }
        return !dialog.closable;
      });
    case "save":
      if (action.dialogKey) {
        const dialog = selectedDialog(action.dialogKey);
        dialog?.onSave && dialog.onSave();
      }
      return state;
    case "saveAndClose":
      if (action.dialogKey) {
        const dialog = selectedDialog(action.dialogKey);
        dialog?.onSave && dialog.onSave();
        return removeDialog(action.dialogKey);
      }

      return state;
    case "setData":
      if (action.data) {
        return state.map((item) =>
          item.dialogKey === action.dialogKey
            ? { ...item, data: { ...item.data, ...action.data?.data } }
            : item
        );
      }
      return state;
    default:
      return state;
  }
};

export const Dialog: FC = ({ children }) => {
  const [dialogs, dispatcher] = useReducer(fnReducer, []);
  const closeOnEsc = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      dispatcher({ type: "closeAll" });
    }
  };
  useEffect(() => {
    document.addEventListener("keyup", closeOnEsc);
    return (): void => {
      document.removeEventListener("keyup", closeOnEsc);
    };
  });

  return (
    <>
      <DialogDispatch.Provider value={dispatcher}>
        <DialogContext.Provider value={dialogs}>
          {children}
          <DialogComponent />
        </DialogContext.Provider>
      </DialogDispatch.Provider>
    </>
  );
};
