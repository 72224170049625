/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,
    kpi: "KPIB40",
    revenue_curve: "OF006",
    revenue_growth: 0,
    poster: "/assets/video_preview.png",
  },
  shared_links: {
    imprint: "https://canei.ag/impressum/",
    terms_url: "https://canei.ag/allgemeine-geschaeftsbedingungen/",
    privacy_url: "https://canei.ag/datenschutzerklaerung/",
    plan_meeting: "https://canei.ag/#contact",
  },
  product_key: "digital",
  product_id: "canei_digital",
  pricing_id: "canei_digital_01",
};

export default config;
