export type TParseDate = (MM_YYYY: string) => TParseDateReturnValue;
export interface TParseDateReturnValue {
  /**
   * index of given month (0-11)
   */
  month: number;
  /**
   * Four digit Year
   */
  year: number;
  /**
   * Date Object from given input
   */
  date: Date;
}
export const parseDate: TParseDate = (MM_YYYY) => {
  const [MM, YYYY] = MM_YYYY.split("/");
  if (MM.length !== 2 || YYYY.length !== 4) {
    throw new Error("String date entry must be in MM/YYY format. ex: 01/2020");
  }
  const month = parseInt(MM) > 0 ? parseInt(MM) - 1 : 0;
  const year = parseInt(YYYY);
  return {
    month,
    year,
    date: new Date(year, month),
  };
};
