import { useEffect } from "react";
import { gql, LazyQueryResult, useLazyQuery } from "@apollo/client";
import { IGetKpisQueryVars, IKpiPreferences, ILocalState } from "../../@types/index.d";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const KPIS_LIST = gql`
  query getKpis($kpiQuery: KpiListInput) {
    kpiPreferences: getAllKPIsOfClient(options: $kpiQuery) {
      client_id
      kpis {
        value
        benchmark
        months {
          actual
          month
          plan
          potential
        }
      }
    }
  }
`;

interface IUseSelectedKpisData {
  kpiPreferences: IKpiPreferences | null;
}
type TUseSelectedKpis = () => LazyQueryResult<IUseSelectedKpisData, IGetKpisQueryVars>;

export const useSelectedKpis: TUseSelectedKpis = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const [getSelectedKpis, queryResult] = useLazyQuery<IUseSelectedKpisData, IGetKpisQueryVars>(
    KPIS_LIST,
    {
      fetchPolicy: "cache-first",
    }
  );

  const { called, loading, refetch, variables } = queryResult;
  useEffect(() => {
    const { kpis, client_id } = currentUser.selectedClient;
    if (kpis === undefined) return;
    if (kpis === null) return;
    if (kpis.length === 0) return;
    if (client_id === null) return;
    if (variables !== undefined) return;
    if (called) return;
    if (loading) return;

    getSelectedKpis({ variables: { kpiQuery: { client_id, filter: kpis, latest: true } } });
  }, [currentUser.selectedClient, variables, called, loading, getSelectedKpis]);

  useEffect(() => {
    const { kpis, client_id } = currentUser.selectedClient;
    if (refetch === undefined) return;
    if (client_id === null) return;
    if (kpis === undefined) return;
    if (kpis === null) return;
    if (kpis.length === 0) return;
    if (loading) return;
    const refetchVars = { kpiQuery: { client_id, filter: kpis, latest: true } };
    if (Object.is(variables, refetchVars)) return;
    refetch(refetchVars);
  }, [currentUser.selectedClient, refetch, loading, variables]);

  return queryResult;
};
