import { useApolloClient } from "@apollo/client";
import {
  EAssetType,
  EDataSyncLevel,
  EDataSyncScope,
  EDataSyncTypes,
  FileUploadFormat,
  Relation,
  RelationLink,
} from "../../../@types/index.d";
import { useRelationLinks } from "../useRelationLinks";

import {
  DELETE_ASSET,
  GET_ASSET_SRC,
  IAssetUploadReVal,
  IGetAssetSrcReVal,
  UPLOAD_ASSET,
} from "./useAssets.queries";
import { useState } from "react";
import { useEventListener } from "../useEventListener";
import { FileData } from "@canei/app-components";

interface IAssetFileInput {
  covid: string;
  name: string;
  content: string;
  date: string;
  size: number;
  type: string;
  lastModified: string;
  links: RelationLink[];
}

interface IAssetUploadParams {
  accountId: string;
  file: FileData;
  assetType: EAssetType;
  covid?: string;
}

interface IAssetDeleteParams {
  accountId: string;
  assetType: EAssetType;
  covid?: string;
}

interface IUseAssetsRetVal {
  uploadAsset: (params: IAssetUploadParams) => void;
  deleteAsset: (params: IAssetDeleteParams) => void;
  getAssetSrc: (link: RelationLink) => Promise<string>;
  assetsLoading: boolean;
}

export const useAssets = (): IUseAssetsRetVal => {
  const apolloClient = useApolloClient();
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const { startEventSync } = useEventListener();
  const { getUploadRelationLinks } = useRelationLinks();

  // get customization metadata from CRM
  const uploadAsset = (params: IAssetUploadParams): void => {
    const { file, accountId, assetType, covid } = params;
    const fileFormat: { [key: string]: FileUploadFormat } = {
      "image/jpeg": FileUploadFormat.JPG,
      "image/png": FileUploadFormat.PNG,
      "image/svg+xml": FileUploadFormat.SVG,
    };

    const fileParams = {
      ...file,
      covid,
      format: fileFormat[file.type],
      date: Date.now().toString(),
      links: getUploadRelationLinks(accountId, assetType),
    } as IAssetFileInput;

    // define if the asset is uploaded for client
    const isClient = !!fileParams.links.find((link) => link.rel === Relation.client);

    setAssetsLoading(true);
    apolloClient
      .mutate<IAssetUploadReVal>({
        mutation: UPLOAD_ASSET,
        variables: {
          file: {
            ...file,
            covid,
            format: fileFormat[file.type],
            date: Date.now().toString(),
            links: getUploadRelationLinks(accountId, assetType),
          } as IAssetFileInput,
          assetType: assetType,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.result) {
          startEventSync({
            level: isClient ? EDataSyncLevel.CLIENT : EDataSyncLevel.CUSTOMER,
            accountId: params.accountId,
            transactionId: data.result.covid,
            scope: isClient ? EDataSyncScope.CLIENT : EDataSyncScope.CUSTOMER,
            type: EDataSyncTypes.UPSERT,
          });
          return data.result;
        }
      })
      .then((res) => {
        setAssetsLoading(false);
        return res;
      });
  };

  // get customization metadata from CRM
  const deleteAsset = (params: IAssetDeleteParams): void => {
    setAssetsLoading(true);
    const { accountId, assetType, covid } = params;
    // generate links
    const links = getUploadRelationLinks(accountId, assetType);
    // define if the asset is uploaded for client
    const isClient = !!links.find((link) => link.rel === Relation.client);
    apolloClient
      .mutate<IAssetUploadReVal>({
        mutation: DELETE_ASSET,
        variables: {
          accountId,
          links,
          assetType,
          covid,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.result) {
          startEventSync({
            level: isClient ? EDataSyncLevel.CLIENT : EDataSyncLevel.CUSTOMER,
            accountId: params.accountId,
            transactionId: data.result.covid,
            scope: isClient ? EDataSyncScope.CLIENT : EDataSyncScope.CUSTOMER,
            type: EDataSyncTypes.UPSERT,
          });
          return data.result;
        }
      })
      .then((res) => {
        setAssetsLoading(false);
        return res;
      });
  };

  // get src for an asset
  const getAssetSrc = async (link: RelationLink): Promise<string> => {
    setAssetsLoading(true);
    const assetHref = link.href;
    return apolloClient
      .query<IGetAssetSrcReVal>({
        query: GET_ASSET_SRC,
        variables: { assetHref },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (data && data.result) {
          return data.result;
        } else return "";
      })
      .then((src) => {
        setAssetsLoading(false);
        return src;
      })
      .catch(() => "");
  };

  return { uploadAsset, deleteAsset, getAssetSrc, assetsLoading };
};
