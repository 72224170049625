import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import store from "./_lib/store";

import "./i18n";

import App from "./App";

import "./index.css";

import awsConfig from "./awsConfig";

import { Amplify } from "aws-amplify";

import { Apollo } from "./_lib/Apollo";
import ErrorBoundary from "./_lib/ErrorBoundry";
import { LoaderTypeEnums, LoadingIndicator } from "./components/LoadingIndicator";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
  },
  Storage: {
    bucket: "reporting-hbb-dev-sb-reports",
    region: awsConfig.cognito.REGION,
  },
  API: {
    endpoints: [
      {
        name: "graphql",
        endpoint: awsConfig.gqlGateway.URL,
        region: awsConfig.gqlGateway.REGION,
      },
      {
        name: "downloads",
        endpoint: awsConfig.gqlGateway.URL,
        region: awsConfig.gqlGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <Apollo>
          <ErrorBoundary>
            <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
              <App />
            </Suspense>
          </ErrorBoundary>
        </Apollo>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
