import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";

const IS_COUPON_VALID = gql`
  query validate($coupon: String!, $product: String!) {
    isValid: isCouponValid(coupon: $coupon, product: $product)
  }
`;
interface ValidateCouponVars {
  coupon: string;
  product: string;
}

interface ValidateCouponResult {
  isValid: boolean;
}

type TUseValidateCoupon = () => TUseValidateCouponRetVal;

type TUseValidateCouponRetVal = (
  coupon: string,
  product: string
) => Promise<ApolloQueryResult<ValidateCouponResult>>;

export const useValidateCoupon: TUseValidateCoupon = () => {
  const apolloClient = useApolloClient();

  return (coupon: string, product: string): Promise<ApolloQueryResult<ValidateCouponResult>> => {
    return apolloClient.query<ValidateCouponResult, ValidateCouponVars>({
      query: IS_COUPON_VALID,
      variables: { coupon, product },
      fetchPolicy: "network-only",
    });
  };
};
