import styled, { css } from "styled-components/macro";

export const StyledLogo = styled.div(({ theme }) => {
  return css`
    max-width: 100%;
    margin-bottom: 40px;
  `;
});

export const StyledLogoImage = styled.img(({ theme }) => {
  return css`
    display: block;
    max-width: 100%;
  `;
});
