import {
  ChangeEvent,
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Panel, PanelBody, PanelFooter, PanelTitle } from "../../containers/Panel";
import { ChartContainer } from "../Charts";
import { ChartRef, ERevenueZoom, RevenueRatiosChart } from "../Charts/RevenueRatiosChart";

import {
  AlignmentEnums,
  ERevenueCurves,
  IDialogKeyEnums,
  InputTypes,
  IRevenuePlan,
  ISelectValue,
  SelectionTypes,
} from "../../@types/index.d";
import { Input, Select, SelectItem, Button, ButtonVariantEnums, FormRow } from "../formElements";

import { formatNumber } from "../../_lib/common";
import { StyledRevenueRatios } from "./styled.revenue-ratios";

import { useDialogContext, useDialogs } from "../../_lib/hooks";
import { IRevenueFormManualEdit, ManualEdit } from "./ManualEdit";

import { useDefaultRevenueRatios } from "./useRevenueRatios";

const CHART_NAME = "revenue_ratios";
const i18nComponentId = "company.revenue_data";
export const i18nKey = {
  sector: `${i18nComponentId}.sector`,
  sectors: `${i18nComponentId}.sectors`,
  headline: `${i18nComponentId}.headline`,
  subline: `${i18nComponentId}.subline`,
  zoom: `${i18nComponentId}.zoom`,
  annual_growth: `${i18nComponentId}.annual_growth`,
  dialog_title: `${i18nComponentId}.enter_manually.dialog_title`,
  enter_manually_cta: `${i18nComponentId}.enter_manually.cta`,
  enter_manually_save: `${i18nComponentId}.enter_manually.save`,
  enter_manually_total: `${i18nComponentId}.enter_manually.total`,
  enter_manually_exceed_error: `${i18nComponentId}.enter_manually.exceed_error`,
};

export interface IRevenueRatiosProps {
  context: IRevenuePlan;
  dispatch: Dispatch<Partial<IRevenuePlan>>;
  onlyGraph?: boolean; // if true, only returns the graph for planning revenue subpage
  valuesForRevLine?: number[];
}
export const RevenueRatios: FC<IRevenueRatiosProps> = ({
  context: revenueContext,
  dispatch: revenueDispatch,
  onlyGraph,
  valuesForRevLine,
}) => {
  const chartRef = useRef<ChartRef>(null);
  const defaultRevenueRatios = useDefaultRevenueRatios();
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const dialogContext = useDialogContext<IRevenueFormManualEdit>(
    IDialogKeyEnums.EDIT_REVENUE_RATIOS,
    {
      chartData: [],
      valid: false,
    }
  );

  const sectors: { value: string; text: string }[] = t(i18nKey.sectors, {
    returnObjects: true,
  });

  const [zoom, setZoom] = useState<ERevenueZoom>(ERevenueZoom.MIN);

  const saveUserChangesInContext = useCallback(
    (chartData: (number | undefined)[]): void => {
      revenueDispatch({
        revenue_ratios: Object.keys(revenueContext.revenue_ratios).reduce((p, c, i) => {
          return { ...p, [c]: chartData[i] };
        }, revenueContext.revenue_ratios),
      });
    },
    [revenueContext, revenueDispatch]
  );
  const handleManualEdit = (): void => {
    const chartData = chartRef.current?.chart.series[0].data.map((point) => point?.y || 0) || [];

    dialogs.open({
      dialogKey: IDialogKeyEnums.EDIT_REVENUE_RATIOS,
      closable: true,
      title: t(i18nKey.dialog_title),
      content: <ManualEdit.Content />,
      footer: <ManualEdit.Footer />,
      flexible: true,
      data: {
        chartData,
        valid: false,
      } as IRevenueFormManualEdit,
    });
  };

  const handleSectorSelectionChange = (e: ISelectValue<ERevenueCurves>): void => {
    if (revenueContext.revenue_curve === e.value) return;
    const ratios =
      defaultRevenueRatios?.defaults &&
      defaultRevenueRatios.defaults.find((d) => d.value === e.value);

    revenueDispatch({
      revenue_curve: e.value,
      revenue_ratios: ratios?.revenue_ratios || revenueContext.revenue_ratios,
    });
  };
  const handleRevenueGrowthChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const revenue_growth = parseFloat(e.target.value);
    if (revenueContext.revenue_growth === revenue_growth) return;
    if (isNaN(revenue_growth) || revenue_growth < 0) {
      revenueDispatch({
        revenue_growth: 0,
      });
      return;
    }
    revenueDispatch({
      revenue_growth,
    });
  };

  const selectedSector = useMemo(() => {
    return sectors.find(({ value }) => value === revenueContext.revenue_curve);
  }, [revenueContext.revenue_curve, sectors]);

  useEffect(() => {
    if (dialogContext?.editComplete === true && Array.isArray(dialogContext.editedData)) {
      saveUserChangesInContext(dialogContext.editedData);

      dialogs.close(IDialogKeyEnums.EDIT_REVENUE_RATIOS);
    }
  }, [dialogContext, dialogs, saveUserChangesInContext]);
  useEffect(() => {
    const currentChart =
      chartRef.current && chartRef.current.container.current
        ? chartRef.current.container.current
        : undefined;
    const fixedChartData = (unFixedData: number[]): number[] => {
      const sum = unFixedData.reduce(
        (a, b) => parseFloat(a.toString()) + parseFloat(b.toString()),
        0
      );
      const diff = sum - 100;

      if (diff > 0) {
        const i = unFixedData.indexOf(Math.max(...unFixedData));
        unFixedData[i] = parseFloat((unFixedData[i] - diff).toFixed(2));
      }
      if (diff < 0) {
        const i = unFixedData.indexOf(Math.min(...unFixedData));
        unFixedData[i] = parseFloat((unFixedData[i] + diff).toFixed(2));
      }
      return unFixedData;
    };
    const listener = (): void => {
      setTimeout(() => {
        const chartData =
          chartRef.current?.chart.series[0].data.map((point) =>
            parseFloat((point.y || 0).toFixed(2))
          ) || [];

        saveUserChangesInContext(fixedChartData(chartData));
      }, 500);
    };
    currentChart && currentChart.addEventListener("mouseup", listener);
    return (): void => {
      currentChart && currentChart.removeEventListener("mouseup", listener);
    };
  }, [saveUserChangesInContext]);

  return onlyGraph ? (
    <>
      <StyledRevenueRatios.ZoomItemsWrapper>
        <StyledRevenueRatios.ZoomList>
          <StyledRevenueRatios.ZoomListItem>{t(i18nKey.zoom)}</StyledRevenueRatios.ZoomListItem>
        </StyledRevenueRatios.ZoomList>

        {[ERevenueZoom.MIN, ERevenueZoom.MED, ERevenueZoom.MAX].map((value, index) => {
          return (
            <StyledRevenueRatios.ZoomListItem
              key={value}
              zoom={value}
              onClick={(): void => {
                setZoom(value);
              }}
              selected={zoom === value}
            >
              {index + 1}
            </StyledRevenueRatios.ZoomListItem>
          );
        })}
      </StyledRevenueRatios.ZoomItemsWrapper>
      <StyledRevenueRatios.ChartContainerForIncome>
        <RevenueRatiosChart
          data={valuesForRevLine as number[]}
          sector=""
          name={CHART_NAME}
          zoom={zoom}
          forwardRef={chartRef}
          fixed={onlyGraph}
        />
      </StyledRevenueRatios.ChartContainerForIncome>
    </>
  ) : (
    <Panel>
      <PanelTitle headline={t(i18nKey.headline)} subline={t(i18nKey.subline)} help="0" />
      <PanelBody>
        <FormRow align={AlignmentEnums.LEFT}>
          <Input
            name={"growth"}
            value={`${revenueContext.revenue_growth}`}
            placeholder={formatNumber(0.0)}
            label={t(i18nKey.annual_growth)}
            type={InputTypes.NUMBER}
            required={true}
            min={0}
            valid={
              revenueContext.revenue_growth !== undefined && revenueContext.revenue_growth >= 0
            }
            invalidWarning={t("misc.required_field")}
            onInput={handleRevenueGrowthChange}
          />
        </FormRow>
        <FormRow align={AlignmentEnums.LEFT}>
          <Select
            text={selectedSector?.text || ""}
            type={SelectionTypes.LIST}
            name={"sector_select"}
            value={selectedSector?.value || revenueContext.revenue_curve}
            label={t(i18nKey.sector)}
            key={selectedSector?.value || "1"}
            onChange={handleSectorSelectionChange}
          >
            {sectors.map(({ value, text }) => {
              return (
                <SelectItem
                  value={value}
                  text={text}
                  key={value}
                  selected={value === revenueContext.revenue_curve}
                />
              );
            })}
          </Select>
        </FormRow>
        <FormRow align={AlignmentEnums.RIGHT}>
          <StyledRevenueRatios.ZoomList>
            <StyledRevenueRatios.ZoomListItem>{t(i18nKey.zoom)}</StyledRevenueRatios.ZoomListItem>
          </StyledRevenueRatios.ZoomList>

          {[ERevenueZoom.MIN, ERevenueZoom.MED, ERevenueZoom.MAX].map((value, index) => {
            return (
              <StyledRevenueRatios.ZoomListItem
                key={value}
                zoom={value}
                onClick={(): void => {
                  setZoom(value);
                }}
                selected={zoom === value}
              >
                {index + 1}
              </StyledRevenueRatios.ZoomListItem>
            );
          })}
        </FormRow>
        <ChartContainer>
          <RevenueRatiosChart
            data={Object.values(revenueContext.revenue_ratios)}
            sector={sectors.find(({ value }) => value === revenueContext.revenue_curve)?.text || ""}
            name={CHART_NAME}
            zoom={zoom}
            forwardRef={chartRef}
          />
        </ChartContainer>
      </PanelBody>
      <PanelFooter>
        <FormRow align={AlignmentEnums.CENTER}>
          <Button
            variant={ButtonVariantEnums.SECONDARY}
            inverted={false}
            type={"button"}
            inline={true}
            onClick={handleManualEdit}
          >
            {t(i18nKey.enter_manually_cta)}
          </Button>
        </FormRow>
      </PanelFooter>
    </Panel>
  );
};
