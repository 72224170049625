import { AlertTypeEnums } from "../../../@types";

export interface IAppStoreMenuItems {
  value: EAppStoreMenuItem;
}

export interface IEditQuickCompanyData {
  menu: EAppStoreMenuItem;
}

export enum ETriState {
  INITIAL,
  TRUE,
  FALSE,
}

export interface IFeatureContentItem {
  label: string;
  product_id: string;
  feature_id: string;
  title: string;
  title_icon: string;
  section_one_title: string;
  section_one_text: string;
  extra_content: IExtraContent;
  selected: boolean;
  disabled: boolean;
  sub_feature?: boolean;
  price: string | undefined;
  finePrint: Record<string, string | null | number | undefined>;
  button: Record<string, string | boolean>;
}

export interface IUpdateFeaturesVariables {
  type: string;
  feature_id?: string;
  product_id?: string;
  pricing_id: string;
  customer_id: string;
  coupon_id?: string;
}

export interface IExtraContent {
  title: string;
  images: string[];
  list: string[];
}

export interface IFeatureMenuItemProps {
  label: string;
  // onClick?: (e: MouseEvent<HTMLElement>) => void;
  handleSelected?: (e: MouseEvent<HTMLElement>) => void;
  selected?: boolean;
}

export interface IAppStoreContext {
  getFeaturesData?: IFeaturesData;
  location?: EAppStoreMenuItem;
  state?: EAppStoreState;
  closable?: boolean;
  showSubFeatures?: boolean;
}

export interface IFeaturesData {
  getFeaturesData: IFeaturesDataProps;
}

export interface IFeaturesDataProps {
  featuresTree: IFeatureTree[];
  components: IComponents[];
  bundleTree: IFeatureTree[];
}

export interface IComponents {
  value: string;
  key: string;
  type: string;
}

export interface IFeatureTree {
  value: string;
  tree: IFeatureTreeItem[];
}

export interface IFeatureTreeItem {
  value: string;
}

export interface IAppStoreProps {
  location?: EAppStoreMenuItem;
  closable?: boolean;
  state?: AlertTypeEnums;
}

export interface IAppStoreConfirmProps {
  type: EAppStoreConfirmType;
  feature: IFeatureContentItem;
}

export interface IGetFeatureCouponsListVar {
  product_id: string;
  feature_id: string;
}

export interface IGetFeatureCouponsReturn {
  getCouponFeature: IGetFeatureCouponsList;
}

export interface IGetFeatureCouponsList {
  campaigns: ICampaign[];
}

export interface ICampaign {
  coupon_id: string;
  trial_days: number;
  features: IFeatureCoupon[];
}

export interface IFeatureCoupon {
  feature_id?: string;
  pricing_id?: string;
  price?: number;
}

export enum EAppStoreConfirmType {
  CANCEL = "CANCEL",
  ADD = "ADD",
}

export enum EFinePrint {
  ACTIVATE_TEXT = "activate_text",
  TRIAL_TEXT = "trial_text",
  USE_TEXT = "use_text",
}

export enum EButtonText {
  ACTIVATE = "activate",
  ADD = "add",
  REMOVE = "remove",
  INCLUDED = "included",
  USE = "use",
}

export enum EButtonFunction {
  ADD_FEATURE = "addFeature",
  CANCEL_FEATURE = "cancelFeature",
  USE_FEATURE = "useFeature",
}
