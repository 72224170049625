import { FC } from "react";
import { StyledTableCell } from "./styled.table";
export interface CellProps {
  colSpan?: number;
  align?: "left" | "center" | "right";
  highlight?: boolean;
  editable?: boolean;
  level?: "title" | "upper" | "subTitle" | "normal";
  animate?: boolean;
  width?: string;
  borderRight?: boolean;
  noPadding?: boolean;
}

export const Cell: FC<CellProps> = ({ children, ...rest }) => {
  return <StyledTableCell {...rest}> {children}</StyledTableCell>;
};
