import styled, { css } from "styled-components/macro";
import { useResponsive } from "../../_lib/hooks/useResponsive";

export const StyledSideNav = styled.section(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        ${theme.sideNavExpanded
          ? css`
              width: ${theme.rem(6)};
              padding: ${theme.rem(0.75)} ${theme.rem(0.5)};
            `
          : css`
              display: none;
            `};
        transition: all 300ms;
        grid-area: side-nav;
        background-color: ${theme.branded.colors.nav_background_color};
        color: ${theme.branded.colors.nav_text_color};
        position: realtive;
        z-index: 10;
      `,
    },
    LARGE: {
      style: css`
        display: block;
        ${theme.sideNavExpanded
          ? css`
              width: ${theme.rem(16)};
              padding: ${theme.rem(1.5)} ${theme.rem(1)};
            `
          : css`
              width: ${theme.rem(6)};
              padding: ${theme.rem(0.75)} ${theme.rem(0.5)};
            `};
      `,
    },
  });
});
