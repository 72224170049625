type IIsValueInRange = (
  startValue: number,
  endValue: number,
  givenValue: string | undefined
) => boolean;

// TO CHECK WHETHER THE GIVEN VALUE IS BETWEEN SPECIFIED VALUES

export const isValueInRange: IIsValueInRange = (startValue, endValue, givenValue) => {
  const parsedGivenValue =
    typeof givenValue === "string" ? parseFloat(givenValue.replace(",", ".")) : givenValue;

  if (!parsedGivenValue && parsedGivenValue !== 0) return false;
  return parsedGivenValue >= startValue && parsedGivenValue <= endValue;
};
