import { gql, useMutation } from "@apollo/client";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../@types";

const PAGE_VISIT = gql`
  mutation userVisitPageEvent($user_id: ID!, $page_id: String) {
    userVisitPageEvent(user_id: $user_id, page_id: $page_id)
  }
`;

const LINK_VISIT = gql`
  mutation userVisitLinkEvent($user_id: ID!, $link_url: String) {
    userVisitLinkEvent(user_id: $user_id, link_url: $link_url)
  }
`;

const USER_LOGIN = gql`
  mutation appActivity($user_id: ID!) {
    userLoginEvent(user_id: $user_id)
  }
`;

const USER_SUSA_UPLOAD_EVENT = gql`
  mutation userUploadEvent($user_id: ID!, $file_id: String) {
    userUploadEvent(user_id: $user_id, file_id: $file_id)
  }
`;

const VIDEO_EVENT = gql`
  mutation userPlayVideoEvent($user_id: ID!, $video_id: String) {
    userPlayVideoEvent(user_id: $user_id, video_id: $video_id)
  }
`;

const USER_APP_STORE_EVENT = gql`
  mutation userAppStoreEvent($user_id: ID!, $buyOrCancel: Boolean!, $feature_id: String) {
    userAppStoreEvent(user_id: $user_id, buyOrCancel: $buyOrCancel, feature_id: $feature_id)
  }
`;

const USER_REPORT_EVENT = gql`
  mutation userReportEvent($user_id: ID!, $report_type: String!) {
    userReportEvent(user_id: $user_id, report_type: $report_type)
  }
`;

export interface IUseAppActivity {
  userVisitPage: (page_id: string) => void;
  userVisitLink: (link_url: string) => void;
  userLoginActivity: (user_id: string) => void;
  userUploadSusa: (file_id: string) => void;
  userPlayVideo: (video_id: string) => void;
  userPurchaseOrCancel: (buyOrCancel: boolean, feature_id?: string) => void;
  userDownloadReport: (report_type: string) => void;
}

export const useAppActivity = (): IUseAppActivity => {
  const [userVisitPageEvent] = useMutation(PAGE_VISIT, { fetchPolicy: "no-cache" });
  const [userVisitLinkEvent] = useMutation(LINK_VISIT, { fetchPolicy: "no-cache" });
  const [userLoginEvent] = useMutation(USER_LOGIN, { fetchPolicy: "no-cache" });
  const [userUploadEvent] = useMutation(USER_SUSA_UPLOAD_EVENT, { fetchPolicy: "no-cache" });
  const [userPlayVideoEvent] = useMutation(VIDEO_EVENT, { fetchPolicy: "no-cache" });
  const [userAppStoreEvent] = useMutation(USER_APP_STORE_EVENT, { fetchPolicy: "no-cache" });
  const [userReportEvent] = useMutation(USER_REPORT_EVENT, {
    fetchPolicy: "no-cache",
  });
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  // used for page visit events (add the page_id to config if it is missing and call the function with the page id)
  const userVisitPage = (page_id: string): void => {
    userVisitPageEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        page_id: page_id,
      },
    });
  };

  // used for link visit events (call the function with the link url)
  const userVisitLink = (link_url: string): void => {
    userVisitLinkEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        link_url: link_url,
      },
    });
  };

  // used for user login events (call the function)
  const userLoginActivity = (user_id: string): void => {
    userLoginEvent({
      variables: {
        user_id: user_id,
      },
    });
  };

  // used for user upload events (call the function with the file id)
  const userUploadSusa = (file_id: string): void => {
    userUploadEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        file_id: file_id,
      },
    });
  };

  // used for user video events (call the function with the video id)
  const userPlayVideo = (video_id: string): void => {
    userPlayVideoEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        video_id: video_id,
      },
    });
  };

  // used for user app store events (call the function with the buyOrCancel and feature_id)
  const userPurchaseOrCancel = (buyOrCancel: boolean, feature_id?: string): void => {
    userAppStoreEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        buyOrCancel: buyOrCancel,
        feature_id: feature_id,
      },
    });
  };

  // used for user app store events (call the function with the buyOrCancel and feature_id)
  const userDownloadReport = (report_type: string): void => {
    userReportEvent({
      variables: {
        user_id: currentUser.appUser.user_id,
        report_type: report_type,
      },
    });
  };

  return {
    userVisitPage,
    userVisitLink,
    userLoginActivity,
    userUploadSusa,
    userPlayVideo,
    userPurchaseOrCancel,
    userDownloadReport,
  };
};
